import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { TextField, Chip, styled } from '@mui/material';
import { ArrayItem } from './styles';

const StyledTextField = styled(TextField)`
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const ArrayField = ({ field, value, onChange }) => {
  const [input, setInput] = useState('');

  const deleteItem = (itemToDelete) => {
    onChange(field, value.filter((item) => item !== itemToDelete));
  };

  const AddItem = () => {
    if (value.some((item) => item === input)) {
      toast.error(`${input} already exists in ${field} array`);
      return;
    }
    onChange(field, [...value, input]);
    setInput('');
  };

  return (
    <>
      <ArrayItem>
        {value.map((item) => (
          <Chip
            key={item}
            label={item}
            variant="outlined"
            onDelete={() => deleteItem(item)} />
        ))}
      </ArrayItem>
      <StyledTextField
        id="article-tags"
        variant="outlined"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => (e.key === 'Enter' ? AddItem() : null)}
        size="small"
      />
    </>
  );
};

export default ArrayField;