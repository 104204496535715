import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    INITIATE_AUTH,
    VERIFY_AUTH,
    API_ENDPOINT,
    PROCESS_OTP,
} from "../constants/Endpoint";
toast.configure();

/****************************************************************** */
export const verifyCode = async (email, session, answer) => {
    try {
        const url = `${VERIFY_AUTH}`;
        const response = await axios.post(
            url,
            {
                email: email,
                session: session,
                answer: answer
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                },
                timeout: 30000
            }
        );
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/*************************************************** */

export const signIn = async (email, org_id) => {
    try {
        const url = `${INITIATE_AUTH}`;
        const response = await axios.post(
            url,
            { email: email, org_id: org_id },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/*********************************************** */

/************************ Send and verify OTP to unauthenticated Users mail **************************/

export const processOtp = async (email, operation, otp) => {
    try {
        const response = await axios.post(`${PROCESS_OTP}`,
            {
                email_address: email,
                operation: operation,
                otp: Number(otp) || 0
            },
            {
                headers: {
                    "x-api-key": process.env.REACT_APP_API_KEY,
                },
                timeout: 30000
            });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/************************************ Toggle OTP Validation *************************************/
export const toggleOtpValidationApi = async (orgId, isValidateEndUserActive) => {
    const url = `${API_ENDPOINT}/toggle-otp`;
    const headers = {
        'x-api-key': process.env.REACT_APP_API_KEY,
    };
    const data = {
        id: orgId,
        isValidateEndUserActive: isValidateEndUserActive
    };
    try {
        const response = await axios.post(url, data, { headers });
        return response;
    } catch (error) {
        console.error(error);
        return { status: "failed", message: error }
    }
};
