import React, { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import uuid from 'react-uuid';
import { TableData, TableHeader } from './styles';
import {
  TextField,
  Button,
  styled
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';


// Styles:
const StyledTextField = styled(TextField)`
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const Synonyms = ({ articleData, autosaveFn, changeArticle, buttonTitle, title }) => {
  const DEFAULT_INPUT = () => ({ id: uuid(), key: '', description: '' });
  const [inputs, setInputs] = useState([]);
  const [count, setCount] = useState(0);

  useDeepCompareEffect(() => {
    if (articleData?.meta?.synonyms) {
      setInputs(articleData?.meta?.synonyms);
    } else {
      setInputs([]);
    }
  }, [articleData]);

  return (
    <>
    {count <= 2 && (
        <Button 
          size="small"
          variant="contained"
          startIcon={<AddOutlinedIcon />}
          sx={{ textTransform: 'none', marginBottom: '10px', marginRight: '10px' }}
          onClick={() => {
            setInputs([...inputs, DEFAULT_INPUT()]);
            setCount(count + 1);
          }}
        >
          {buttonTitle}
        </Button>
      )}
      { count >0 &&
        <table
        style={{
          width: '100%',
          textAlign: 'left',
          borderCollapse: 'collapse',
        }}
      >
        <thead style={{ borderBottom: '0.5rem solid transparent' }}>
          <tr>
            <TableHeader>Key</TableHeader>
            <TableHeader>{title}</TableHeader>
          </tr>
        </thead>
        <tbody>
          {inputs.map((input, i) => (
            <tr key={`tr-${input.id}`}>
              <TableData style={{ width: '5rem' }}>
                <StyledTextField 
                  id="article-key" 
                  variant="outlined" 
                  placeholder="Key"
                  value={input.key}
                  onChange={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const newInputs = [...inputs];
                    newInputs[i] = {
                      ...input,
                      key: event.currentTarget.value
                    };
                    setInputs(newInputs);
                    const newArticle = {
                      ...articleData,
                      meta: {
                        ...articleData.meta,
                        synonyms: newInputs
                      }
                    };
                    changeArticle(newArticle);
                    autosaveFn(newArticle);
                  }}
                  size="small"
                />
              </TableData>
              <TableData>
                <StyledTextField 
                  id="article-synonyms" 
                  variant="outlined" 
                  placeholder={title}
                  value={input.description}
                  onChange={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    const newInputs = [...inputs];
                    newInputs[i] = {
                      ...input,
                      description: event.currentTarget.value
                    };
                    setInputs(newInputs);
                    const newArticle = {
                      ...articleData,
                      meta: {
                        ...articleData.meta,
                        synonyms: newInputs
                      }
                    };
                    changeArticle(newArticle);
                    autosaveFn(newArticle);
                  }}
                  size="small"
                  fullWidth
                />
              </TableData>
              <TableData>
                <Button 
                  size="small"
                  color="error"
                  variant="contained"
                  startIcon={<RemoveOutlinedIcon />}
                  sx={{ textTransform: 'none', marginLeft: '10px' }}
                  onClick={() => {
                    setInputs([...inputs].slice(0, -1));
                    setCount(count - 1);
                    const newArticle = {
                      ...articleData,
                      meta: {
                        ...articleData.meta,
                        synonyms: [...inputs].slice(0, -1)
                      }
                    };
                    changeArticle(newArticle);
                    autosaveFn(newArticle);
                  }}
              >
                  Remove Field
              </Button>
              </TableData>
            </tr>
          ))}
        </tbody>
      </table>}
      
    </>
  );
};

export default Synonyms;
