import React, { createContext, useContext,memo } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ROUTES from '../constants/routes.js';

toast.configure();
const AuthContext = createContext();
export const AuthProvider =memo( ({ children }) => {
  //const [username, setUser] = useState(null)
  const navigate = useNavigate();
  /*
    var cognitoUser;

    var poolData = { UserPoolId : 'us-east-1_XKsX4Z1Ts',
        ClientId : '6r9b4ggraidl3g7s97me629dvm'
    };
    var userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
    */

  const getCurrentUser = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // console.log(user);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const login = async (username, password, changeLoading,redirectPath) => {
    // console.log(Auth);
    Auth.signIn(username, password, changeLoading)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          //const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
          const newPassword = prompt('New Password Required');
          Auth.completeNewPassword(
            user, // the Cognito User Object
            newPassword
          )
            .then((user) => {
              // at this time the user is logged in if no MFA required
              navigate(redirectPath,{replace:true});
              changeLoading();
              window.location.reload(true);
            })
            .catch((e) => {
              toast.error(e.message);
              navigate(ROUTES.AGENT.LOGIN,{state:{path:redirectPath}});
              changeLoading();
            });
        } else {
            navigate(redirectPath,{replace:true});
            changeLoading();
          window.location.reload(true);
        }
      })
      .catch((e) => {
        toast.error(e.message);
        changeLoading();
        navigate(ROUTES.AGENT.LOGIN,{state:{path:redirectPath}});
      });
    /*var authenticationData = {
            Username : username,
            Password : password,
        };
        var authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);

        var userData = {
            Username : username,
            Pool : userPool
        };
        cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                //var accessToken = result.getAccessToken().getJwtToken();
                //var idToken = result.idToken.jwtToken;
                //var refreshToken = result.refreshToken.token
                //console.log(result)
                window.location.reload(true)
                navigate(ROUTES.INDEX);
                
            },
            newPasswordRequired: function(userAttributes, requiredAttributes) {
                const newPassword=prompt("New Password Required");
                delete userAttributes.email_verified;
                cognitoUser.completeNewPasswordChallenge(
                    newPassword,
                    userAttributes,
                    this
                    );
            },
    
            onFailure: function(err) {
                toast.error(err.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    });
                navigate(ROUTES.AGENT.LOGIN);
            }

           
            
    
    });*/
  };
  const sendCode = (username) => {
    Auth.forgotPassword(username)
      .then((data) => {
        //console.log(data)
        toast.success('Code sent successfully');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const changePassword = (oldpassword, newpassword) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldpassword, newpassword);
      })
      .then((data) => {
        toast.success('Password changed successfuly');
        
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const validatePassword = (username, code, new_password) => {
    Auth.forgotPasswordSubmit(username, code, new_password)
      .then((data) => {
        toast.success('Password changed successfully');
        navigate(ROUTES.AGENT.LOGIN);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const logout = () => {
    signOut();
  };
  async function signOut() {
    try {
      await Auth.signOut();
      window.location.reload(true);
      navigate('/');
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        validatePassword,
        sendCode,
        changePassword,
        getCurrentUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

export const useAuth =() => {
  return useContext(AuthContext);
};
