// Packages:
import React, { useMemo, useState, useCallback } from 'react';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  usePagination
} from 'react-table';
import { CSVLink } from 'react-csv';
import { 
  TextField, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Tooltip,
  Button
} from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';


import { getContentActions } from '../../redux/actions/contentActions';

// Imports:
import { ColumnFilter } from './ColumnFilter';
import { GlobalFilter } from './GlobalFilter';
import {
  Wrapper,
  Container,
  AgentsTable,
  Header,
  Body,
  Title,
  Filters,
  Heading
} from './styles';

// Components:
import Pagination from '../../components/Records/Pagination';
import { useEffect } from 'react';
import { changeChatPreferenceApi } from '../../api';
toast.configure();

// Styles:
const StyledDialog = styled(Dialog)`
  font-family: 'Inter', sans-serif;
  & .MuiTypography-root {
    font-family: 'Inter', sans-serif;
  }
  & .MuiFormControl-root {
    margin-bottom: 1rem;
  }
  & .MuiPaper-root {
    padding: 1.5rem;
  }
`;

const StyledFormControl = styled(FormControl)`
  background: #ffffff;
  color: #333333;
  width: 150px;
  & .MuiFormLabel-root {
    font-size: 14px;
  }
  & .MuiOutlinedInput-root {
    font-size: 14px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
    font-size: 14px;
`;

// Constants
export const COLUMNS = [
  {
    Header: 'Email',
    accessor: 'user_id',
    Filter: ColumnFilter
  },
  {
    Header: 'First Name',
    accessor: 'first_name',
    Filter: ColumnFilter
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
    Filter: ColumnFilter
  },
  {
    Header: 'Role',
    accessor: 'agent_type',
    Filter: ColumnFilter
  },
  {
    Header: 'Slack Id',
    accessor: 'slack_id',
    Filter: ColumnFilter
  },
  {
    Header: 'Actions',
    disableSortBy: true
  }
];

// Functions:
const Agents = () => {
  // Constants:
  const dispatch = useDispatch();
  const organisation_info = useSelector((state) => state.content.org_info);
  const orgid = organisation_info?.org_data?._id;
  const userpoolid = organisation_info?.org_data?.cognito?.userpool_id;
  const contentActions = getContentActions(dispatch);
  const isContentLoading = useSelector((state) => state.content.loading);
  const [isLoading, setIsLoading] = useState(isContentLoading);
  const columns = useMemo(() => COLUMNS, []);
  const agentsRecords = useSelector((state) => state.content.agents);
  const [agentData, setAgentData] = useState(agentsRecords);
  const data = useMemo(() => agentData, [agentData]);
  const [open, setOpen] = useState(false);
  const [hideInfo, setHideInfo] = useState(true);
  const [editData, setEditData] = useState({});
  const agent_type_info = useSelector((state) => state.content.agent_type);
  const [disableEmail, setDisableEmail] = useState(true);
  const [accountActionType, setAccountActionType] = useState('new');
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [createEnduser, setCreateEnduser] = useState(false);
  const [dialogTitle, setdialogTitle] = useState('');
  const [channel, setChannel] = useState('');

  let newData = {
    user_id: '',
    first_name: '',
    last_name: '',
    agent_type: '',
    slack_id: '',
    personas: ''
  };

  useEffect(() => {
    setAgentData(agentsRecords);
  }, [agentsRecords]);

  useEffect(() => {
    if (editData.user_id !== '' && editData.agent_type !== '') {
      setShowSaveButton(true);
    } else {
      setShowSaveButton(false);
    }
  }, [editData.user_id, editData.agent_type]);

  useEffect(() => {
    setIsLoading(isContentLoading);
  }, [isContentLoading]);

  useEffect(() => {
    contentActions.fetchAgents(orgid);
  }, [orgid]);

  const tableInstance = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    pageCount,
    prepareRow
  } = tableInstance;
  const { globalFilter, pageIndex } = state;

  const handleAgentDelete = (row) => {
    //console.log(row.user_id);
    if (agent_type_info !== 'superadmin' && row.agent_type === 'superadmin') {
      toast.error('You cannot delete superadmin');
    } else {
      contentActions.handleAgentOperations(
        'delete',
        orgid,
        row?.user_id?.toLowerCase(),
        '',
        '',
        '',
        '',
        '',
        userpoolid
      );
    }
  };

  const handleClickOpen = (row, user) => {
    if (user === 'new') {
      setdialogTitle('Create User Account');
      setCreateEnduser(false);
      setAccountActionType('new');
      setDisableEmail(false);
    } else if (user === 'edit') {
      setdialogTitle('Edit User Details');
      if (agent_type_info !== 'superadmin' && row.agent_type === 'superadmin') {
        setCreateEnduser(true);
        setShowSaveButton(false);
      } else setCreateEnduser(false);
      setAccountActionType('edit');
      setDisableEmail(true);
    } else {
      setdialogTitle('Create Enduser Account');
      setAccountActionType('new');
      newData.agent_type = 'endUser';
      setDisableEmail(false);
      setCreateEnduser(true);
    }
    setOpen(true);
    setEditData(row);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChannelChange = useCallback(
    (event) => setChannel(event.target.value),
    []
  );

  const changeChatPreference = useCallback(async () => {
    const slackPreference = {
      orgid,
      chatMedium: channel
    };
    await changeChatPreferenceApi(slackPreference);
  }, [channel, orgid]);

  const formatPersonas = (value) => {
    const personaArray = value.split(',');
    const trimmedValues = personaArray.map((val) => val.trim());
    const filteredValues = trimmedValues.filter((val) => val?.length > 0);
    return filteredValues.join(',');
  }

  const handleSave = (editData) => {
    if (
      !String(editData.user_id)
        ?.toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      toast.error('Invalid Email');
    } else {
      if (createEnduser) {
        contentActions.createEnduserAccountAction(editData?.user_id?.toLowerCase());
        setOpen(false);
      } else {
        if (
          agent_type_info !== 'superadmin' &&
          editData.agent_type === 'superadmin'
        ) {
          toast.error('You cannot create superadmin');
        } else {
          contentActions.handleAgentOperations(
            accountActionType,
            orgid,
            editData.user_id?.toLowerCase(),
            editData.first_name,
            editData.last_name,
            editData.agent_type,
            editData.slack_id,
            formatPersonas(editData.personas ?? ''),
            userpoolid
          );
          setOpen(false);
        }
      }
    }
  };

  const handleActivation_Deactivation = (row, action) => {
    if (agent_type_info !== 'superadmin' && row.agent_type === 'superadmin') {
      toast.error(`You cannot ${action} superadmin`);
    } else {
      contentActions.handleAgentOperations(
        action,
        orgid,
        row?.user_id?.toLowerCase(),
        '',
        '',
        '',
        '',
        '',
        userpoolid
      );
    }
  };

  const handleChange = (ev, type) => {
    let newEditData = JSON.parse(JSON.stringify(editData));
    newEditData[type] = ev.target.value;
    setEditData(newEditData);
  };

  // Return:
  return (
    <Wrapper>
      <StyledDialog open={open} onClose={handleClose} sx={{ p: '1rem' }}>
        <DialogTitle sx={{ mb: '2.5rem' }}>{dialogTitle}</DialogTitle>
        <DialogContent>
          <TextField
            disabled={disableEmail}
            autoFocus
            id="user_id"
            label="Email"
            required
            type="email"
            fullWidth
            variant="standard"
            value={editData.user_id ? editData.user_id : ''}
            onChange={(ev) => handleChange(ev, 'user_id')}
          />
          <TextField
            disabled={createEnduser}
            id="first_name"
            label="First Name"
            type="text"
            fullWidth
            variant="standard"
            value={editData.first_name ? editData.first_name : ''}
            onChange={(ev) => handleChange(ev, 'first_name')}
          />
          <TextField
            disabled={createEnduser}
            id="last_name"
            label="Last Name"
            type="text"
            fullWidth
            variant="standard"
            value={editData.last_name ? editData.last_name : ''}
            onChange={(ev) => handleChange(ev, 'last_name')}
          />
          <FormControl required variant="standard" fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              disabled={createEnduser}
              id="agent_type"
              label="Role*"
              value={editData.agent_type ? editData.agent_type : ''}
              onChange={(ev) => handleChange(ev, 'agent_type')}
            >
              <MenuItem value="endUser" key="endUser">
                End User
              </MenuItem>
              <MenuItem value="agent" key="agent">
                Agent
              </MenuItem>
              <MenuItem value="supervisor" key="supervisor">
                Supervisor
              </MenuItem>
              <MenuItem value="superadmin" key="superadmin">
                Superadmin
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            disabled={createEnduser}
            id="slack_id"
            label="Slack Id"
            type="text"
            fullWidth
            variant="standard"
            value={editData.slack_id ? editData.slack_id : ''}
            onChange={(ev) => handleChange(ev, 'slack_id')}
          />
          <TextField
            disabled={createEnduser}
            id="personas"
            label="Personas"
            type="text"
            fullWidth
            variant="standard"
            value={editData.personas ?? ''}
            placeholder='persona_1,persona_2'
            onChange={(ev) => handleChange(ev, 'personas')}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            size="medium"
            startIcon={<CancelIcon />}
            sx={{ textTransform: 'none' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          {showSaveButton && (
            <Button
              variant="contained"
              size="medium"
              startIcon={<SaveIcon />}
              sx={{ textTransform: 'none' }}
              color="success"
              onClick={() => handleSave(editData)}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </StyledDialog>

      <Container>
        <Heading>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {hideInfo ? (
            <Tooltip title="Show Details">
              <IconButton color="success" onClick={() => setHideInfo(false)} >
                <VisibilityOffOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Hide Details">
              <IconButton color="error" onClick={() => setHideInfo(true)} >
                <VisibilityOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="New Agent">
            <IconButton color="primary" onClick={() => handleClickOpen(newData, 'new')} >
              <PersonAddOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="New End User">
            <IconButton color="primary" onClick={() => handleClickOpen(newData, 'enduser')} >
              <PersonAddOutlinedIcon />
            </IconButton>
          </Tooltip>
          <StyledFormControl size="small">
            <InputLabel>Channel</InputLabel>
            <Select
              value={channel}
              label="Channel"
              input={<OutlinedInput label="Channel" />}
              onChange={(event) => handleChannelChange(event)}
            >
              <StyledMenuItem value="slack" key="slack">
                Slack
              </StyledMenuItem>
              <StyledMenuItem value="fasttrack" key="fasttrack">
                FastTrack
              </StyledMenuItem>
            </Select>
          </StyledFormControl>
          <Tooltip title="Save">
            <IconButton color="success" onClick={() => changeChatPreference()} >
              <SaveOutlinedIcon />
            </IconButton>
          </Tooltip>
          {data !== null && (
          <CSVLink
            filename={'agents.csv'}
            data={data.map((row) => {
              let data = {
                email: row.user_id,
                first_name: row.first_name,
                last_name: row.last_name,
                role: row.agent_type,
                slack_id: row.slack_id
              };
              return data;
            })}
          >
            <Tooltip title="Export Data">
              <IconButton color="primary" >
                <FileUploadOutlinedIcon />
              </IconButton>
          </Tooltip>
          </CSVLink>
        )}
        </Heading>
        <AgentsTable {...getTableProps()}>
          {isLoading ? (
            <thead style={{ marginTop: '15rem' }}>
              <tr>
                <th>
                  <CircularProgress />
                </th>
              </tr>
            </thead>
          ) : (
            <React.Fragment>
              <Header>
                {headerGroups.map((headerGroup) => (
                  <Title {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' ⬇️'
                              : ' ⬆️'
                            : ''}
                        </span>
                      </th>
                    ))}
                  </Title>
                ))}
                {headerGroups.map((headerGroup) => (
                  <Filters {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, i) => (
                      <th key={i} {...column.getHeaderProps()}>
                        <div>
                          {column.canFilter
                            ? column.Header !== 'Actions'
                              ? column.render('Filter')
                              : null
                            : null}
                        </div>
                      </th>
                    ))}
                  </Filters>
                ))}
              </Header>
              <Body {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        if (cell.column.Header === 'Actions') {
                          return (
                            <td {...cell.getCellProps()}>
                              <div>
                                <Tooltip title="Edit Details">
                                  <IconButton
                                    color="success"
                                    aria-label="edit"
                                    onClick={() =>
                                      handleClickOpen(row.original, 'edit')
                                    }
                                  >
                                    <EditOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                                {cell.row.original.account_status ===
                                'enabled' ? (
                                  <Tooltip title="Disable Agent">
                                    <IconButton
                                      color="warning"
                                      sx={{ ml: '1rem' }}
                                      aria-label="deactivate"
                                      onClick={() =>
                                        handleActivation_Deactivation(
                                          row.original,
                                          'disable'
                                        )
                                      }
                                    >
                                      <LockOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Enable Agent">
                                    <IconButton
                                      color="success"
                                      sx={{ ml: '1rem' }}
                                      aria-label="activate"
                                      onClick={() =>
                                        handleActivation_Deactivation(
                                          row.original,
                                          'enable'
                                        )
                                      }
                                    >
                                      <KeyOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title="Delete Agent">
                                  <IconButton
                                    color="error"
                                    sx={{ ml: '1rem' }}
                                    aria-label="delete"
                                    onClick={() =>
                                      handleAgentDelete(row.original)
                                    }
                                  >
                                    <DeleteOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </td>
                          );
                        } else if (cell.column.Header === 'Email') {
                          return (
                            <td {...cell.getCellProps()}>
                              {hideInfo ? (
                                <>
                                  ••{row.original.user_id.slice(2, 5).trim()}•••
                                  {row.original.user_id.slice(8, 11).trim()}••
                                </>
                              ) : (
                                <>{row.original.user_id}</>
                              )}
                            </td>
                          );
                        } else {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </Body>
            </React.Fragment>
          )}
        </AgentsTable>

        <Pagination
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          previousPage={previousPage}
          nextPage={nextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageCount={pageCount}
        />
      </Container>
    </Wrapper>
  );
};

// Exports:
export default Agents;
