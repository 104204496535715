import { IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import {
  MdOutlineThumbDown,
  MdOutlineThumbUp,
  MdThumbDown,
  MdThumbUp
} from 'react-icons/md';
import { ThreeDots } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { ActionStyle, FeedbackMessage, Footer } from './styles';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ROUTES from '../../../constants/routes';
import { Link } from 'react-router-dom';
import { createDraftFromArticle } from '../../../lib/articles/publishedOperations';
import { createDraft } from '../../../lib/articles/draftsOperations';
import {
  ARTICLE_IS_DRAFT,
  ARTICLE_IS_PUBLISHED,
  ARTICLE_IS_UNDER_REVIEW
} from '../../../constants/articles';

const FooterComponent = ({
  changeIsEdit,
  changeArticle,
  saveArticle,
  isEdit,
  articleData,
  deleteArticle,
  addReaction,
  handleOpenModal,
  handleModalProps,
  publishingArticle,
  handleShowOptions
}) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const agentType = useSelector((state) => state.content.agent_type);
  const [likeStatus, setLikeStatus] = useState(0);
  const navigate = useNavigate();

  const handleEdit = async () => {
    switch (articleData?.meta?.status) {
      case ARTICLE_IS_PUBLISHED:
        const draft = createDraftFromArticle(articleData, userDetails?.email);
        
        try {
          const response = await createDraft(draft, {
            user: userDetails?.email,
            role: agentType
          });
          const query = `articleId=${response.articleId}&status=${response.meta.status}`;
          navigate(`${ROUTES.KNOWLEDGE_ARTICLE_V2}?${query}`, {
            state: {
              initialArticleData: response,
              isEdit: true
            }
          });
        } catch (error) {
          console.error(error);
          toast.error("Failed to create draft from article");
        }

        return;
      case ARTICLE_IS_DRAFT:
        changeIsEdit(true);
        break;
      case ARTICLE_IS_UNDER_REVIEW:
        // don't allow this edit
        toast.error("Review/withdraw submission before editing");
        break;
      default:
        toast.error("Missing or malformed article data");
    }
  };

  return publishingArticle ? (
    <Footer>
      <ThreeDots color="grey" height={50} width={50} ariaLabel="three-dots" />
    </Footer>
  ) : (
    <Footer>
      {isLoggedIn ? (
        <>
          <div>
            {isEdit ? (
              <>
                <Tooltip title="Cancel">
                  <IconButton
                    color="error"
                    onClick={() => {
                      if (!publishingArticle) {
                        changeArticle(articleData);
                        changeIsEdit(false);
                      }
                      handleShowOptions(false)
                    }}
                  >
                    <ClearOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Options">
                  <IconButton
                    color="primary"
                    onClick={() => handleShowOptions()}
                  >
                    <SettingsOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Save Article">
                  <IconButton
                    color="success"
                    onClick={() => [!publishingArticle && saveArticle(), handleShowOptions(false)]}
                  >
                    <SaveOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title="Edit Article">
                  <IconButton
                    sx={{ color: '#3F9820' }}
                    onClick={() => {
                      handleOpenModal();
                      handleModalProps({
                        actionButton: 'Edit',
                        message: 'Are you sure you want to edit this article?',
                        title: 'Edit Article',
                        actionFn: () => handleEdit()
                      });
                    }}
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete Article">
                  <IconButton
                    sx={{ color: '#C71D1A' }}
                    onClick={() => {
                      if (!publishingArticle) {
                        handleOpenModal();
                        handleModalProps({
                          actionButton: 'Delete',
                          message:articleData?.meta?.isArchived? 'This Article is Already Archived! Are you sure you want to Permanently delete this article?':'Are you sure you want to delete this article?',                          title: 'Delete Article',
                          actionFn: () => deleteArticle()
                        });
                      }
                    }}
                  >
                    <DeleteOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                
                <div style={{ marginLeft: '1rem', marginRight: '0.5rem' }}>
                  Was this helpful?
                </div>

                <div>
                  {likeStatus === 1 ? (
                    <Tooltip title="Undo">
                      <IconButton
                        sx={{ color: '#109648' }}
                        onClick={() => setLikeStatus(0)}
                      >
                        <ThumbUpIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Like">
                      <IconButton
                        onClick={() => {
                          addReaction('like');
                          setLikeStatus(1);
                        }}
                      >
                        <ThumbUpOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {likeStatus === -1 ? (
                    <Tooltip title="Undo">
                      <IconButton
                        sx={{ color: '#ED474A' }}
                        onClick={() => setLikeStatus(0)}
                      >
                        <ThumbDownIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Dislike">
                      <IconButton
                        onClick={() => {
                          addReaction('dislike');
                          setLikeStatus(-1);
                        }}
                      >
                        <ThumbDownOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>

                <div style={{ marginLeft: '1rem' }}>
                  <FeedbackMessage
                    style={{ color: likeStatus === 1 ? '#109648' : 'inherit' }}
                  >
                    {likeStatus === 1 ? (
                      'Glad it helped!'
                    ) : likeStatus === -1 ? (
                      <>
                        Sorry! Would you like to talk to
                        <Link
                          to={ROUTES.CUSTOMER_SERVICE}
                          style={{ marginLeft: '0.25rem' }}
                        >
                          customer service
                        </Link>
                        ?
                      </>
                    ) : (
                      ''
                    )}
                  </FeedbackMessage>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <span>Was this helpful?</span>
          <div style={{ marginBottom: '-0.3rem' }}>
            {likeStatus === 1 ? (
              <MdThumbUp
                title="Undo"
                style={{
                  ...ActionStyle,
                  color: '#109648',
                  fontSize: '16px'
                }}
                onClick={() => setLikeStatus(0)}
              />
            ) : (
              <MdOutlineThumbUp
                title="Yes"
                style={{ ...ActionStyle, fontSize: '16px' }}
                onClick={() => {
                  addReaction('like');
                  setLikeStatus(1);
                }}
              />
            )}
            {likeStatus === -1 ? (
              <MdThumbDown
                title="Undo"
                style={{
                  ...ActionStyle,
                  color: '#ED474A',
                  fontSize: '16px'
                }}
                onClick={() => setLikeStatus(0)}
              />
            ) : (
              <MdOutlineThumbDown
                title="No"
                style={{ ...ActionStyle, fontSize: '16px' }}
                onClick={() => {
                  addReaction('dislike');
                  setLikeStatus(-1);
                }}
              />
            )}
          </div>
          <div style={{ marginLeft: '1rem' }}>
            <FeedbackMessage
              style={{ color: likeStatus === 1 ? '#109648' : 'inherit' }}
            >
              {likeStatus === 1 ? (
                'Glad it helped!'
              ) : likeStatus === -1 ? (
                <>
                  Sorry! Would you like to talk to
                  <Link
                    to={ROUTES.CUSTOMER_SERVICE}
                    style={{ marginLeft: '0.25rem' }}
                  >
                    customer service
                  </Link>
                  ?
                </>
              ) : (
                ''
              )}
            </FeedbackMessage>
          </div>
        </>
      )}
    </Footer>
  );
};

export default FooterComponent;
