import { useSelector } from 'react-redux';
import {
    Wrapper,
    Info,
    InfoTitle, 
    InfoWrapper,
    GapsTable
} from './styles';
import parse from 'html-react-parser';


const GapsInformation = (props) => {
    const chatRecordByRecordIds = useSelector(state => state?.chat?.chatRecordByRecordIds);
    const chatLoading = useSelector(state => state?.chat?.loading);
    const chaterror = useSelector(state => state?.chat?.error);
    const record_id = props.stat.recordId;

    return (
        <Wrapper>
            <Info>
                <InfoTitle>Transcript: </InfoTitle>
                <InfoWrapper>
                    <div>
                        {
                            props.stat && !chatLoading && !chaterror && chatRecordByRecordIds[record_id]?.record ?
                            (
                                <div> {parse(chatRecordByRecordIds[record_id]?.record.split('\n').join('<br />'))} </div>
                            ) :
                            (
                                <div>Select a row to display reaction information</div>
                            )
                        }
                    </div>
                </InfoWrapper>
            </Info>
        </Wrapper>
    );
}

export default GapsInformation;