import { toast } from 'react-toastify';

const deleteUnverifiedArticle = async (data, deleteKA) => {
  try {
    await deleteKA(data);
  } catch (error) {
    console.error(error);
    toast.error(error);
  }
};

export default deleteUnverifiedArticle;
