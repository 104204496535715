// Packages:
import deterministicStringify from 'json-stringify-deterministic';
import leven from 'leven';
import cloneDeep from 'lodash.clonedeep';

// Constants:
import { WORKFLOW_STAGES } from '../../../constants/studio';

// Functions:
export const shouldAutosaveWorkflow = (original, modified, opts) => {
  const LEV_DIS = opts?.triggerAnyway ? 0 : 25 ?? opts?.LEV_DIS;
  const originalStringified = deterministicStringify(original);
  const modifiedStringified = deterministicStringify(modified);
  const isDifferent = originalStringified !== modifiedStringified;
  if (isDifferent)
    if (leven(originalStringified, modifiedStringified) > LEV_DIS) return true;
  return false;
};

export const prepareNodes = (nodes) =>
  nodes.map(({ data, id, position, type }) => ({ data, id, position, type }));

export const determineWorkflowStage = (workflow) => {
  if (workflow.published === undefined && workflow.isReviewed === undefined)
    return WORKFLOW_STAGES.DRAFT;
  else if (workflow.published === false && workflow.isReviewed === false)
    return WORKFLOW_STAGES.UP_FOR_REVIEW;
  else if (workflow.published === false && workflow.isReviewed === true)
    return WORKFLOW_STAGES.REVIEWED_BUT_NEEDS_CHANGES;
  else if (workflow.published === true && workflow.isReviewed === true)
    return WORKFLOW_STAGES.PUBLISHED;
};

export const prepareWorkflowForPublishing = (newWorkflow) => {
  const _workflow = cloneDeep(newWorkflow);
  const workflowName = _workflow.name;
  const nodes = _workflow.meta.nodes?.map((node) => {
    if (node?.type === 'message') {
      const outputs = [...node?.data?.outputs];
      const tempOutputs = outputs.map((output) => {
        if (output?.type === 'NAME' || output?.type === 'ADDRESS') {
          return output?.fields;
        } else return output;
      });
      const newOutputs = tempOutputs.flat(1);
      const newNode = {
        ...node,
        data: { ...node?.data, outputs: newOutputs }
      };
      return newNode;
    } else return node;
  });

  const edges = _workflow.meta.edges;
  const viewport = _workflow.meta.viewport;
  const orgid = _workflow.orgid;
  delete _workflow.name;
  delete _workflow.meta;
  delete _workflow.orgID;
  _workflow.workflowName = workflowName;
  _workflow.nodes = nodes;
  _workflow.edges = edges;
  _workflow.viewport = viewport;
  _workflow.orgid = orgid;
  _workflow.published = true;
  _workflow.isReviewed = true;
  return _workflow;
};

export const prepareWorkflowForSubmitForReview = (newWorkflow) => {
  const _workflow = cloneDeep(newWorkflow);
  const workflowName = _workflow.name;
  const nodes = _workflow.meta.nodes;
  const edges = _workflow.meta.edges;
  const viewport = _workflow.meta.viewport;
  const orgID = _workflow.orgid;
  delete _workflow.name;
  delete _workflow.meta;
  delete _workflow.orgid;
  _workflow.workflowName = workflowName;
  _workflow.nodes = nodes;
  _workflow.edges = edges;
  _workflow.viewport = viewport;
  _workflow.orgID = orgID;
  _workflow.published = false;
  _workflow.isReviewed = false;
  return _workflow;
};

export const prepareWorkflowToSetAsReviewed = (newWorkflow) => {
  const _workflow = cloneDeep(newWorkflow);
  const workflowName = _workflow.name;
  const nodes = _workflow.meta.nodes;
  const edges = _workflow.meta.edges;
  const viewport = _workflow.meta.viewport;
  const orgID = _workflow.orgid;
  delete _workflow.name;
  delete _workflow.meta;
  delete _workflow.orgid;
  _workflow.workflowName = workflowName;
  _workflow.nodes = nodes;
  _workflow.edges = edges;
  _workflow.viewport = viewport;
  _workflow.orgID = orgID;
  _workflow.published = false;
  _workflow.isReviewed = true;
  return _workflow;
};

export const prepareWorkflowForAutosave = (newWorkflow) => {
  const _workflow = cloneDeep(newWorkflow);
  const nodes = _workflow.meta.nodes;
  const workflowName = _workflow.workflowName;
  const edges = _workflow.meta.edges;
  const viewport = _workflow.meta.viewport;
  const workflowid = _workflow.workflowid;
  const orgID = _workflow.orgID;
  delete _workflow.workflowName;
  delete _workflow.workflowid;
  delete _workflow.meta;
  delete _workflow.orgid;
  delete _workflow.published;
  delete _workflow.isReviewed;
  _workflow.workflowName = workflowName;
  _workflow.nodes = nodes;
  _workflow.edges = edges;
  _workflow.viewport = viewport;
  _workflow.orgID = orgID;
  _workflow.workflowid = workflowid;
  return _workflow;
};
