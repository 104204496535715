// Packages:
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createGlobalStyle } from 'styled-components'


// Components:
import App from './App'


// Redux:
import { store } from './redux/store'


// Styles:
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    background: #FFFFFF;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  *, ::after, ::before {
    box-sizing: border-box;
  }


  html {
    font-size: 100%;
    height: 100%;
  }

  #root {
    height: 100%;
  }

  a {
    width: fit-content;
    display: inline-block;
    text-decoration: none;
    color: #2f80ed;
    cursor: pointer;

    &:hover {
      transform scale(1.01);
      transition: all .25s ease;
    }
  
    &:active {
      transform scale(0.99);
      transition: all .25s ease;
    }
  }
`


// Functions:
ReactDOM.render(
  <Provider store={ store }>
    <GlobalStyle />
    <App />
  </Provider>,
  document.getElementById('root')
)
