// Packages:
import React, { useCallback, useState, useEffect } from "react";
import { ResponsiveContainer, PieChart, Pie, Sector } from "recharts";
import styled, { keyframes } from 'styled-components'
import { IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';


// Styles:
const fadeInAndRiseUp = keyframes`
  from {
    filter: opacity(0);
    transform: translateY(-10px);
  }
  to {
    filter: opacity(1);
    transform: translateY(0);
  }
`

export const InfoWrapper = styled.div`
  animation: ${ fadeInAndRiseUp } 1s ease;
  font-size: 14px;
`

export const StyledText = styled.div`
  color: ${(props) => ((props.topName !== "Likes" && props.topName !== "Dislikes") ? '#B86800;' : props.topName !== "Likes" ? 'red' : 'green')};
`
export const Title = styled.div`
  font-weight: bold;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Items = styled.div`
  text-align: left;
  margin-bottom: 1rem;
`;

// Functions:
const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        cursor="pointer"
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
        cursor="pointer"
      />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * -17}
        y={ey}
        textAnchor={textAnchor}
        fill="#999"
      >{`Total`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * -20}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#333"
      >
        {`${value}`}
      </text>
    </g>
  );
};

const UserChart = (props) => {
  const [likeData, setLikeData] = useState([]);
  const [dislikeData, setDislikeData] = useState([]);
  const [misshitData, setMisshitData] = useState([]);
  const [records, setRecords] = useState([]);

  // Chart Data
  const data = [
    {
      name: 'Likes',
      value: likeData.length,
      questions: likeData,
      fill: '#3F9820',
      tooltip: 'like = like / total interaction'
    },
    {
      name: 'Dislikes',
      value: dislikeData.length,
      questions: dislikeData,
      fill: '#C71D1A',
      tooltip: 'dislike = dislike / total interaction'
    },
    { 
      name: "Mishits", 
      value: misshitData.length,
      questions: misshitData,
      fill: '#F39C12',
      tooltip: 'mishit = mishit / total interaction'
    }
  ];

  const [activeIndex, setActiveIndex] = useState(2);
  const [topName, setTopName] = useState('Mishits')
  const [ topTooltip, setTopTooltip ] = useState("mishit = mishit / total interaction")
  const [topData, setTopData] = useState([{query: 'Click on doughnut section to view'}])


  useEffect(() => {
    if(records !== undefined && records !== null){
      let likes = records.filter((reaction) => reaction.type === 'like')
      let dislikes = records.filter((reaction) => reaction.type === 'dislike')
      let misshits = records.filter((reaction) => reaction.type === 'mishit')
      setLikeData(likes)
      setDislikeData(dislikes)
      setMisshitData(misshits)
      setTopData(misshits)
    }
  }, [records]);

  useEffect(() => {
    if (props.reactions !== undefined && props.reactions !== null) {
      let filteredData = props.reactions.filter(
        (item) =>
          moment(item.createdAt).format('YYYY-MM-DD') >=
            moment(props.startDate).format('YYYY-MM-DD') &&
          moment(item.createdAt).format('YYYY-MM-DD') <=
            moment(props.endDate).format('YYYY-MM-DD')
      );
      if (props.type.length > 0) {
        let typeFilteredData = filteredData.filter((item) =>
          props.type.includes(item.type)
        );
        setRecords(typeFilteredData);
      } else {
        setRecords(filteredData);
      }
    }
  }, [props.startDate, props.endDate, props.type]);


  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const onPieClick = useCallback(
    (_, index) => {
      setTopData(data[index].questions)
      setTopName(data[index].name)
      setTopTooltip(data[index].tooltip)
    }
  );

  return (
    <>
      <InfoWrapper>
        <ResponsiveContainer width="100%" height={280}>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx="50%"
              cy="50%"
              innerRadius="35%"
              outerRadius="55%"
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={onPieEnter}
              onClick={onPieClick}
            />
          </PieChart>
        </ResponsiveContainer>
      </InfoWrapper>
      <InfoWrapper style={{padding: '5px'}}>
        <Title>
          {topName} 
          <Tooltip title={topTooltip} placement="top">
            <IconButton color="primary" size="small" >
              <InfoOutlinedIcon sx={{ fontSize: '20px' }} />
            </IconButton>
          </Tooltip>
        </Title>
        <div style={{ height: '190px', overflowY: 'scroll', width: '100%' }}>
          {topData.map((item, i) => (
            <Items key={i}><StyledText topName={topName}> { item.title==="" ? item.query : item.title } </StyledText></Items>
          ))}
        </div>
      </InfoWrapper>
    </>
  );
};

// Exports:
export default UserChart;
