import axios from 'axios';
import {
    CREATE_CHANNEL,
    SEND_MESSAGE,
    CREATE_CONNECTION,
    ARCHIVE_CHANNEL,
} from "../constants/Endpoint";


export const createChannel = async (org_name, email) => {
    try {
        const response = await axios.post(
            `${CREATE_CHANNEL}`,
            {
                org: org_name,
                validated_email: email,
                orgId: localStorage.getItem('org_id')
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};



export const createConnection = async (channelId) => {
    const url = `${CREATE_CONNECTION}`;
    try {
        //const token = '';
        const response = await axios.post(
            url,
            { channelId: channelId , orgId: localStorage.getItem('org_id') },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/************************************************************** */

export const sendMessage = async (message, id) => {
    try {
        const data = { message, channelId: id , orgId: localStorage.getItem('org_id') };
        const response = await axios.post(`${SEND_MESSAGE}`, data, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const archiveChannel = async (id) => {
    try {
        const data = { channelId: id , orgId: localStorage.getItem('org_id') };
        const response = await axios.post(`${ARCHIVE_CHANNEL}`, data, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        localStorage.removeItem('slack_channel_id');
        localStorage.removeItem('validated_user');
        return response;
    } catch (error) {
        throw error;
    }
};
