// Packages:
import React, { memo } from "react";
import { useSelector } from "react-redux";

// Styles:
import { Body, Description, NodeLabel, NodeWrapper, StyledHandle } from "../styles";
import BaseNode from "../BaseNode";
import { config } from "./config";
import useNodeStyles from "../../../../hooks/useNodeStyles";

// Functions:
const ChoiceNode = ({ data }) => {
  // Constants:
  const { id, label } = data;

  const { studio, isSelected } = useSelector((state) => ({
    studio: state.studio,
    isSelected: state.studio.selectedNode?.id === id,
  }));

  const {computedLabelStyle,computedBodyStyle,computedWrapperStyle}=useNodeStyles({isSelected,config})



  // Return:
  return (
    <BaseNode data={data} config={config}>
      <NodeWrapper style={computedWrapperStyle} backgroundColor={'#fefffe'} borderColor={'#7721e8'}>
      {config.handleStyles.map((handle, index) => (
        <StyledHandle
            key={index}
            id={handle.properties.id}
            type={handle.properties.type}
            position={handle.properties.position}
            style={handle.style}
        />
        ))}
        <NodeLabel htmlFor="text" style={computedLabelStyle}>
          {label}
        </NodeLabel>
        <Body style={computedBodyStyle}>
          <Description></Description>
        </Body>
      </NodeWrapper>
    </BaseNode>
  );
};

// Exports:
export default memo(ChoiceNode);