// Packages:
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback
} from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls
  // Position
} from 'react-flow-renderer';
import uuid from 'react-uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import {
  Tooltip,
  IconButton,
  TextField,
  styled,
  CircularProgress
} from '@mui/material';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

// Constants:
import { NODE_TYPE, WORKFLOW_STAGES } from '../../constants/studio';

// Components:
import {
  Assets,
  Configuration,
  SettingsBox,
  Nodes
} from '../../components/Studio';
import Workflows from '../../components/Studio/Workflows';
import {
  shouldAutosaveWorkflow,
  prepareNodes,
  determineWorkflowStage
} from './utils';

import {
  autosaveWorkflow,
  getDraftWorkflows,
  createNewWorkflow,
  getRemoteWorkflows,
  submitWorkflowForReview,
  publishWorkflow,
  deleteWorkflow,
  deleteDraftWorkflow,
  fetchCommentOnWorkflowAPI,
  addCommentOnWorkflowAPI,
  setWorkflowAsReviewed
} from './utils/api';
import { CommentBox } from '../../components';

// Redux:
import { setSelectedNode } from '../../redux/actions/studioActions';

// Styles:
import {
  Wrapper,
  Title,
  ButtonGroup,
  Canvas,
  LoadingCanvas,
  MainDiv,
  SideBarCollapse
} from './styles';

const StyledTextField = styled(TextField)`
  width: 30%;
  margin-left: 10px;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

// Functions:
const Studio = () => {
  // Constants:
  const orgID = localStorage.getItem('org_id');
  const dispatch = useDispatch();

  // Ref:
  const reactFlowWrapper = useRef(null);

  // Memo:
  const nodeTypes = useMemo(
    () => ({
      [NODE_TYPE.start]: Nodes.StartNode,
      [NODE_TYPE.leaf]: Nodes.LeafNode,
      [NODE_TYPE.message]: Nodes.MessageNode,
      [NODE_TYPE.form]: Nodes.FormNode,
      [NODE_TYPE.api]: Nodes.APINode,
      [NODE_TYPE.email]: Nodes.EmailNode,
      [NODE_TYPE.decision]: Nodes.DecisionNode,
      [NODE_TYPE.ticket]: Nodes.TicketNode,
      [NODE_TYPE.shopify]: Nodes.ShopifyNode,
      [NODE_TYPE.google_sheets]: Nodes.GoogleSheetsNode,
      [NODE_TYPE.shipping]: Nodes.ShippingNode,
      [NODE_TYPE.decision]: Nodes.DecisionNode
    }),
    []
  );

  // State:
  const userDetails = useSelector((state) => state.auth.userDetails);
  const agent_type = useSelector((state) => state.content.agent_type);
  const TEMPLATE_WORKFLOW = {
    workflowid: uuid(),
    meta: {
      nodes: [],
      edges: [],
      viewport: {
        x: 0,
        y: 0,
        zoom: 1
      }
    },
    settings: {},
    orgID,
    lastEdited: Date.now(),
    createdBy: userDetails?.email ?? '',
    name: 'untitled'
  };
  const isAutosaving = useRef(false);
  const [workflows, setWorkflows] = useState([]);
  const [currentRemoteWorkflow, setCurrentRemoteWorkflow] =
    useState(TEMPLATE_WORKFLOW);
  const [workflowStage, setWorkflowStage] = useState(WORKFLOW_STAGES.DRAFT);
  const [id, setID] = useState('');
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [name, setName] = useState('Untitled');
  const [canPublish, setCanPublish] = useState(false);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isCreatingNewWorkflow, setIsCreatingNewWorkflow] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [reviewed, setReviwed] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [bit, setBit] = useState(true);
  const [open, setOpen] = useState(false);
  const [workflowComments, setWorkflowComments] = useState([]);
  const [fetchingComments, setFetchingComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);


  // Functions:
  const fetchAllWorkflows = async (orgID) => {
    const remoteWorkflows = await getRemoteWorkflows(
      orgID,
      userDetails.email,
      agent_type
    );
    const draftWorkflows = await getDraftWorkflows(orgID, userDetails.email);
    const fetchedWorkflows = remoteWorkflows.concat(draftWorkflows);
    fetchedWorkflows.sort(
      (workflowA, workflowB) => workflowB.lastEdited - workflowA.lastEdited
    );
    if (fetchedWorkflows.length === 0) {
      fetchedWorkflows.push(TEMPLATE_WORKFLOW);
    }
    return fetchedWorkflows;
  };

  //Inline editing for workflowName change

  const InlineEdit = ({ title, setTitle }) => {
    const [editingValue, setEditingValue] = React.useState(name);

    const onTitleChange = (event) => setEditingValue(event.target.value);

    const onTitleChangeKeyDown = (event) => {
      if (event.key === 'Enter' || event.key === 'Escape') {
        event.target.blur();
        setEditingValue(event.target.value);
        setName(editingValue);
      }
    };

    const onTitleBlur = async (event) => {
      if (event.target.value.trim() === '') {
        setEditingValue(title);
      } else {
        setEditingValue(event.target.value);
        setTitle(event.target.value);
        setName(editingValue);
        const modifiedWorkflow = getModifiedWorkflow(
          currentRemoteWorkflow,
          nodes,
          edges,
          editingValue
        );
        const newWorkflow = getSaveReadyWorkflow(modifiedWorkflow);
        await autosaveWorkflow(newWorkflow);
        setCurrentRemoteWorkflow(newWorkflow);
      }
    };

    return (
      <StyledTextField
        id="workflow-name"
        variant="outlined"
        value={editingValue}
        onChange={onTitleChange}
        onKeyDown={onTitleChangeKeyDown}
        onBlur={onTitleBlur}
        placeholder="Please enter workflow name"
        size="small"
      />
    );
  };

  const loadWorkflow = (workflow) => {
    setCurrentRemoteWorkflow(workflow);
    setWorkflowStage(determineWorkflowStage(workflow));
    setID(workflow.workflowid);
    setNodes(() => workflow.meta.nodes);
    setEdges(() => workflow.meta.edges);
    if (reactFlowInstance) {
      reactFlowInstance.setViewport(workflow.meta.viewport);
    }
    setName(workflow.workflowName ?? workflow.name);
    if (agent_type === 'supervisor') {
      setCanPublish(true);
    }
  };

  const getModifiedWorkflow = useCallback(
    (currentWorkflow, nodes, edges, name) => {
      return {
        ...currentWorkflow,
        workflowName: name,
        orgid: orgID,
        meta: {
          ...currentWorkflow.meta,
          nodes: prepareNodes(nodes),
          edges
        },
        name
      };
    },
    [orgID]
  );

  const getSaveReadyWorkflow = useCallback(
    (modifiedWorkflow) => {
      return {
        ...modifiedWorkflow,
        orgID: orgID,
        name: name,
        meta: {
          ...modifiedWorkflow.meta,
          viewport: reactFlowInstance?.getViewport()
        }
      };
    },
    [name, orgID, reactFlowInstance]
  );

  const initiateAutosaveWorkflow = async (modifiedWorkflow) => {
    if (isAutosaving.current) return;
    isAutosaving.current = true;
    const newWorkflow = getSaveReadyWorkflow(modifiedWorkflow);
    await autosaveWorkflow(newWorkflow);
    setCurrentRemoteWorkflow(newWorkflow);
    isAutosaving.current = false;
  };

  const initiateSubmitWorkflowForReview = async (modifiedWorkflow) => {
    if (isAutosaving.current) return;
    isAutosaving.current = true;

    const newWorkflow = getSaveReadyWorkflow(modifiedWorkflow);
    await submitWorkflowForReview(newWorkflow);
    setCurrentRemoteWorkflow(newWorkflow);
    isAutosaving.current = false;
  };

  const initiatePublishWorkflow = async (modifiedWorkflow) => {
    if (isAutosaving.current) return;
    isAutosaving.current = true;

    const newWorkflow = getSaveReadyWorkflow(modifiedWorkflow);
    setCurrentRemoteWorkflow(newWorkflow);
    isAutosaving.current = false;
    await publishWorkflow(newWorkflow);
  };

  const initiateCreateNewWorkflow = async () => {
    setIsCreatingNewWorkflow(true);
    const newRemoteWorkflow = {
      orgID,
      createdBy: userDetails?.email,
      workflowid: uuid(),
      workflowName: 'Untitled',
      published: undefined,
      isReviewed: undefined,
      lastEdited: Date.now(),
      nodes: [],
      edges: [],
      viewport: {
        x: 0,
        y: 0,
        zoom: 1
      }
    };
    const newWorkflow = {
      orgid: orgID,
      createdBy: userDetails?.email,
      workflowid: newRemoteWorkflow.workflowid,
      name: newRemoteWorkflow.workflowName,
      lastEdited: newRemoteWorkflow.lastEdited,
      meta: {
        nodes: [],
        edges: [],
        viewport: {
          x: 0,
          y: 0,
          zoom: 1
        }
      },
      settings: {}
    };
    await createNewWorkflow(newRemoteWorkflow);
    const newWorkflows = workflows;
    newWorkflows.push(newWorkflow);
    setWorkflows(newWorkflows);
    loadWorkflow(newWorkflow);
    setIsCreatingNewWorkflow(false);
  };

  const initiateDeleteWorkflow = async () => {
    if (isDeleting === false) setIsDeleting(true);
    else setIsDeleting(false);
    const currentWorkflow = currentRemoteWorkflow;
    if (
      currentWorkflow.published === undefined &&
      currentWorkflow.isReviewed === undefined
    ) {
      deleteDraftWorkflow(orgID, id);
    } else {
      deleteWorkflow(orgID, id);
    }
    setOpenModal(false);
    // const newWorkflows = workflows.filter(workflow => workflow.workflowID !== currentWorkflow.workflowID)
    // if (newWorkflows.length > 0) loadWorkflow(newWorkflows[ newWorkflows.length - 1 ])
    // else {
    //   newWorkflows.push(TEMPLATE_WORKFLOW)
    //   loadWorkflow(newWorkflows[0])
    // }
    // setWorkflows(newWorkflows)
  };

  const changeCommentsArray = useCallback((val) => {
    setComments(val);
  }, []);

  const handleOpenModal = async () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    changeCommentsArray([]);
    setOpenModal(false);
  };

  const initiateSubmitForReview = async () => {
    const modifiedWorkflow = getModifiedWorkflow(
      currentRemoteWorkflow,
      nodes,
      edges,
      name
    );
    await initiateSubmitWorkflowForReview(modifiedWorkflow);
    deleteDraftWorkflow(orgID, id);
    setReviwed(true);
  };

  const handleAddComment = useCallback(
    async (comment, changeComment) => {
      const isPublished = !!currentRemoteWorkflow.published;
      if (comment !== '' && !isPublished) {
        let newId = comments.length + 1;
        const d = new Date();
        let newDateTime = d.toString().substring(0, 24);
        let newComment = {
          id: newId,
          createdAt: newDateTime,
          comment: comment,
          author: userDetails?.email
        };
        changeCommentsArray([...comments, newComment]);
        changeComment('');
        addCommentOnWorkflowAPI(orgID, id, comment, userDetails?.email);
        const modifiedWorkflow = getModifiedWorkflow(
          currentRemoteWorkflow,
          nodes,
          edges,
          name
        );
        const newWorkflow = getSaveReadyWorkflow(modifiedWorkflow);
        await setWorkflowAsReviewed(newWorkflow);
      }
    },
    [
      changeCommentsArray,
      comments,
      currentRemoteWorkflow,
      edges,
      getModifiedWorkflow,
      getSaveReadyWorkflow,
      id,
      name,
      nodes,
      orgID,
      userDetails?.email
    ]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    changeCommentsArray([]);
  }, [changeCommentsArray]);

  const handleSettingsClose = () => setSettingsOpen(false);

  const handleClickOpen = useCallback(async (orgid, workflowid) => {
    setFetchingComments(true);
    setOpen(true);
    const response = await fetchCommentOnWorkflowAPI(orgid, workflowid);
    const comments = response?.comments;
    const newComments = comments?.map((comment) => {
      const createdAt = new Date(comment?.createdAt).toUTCString();
      return { ...comment, createdAt };
    });
    setWorkflowComments(newComments);
    setFetchingComments(false);
  }, []);

  // Effects:
  useEffect(() => {
    (async () => {
      setIsFetching(true);
      if (orgID) {
        const fetchedWorkflows = await fetchAllWorkflows(orgID);
        setWorkflows(fetchedWorkflows);
        loadWorkflow(fetchedWorkflows[0]);
      }
      setIsFetching(false);
    })();
  }, [bit]);

  //After Saving the name refresh the page to get the autosave name
  useEffect(() => {
    (async () => {
      setIsFetching(true);
      if (orgID) {
        const fetchedWorkflows = await fetchAllWorkflows(orgID);
        setWorkflows(fetchedWorkflows);
      }
      setIsFetching(false);
    })();
  }, [name, reviewed, isDeleting]);

  useDeepCompareEffect(() => {
    if (reactFlowInstance && !isAutosaving.current) {
      const modifiedWorkflow = getModifiedWorkflow(
        currentRemoteWorkflow,
        nodes,
        edges,
        name
      );

      if (shouldAutosaveWorkflow(currentRemoteWorkflow, modifiedWorkflow)) {
        initiateAutosaveWorkflow(modifiedWorkflow);
      }
    }
  }, [
    name,
    nodes,
    edges,
    reactFlowInstance,
    getModifiedWorkflow,
    currentRemoteWorkflow,
    initiateAutosaveWorkflow
  ]);

  // Functions:
  const onConnect = useCallback(
    (params) =>
      setEdges((currentEdges) =>
        addEdge(
          {
            ...params,
            animated: false
          },
          currentEdges
        )
      ),
    [setEdges]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');
      if (typeof type === 'undefined' || !type) return;
      const newNodeID = uuid();
      const newNode = {
        id: newNodeID,
        type,
        position: reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top
        }),
        data: {
          ...JSON.parse(event.dataTransfer.getData('data')),
          id: newNodeID,
          type
        }
      };
      setNodes((currentNodes) => currentNodes.concat(newNode));
    },
    [reactFlowInstance, setNodes]
  );

  const updateNode = useCallback(
    (id, data) => {
      setNodes((oldNodes) =>
        oldNodes.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              ...data
            };
          }
          return node;
        })
      );
    },
    [setNodes]
  );

  const deleteNode = useCallback(
    (id) => {
      setNodes((oldNodes) => oldNodes.filter((node) => node.id !== id));
      setEdges((currentEdges) =>
        currentEdges.filter((edge) => ![edge.source, edge.target].includes(id))
      );
      dispatch(setSelectedNode());
    },
    [setNodes]
  );

  //for sidebar
  const [openCollapse, setOpenCollapse] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenCollapse(true);
  };

  const handleDrawerClose = () => {
    setOpenCollapse(false);
  };
  const message = (
    <div>
      Are you sure you want to delete{' '}
      <b>{currentRemoteWorkflow.workflowName}</b>?{' '}
    </div>
  );
  const actionButton = 'Delete';
  // Return:
  return (
    <Wrapper>
      <Tooltip title="Workflow Menu">
        <IconButton
          onClick={handleDrawerOpen}
          sx={{
            mt: '10px',
            backgroundColor: '#FFFFFF',
            boxShadow:
              '0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)',
            ...(openCollapse && { display: 'none' })
          }}
        >
          <MenuOutlinedIcon />
        </IconButton>
      </Tooltip>
      <ReactFlowProvider>
        <MainDiv>
          {openCollapse && (
            <SideBarCollapse>
              <Workflows
                handleDrawerClose={handleDrawerClose}
                openCollapse={openCollapse}
                isFetching={isFetching}
                isCreatingNewWorkflow={isCreatingNewWorkflow}
                workflows={workflows}
                currentWorkflowID={id}
                workflowStage={workflowStage}
                onClick={async (selectedWorkflowid, selectedWorkflowStage) => {
                  if (selectedWorkflowid === id) return;
                  const selectedWorkflow = workflows.find((workflow) => {
                    const currentWorkflowStage =
                      determineWorkflowStage(workflow);
                    if (
                      workflow.workflowid === selectedWorkflowid &&
                      currentWorkflowStage === selectedWorkflowStage
                    )
                      return workflow;
                  });
                  if (!selectedWorkflow) return;
                  loadWorkflow(selectedWorkflow);
                }}
                createNewWorkflow={initiateCreateNewWorkflow}
                setBit={setBit}
              />
            </SideBarCollapse>
          )}
          <Assets />
          <Canvas ref={reactFlowWrapper}>
            {isFetching ? (
              <>
                <Title>Canvas</Title>
                <LoadingCanvas>
                  <CircularProgress />
                </LoadingCanvas>
              </>
            ) : (
              <>
                <Title>
                  {open && (
                    <CommentBox
                      open={open}
                      handleClose={handleClose}
                      agent_type={agent_type}
                      documentId={id}
                      handleAddComment={handleAddComment}
                      storeComments={workflowComments}
                      loading={fetchingComments}
                      changeCommentsArray={changeCommentsArray}
                      comments={comments}
                    />
                  )}
                  {settingsOpen && (
                    <SettingsBox
                      settingsOpen={settingsOpen}
                      handleSettingsClose={handleSettingsClose}
                      currentRemoteWorkflow={currentRemoteWorkflow}
                      setCurrentRemoteWorkflow={setCurrentRemoteWorkflow}
                      settings={currentRemoteWorkflow.settings}
                    />
                  )}
                  <ConfirmationDialog
                    title={'Delete Workflow'}
                    message={message}
                    performAction={initiateDeleteWorkflow}
                    openModal={openModal}
                    handleOpenModal={handleOpenModal}
                    handleCloseModal={handleCloseModal}
                    actionButton={actionButton}
                  />
                  Canvas:
                  <InlineEdit title={name} setTitle={setName} />
                  <ButtonGroup>
                    {agent_type !== 'endUser' && (
                      <Tooltip title="Info">
                        <IconButton onClick={() => handleClickOpen(orgID, id)}>
                          <InfoOutlinedIcon
                            color="primary"
                            sx={{ fontSize: 25 }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Delete">
                      <IconButton color="error" onClick={handleOpenModal}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Submit for Review">
                      <IconButton
                        color="primary"
                        onClick={initiateSubmitForReview}
                      >
                        <SaveAsOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    {(agent_type === 'supervisor' ||
                      agent_type === 'superadmin') && (
                      <>
                        <Tooltip title="Publish">
                          <IconButton
                            color="success"
                            onClick={async () => {
                              const modifiedWorkflow = getModifiedWorkflow(
                                currentRemoteWorkflow,
                                nodes,
                                edges,
                                name
                              );
                              await initiatePublishWorkflow(modifiedWorkflow);
                            }}
                          >
                            <PublishOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                          <IconButton onClick={() => setSettingsOpen(true)}>
                            <SettingsOutlinedIcon
                              color="primary"
                              sx={{ fontSize: 25 }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </ButtonGroup>
                </Title>
                <ReactFlow
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={onConnect}
                  onInit={setReactFlowInstance}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  // fitView
                  defaultZoom={1}
                  style={{ height: '95%' }}
                  nodeTypes={nodeTypes}
                >
                  <Controls />
                </ReactFlow>
              </>
            )}
          </Canvas>
          <Configuration updateNode={updateNode} deleteNode={deleteNode} settings={currentRemoteWorkflow.settings} />
        </MainDiv>
      </ReactFlowProvider>
    </Wrapper>
  );
};

// Exports:
export default Studio;
