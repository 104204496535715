import { toast } from 'react-toastify';
import { getArticleAuditLog } from "../api";

export function saveLogs(entity, entityid, logs) {
  const href = URL.createObjectURL(logs);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', `${entity}-${entityid}.json`);
  link.click();
  URL.revokeObjectURL(href);
}

export function downloadEntityLogs(data, entityId) {
  if (data && data.error) {
    toast.error(data.error);
    return;
  }
  data = JSON.stringify(data, null, 2);
  data = new Blob([ data ], {
    type: 'application/json'
  });
  saveLogs("article", entityId, data);
  return data;
}

export async function downloadArticleLogs(orgid, articleData, user) {
  let response = await getArticleAuditLog(orgid, articleData, user);
  return downloadEntityLogs(response.data, articleData.articleId);
}