// Packages:
import styled from 'styled-components';

// Exports:
export const Wrapper = styled.div`
    width: 100%;
    font-size: 14px;
`;

export const FieldName = styled.div`
  margin-bottom: 0.5rem;
  font-weight: 600;
  `;