// Packages:
import React, { memo } from 'react';
import { useSelector } from 'react-redux';


// Styles:
import { Body, Description, Field, NodeLabel, NodeWrapper, StyledHandle } from '../styles';
import { config } from './config';
import BaseNode from '../BaseNode';
import useNodeStyles from '../../../../hooks/useNodeStyles';


// Functions:
const TicketureNode = ({ data }) => {
  // Constants:
  const { id, action, label } = data;
  const isSelected = useSelector(
    (state) => state.studio.selectedNode?.id === id
  );

  const {computedLabelStyle,computedBodyStyle,computedWrapperStyle}=useNodeStyles({isSelected,config})

  // State:
  const studio = useSelector((state) => state.studio);
  // Return:
  return (
<BaseNode data={data} config={config}>
    <NodeWrapper
      style={computedWrapperStyle}
      backgroundColor={'#FEFFFE'}
      borderColor={'#235789'}
    >
    {config.handleStyles.map((handle, index) => (
        <StyledHandle
            key={index}
            id={handle.properties.id}
            type={handle.properties.type}
            position={handle.properties.position}
            style={handle.style}
        />
        ))}
      <NodeLabel
        htmlFor="text"
        style={computedLabelStyle}
      >
        {label}
      </NodeLabel>
      <Body
        style={computedBodyStyle}
      >
        <Description>
          <Field isActive={studio.selectedNode?.id === id} activeColor={'#112E49'} inActiveColor={'#235789'}>
            {!action ? (
              <span style={{ fontStyle: 'italic', fontWeight: 400 }}>
                Ticketure Action
              </span>
            ) : (
              action
            )}
          </Field>
        </Description>
        <Description>
        </Description>
      </Body>
    </NodeWrapper>
    </BaseNode>   
  );
};

// Exports:
export default memo(TicketureNode);