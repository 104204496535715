// Packages:
import produce from 'immer';

// Actions:
import { studioActions } from '../actions/studioActions';

// Constants:
const initialState = {
  selectedNode: undefined
};

const studioReducer = produce((draft, action) => {
  switch (action.type) {
    case studioActions.SET_SELECTED_NODE:
      draft.selectedNode = action.payload;
      return;
    default:
      return;
  }
}, initialState);

// Exports:
export default studioReducer;
