import axios from 'axios';
import {
    CHAT_RECORD,
    EDIT_CHAT_RECORD_V2,
} from "../constants/Endpoint";
import { REQUEST_CHART_SESSION_TYPE } from '../constants/requestType';
import { CHAT_FILTER_PARAMS } from '../constants/api';
import moment from 'moment';
import { ISO_DATE_FORMAT } from '../constants/misc';



/***********************CHAT RECORDS****************************/

export const fetchRecords = async (org_id) => {
    try {
        const result = await axios.post(
            `${CHAT_RECORD}/fetch-records`,
            {
                org_id
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchRecordsV2 = async (org_id, startDate, endDate, requestType, filter = {}) => {
    try {
        startDate = moment(startDate).utc().format(ISO_DATE_FORMAT);
        endDate = moment(endDate).utc().format(ISO_DATE_FORMAT);
        const result = await axios.get(
            `${CHAT_RECORD}/analytics/chatRecords/${org_id}?startDate=${startDate}&endDate=${endDate}`,
            {
                params: { requestType: requestType || REQUEST_CHART_SESSION_TYPE, filter: { ...CHAT_FILTER_PARAMS, ...filter } },
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchSingleChatRecord = async (org_id, recordId) => {
    try {
        const response = await axios.get(`${CHAT_RECORD}/analytics/chatRecord/${org_id}`, {
            params: { recordId: recordId },
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export const editChatRecordApi = async (data) => {
    try {
        const result = await axios.post(`${CHAT_RECORD}/edit-records`, data, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        return result;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const editChatRecordApiV2 = async (data) => {
    try {
        const result = await axios.post(`${EDIT_CHAT_RECORD_V2}`, data, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        return result;
    } catch (error) {
        console.error(error);
        throw error?.response?.data?.message || "Edit Api Error: Unable To update Chat Record!!";
    }
};

export const changeChatPreferenceApi = async (data) => {
    try {
        const result = await axios.post(
            `${CHAT_RECORD}/changeChatPreference`,
            data,
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
