// Packages:
import styled from 'styled-components'


// Exports:
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.75rem;
  width: 99%;
  height: 100%;
  margin-top: 4rem;

  @media (max-width: 1024px) {
    margin-top: 4rem;
    flex-direction: column;
  }
`
