import { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { CONVERSATION_TYPE } from "../../../constants/chat";
import { recordsFilteredByPersonas, recordsFilteredByProfile } from "../utils";
import { selectSessionItems } from "../../../redux/selectors/chatSelectors";

const { CONVERSATION_TYPE_AI, CONVERSATION_TYPE_AGENT, CONVERSATION_TYPE_TICKET } = CONVERSATION_TYPE;

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

const options = {
    plugins: {
        title: {
            display: false,
            text: 'Escalation Chart',
        },
        tooltip: {
            filter: item => item.dataset.data[item.dataIndex] > 0
        },
        legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              boxWidth: 20,
              boxHeight: 18,
              padding: 20
            },
        }
    },
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
    layout: {
        padding: 25
    }
};

const EscalationBreakDownChart = ({ startDate, endDate, persona, profile }) => {
    const chatRecords = useSelector(selectSessionItems, shallowEqual);
    const eventRecords = useSelector(state => state.content.reactionRecordsV2);
    const personas = useSelector(state => state.content.personas);

    const [graphData, setGraphData] = useState({
        solvedTickets: [],
        escalateTickets: [],
        submittedTickets: []
    });

    const [labels, setLabels] = useState([]);
    const [total, setTotalValue] = useState([]);
    const [sortedValues, setSortedValues] = useState([]);

    useEffect(() => {
        const diff = moment(endDate).diff(startDate, 'days');
        let filteredRecords = recordsFilteredByPersonas(chatRecords, personas, persona);
        let filteredEvents = recordsFilteredByPersonas(eventRecords, personas, persona, diff);
        filteredRecords = recordsFilteredByProfile(filteredRecords, profile);
        filteredEvents = recordsFilteredByProfile(filteredEvents, profile);
  
        const periodTypes = {
            '0': 'hour',
            '1': 'day',
            '31': 'month',
            '365': 'year',
        };
    
        let periodType = periodTypes['0']; // Default aggregation function
        Object.keys(periodTypes).forEach(key => {
            if (diff > parseInt(key)) {
                periodType = periodTypes[key];
            }
        });

        aggregateDataByPeriod(startDate, endDate, periodType, filteredRecords, filteredEvents, setLabels, setGraphData, setTotalValue, setSortedValues)
    
    }, [chatRecords]);


    let data = {
        labels,
        datasets: [
            {
                type: "line",
                label: "Total Sessions",
                borderColor: "black",
                borderWidth: 2,
                fill: false,
                data: total,
                pointRadius: 0,
                stack: "Stack 1",
                hidden: true,
            },
            {
                label: "AI Solved",
                borderColor: "black",
                backgroundColor: 'rgb(233, 233, 233)',
                borderWidth: 2,
                fill: false,
                data: graphData.solvedTickets,
                stack: "Stack 0",
            },
            {
                label: "Escalation Request",
                borderColor: "black",
                backgroundColor: 'grey',
                borderWidth: 2,
                fill: false,
                data: graphData.escalateTickets,
                stack: "Stack 0",
            },
            {
                label: "Handoff to Agent",
                borderColor: "black",
                backgroundColor: 'black',
                borderWidth: 2,
                fill: false,
                data: graphData.submittedTickets,
                stack: "Stack 0",
            },
        ]
    };

    return (
        <>
            <Bar data={data} options={options} />
        </>
    );
}

export default EscalationBreakDownChart;

/* Data Calculations */

function aggregateDataByPeriod(startDate, endDate, periodType, chatRecords, eventRecords, setLabels, setGraphData, setTotalValue, setSortedValues) {
    let currentDate = moment(startDate).startOf(periodType);
    let labels = [];
    let solvedTickets = [];
    let escalateTickets = [];
    let submittedTickets = [];
    let total = [];
    let dateFormat = '';

    const periodFormats = {
        'year': 'YYYY',
        'month': 'MMM YYYY',
        'day': 'MMM D, YYYY',
        'hour': 'MMM D, YYYY HH:mm'
    };

    dateFormat = periodFormats[periodType] || 'MMM D, YYYY HH:mm';

    while (currentDate.isSameOrBefore(endDate)) {
        const start = moment(currentDate).startOf(periodType);
        const end = moment(currentDate).endOf(periodType);
        aggregateData(start, end, chatRecords, eventRecords, solvedTickets, escalateTickets, submittedTickets, total, periodType);
        labels.push(start.format(dateFormat));
        currentDate.add(1, periodType);
    }

    setLabels(labels);
    setGraphData({ solvedTickets, escalateTickets, submittedTickets });
    setTotalValue(total);
}

function aggregateData(start, end, chatRecords, eventRecords, solvedTickets, escalateTickets, submittedTickets, total, periodType) {
    let solved = 0;
    let escalate = 0;
    let submit = 0;
    let tot = 0;

    for (let i = 0; i < chatRecords?.length || 0; i++) {
        const record = chatRecords[i];
        if (moment(record.created_date).isBetween(start, end, null, '[]')) {
            if (record.conversation_type === CONVERSATION_TYPE_AI) solved++;
            else if (record.conversation_type === CONVERSATION_TYPE_AGENT) escalate++;
            else if(record.conversation_type === CONVERSATION_TYPE_TICKET) submit++;
            tot++;
        }
    }
    
    solvedTickets.push(solved);
    escalateTickets.push(escalate);
    submittedTickets.push(submit);
    total.push(tot);
}

/* Data Calculations End */
