import React from 'react';
import FAQResult from '../FAQResult';

const Articles = ({ articlesArray = [], deleteArticle = () => {} }) => {
  return articlesArray?.map((article) => (
    <React.Fragment
      key={
        article?.meta?.published
          ? article?.dialog_id
          : article?.articleId + article?.meta?.published
      }
    >
      <FAQResult
        article={article}
        title={article?.display_name || article?.question || ''}
        description={
          typeof article?.meta?.firstLine === 'string' &&
          article?.meta?.firstLine
        }
        fontColor={'#f14141'}
        deleteArticle={(article) => deleteArticle(article)}
      />
    </React.Fragment>
  ));
};

export default Articles;