import React from 'react';
import { Title, TitleInput } from './styles';

const TitleComponent = ({ isEdit, articleData, changeArticle }) => {
  return isEdit ? (
    <TitleInput
      required
      type="text"
      placeholder="Enter title here"
      defaultValue={
        articleData?.meta?.title
      }
      onChange={(event) => {
        const newArticleData = {
          ...articleData,
          meta: {
            ...articleData['meta'],
            title: event.target.value,
          }
        };
        changeArticle(newArticleData);
      }}
    />
  ) : (
    <Title>
      {articleData?.meta?.title}
    </Title>
  );
};

export default TitleComponent;
