import styled from "styled-components";

export const FieldName = styled.div`
  margin-bottom: 0.5rem;
  font-weight: 600;
`

export const VariableField = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const Variable = styled.span`
  display:flex;
  align-items:center;
  width: fit-content;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 3px -3px rgba(0, 0, 0, 0.25);
  background-color: #f4f4f9;
  border-radius: 4px;
  user-select: none;
`