// Packages:
import React, { useEffect, useState } from 'react'
import {
    Drawer,
    IconButton,
    Tooltip,
    styled,
    CircularProgress
  } from '@mui/material';
import { getIframeWebpage } from '../../../api';
import isResponseOpaque from '../../../lib/isResponseOpaque';
import { base64toBlob } from '../../../lib/base64ToBinary';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import LaunchIcon from '@mui/icons-material/Launch';


// Styles:
import {
    Title,
    IFrameElement, 
    IFrameContainer
  } from './styles'
  
const StyledDrawer = styled(Drawer)`
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    & .MuiPaper-root {
        width: 39%;
        top: 74.5px;
        right: 17px;
        height: 88.5%;
        border: 0;
        box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    }
`;


// Functions:
const Iframe = (props) => { 
    // State: 
    const [isExternalURLOpaque, setIsExternalURLOpaque] = useState(true);
    const [iframeSrcDoc, setIframeSrcDoc] = useState(null);
    const [iframeSrc, setIframeSrc] = useState(null);
    const [pdfPageQuery, setPdfPageQuery] = useState('#page=1')

    const resetState = () => {
      setIsExternalURLOpaque(true);
      setIframeSrc(null);
      setIframeSrcDoc(null);
      setPdfPageQuery('#page=1');
    }

    // Effect:
    useEffect(() => {
        resetState();
        const url = props.source;
        let func = () => {};
        (async () => {
          if (
            !url ||
            !url?.match(
              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g
            )
          )
            return;
          const isURLOpaque = await isResponseOpaque(url);
          const urlObject = new URL(url);
          setIsExternalURLOpaque(isURLOpaque);
          if (/\.pdf/.test(urlObject.pathname)) {
            let base64 = '';
            const fetchOptions = {
              targetURL: url,
              type: 'BLOB',
              pointer: 0,
              axiosOptions: {
                headers: {
                  Accept: 'application/pdf'
                },
                config: {
                  responseType: 'arraybuffer'
                }
              }
            };
            do {
              const res = await getIframeWebpage(JSON.stringify(fetchOptions));
              base64 += res.data;
              fetchOptions.pointer = res.next;
            } while (fetchOptions.pointer)
            const blob = base64toBlob(base64, 'application/pdf');
            const dataLink = URL.createObjectURL(blob);
            setIframeSrc(dataLink);
            func = () => URL.revokeObjectURL(dataLink);
          } else {
            const stringifiedFetchOptions = JSON.stringify({
              targetURL: url,
              type: 'DOCUMENT',
              puppeteerOptions: {
                baseURL: urlObject.protocol + urlObject.hostname
              }
            });
            const pageContent = await getIframeWebpage(stringifiedFetchOptions);
            if (!pageContent) return;
            setIframeSrcDoc(pageContent);
          }
        })();
        return func;
      }, [props.source]);

    useEffect(() => {
      if (Number.isInteger(props.pageNumber)) {
        const dataLink = iframeSrc;
        setIframeSrc(null);
        setTimeout(() => {
          setIframeSrc(dataLink)
          setPdfPageQuery(`#page=${props.pageNumber}`);
        }, 1000);
      }
    }, [props.pageNumber]);
    // Return:
    return (
        <StyledDrawer variant="persistent" anchor="right" open={props.openCollapse}>
            <Title>
                Original Page
                {`${iframeSrc}` && (
                  <Tooltip title="Open in a New Tab">
                    <IconButton onClick={() => window.open(`${iframeSrc}${pdfPageQuery}`, '_blank')}>
                      <LaunchIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Close">
                    <IconButton onClick={props.handleDrawerClose}>
                        <MenuOpenOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Title>
            <IFrameContainer>
                {(props.source && !isExternalURLOpaque) || iframeSrcDoc || iframeSrc ? (
                    isExternalURLOpaque && iframeSrcDoc ? (
                        <IFrameElement srcDoc={iframeSrcDoc} />
                    ) : (
                        <IFrameElement src={`${iframeSrc}${pdfPageQuery}`} />
                    )
                ) : (
                    <CircularProgress />
                )}
            </IFrameContainer>
        </StyledDrawer>
    )
}


// Exports:
export default Iframe
