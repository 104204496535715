// Packages:
import React, { useState, useEffect, memo, useCallback, useMemo } from "react";
import {
  MenuItem,
  FormControl,
  Select,
  TextField,
  styled,
} from "@mui/material";
import BaseConfiguration from "../BaseConfiguration";
import { FieldName } from "../styles";
import { DEFAULT_OUTPUT_BASE_URL, DEFAULT_OUTPUT_DATE, DEFAULT_OUTPUT_DATETIME, DEFAULT_OUTPUT_DATETIMEGRID, DEFAULT_OUTPUT_EMAIL, DEFAULT_OUTPUT_LOCATION, TICKETACTION, ticketureResponse } from "./utils";
import AutocompleteGlobal from "../../Components/AutocompleteGlobal";


const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  max-height: 36px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;


const TicketureConfiguration = ({
  node,
  updateNode,
  deleteNode,
  settings,
  isEditable,
  currentRemoteWorkflow,
  setNodes
}) => {
  // State:
  const [action,setAction]=useState(node.action??"FETCH EVENT DETAILS")
  const [baseUrl,setBaseUrl]=useState(node.baseUrl??DEFAULT_OUTPUT_BASE_URL)
  const [location,setLocation]=useState(node.location??DEFAULT_OUTPUT_LOCATION)
  const [date,setDate]=useState(node.date??DEFAULT_OUTPUT_DATE)
  const [mappingExpected, setMappingExpected] = useState(node.mappingExpected??false);
  const [numberOfMapping, setNumberOfMapping] = useState( node.numberOfMapping ?? 0);
  const [locationMapping, setLocationMapping] = useState(node.locationMapping??[]);
  const [ticketMappingExpected, setTicketMappingExpected] = useState(node.ticketMappingExpected??false);
  const [numberOfTicketMapping, setNumberOfTicketMapping] = useState( node.numberOfTicketMapping ?? 0);
  const [ticketMapping, setTicketMapping] = useState(node.ticketMapping??[]);
  const [dateTime,setDateTime]=useState(node.dateTime??DEFAULT_OUTPUT_DATETIME)
  const [email,setEmail]=useState(node.email??DEFAULT_OUTPUT_EMAIL)
  const [dateTimeGrid,setDateTimeGrid]=useState(node.dateTimeGrid??DEFAULT_OUTPUT_DATETIMEGRID)

  const inputData = useMemo(() => {
    const input = currentRemoteWorkflow?.meta?.settings?.input || [];
    const secrets = currentRemoteWorkflow?.meta?.settings?.secrets || [];
    return [
      ...input.map((val) => `${val.variableName}`),
      ...secrets.map((val) => `${val.key}`)
    ];
  }, [currentRemoteWorkflow]);

  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields,setOutputFields]=useState([])


  useEffect(()=>{
     setAction(node.action??"FETCH EVENT DETAILS")
     setBaseUrl(node.baseUrl??DEFAULT_OUTPUT_BASE_URL)
     setLocation(node.location??DEFAULT_OUTPUT_LOCATION)
     setDate(node.date??DEFAULT_OUTPUT_DATE)
     setMappingExpected(node.mappingExpected??false)
     setNumberOfMapping(node.numberOfMapping??0)
     setLocationMapping(node.locationMapping??[])
     setTicketMappingExpected(node.ticketMappingExpected??false)
     setNumberOfTicketMapping(node.numberOfTicketMapping??0)
     setTicketMapping(node.ticketMapping??[])
     setDateTime(node.dateTime??DEFAULT_OUTPUT_DATETIME)
     setEmail(node.email??DEFAULT_OUTPUT_EMAIL)
     setDateTimeGrid(node.dateTimeGrid??DEFAULT_OUTPUT_DATETIMEGRID)
  },[node.action,node.baseUrl,node.location,node.date,node.mappingExpected,node.ticketMappingExpected,node.numberOfMapping,node.email,node.numberOfTicketMapping,node.locationMapping,node.ticketMapping,node.dateTime,node.dateTimeGrid])

  useEffect(()=>{
    updateNode(node.id,{action,email,baseUrl,location,dynamicFields: [...ticketureResponse[action],...dynamicFields],outputFields: [...outputFields, {id: node?.id,values: [...ticketureResponse[action]??[]]}], date,mappingExpected,numberOfMapping,locationMapping,ticketMappingExpected,numberOfTicketMapping,ticketMapping,dateTime,dateTimeGrid})
  },[action,baseUrl,location,dynamicFields,email,date,mappingExpected,numberOfMapping,locationMapping,ticketMappingExpected,ticketMapping,numberOfTicketMapping,dateTime,dateTimeGrid]) 

  const handleCheckboxChange = useCallback((setter, initialMappingSetter, initialValueSetter) => {
    setter(prev => !prev);
    initialValueSetter(0);
    initialMappingSetter([]);
  }, []);

  const handleNumberOfInputChange = useCallback((e, setter, initialMappingSetter) => {
    const value = parseInt(e.target.value, 10) || 0;
    if (value >= 0) {
      setter(value);
      initialMappingSetter((prevData) => {
        const newData = [...prevData];
        while (newData.length < value) {
          newData.push({ key: '', value: '' });
        }
        while (newData.length > value) {
          newData.pop();
        }
        return newData;
      });
    }
  }, []);

  const handleDataInputChange = useCallback((index, field, value, initialMappingSetter) => {
    initialMappingSetter((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [field]: value };
      return newData;
    });
  }, []);

  // Return:
  return (
    <BaseConfiguration setDynamicFields={setDynamicFields} node={node} isEditable={isEditable} deleteNode={deleteNode} outputFields={outputFields} setOutputFields={setOutputFields} setNodes={setNodes}>
          <FieldName>TICKETURE ACTION</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="ticketure-action"
          disabled={!isEditable}
          value={action}
          onChange={(e) => setAction(e.target.value)}
        >
          {Object.values(TICKETACTION).map((action, index) => (
            <StyledMenuItem key={index} value={action}>
              {action}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <AutocompleteGlobal
           Title="BASE URL"
           dynamicFields={inputData}
           data={baseUrl} 
           setData={setBaseUrl}
           DEFAULT_OUTPUT={DEFAULT_OUTPUT_BASE_URL}
           isEditable={isEditable}
      />
      {action===TICKETACTION.TRIGGER_EMAIL_CONFIRMATION&&
      <AutocompleteGlobal Title="Email" dynamicFields={dynamicFields} data={email} DEFAULT_OUTPUT={DEFAULT_OUTPUT_EMAIL} setData={setEmail} isEditable={isEditable}/>
      }
      {action!==TICKETACTION.TRIGGER_EMAIL_CONFIRMATION&&<>
      {action===TICKETACTION.CREATE_REDIRECT_URL&&
      <>
      <AutocompleteGlobal Title="Date Time" dynamicFields={dynamicFields} data={dateTime} DEFAULT_OUTPUT={DEFAULT_OUTPUT_DATETIME} setData={setDateTime} isEditable={isEditable}/>
      <AutocompleteGlobal Title="Date Time Grid" dynamicFields={dynamicFields} data={dateTimeGrid} DEFAULT_OUTPUT={DEFAULT_OUTPUT_DATETIMEGRID} setData={setDateTimeGrid} isEditable={isEditable}/>
      </>
      }
     <AutocompleteGlobal Title="Location" dynamicFields={dynamicFields} data={location} DEFAULT_OUTPUT={DEFAULT_OUTPUT_LOCATION} setData={setLocation} isEditable={isEditable}/>
    {action!==TICKETACTION.CREATE_REDIRECT_URL&&  <AutocompleteGlobal Title="Date" dynamicFields={dynamicFields} data={date} DEFAULT_OUTPUT={DEFAULT_OUTPUT_DATE} setData={setDate} isEditable={isEditable}/>}
          <FieldName>LOCATION MAPPING</FieldName>  
          <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
      >
        <input
          type="checkbox"
          style={{ marginRight: "0.35rem" }}
          disabled={!isEditable}
          checked={mappingExpected}
          onChange={() => handleCheckboxChange(setMappingExpected, setLocationMapping, setNumberOfMapping)}

        />
        <div style={{ fontSize: "0.8rem" }}>mapping expected:</div>
        <input
          type="number"
          min="0"
          disabled={!mappingExpected || !isEditable}
          style={{
            width: "3rem",
            marginLeft: "0.25rem",
          }}
          value={numberOfMapping}
          onChange={(e) => handleNumberOfInputChange(e, setNumberOfMapping, setLocationMapping)}
        />
      </div>
      {mappingExpected && (
  <div style={{ display: "flex", flexDirection: "column" }}>
    {locationMapping.map((mapping, index) => (
      <div key={index} style={{ display: "flex", flexDirection: "row" }}>
        <StyledTextField
          type="text"
          placeholder="Key"
          disabled={!isEditable}
          value={mapping.key ?? ''}
          size="small"
          style={{ padding: 0 }}
          onChange={(e) =>
            handleDataInputChange(index, 'key', e.target.value, setLocationMapping)
          }
        />
        <StyledTextField
          type="text"
          placeholder="Value"
          disabled={!isEditable}
          value={mapping.value ?? ''}
          size="small"
          style={{ padding: 0 }}
          onChange={(e) =>
            handleDataInputChange(index, 'value', e.target.value, setLocationMapping)
          }
        />
      </div>
    ))}
  </div>
)}
{action===TICKETACTION.FETCH_DATE_TIME_WITH_PRICE &&<>
 <FieldName>Ticket MAPPING</FieldName>  
          <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
      >
        <input
          type="checkbox"
          style={{ marginRight: "0.35rem" }}
          disabled={!isEditable}
          checked={ticketMappingExpected}
          onChange={() => handleCheckboxChange(setTicketMappingExpected, setTicketMapping, setNumberOfTicketMapping)}

        />
        <div style={{ fontSize: "0.8rem" }}>mapping expected:</div>
        <input
          type="number"
          min="0"
          disabled={!ticketMappingExpected || !isEditable}
          style={{
            width: "3rem",
            marginLeft: "0.25rem",
          }}
          value={numberOfTicketMapping}
          onChange={(e) => handleNumberOfInputChange(e, setNumberOfTicketMapping, setTicketMapping)}
        />
      </div>
      {ticketMappingExpected && (
  <div style={{ display: "flex", flexDirection: "column" }}>
    {ticketMapping.map((mapping, index) => (
      <div key={index} style={{ display: "flex", flexDirection: "row" }}>
        <StyledTextField
          type="text"
          placeholder="Key"
          disabled={!isEditable}
          value={mapping.key ?? ''}
          size="small"
          style={{ padding: 0 }}
          onChange={(e) =>
            handleDataInputChange(index, 'key', e.target.value, setTicketMapping)
          }
        />
        <StyledTextField
          type="text"
          placeholder="Value"
          disabled={!isEditable}
          value={mapping.value ?? ''}
          size="small"
          style={{ padding: 0 }}
          onChange={(e) =>
            handleDataInputChange(index, 'value', e.target.value, setTicketMapping)
          }
        />
      </div>
    ))}
  </div>
)}</>}</>}
  </BaseConfiguration>
  );
};

// Exports:
export default memo(TicketureConfiguration);