// Packages:
import styled from 'styled-components';

// Exports:
export const Wrapper = styled.div`
  width: 40%;
  height: 95%;
  padding: 0.5rem;
  padding-bottom: 5rem;
  font-size: 14px;
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background: #ffffff;
  visibility: ${(props) => (props.openCollapse ? 'hidden' : 'unset')};
  overflow: hidden;

  h3 {
    margin-bottom: 11px;
    font-size: 20px;
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    width: 98%;
    min-height: 98%;
  }

  @media (max-width: 768px) {
    display: ${(props) => (props.tab === 'resources' ? '' : 'none')};
    font-size: 16px;
  }
`;
