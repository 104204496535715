import React from "react";

const UserCheckbox = ({title,value,setValue,isEditable}) => {

  const handleCheckboxChange = (event) => {
    setValue(event.target.checked);
  };


  return (
    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
      <input
        type="checkbox"
        checked={value}
        disabled={!isEditable}
        style={{ marginRight: "0.35rem",marginTop:"0.25rem" }}
        onChange={handleCheckboxChange}
      />
      <div style={{ fontSize: "0.8rem" }}>{title}</div>
    </div>
  );
};

export default UserCheckbox;