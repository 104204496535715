// Packages:
import styled from 'styled-components'


// Exports:
export const FieldName = styled.div`
  margin-bottom: 0.5rem;
  font-weight: 600;
`

export const TableHeader = styled.th`
  font-size: 0.75rem;
`

export const TableData = styled.td`
  border: none;
  text-align: left;
  padding: 0;
`

export const VariableField = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

export const Variable = styled.span`
  width: fit-content;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  padding: 0.25rem 0.5rem;
  color: #FFFFFF;
  font-size: 0.75rem;
  background-color: #0B7A75;
  border-radius: 4px;
  user-select: none;
`

export const AddNewVariable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: #001d4f;
  user-select: none;
  cursor: pointer;
`