// Packages:
import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import useInput from "../../../../lib/hooks/use-input";
import {
  Autocomplete,
  FormControl,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";

// Styles:
import { FieldName } from "../styles";
import BaseConfiguration from "../BaseConfiguration";
import { APIMETHOD, AUTHORIZATIONTYPE, DEFAULT_OUTPUT_AUTHKEY, DEFAULT_OUTPUT_AUTHVALUE, DEFAULT_OUTPUT_BEARER_TOKEN, DEFAULT_OUTPUT_PASSWORD, DEFAULT_OUTPUT_QUERY_PARAMS, DEFAULT_OUTPUT_USERNAME } from "./utils";
import FormInputs from "./FormInputs";
import AutocompleteGlobal from "../../Components/AutocompleteGlobal";

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;
const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  max-height: 36px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;
// Functions:
const parseOutputObject = (templateString) => {
  try {
    const templateObject = JSON.parse(templateString);
    return {
      success: {
        ...templateObject._success._output,
      },
      failure: {
        ...templateObject._failure._output,
      },
    };
  } catch (e) {
    throw new Error("Invalid Template JSON");
  }
};

const APIConfiguration = ({
  node,
  updateNode,
  deleteNode,
  isEditable,
  setNodes,
  currentRemoteWorkflow
}) => {
  // Ref:
  const requestPayloadAreaRef = useRef(null);

  // State:
  const [action, setAction] = useState(node.action ?? "GET");
  const { value: endpoint, setValue: setEndpoint, bind: bindEndpoint} = useInput(node.endpoint ?? "");
  const { value: requestPayload,setValue: setRequestPayload,bind: bindPayload,} = useInput(node.requestPayload ?? "");
  const [dynamicDataExpected, setDynamicDataExpected] = useState(node.dynamicDataExpected ?? false);
  const [numberOfVariables, setNumberOfVariables] = useState(node.numberOfVariables ?? 0);
  const [dynamicData, setDynamicData] = useState(node.dynamicData ?? []);
  const [queryParams, setQueryParams] = useState(node.queryParams ?? [{...DEFAULT_OUTPUT_QUERY_PARAMS}]);
  const [expectParams, setExpectParams] = useState(node.expectParams ?? false);
  const [paramsCount, setParamsCount] = useState(node.paramsCount ?? 0);
  const [outputsExpected, setOutputsExpected] = useState(node.outputsExpected ?? false);
  const [numberOfOutputs, setNumberOfOutputs] = useState(node.numberOfOutputs ?? 0);
  const [responseMapping, setResponseMapping] = useState(node.responseMapping ?? []);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields, setOutputFields] = useState([]);
  const [authType,setAuthType]=useState(node.authType??'')
  const [bearerToken,setBearerToken]=useState(node.bearerToken??DEFAULT_OUTPUT_BEARER_TOKEN)
  const [username,setUsername]=useState(node.username??DEFAULT_OUTPUT_USERNAME)
  const [password,setPassword]=useState(node.password??DEFAULT_OUTPUT_PASSWORD)
  const [authKey,setAuthKey]=useState(node.authKey??DEFAULT_OUTPUT_AUTHKEY)
  const [authValue,setAuthValue]=useState(node.authValue??DEFAULT_OUTPUT_AUTHVALUE)
  const [authRequired,setAuthRequired]=useState(node.authRequired??false)
  const [payloadType,setPayloadType]=useState(node.payloadType??'JSON')
  const inputData = useMemo(() => {
    const input = currentRemoteWorkflow?.meta?.settings?.input || [];
    const secrets = currentRemoteWorkflow?.meta?.settings?.secrets || [];
    return [
      ...input.map((val) => `${val.variableName}`),
      ...secrets.map((val) => `${val.key}`)
    ];
  }, [currentRemoteWorkflow]);

  // Effects:
  useEffect(() => {
    setAction(node.action ?? "GET");
    setEndpoint(node.endpoint??"");
    setRequestPayload(node.requestPayload ?? "");
    setDynamicDataExpected(node.dynamicDataExpected ?? false);
    setNumberOfVariables(node.numberOfVariables ?? 0);
    setDynamicData(node.dynamicData ?? []);
    setQueryParams(node.queryParams?? [{...DEFAULT_OUTPUT_QUERY_PARAMS}])
    setExpectParams(node.expectParams??false)
    setParamsCount(node.paramsCount??0)
    setOutputsExpected(node.outputsExpected??false)
    setNumberOfOutputs(node.numberOfOutputs??0)
    setResponseMapping(node.responseMapping??[])
    setAuthType(node.authType??'')
    setBearerToken(node.bearerToken??DEFAULT_OUTPUT_BEARER_TOKEN)
    setUsername(node.username??DEFAULT_OUTPUT_USERNAME)
    setPassword(node.password??DEFAULT_OUTPUT_PASSWORD)
    setAuthKey(node.authKey??DEFAULT_OUTPUT_AUTHKEY)
    setAuthValue(node.authValue??DEFAULT_OUTPUT_AUTHVALUE)
    setAuthRequired(node.authRequired??false)
    setPayloadType(node.payloadType??'JSON')
  }, [
    node.action,
    node.endpoint,
    node.requestPayload,
    node.dynamicDataExpected,
    node.numberOfVariables,
    node.dynamicData,
    node.queryParams,
    node.expectParams,
    node.paramsCount,
    node.outputsExpected,
    node.numberOfOutputs,
    node.responseMapping,
    node.authType,
    node.bearerToken,
    node.username,
    node.password,
    node.authKey,
    node.authValue,
    node.authRequired,
    node.payloadType
  ]);

  useEffect(() => {
    updateNode(node.id, {
      action,
      endpoint,
      requestPayload,
      dynamicDataExpected,
      numberOfVariables,
      dynamicData,
      queryParams,
      expectParams,
      paramsCount,
      outputsExpected,
      numberOfOutputs,
      responseMapping,
      authType,
      bearerToken,
      username,
      password,
      authKey,
      authValue,
      authRequired,
      dynamicFields: dynamicFields,
      outputFields: outputFields,
      payloadType
    });
  }, [
    action,
    endpoint,
    requestPayload,
    dynamicDataExpected,
    numberOfVariables,
    dynamicData,
    queryParams,
    expectParams,
    outputsExpected,
    numberOfOutputs,
    authType,
    responseMapping,
    bearerToken,
    username,
    password,
    authKey,
    authValue,
    authRequired,
    dynamicFields,
    payloadType
  ]);

  // useEffect(() => {
  //   try {
  //     setRequestPayload(parseOutputObject(requestPayload));
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, [requestPayload]);

  const handleCheckboxChange = useCallback(() => {
    setDynamicDataExpected(!dynamicDataExpected);
    setNumberOfVariables(0);
    setDynamicData([]);
  }, [dynamicDataExpected]);
  const handleMappingCheckboxChange = useCallback(() => {
    setOutputsExpected(!outputsExpected);
  }, [outputsExpected]);

  const handleNumberOfInputChange = useCallback((e) => {
    const value = parseInt(e.target.value, 10) || 0;
    setNumberOfVariables(value);

    setDynamicData((prevDynamicData) => {
      const newDynamicData = [...prevDynamicData];
      if (value < newDynamicData.length) {
        newDynamicData.length = value;
      } else if (value > newDynamicData.length) {
        for (let i = newDynamicData.length + 1; i <= value; i++) {
          newDynamicData.push({
            key: null,
            value: { fieldName: null, value: null },
          });
        }
      }
      return newDynamicData;
    });
  }, []);

  const handleDataInputChange = useCallback(
    (index, field, value) => {
      if (field !== "key" && !dynamicFields.includes(value)) return;

      setDynamicData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          [field === "key" ? "key" : "value"]:
            field === "key"
              ? value
              : { ...newData[index].value, fieldName: value },
        };
        return newData;
      });
    },
    [dynamicFields]
  );

  const updateParams = (value) => {
    setQueryParams(value);
  };

  const handleNumberOfOutputInputChange = useCallback((e) => {
    const value = parseInt(e.target.value, 10) || 0;
    if (value >= 0) {
      setNumberOfOutputs(value);
      setResponseMapping((prevData) => {
        const newData = [...prevData];
        while (newData.length < value) {
          newData.push({ key: "", value: "" });
        }
        while (newData.length > value) {
          newData.pop();
        }
        return newData;
      });
    }
  }, []);

  const handleOutputDataInputChange = useCallback((index, field, value) => {
    setResponseMapping((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [field]: value,
      };
      return newData;
    });
  }, []);

  // Return:
  return (
    <BaseConfiguration
      setDynamicFields={setDynamicFields}
      node={node}
      isEditable={isEditable}
      deleteNode={deleteNode}
      outputFields={outputFields}
      setOutputFields={setOutputFields}
      setNodes={setNodes}
    >
      <FieldName>ACTION</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="ticket-channel"
          disabled={!isEditable}
          value={action}
          onChange={(e) => setAction(e.target.value)}
        >
          {Object.values(APIMETHOD).map((action, index) => (
            <StyledMenuItem key={index} value={action}>
              {action}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>

      <FieldName>Endpoint</FieldName>
      <StyledTextField
        id="api-endpoint"
        variant="outlined"
        disabled={!isEditable}
        placeholder="Name of the endpoint"
        {...bindEndpoint}
        size="small"
        fullWidth
      />
       <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
      >
        <input
          type="checkbox"
          style={{ marginRight: "0.35rem" }}
          disabled={!isEditable}
          checked={authRequired}
          onChange={(e)=>setAuthRequired(e.target.checked)}
        />
        <div style={{ fontSize: "0.8rem" }}>Authorization Required</div>
        </div>
       {authRequired&&(<>
        <FieldName>AUTHORIZATION</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="ticket-channel"
          disabled={!isEditable}
          value={authType}
          onChange={(e) => {setAuthType(e.target.value)}}
          >
          {Object.values(AUTHORIZATIONTYPE).map((authType, index) => (
            <StyledMenuItem key={index} value={authType}>
              {authType}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>
      {authType===AUTHORIZATIONTYPE["Bearer Token"]&&<>
        <AutocompleteGlobal
            Title="Token"
            dynamicFields={inputData}
            data={bearerToken} 
            setData={setBearerToken}
            DEFAULT_OUTPUT={DEFAULT_OUTPUT_BEARER_TOKEN}
            isEditable={isEditable}
        />
      </>
      }
      {authType===AUTHORIZATIONTYPE["Basic Auth"]&&<>
        <AutocompleteGlobal
            Title="USERNAME"
            dynamicFields={inputData}
            data={username} 
            setData={setUsername}
            DEFAULT_OUTPUT={DEFAULT_OUTPUT_USERNAME}
            isEditable={isEditable}
        />
        <AutocompleteGlobal
            Title="Password"
            dynamicFields={inputData}
            data={password} 
            setData={setPassword}
            DEFAULT_OUTPUT={DEFAULT_OUTPUT_PASSWORD}
            isEditable={isEditable}
        />
      </>
      }
      {authType===AUTHORIZATIONTYPE["API Key"]&&<>
        <AutocompleteGlobal
            Title="AUTH KEY"
            dynamicFields={inputData}
            data={authKey} 
            setData={setAuthKey}
            DEFAULT_OUTPUT={DEFAULT_OUTPUT_AUTHKEY}
            isEditable={isEditable}
        />
        <AutocompleteGlobal
            Title="AUTH VALUE"
            dynamicFields={inputData}
            data={authValue} 
            setData={setAuthValue}
            DEFAULT_OUTPUT={DEFAULT_OUTPUT_AUTHVALUE}
            isEditable={isEditable}
        />
      </>
      }
      </> )}
      <FieldName>Enter Query Params</FieldName>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "0.75rem",
        }}
      >
        <input
          type="checkbox"
          disabled={!isEditable}
          style={{ marginRight: "0.35rem" }}
          checked={expectParams}
          onChange={(e) => {
            if (!e.target.checked && expectParams) {
              setQueryParams([]);
              setParamsCount(0);
            }
            setExpectParams(e.target.checked);
          }}
        />
        <div style={{ fontSize: "0.8rem" }}>Enter Name and Values:</div>
        <input
          type="number"
          min="0"
          disabled={!expectParams || !isEditable}
          style={{
            width: "3rem",
            marginLeft: "0.25rem",
          }}
          value={paramsCount}
          onChange={(event) => {
            event.preventDefault();
            event.stopPropagation();
            const newDataLength = parseInt(event.currentTarget.value);
            setParamsCount(newDataLength);
            const currentDataLength = queryParams.length;
            if (newDataLength === currentDataLength + 1)
              setQueryParams([...queryParams, ...[{...DEFAULT_OUTPUT_QUERY_PARAMS}]]);
            if (newDataLength === currentDataLength - 1)
            setQueryParams([...queryParams].slice(0, -1));
          }}
        />
      </div>

      {paramsCount > 0 &&
        Object.values(queryParams).map((param, i) => (
          <FormInputs
            key={i}
            outputs={queryParams}
            isEditable={isEditable}
            output={param}
            i={i}
            updateOutput={updateParams}
            dynamicFields={dynamicFields}
          />
        ))}
        <FieldName>Payload Type</FieldName>
        <StyledFormControl size="small" fullWidth>
      <Select
        fullWidth
        id="date-range"
        value={payloadType}
        onChange={(event) => setPayloadType(event.target.value)}
      >
        <StyledMenuItem value="JSON" key="JSON">JSON</StyledMenuItem>
        <StyledMenuItem value="FORM" key="FORM">FORM</StyledMenuItem>
      </Select>
      </StyledFormControl>
      <FieldName>Payload</FieldName>
      <StyledTextField
        id="api-requestPayload"
        variant="outlined"
        disabled={!isEditable}
        ref={requestPayloadAreaRef}
        placeholder="Enter json requestPayload.."
        {...bindPayload}
        onKeyDown={(e) =>
          e.key === "Tab"
            ? (() => {
                e.preventDefault();
                const start = requestPayloadAreaRef.current.selectionStart;
                const end = requestPayloadAreaRef.current.selectionEnd;
                requestPayloadAreaRef.current.value =
                  requestPayloadAreaRef.current.value.substring(0, start) +
                  "  " +
                  requestPayloadAreaRef.current.value.substring(end);
                setRequestPayload(requestPayloadAreaRef.current.value);
                requestPayloadAreaRef.current.selectionStart =
                  requestPayloadAreaRef.current.selectionEnd = start + 2;
              })()
            : null
        }
        multiline
        rows={10}
        size="small"
        fullWidth
      />
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
      >
        <input
          type="checkbox"
          style={{ marginRight: "0.35rem" }}
          disabled={!isEditable}
          checked={dynamicDataExpected}
          onChange={handleCheckboxChange}
        />
        <div style={{ fontSize: "0.8rem" }}>dynamic fields expected:</div>
        <input
          type="number"
          min="0"
          disabled={!dynamicDataExpected || !isEditable}
          style={{
            width: "3rem",
            marginLeft: "0.25rem",
          }}
          value={numberOfVariables}
          onChange={handleNumberOfInputChange}
        />
      </div>
      {dynamicDataExpected && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {dynamicData.map((_, index) => (
            <div key={index} style={{ display: "flex", flexDirection: "row" }}>
              <StyledTextField
                type="text"
                placeholder="Variable"
                disabled={!isEditable}
                value={dynamicData[index].key}
                size="small"
                style={{ padding: 0 }}
                onChange={(e) =>
                  handleDataInputChange(index, "key", e.target.value)
                }
              />
              <Autocomplete
                options={dynamicFields}
                disabled={!isEditable}
                value={dynamicData[index].value.fieldName}
                fullWidth
                onChange={(_, value) =>
                  handleDataInputChange(index, "fieldName", value)
                }
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder="Value"
                    variant="outlined"
                    size="small"
                    style={{ padding: 0 }}
                  />
                )}
              />
            </div>
          ))}
        </div>
      )}
      <FieldName>OUTPUT MAPPING</FieldName>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}
      >
        <input
          type="checkbox"
          style={{ marginRight: "0.35rem" }}
          disabled={!isEditable}
          checked={outputsExpected}
          onChange={handleMappingCheckboxChange}
        />
        <div style={{ fontSize: "0.8rem" }}>mapping expected:</div>
        <input
          type="number"
          min="0"
          disabled={!outputsExpected || !isEditable}
          style={{
            width: "3rem",
            marginLeft: "0.25rem",
          }}
          value={numberOfOutputs}
          onChange={handleNumberOfOutputInputChange}
        />
      </div>
      {outputsExpected && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {responseMapping.map((output, index) => (
            <div key={index} style={{ display: "flex", flexDirection: "row" }}>
              <StyledTextField
                type="text"
                placeholder="Key"
                disabled={!isEditable}
                value={output.key ?? ""}
                size="small"
                style={{ padding: 0 }}
                onChange={(e) =>
                  handleOutputDataInputChange(index, "key", e.target.value)
                }
              />
              <StyledTextField
                type="text"
                placeholder="Value"
                disabled={!isEditable}
                value={output.value ?? ""}
                size="small"
                style={{ padding: 0 }}
                onChange={(e) =>
                  handleOutputDataInputChange(index, "value", e.target.value)
                }
              />
            </div>
          ))}
        </div>
      )}
    </BaseConfiguration>
  );
};

// Exports:
export default APIConfiguration;