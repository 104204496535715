// Packages:
import React, { useCallback, useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import sanitizeHtml from "sanitize-html";
import "react-loading-skeleton/dist/skeleton.css";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import isResponseOpaque from "../../../lib/isResponseOpaque";
import { useDebounce, useDeepCompareEffect } from "react-use";
import makeSuggestionsArray from "../../../lib/makeSuggestionsArray";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Popover,
  Tooltip,
  Badge,
  styled,
  CircularProgress,
} from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

// Components:
import MobileResourceActions from "../MobileResourceActions";

// Constants:
import ROUTES from "../../../constants/routes";

// Styles:
import {
  Wrapper,
  Header,
  Title,
  List,
  OriginalPage,
  IFrame,
  IFrameContainer,
  ListItem,
  FlexButton,
  StyledLink,
  ResourceDescription,
  LoadingContainer,
  Footer,
  FeedbackMessage,
  Tab,
} from "./styles";
import { LoadingDot } from "../ChatConversation/styles";
import { getIframeWebpage } from "../../../api";
import { getContentActions } from "../../../redux/actions/contentActions";
import parse from "html-react-parser";
import { urlify } from "../../../lib/urlify";
import { ARTICLE_IS_PUBLISHED } from "../../../constants/articles";
import { VALID_URL_REGEX, SUPPORTED_IFRAME_DOCS } from "../../../constants/regex";
import WorkflowList from "./workflowList";

const StyledIconButton = styled(IconButton)`
  & .MuiSvgIcon-root {
    @media (max-width: 520px) {
      font-size: 16px;
    }
  }
`;

const StyledPopover = styled(Popover)`
  font-size: 14px;
  font-family: "Inter", sans-serif;
  & .MuiPaper-root {
    margin-top: 0.5rem;
    width: 30%;
    padding: 1rem;
  }
`;

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    top: 5px;
    right: 5px;
  }
`;

// Functions:
const Resources = ({ setTab, setNumberOfResources, previewSource }) => {
  // Dummy Data:
  const [likeStatus, setLikeStatus] = useState(0);

  // Constants:
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // State:
  const [anchorEl, setAnchorEl] = useState(null);
  const interactData = useSelector((state) => state.content.interactData);
  const workflows = useSelector((state) => state.content.workflows);
  const searching = useSelector((state) => state.content.searching);
  const orgid = useSelector((state) => state.content.org_info?.org_data?._id);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const user = useSelector((state) => state.auth.user);
  const query = useSelector((state) => state.content.query);
  const workflow_url = useSelector((state) => state.content.workflow_url);
  const agent_type_info = useSelector((state) => state.content.agent_type);
  const chat = useSelector((state) => state.chat);
  const [iframeSrcDoc, setIframeSrcDoc] = useState(null);
  const [isExternalURLOpaque, setIsExternalURLOpaque] = useState(true);
  const [resources, setResources] = useState([]);
  const similarity = useSelector((state) => state.content.similarity);
  const [isLoading, setIsLoading] = useState(searching);
  const [activeTab, setActiveTab] = useState("resources");
  const contentActions = getContentActions(dispatch);
  const botReply = interactData?.intent?.meta?.formattedText;
  const invisible = !(
    interactData?.intent?.question?.length !== 0 &&
    interactData?.intent?.result !== "Bad input received" &&
    interactData?.intent?.question !== "Connect_To_Live_Agent" &&
    typeof interactData?.intent?.meta !== "string" &&
    typeof botReply !== "object"
  );

  const verificationUrl = useSelector(
    (state) => state?.chat?.incomingMessage?.messageData?.verification_url
  );

  // Event Handlers:
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleBotClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBotClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setNumberOfResources(resources.length);
  }, [resources, setNumberOfResources]);

  const setSuggestions = useCallback(
    (similarity, intent, changeResourcesArray) => {
      const generateId = (meta) =>
        `${meta.aid ?? meta.articleId}-${meta.source}`;
      const isInvalid = (meta) =>
        Number.isNaN(parseFloat(meta?.version)) &&
        !VALID_URL_REGEX.test(meta?.source ?? "");

      const metaArray = intent?.meta;
      similarity = Array.isArray(similarity) ? similarity : [];

      if (Array.isArray(metaArray)) {
        const articles = {};
        for (const meta of metaArray) {
          if (isInvalid(meta)) {
            continue;
          }
          const id = generateId(meta);
          articles[id] = { id, meta };
        }
        for (const article of similarity) {
          const meta = article.meta;
          if (isInvalid(meta)) {
            continue;
          }
          const id = generateId(meta);
          articles[id] = { ...article, id };
        }
        changeResourcesArray(Object.values(articles));
      } else {
        const filteredSimilarity = similarity
          .filter((article) => !isInvalid(article?.meta))
          .map((article) => ({
            ...article,
            id: generateId(article?.meta),
          }));
        changeResourcesArray(filteredSimilarity);
      }
    },
    [user]
  );

  const changeResourcesArray = useCallback((val) => {
    const tempResources = val;
    const newResources = tempResources.map((res) => {
      return { ...res, likeStatus: 0 };
    });
    setResources(newResources);
  }, []);

  const unicodeToString = (raw) => {
    return raw?.replace(/&#x[0-9]+;/g, (value) => {
      value = value?.replace("&#x", "");
      return String.fromCharCode(parseInt(value, 16));
    });
  };

  useEffect(() => {
    (async () => {
      if (
        !workflow_url === null ||
        !workflow_url?.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g
        )
      )
        return;
      const isURLOpaque = await isResponseOpaque(workflow_url);
      const urlObject = new URL(workflow_url);
      setIsExternalURLOpaque(isURLOpaque);
      const stringifiedFetchOptions = JSON.stringify({
        targetURL: workflow_url,
        type: "DOCUMENT",
        puppeteerOptions: {
          baseURL: urlObject.protocol + urlObject.hostname,
        },
      });
      const pageContent = await getIframeWebpage(stringifiedFetchOptions);
      if (!pageContent) return;
      setIframeSrcDoc(pageContent);
    })();
  }, [workflow_url]);

  useEffect(() => {
    (async () => {
      if (
        !verificationUrl === null ||
        !verificationUrl?.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g
        )
      )
        return;
      const isURLOpaque = await isResponseOpaque(verificationUrl);
      const urlObject = new URL(verificationUrl);
      setIsExternalURLOpaque(isURLOpaque);
      const stringifiedFetchOptions = JSON.stringify({
        targetURL: verificationUrl,
        type: "DOCUMENT",
        puppeteerOptions: {
          baseURL: urlObject.protocol + urlObject.hostname,
        },
      });
      const pageContent = await getIframeWebpage(stringifiedFetchOptions);
      if (!pageContent) return;
      setIframeSrcDoc(pageContent);
    })();
  }, [verificationUrl]);

  const changeLikeStatusOfResource = useCallback(
    (status, id) => {
      const tempResources = resources.map((res) => {
        if (res.id === id) {
          return {
            ...res,
            likeStatus: status,
          };
        } else return res;
      });

      setResources(tempResources);
    },
    [resources]
  );

  // Functions:
  const addReaction = async (type, articleData) => {
    try {
      const data = {
        orgid,
        user_email: userDetails?.email || "anonymous",
        type,
        subtype: articleData?.confidence ? "title search" : "intent",
        article_id: "",
        title: articleData?.meta?.title ?? "",
        query,
      };
      await contentActions.reactOnArticle(data);
    } catch (error) {
      console.error(error);
    }
  };

  const mapIntentToMessage = (response) => ({
    ...response,
    id: uuid(),
    question:
      response?.question.split(" ")[1] === "Welcome"
        ? "Default Knowledge Article"
        : "No relevant article found",
    result:
      response?.result.split(" ")[1] === "Welcome"
        ? "A short description will appear here."
        : "We couldn't find any knowledge article that matches your needs.",
    meta: {
      formattedText:
        response?.result.split(" ")[1] === "Welcome"
          ? "A short description will appear here."
          : "We couldn't find any knowledge article that matches your needs.",
    },
  });

  // Effects:

  useDebounce(
    () => {
      setIsLoading(searching);
    },
    2000,
    [searching]
  );

  useDeepCompareEffect(() => {
    if (
      !searching &&
      (!interactData?.qna ||
        ["", "Default Welcome Intent", "Default Fallback Intent"].includes(
          interactData?.qna?.question
        )) &&
      similarity?.length === 0
    ) {
      const tempResources = [mapIntentToMessage(interactData?.qna)];
      const newResources = tempResources.map((res) => {
        return { ...res, likeStatus: 0 };
      });
      setResources(newResources);
    } else {
      setSuggestions(similarity, interactData?.intent, changeResourcesArray);
      setSuggestions(similarity, interactData?.intent, changeResourcesArray);
    }
  }, [interactData?.qna?.question, similarity]);

  const navigateToArticle = (resource) => {
    const params = {
      articleId: resource.meta.aid,
      version: resource.meta.version,
      status: ARTICLE_IS_PUBLISHED,
    };

    if (
      typeof previewSource === "function" &&
      Number.isNaN(parseFloat(params.version))
    ) {
      previewSource(resource.meta.source, resource.meta.page_number ?? 1);
      return;
    }

    const queryString = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");
    navigate(`${ROUTES.KNOWLEDGE_ARTICLE_V2}?${queryString}`, {
      state: { initialArticleData: null, isEdit: false },
    });
  };

  const isUrlToDoc = (url) => SUPPORTED_IFRAME_DOCS.test(url ?? '');
  const isFastTrackArticle = (meta) => !Number.isNaN(parseFloat(meta?.version));

  // Return:
  return (
    <Wrapper>
      <Header>
        <Tab
          active={activeTab === "resources"}
          onClick={() => handleTabClick("resources")}
        >
          Resources
        </Tab>
        <Tab
          active={activeTab === "workflows"}
          onClick={() => handleTabClick("workflows")}
        >
          Workflows
        </Tab>
        <MobileResourceActions setTab={setTab} />

        {agent_type_info !== "endUser" && chat?.agentChatWorkflowStarted && (
          <FlexButton>
            <StyledBadge color="error" variant="dot" invisible={invisible}>
              <Tooltip title="Bot Reply">
                <IconButton color="primary" onClick={handleBotClick}>
                  <SmartToyOutlinedIcon />
                </IconButton>
              </Tooltip>
            </StyledBadge>
          </FlexButton>
        )}
      </Header>
      {activeTab === "resources" && (
        <>
          <StyledPopover
            open={open}
            anchorEl={anchorEl}
            onClose={handleBotClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            elevation={3}
          >
            {interactData?.intent?.question?.length !== 0 &&
            interactData?.intent?.result !== "Bad input received" &&
            interactData?.intent?.question !== "Connect_To_Live_Agent" &&
            typeof interactData?.intent?.meta !== "string" &&
            typeof botReply === "string" ? (
              <div>{parse(botReply)}</div>
            ) : (
              <p>No relevant information found.</p>
            )}

            <Footer>
              <>
                <span style={{ marginRight: "0.5rem" }}>Was this helpful?</span>
                <div>
                  {likeStatus === 1 ? (
                    <Tooltip title="Undo">
                      <StyledIconButton
                        sx={{ color: "#109648" }}
                        onClick={() => setLikeStatus(0)}
                      >
                        <ThumbUpIcon sx={{ fontSize: 18 }} />
                      </StyledIconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Like">
                      <StyledIconButton
                        onClick={() => {
                          setLikeStatus(1);
                        }}
                      >
                        <ThumbUpOutlinedIcon sx={{ fontSize: 18 }} />
                      </StyledIconButton>
                    </Tooltip>
                  )}
                  {likeStatus === -1 ? (
                    <Tooltip title="Undo">
                      <StyledIconButton
                        sx={{ color: "#ED474A" }}
                        onClick={() => setLikeStatus(0)}
                      >
                        <ThumbDownIcon sx={{ fontSize: 18 }} />
                      </StyledIconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Dislike">
                      <StyledIconButton
                        onClick={() => {
                          setLikeStatus(-1);
                        }}
                      >
                        <ThumbDownOutlinedIcon sx={{ fontSize: 18 }} />
                      </StyledIconButton>
                    </Tooltip>
                  )}
                </div>
                <div style={{ marginLeft: "1rem" }}>
                  <FeedbackMessage
                    style={{ color: likeStatus === 1 ? "#109648" : "#ED474A" }}
                  >
                    {likeStatus === 1
                      ? "Glad it helped!"
                      : likeStatus === -1
                      ? "Sorry we couldn't help you."
                      : ""}
                  </FeedbackMessage>
                </div>
              </>
            </Footer>
          </StyledPopover>

          <Scrollbars style={{ width: "100%", height: "calc(100% - 2.5rem)" }}>
            {workflow_url !== null &&
            workflow_url?.match(
              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g
            ) ? (
              <OriginalPage>
                <IFrameContainer>
                  {(workflow_url && !isExternalURLOpaque) || iframeSrcDoc ? (
                    isExternalURLOpaque ? (
                      <IFrame srcDoc={iframeSrcDoc} />
                    ) : (
                      <IFrame src={workflow_url} />
                    )
                  ) : (
                    <CircularProgress />
                  )}
                </IFrameContainer>
              </OriginalPage>
            ) : verificationUrl !== null &&
              verificationUrl?.match(
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g
              ) ? (
              <OriginalPage>
                <IFrameContainer>
                  {(verificationUrl && !isExternalURLOpaque) || iframeSrcDoc ? (
                    isExternalURLOpaque ? (
                      <IFrame srcDoc={iframeSrcDoc} />
                    ) : (
                      <IFrame src={verificationUrl} />
                    )
                  ) : (
                    <CircularProgress />
                  )}
                </IFrameContainer>
              </OriginalPage>
            ) : (
              <List>
                {resources?.map((resource, index) => (
                  <ListItem
                    key={resource.id ?? `${resource?.meta?.aid}-${index}`}
                  >
                    {(isFastTrackArticle(resource?.meta) || isUrlToDoc(resource?.meta?.source))
                  ? (
                    <StyledLink
                          rel="noreferrer"
                          onClick={async () => {
                            if (
                              resource?.question !== "No relevant article found" &&
                              resource?.question !== "Default Knowledge Article"
                            ) {
                              navigateToArticle(resource);
                            }
                          }}
                        >
                          {resource?.meta?.title || <Skeleton />}
                        </StyledLink>
                      ) : (
                    <StyledLink
                      rel="noreferrer"
                      target="_blank"
                      href={resource?.meta?.source}
                    >
                      {(resource?.meta?.title && (
                        <>
                          {resource?.meta?.title}
                          <OpenInNewOutlinedIcon sx={{ fontSize: "0.875rem" }} />
                        </>
                      )) || <Skeleton />}
                    </StyledLink>
                  )
                }
                <ResourceDescription>
                      {typeof resource?.meta !== "string" &&
                        typeof resource?.meta?.firstLine === "string" && (
                          <>
                            {sanitizeHtml(
                              urlify(
                                resource?.meta?.firstLine?.replace(
                                  /(?:\\n)/g,
                                  " "
                                )
                              ),
                              {
                                allowedTags: [],
                                allowedAttributes: {},
                              }
                            )}
                            <StyledLink
                              rel="noreferrer"
                              style={{
                                display: "inline-block",
                                marginLeft: "10px",
                              }}
                              onClick={async () => {
                                navigateToArticle(resource);
                              }}
                            >
                              Show more
                            </StyledLink>
                          </>
                        )}
                    </ResourceDescription>
                    <Footer>
                      <>
                        <span style={{ marginRight: "0.5rem" }}>
                          Was this helpful?
                        </span>
                        <div>
                          {resource?.likeStatus === 1 ? (
                            <Tooltip title="Undo">
                              <StyledIconButton
                                sx={{ color: "#109648" }}
                                onClick={() =>
                                  changeLikeStatusOfResource(0, resource.id)
                                }
                              >
                                <ThumbUpIcon sx={{ fontSize: 18 }} />
                              </StyledIconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Like">
                              <StyledIconButton
                                onClick={() => {
                                  addReaction("like", resource);
                                  changeLikeStatusOfResource(1, resource.id);
                                }}
                              >
                                <ThumbUpOutlinedIcon sx={{ fontSize: 18 }} />
                              </StyledIconButton>
                            </Tooltip>
                          )}
                          {resource?.likeStatus === -1 ? (
                            <Tooltip title="Undo">
                              <StyledIconButton
                                sx={{ color: "#ED474A" }}
                                onClick={() =>
                                  changeLikeStatusOfResource(0, resource.id)
                                }
                              >
                                <ThumbDownIcon sx={{ fontSize: 18 }} />
                              </StyledIconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Dislike">
                              <StyledIconButton
                                onClick={() => {
                                  addReaction("dislike", resource);
                                  changeLikeStatusOfResource(-1, resource.id);
                                }}
                              >
                                <ThumbDownOutlinedIcon sx={{ fontSize: 18 }} />
                              </StyledIconButton>
                            </Tooltip>
                          )}
                        </div>
                        <div style={{ marginLeft: "1rem" }}>
                          <FeedbackMessage
                            style={{
                              color:
                                resource?.likeStatus === 1
                                  ? "#109648"
                                  : "#ED474A",
                            }}
                          >
                            {resource?.likeStatus === 1
                              ? "Glad it helped!"
                              : resource?.likeStatus === -1
                              ? "Sorry we couldn't help you."
                              : ""}
                          </FeedbackMessage>
                        </div>
                      </>
                    </Footer>
                  </ListItem>
                ))}
                {(searching ||
                  isLoading ||
                  (chat.chatOn && similarity?.length === 0)) && (
                  <LoadingContainer>
                    <LoadingDot />
                    <LoadingDot />
                    <LoadingDot style={{ margin: 0 }} />
                  </LoadingContainer>
                )}
              </List>
            )}
          </Scrollbars>
        </>
      )}
      {activeTab === "workflows" && <WorkflowList workflows={workflows} />}
    </Wrapper>
  );
};

// Exports:
export default Resources;