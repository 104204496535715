// Packages:
import styled, { keyframes } from 'styled-components'


// Exports:
// const fadeIn = (y) => keyframes`
//   from {
//     filter: opacity(0);
//     transform: translateY(${ y ?? 1 }rem);
//   }
// `

export const Wrapper = styled.div`
  width: 40%;
  height: 92%;
  padding: 0.5rem;
  padding-bottom: 5rem;
  font-size: 14px;
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background: #ffffff;
  overflow: hidden;
  visibility: ${(props) => (props.openCollapse ? 'hidden' : 'unset')};

  @media (max-width: 1024px) {
    width: 98%;
    min-height: 98%;
  }

  @media (max-width: 768px) {
    display: ${(props) => (props.tab === 'resources' ? '' : 'none')};
    font-size: 16px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  padding: 10px 0;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
`

export const Title = styled.div`
  padding-left: 1rem;
  color: #333333;
`

// export const ListItem = styled.li`
//   display: flex;
//   flex-direction: column;
//   width: auto;
//   margin-bottom: 10px;
//   padding: 1rem;
//   border-radius: 0.25rem;
//   background-color: #F4F4F9;
//   /* animation: ${ fadeIn(2) } 0.5s ease; */ // TODO: Add this back when the re-rendering loop hell is fixed

//   @media (max-width: 1024px) {
//     margin-bottom: 1.5rem;
//   }
// `

// export const StyledLink = styled.a`
//   display: block;
//   line-height: 30px;
//   text-decoration: none;
//   color: #2f80ed;
// `

// export const ResourceDescription = styled.div`
//   margin-top: 5px;
//   margin-bottom: 5px;
//   font-size: 14px;
//   font-weight: 400;

//   @media (max-width: 768px) {
//     font-size: 16px;
//   }
// `

// export const Footer = styled.div`
//   margin-top: 10px;
//   display: flex;
//   align-items: center;
//   width: 100%;
//   height: fit-content;
//   font-weight: normal;
//   color: #5f5f5f;

//   @media (max-width: 768px) {
//     height: 2rem;
//   }
// `;

// export const FeedbackMessage = styled.span`
//   font-weight: 600;
// `;
