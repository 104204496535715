// Packages:
import axios from "axios";

// Constants:
import { API_ENDPOINT } from "../../../constants/Endpoint";
import { toast } from "react-toastify";

const headers = {
  "x-api-key": process.env.REACT_APP_API_KEY,
};

const makeRequest = async (method, url, data = null, params = null) => {
  try {
    const response = await axios({ method, url, data, headers, params });
    return response?.data;
  } catch (error) {
    console.error(error);
    if (error.response?.data?.message) {
      toast.error(`Request failed : ${error.response?.data?.message}`);
    } 
    throw error;
  }
};

export const autosaveWorkflow = (newWorkflow) =>
  makeRequest("post", `${API_ENDPOINT}/autosaveWorkflow`, newWorkflow);

export const getAllWorkflows = (orgId, owner, agent_type) =>
  makeRequest("get", `${API_ENDPOINT}/workflows`, null, {
    orgId,
    owner,
    agent_type,
  });

export const getWorkflowContent = (orgId, workflowId, owner, status, version) =>
  makeRequest("get", `${API_ENDPOINT}/workflows/content/${workflowId}`, null, {
    orgId,
    owner,
    status,
    version,
  });

export const workflowOperations = (operation, workflowData) =>
  makeRequest("post", `${API_ENDPOINT}/workflows/${operation}`, {
    ...workflowData,
  });

export const getWorkflow = (orgId, workflowId, owner, status, version) =>
  makeRequest("get", `${API_ENDPOINT}/workflows/${workflowId}`, null, {
    orgId,
    owner,
    status
  });

export const ingestWorkflowToStateMachine = (orgId, workflow) =>
  makeRequest("post", `${API_ENDPOINT}/state-machine-engine`, workflow);

/******************Supervisor comments on articles *************** */
export const addCommentOnWorkflowAPI = (orgid, workflowid, comment, author) =>
  makeRequest("post", `${API_ENDPOINT}/addCommentOnWorkflow `, {
    orgid,
    workflowid,
    comment,
    author,
  });

export const fetchCommentOnWorkflowAPI = (orgid, workflowid) =>
  makeRequest("post", `${API_ENDPOINT}/fetchCommentOnWorkflow`, {
    orgid,
    workflowid,
  });
