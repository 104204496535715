// Constants:
const ROUTES = {
  INDEX:  '/',
  NOT_FOUND: '/not-found',
  CUSTOMER_SERVICE: '/customer-service',
  NEED_HELP: '/need-help/:dialog_id',
  NEED_HELP_V2: '/v2/need-help/:dialog_id',
  KNOWLEDGE_ARTICLE: `/need-help`,
  KNOWLEDGE_ARTICLE_V2: `/v2/need-help`,
  AGENT: {
    LOGIN: '/agent/login',
    FORGOT_PASSWORD: '/agent/forgot-password',
    AI_AGENT: '/agent/ai-agent',
    WORKFLOWS: '/agent/workflows',
    WORKFLOWS_V2:'/v2/agent/workflows',
    PERFORMANCE: '/agent/performance',
    SECURITY_CREDENTIALS: '/agent/security-credentials',
    KNOWLEDGE: '/agent/knowledge',
    KNOWLEDGE_V2: '/v2/agent/knowledge',
    DATA: '/agent/data',
    DASHBOARD: '/agent/dashboard',
    DATA_V2: '/v2/agent/data'  
  },
  ADMIN: {
    ACCOUNTS: '/admin/accounts'
  }
};

// Exports:
export default ROUTES;