// Packages:
import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  MenuItem,
  FormControl,
  Select,
  styled
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { MdCancel } from 'react-icons/md';

// Styles:
import { FieldName, VariableField, Variable } from './styles';

const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

// Functions:
const ShippingConfiguration = ({ node, updateNode, deleteNode }) => {
  // Constants:
  const CARRIER = {
    SHIPPING_EASY: 'SHIPPING EASY'
  };

  const SHIPPINGFUNCTIONALITY = {
    FETCH_ORDER: 'FETCH ORDER',
    EDIT_ADDRESS: 'EDIT ADDRESS',
    CHANGE_SHIPPING_TYPE: 'CHANGE SHIPPING TYPE',
    CREATE_RETURN_SHIPMENT: 'CREATE RETURN SHIPMENT',
    PRINT_SHIPPING_LABEL: 'PRINT SHIPPING LABEL',
    ADD_MANUAL_ORDER: 'ADD MANUAL ORDER'
  };

  const SHIPPINGTYPE = {
    FIRST_CLASS_MAIL: 'FIRST CLASS MAIL',
    PRIORITY_MAIL: 'PRIORITY MAIL',
    PRIORITY_MAIL_EXPRESS: 'PRIORITY MAIL EXPRESS',
    MEDIA_MAIL: 'MEDIA MAIL',
    PARCEL_SELECT: 'PARCEL SELECT'
  };

  // State:
  const [carrier, setCarrier] = useState(node.carrier);
  const [shippingFunctionality, setShippingFunctionality] = useState(
    node.shippingFunctionality
  );
  const [shippingType, setShippingType] = useState(node.shippingType);
  const [multipleOptions, setMultipleOptions] = useState('');
  const [options, setOptions] = useState([]);

  // Effects:
  useEffect(() => {
    setCarrier(node.carrier);
    setShippingFunctionality(node.shippingFunctionality);
    setShippingType(node.shippingType);
  }, [node.carrier, node.shippingFunctionality, node.shippingType]);

  useEffect(() => {
    updateNode(node.id, { carrier });
  }, [carrier]);

  useEffect(() => {
    updateNode(node.id, { shippingFunctionality });
  }, [shippingFunctionality]);

  useEffect(() => {
    updateNode(node.id, { shippingType });
  }, [shippingType]);

  const optionOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = multipleOptions.trim();
    if (
      (key === ',' || key === 'Enter') &&
      trimmedInput.length &&
      !options?.includes(trimmedInput)
    ) {
      e.preventDefault();
      updateNode(node.id, { detailsToDisplay: [...options, trimmedInput] });
      setOptions((prevState) => [...prevState, trimmedInput]);
      setMultipleOptions('');
    }
  };

  const handleOptionValueChange = (e) => {
    const { value } = e.target;
    setMultipleOptions(value);
  };

  const deleteOption = (index) => {
    setOptions((prevState) => prevState?.filter((option, i) => i !== index));
  };

  // Return:
  return (
    <div>
      <FieldName>Carrier</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="shipping-carrier-select"
          value={carrier}
          onChange={(e) => setCarrier(e.target.value)}
        >
          {Object.values(CARRIER).map((functionality, index) => (
            <StyledMenuItem key={index} value={functionality}>
              {functionality}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>

      <FieldName>Functionality</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="shipping-functionality-select"
          value={shippingFunctionality}
          onChange={(e) => setShippingFunctionality(e.target.value)}
        >
          {Object.values(SHIPPINGFUNCTIONALITY).map((functionality, index) => (
            <StyledMenuItem key={index} value={functionality}>
              {functionality}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>

      {shippingFunctionality === 'CHANGE SHIPPING TYPE' ? (
        <>
          <FieldName>Type</FieldName>
          <StyledFormControl size="small" fullWidth>
            <Select
              id="shipping-type-select"
              value={shippingType}
              onChange={(e) => setShippingType(e.target.value)}
            >
              {Object.values(SHIPPINGTYPE).map((functionality, index) => (
                <StyledMenuItem key={index} value={functionality}>
                  {functionality}
                </StyledMenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </>
      ) : (
        <></>
      )}

      {shippingFunctionality === 'FETCH ORDER' ? (
        <>
          <FieldName>Details to display</FieldName>
          <VariableField>
            {options?.map((option, index) => (
              <Variable key={option}>
                {option}
                <MdCancel
                  style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                  onClick={() => deleteOption(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              value={multipleOptions}
              placeholder="Enter details to display"
              onKeyDown={optionOnKeyDown}
              onChange={handleOptionValueChange}
              size="small"
              fullWidth
            />
          </VariableField>
        </>
      ) : (
        <></>
      )}

      <Button
        variant="contained"
        color="error"
        size="small"
        startIcon={<DeleteOutlineOutlinedIcon />}
        sx={{ textTransform: 'none', mt: '1rem' }}
        onClick={() => deleteNode(node.id)}
      >
        Delete Node
      </Button>
    </div>
  );
};

// Exports:
export default ShippingConfiguration;
