import { toast } from 'react-toastify';
import { listKA, loadQnaToSimilarity } from '../../../api';
import createQuestionsMetaArrays from '../../createQuestionsMetaArrays';
import logEntityChanges from '../../logEntityChanges';

const deleteArticleFunction = async (incomingObject, deleteKA, userDetails) => {
  // console.log(incomingObject);
  const { dialog_id, orgid } = incomingObject;
  const arrayOfArticles = await listKA(orgid);
  const listOfAllArticles = [
    ...arrayOfArticles?.qna,
    ...arrayOfArticles?.intent
  ].filter(
    (article) => article?.meta !== undefined && article?.dialog_id !== dialog_id
  );
  const [questions, metas] = createQuestionsMetaArrays(
    listOfAllArticles,
    dialog_id
  );
  try {
    const response = await loadQnaToSimilarity(orgid, questions, metas);
    if (
      response?.status === 'OK' ||
      response?.error.includes('max_df corresponds to < documents than min_df')
    ) {
      const data = {
        dialog_id: incomingObject.dialog_id,
        isarticle: incomingObject.isarticle,
        orgid: incomingObject.orgid,
        target: incomingObject.target,
        access: incomingObject.access,
        articleId: incomingObject.articleId
      };
      await deleteKA(data);
      logEntityChanges(
        data.orgid, 'article', data.articleId,
        userDetails?.email, 'Published delete', {}
      );
    }
  } catch (error) {
    console.error(error);
    toast.error(error);
  }
};

export default deleteArticleFunction;
