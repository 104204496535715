export const TICKETCHANNEL = {
  ZENDESK: "ZENDESK",
  GORGIAS: "GORGIAS",
  HELPSCOUT:"HELPSCOUT",
  FRESHDESK:"FRESHDESK",
  SALESFORCE:"SALESFORCE",
  CLOSE:"CLOSE",
  ZOHO:"ZOHO"
};

export const TICKETACTION = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  FETCH_NEW_TICKETS: "FETCH NEW TICKETS",
  FETCH_TICKET_BY_ID: "FETCH TICKET BY ID",
  PREDICT_TAGS: "PREDICT TAGS",
  EXTRACT_INFORMATION: "EXTRACT INFORMATION",
};

export const CHANNEL = {
  NONE: "NONE",
  EMAIL: "EMAIL",
  SMS: "SMS",
  WHATSAPP: "WHATSAPP",
  FASTTRACK_CHAT: "FASTTRACK CHAT",
};

export const STATUS = {
  NONE:"NONE",
  NEW: "NEW",
  OPEN: "OPEN",
  PENDING: "PENDING",
  HOLD: "HOLD",
  SOLVED: "SOLVED",
  CLOSED: "CLOSED",
};

export const DEFAULT_OUTPUT = {
    key: "ticket_id",
    value: {
      fieldName: "ticket_id",
      value: null,
    },
  }

export const DEFAULT_OUTPUT_SUBJECT = {
    key: "subject",
    value: {
      fieldName: null,
      value: null,
    },
  }

export const DEFAULT_OUTPUT_BASEURL={
    key: "baseUrl",
    value: {
      fieldName: null,
      value: null,
    },
  }
export const DEFAULT_OUTPUT_APIKEY={
    key: "apiKey",
    value: {
      fieldName: null,
      value: null,
    },
  }
export const DEFAULT_OUTPUT_USERNAME={
    key: "username",
    value: {
      fieldName: null,
      value: null,
    },
  }

export const DEFAULT_OUTPUT_SENDER={
  key: "sender",
  value: {
    fieldName: null,
    value: null,
  },
} 
export const DEFAULT_OUTPUT_PHONENUMBER={
  key: "phoneNumber",
  value: {
    fieldName: null,
    value: null,
  },
}  

export const DEFAULT_OUTPUT_NAME={
  key: "name",
  value: {
    fieldName: null,
    value: null,
  },
} 

export const DEFAULT_OUTPUT_COMPANYNAME={
  key: "companyName",
  value: {
    fieldName: null,
    value: null,
  },
} 

export const DEFAULT_OUTPUT_GROUPID={
  key: "group_id",
  value: {
    fieldName: null,
    value: null,
  },
} 

export const DEFAULT_OUTPUT_REQUESTER={
  key: "requester",
  value: {
    fieldName: null,
    value: null,
  },
} 

export const DEFAULT_OUTPUT_FIRST_NAME={
  key: "firstName",
  value: {
    fieldName: null,
    value: null,
  },
} 

export const DEFAULT_OUTPUT_LAST_NAME={
  key: "lastName",
  value: {
    fieldName: null,
    value: null,
  },
} 