import { toast } from 'react-toastify';
import {
  checkSimilarityStatus,
  fetchDialogidMappingAPI,
  listKA,
  loadQnaToSimilarity
} from '../../../api';
import createQuestionsMetaArrays from '../../createQuestionsMetaArrays';
import fetchDialogIdFn from './fetchDialogIdFn';
import sanitizeHtml from 'sanitize-html';
import { urlify } from '../../urlify';
import logEntityChanges from '../../logEntityChanges';

const saveArticle = async (
  articleData,
  orgid,
  setKnowledgeArticleFn,
  isNew,
  changeIsNew,
  changeIsEdit,
  deleteUnverifiedKnowledgeArticle,
  deleteDraft,
  userDetails
) => {
  const dialog_id =
    articleData?.dialog_id || fetchDialogIdFn(articleData, orgid);
  const name = articleData?.display_name || articleData?.question;
  // console.log({ qnaArray, intentArray });

  const newTrainingPhrases = articleData?.trainingPhrases?.map(
    (phrase) => phrase?.desc
  );

  const data = {
    orgid,
    name,
    answer: ':)',
    trainingPhrases: [name, ...newTrainingPhrases],
    isarticle: articleData.meta.isarticle,
    meta: {
      ...articleData.meta,
      articleId: articleData?.articleId || articleData?.meta?.articleId,
      firstLine:
        articleData?.meta?.formattedText?.length < 105
          ? sanitizeHtml( urlify(articleData?.meta?.formattedText), {
              allowedTags: [],
              allowedAttributes: {}
            })
          : sanitizeHtml( urlify(articleData?.meta?.formattedText.slice(0, 100)), {
              allowedTags: [],
              allowedAttributes: {}
            })
    }
  };
  if (!data?.name || data?.name?.trim()?.length === 0) {
    toast.error('Title cannot be empty');
    throw new Error('Title cannot be empty');
  } else if (!data?.meta?.target) {
    toast.error('Select the target user');
    throw new Error('Select the target user');
  } else if (!data?.meta?.access) {
    toast.error('Select privacy level');
    throw new Error('Select privacy level');
  } else if (
    !data?.meta?.formattedText ||
    data?.meta?.formattedText?.trim()?.length < 1
  ) {
    toast.error('Description cannot be empty');
    throw new Error('Description cannot be empty');
  } else {
    changeIsEdit(false);
    changeIsNew(false);
    try {
      let createOrNot = false;
      try {
        const response = await checkSimilarityStatus(orgid);
        const status = response?.response?.creating;
        if (status === false) createOrNot = true;
      } catch (error) {
        if (error.response.data.error) createOrNot = true;
      }
      if (createOrNot) {
        try {
          await setKnowledgeArticleFn(data);
          logEntityChanges(
            orgid, 'article', data.meta.articleId,
            userDetails.email, 'Published update', {}
          );
          const arrayOfArticles = await listKA(orgid);
          const listOfAllArticles = [
            ...arrayOfArticles?.qna,
            ...arrayOfArticles?.intent
          ].filter((article) => article?.meta !== undefined);
          const [questions, tempMetas] = createQuestionsMetaArrays(
            listOfAllArticles,
            dialog_id
          );
          const metas = tempMetas.map((meta) => {
            const returnValue = {
              ...meta
            };
            delete returnValue['formattedText'];
            return returnValue;
          });
          await loadQnaToSimilarity(orgid, questions, metas);
          if (articleData?.meta?.published === undefined) {
            const deleteData = {
              orgid,
              target: articleData?.meta?.target,
              access: articleData?.meta?.access,
              articleId: articleData?.articleId || articleData?.meta?.articleId,
              createdBy: articleData?.createdBy || userDetails?.email
            };
            // console.log({ saveData: data, deleteData });

            await deleteDraft(deleteData);
          } else if (articleData?.meta?.published === false) {
            const deleteData = {
              orgid,
              target: articleData?.meta?.target,
              access: articleData?.meta?.access,
              articleId: articleData?.articleId || articleData?.meta?.articleId
            };
            // console.log({ saveData: data, deleteData });

            await deleteUnverifiedKnowledgeArticle(deleteData);
          }
        } catch (error) {
          console.error(error);
          throw error;
        }
      } else {
        toast.error('Please wait before submitting another article');
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        toast.error('An item with this title already exists');
      } else {
        toast.error('Could not submit, some error occured');
      }
      console.error(error);
      throw error;
    }
  }
};

export default saveArticle;