import React, { useCallback } from "react";
import { saveDraft } from "../../../lib/workflows/draftsOperations";
import { WORKFLOW_IS_DRAFT } from "../../../constants/studioV2";
import { TextField, styled } from "@mui/material";

const StyledTextField = styled(TextField)`
  width: 30%;
  margin-left: 10px;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const InlineEdit = ({ title, setTitle ,name,getModifiedWorkflow,currentRemoteWorkflow,setCurrentRemoteWorkflow,nodes,edges }) => {
  const [editingValue, setEditingValue] = React.useState(name);

  const onTitleChange = useCallback((event) => {
    setEditingValue(event.target.value);
  }, []);
  const onTitleChangeKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter" || event.key === "Escape") {
        event.target.blur();
        setEditingValue(event.target.value);
      }
    },
    []
  );

  const onTitleBlur = useCallback(
    async (event) => {
      const newName = event.target.value.trim();
      if (newName === "") {
        setEditingValue(title);
      } else {
        setEditingValue(newName);
        setTitle(newName);
        const modifiedWorkflow = getModifiedWorkflow(
          currentRemoteWorkflow,
          nodes,
          edges,
          newName
        );
        const newDraft = await saveDraft(modifiedWorkflow);
        setCurrentRemoteWorkflow(newDraft);
      }
    },
    [title, currentRemoteWorkflow, nodes, edges, setTitle, setCurrentRemoteWorkflow]
  );

  return (
    <>
      {currentRemoteWorkflow?.meta?.status === WORKFLOW_IS_DRAFT ? (
        <StyledTextField
          id="workflow-name"
          variant="outlined"
          value={editingValue}
          onChange={onTitleChange}
          onKeyDown={onTitleChangeKeyDown}
          onBlur={onTitleBlur}
          placeholder="Please enter workflow name"
          size="small"
        />
      ) : (
        <div style={{ marginLeft: 5, fontWeight: "bold", color: "gray" }}>
          {editingValue}
        </div>
      )}
    </>
  );
};

export default InlineEdit;
