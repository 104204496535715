// Packages:
import React, { useState, useEffect, memo, useCallback } from "react";
import { FormControl, MenuItem, Select, TextField, styled } from "@mui/material";

import BaseConfiguration from "../BaseConfiguration";
import { AI_ACTIONS, AI_RESPONSE_FIELDS, DEFAULT_AI_PROMPT } from "./utils";
import AutocompleteGlobal from "../../Components/AutocompleteGlobal";
import { FieldName } from "../styles";

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;
const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

// Functions:

const AIConfiguration = ({ node, updateNode, deleteNode, isEditable,setNodes }) => {
  
  // State:
  const [action, setAction] = useState(node.action);
  const [aiPrompt, setAiPrompt] = useState(node.aiPrompt ?? DEFAULT_AI_PROMPT);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields, setOutputFields] = useState([]);

  // Effects:
  useEffect(() => {
    setAction(node.action);
    setAiPrompt(node.aiPrompt ?? DEFAULT_AI_PROMPT);
  }, [
    node.action,
    node.aiPrompt?.value?.fieldName,
    node.aiPrompt?.value?.value,
  ]);

  useEffect(() => {
    updateNode(node.id, {
      action,
      aiPrompt,
      dynamicFields: [dynamicFields,...AI_RESPONSE_FIELDS],
      outputFields: outputFields,
    });
  }, [
    action,
    aiPrompt?.value?.fieldName,
    aiPrompt?.value?.value,
    dynamicFields
  ]);

  const handleAiActionSelect = useCallback((e) => {
    setAction(e.target.value);
  }, []);

  // Return:
  return (
    <BaseConfiguration
      setDynamicFields={setDynamicFields}
      node={node}
      isEditable={isEditable}
      deleteNode={deleteNode}
      outputFields={outputFields}
      setOutputFields={setOutputFields}
      setNodes={setNodes}
    >
      <FieldName>ACTION</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="ai-action-select"
          disabled={!isEditable}
          value={action}
          onChange={handleAiActionSelect}
        >
          {Object.entries(AI_ACTIONS).map(([key, value]) => (
            <StyledMenuItem key={key} value={key}>
              {value}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <AutocompleteGlobal
        Title="AI PROMPT"
        dynamicFields={dynamicFields}
        data={aiPrompt}
        setData={setAiPrompt}
        DEFAULT_OUTPUT={DEFAULT_AI_PROMPT}
        isEditable={isEditable}
      />
    </BaseConfiguration>
  );
};

// Exports:
export default memo(AIConfiguration);