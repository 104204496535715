// Packages:
import styled, { keyframes } from "styled-components";

// Exports:
const fadeIn = (y) => keyframes`
  from {
    filter: opacity(0);
    transform: translateY(${y ?? 1}rem);
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 98%;
  font-size: 14px;
  margin-bottom: 2.5rem;

  @media (max-width: 1024px) {
    height: 97.5%;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  padding: 10px 0;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
`;

export const Title = styled.div`
  padding-left: 1rem;
`;

export const FlexButton = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding-right: 1rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const OriginalPage = styled.div`
  padding: 0.5rem;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 2px 1px -1px rgb(0 0 0 / 12%);
  background: #ffffff;

  @media (max-width: 1024px) {
    width: 98%;
    min-height: 98%;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const List = styled.ul`
  font-weight: 600;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const IFrameContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 3.5rem);
  width: 100%;
  border-radius: 0 0 0.5rem 0.5rem;
`;

export const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
`;
export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  width: auto;
  margin-bottom: 10px;
  padding: 1rem;
  border-radius: 0.25rem;
  background-color: #f4f4f9;
  /* animation: ${fadeIn(
    2
  )} 0.5s ease; */ // TODO: Add this back when the re-rendering loop hell is fixed

  @media (max-width: 1024px) {
    margin-bottom: 1.5rem;
  }
`;

export const StyledLink = styled.a`
  display: block;
  line-height: 30px;
  text-decoration: none;
  color: #2f80ed;
`;

export const ResourceDescription = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  transform: scale(0.75);
`;

export const Footer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  height: fit-content;
  font-weight: normal;
  color: #5f5f5f;

  @media (max-width: 768px) {
    height: 2rem;
  }
`;

export const FeedbackMessage = styled.span`
  font-weight: 600;
`;

export const TabContainer = styled.div`
  display: flex;
`;

export const Tab = styled.div`
  padding: 10px;
  cursor: pointer;
  margin-right: 20px;
  font-size: 16px;
  color: ${(props) => (props.active ? "#4caf50" : "#333")};
  border-bottom: ${(props) => (props.active ? "4px solid #4caf50" : "none")};
`;

const TabContent = styled.div`
  margin-top: 20px;
`;
