import styled from 'styled-components';

export const TitleInput = styled.input`
  width: 100%;
  margin-bottom: 10px;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background: #e8f0fe;
  color: #333333;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  font-size: 1.3rem;
  outline: none;
  border: none;
  transition: all 0.25s ease;
  @media (max-width: 1024px) {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 520px) {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

export const Title = styled.div`
  margin-bottom: 0.25rem;
  padding: 0.25rem 0.75rem;
  color: #333333;
  font-weight: 600;
  font-size: 1.3rem;
  outline: none;
  @media (max-width: 1024px) {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 520px) {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;
