import styled from "styled-components";

export const FieldName = styled.div`
  margin-bottom: 0.5rem;
  font-weight: 600;
`;
export const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.25rem;
`;
