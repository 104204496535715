// Packages:
import React, { useState, useEffect } from 'react';
import useInput from '../../../../lib/hooks/use-input';
import {
  Button,
  MenuItem,
  FormControl,
  Select,
  TextField,
  styled
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { MdCancel } from 'react-icons/md';

// Styles:
import { FieldName, VariableField, Variable } from './styles';

const StyledFormControl = styled(FormControl)`
  margin-bottom: 1rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 14px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 14px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

// Functions:
const DecisionConfiguration = ({ node, updateNode, deleteNode }) => {
  // Constants:
  const DECISIONTYPE = {
    BINARY: 'BINARY',
    MULTIPLE: 'MULTIPLE'
  };

  const {
    value: orderId,
    setValue: setDecisionCriteria,
    bind: bindDecisionCriteria
  } = useInput(node.orderId ?? '');
  const [decisionType, setDecisionType] = useState(node.decisionType);
  const [multipleOptions, setMultipleOptions] = useState('');
  const [options, setOptions] = useState([]);

  // console.log(node)

  // Effects:
  useEffect(() => {
    setDecisionCriteria(node.orderId);
    setDecisionType(node.decisionType);
  }, [node.orderId, node.decisionType]);

  useEffect(() => {
    updateNode(node.id, { orderId, decisionType, options });
  }, [orderId, decisionType, options]);

  const optionOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = multipleOptions.trim();
    if (
      (key === ',' || key === 'Enter') &&
      trimmedInput.length &&
      !options?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setOptions((prevState) => [...prevState, trimmedInput]);
      setMultipleOptions('');
    }
  };

  const handleOptionValueChange = (e) => {
    const { value } = e.target;
    setMultipleOptions(value);
  };

  const deleteOption = (index) => {
    setOptions((prevState) => prevState?.filter((option, i) => i !== index));
  };

  // Return:
  return (
    <div>
      <FieldName>Decision Type</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="decision-type-select"
          value={decisionType}
          onChange={(e) => setDecisionType(e.target.value)}
        >
          {Object.values(DECISIONTYPE).map((functionality, index) => (
            <StyledMenuItem key={index} value={functionality}>
              {functionality}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>

      <FieldName>Decision Criteria</FieldName>
      <StyledTextField
        id="decision-criteria"
        variant="outlined"
        placeholder="Please enter decision criteria"
        {...bindDecisionCriteria}
        size="small"
        fullWidth
      />

      {decisionType === 'MULTIPLE' ? (
        <>
          <FieldName>Options</FieldName>
          <VariableField>
            {options?.map((option, index) => (
              <Variable key={option}>
                {option}
                <MdCancel
                  style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                  onClick={() => deleteOption(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              value={multipleOptions}
              placeholder="Enter an option"
              onKeyDown={optionOnKeyDown}
              onChange={handleOptionValueChange}
              size="small"
              fullWidth
            />
          </VariableField>
        </>
      ) : (
        <></>
      )}

      <Button
        variant="contained"
        color="error"
        size="small"
        startIcon={<DeleteOutlineOutlinedIcon />}
        sx={{ textTransform: 'none', mt: '1rem' }}
        onClick={() => deleteNode(node.id)}
      >
        Delete Node
      </Button>
    </div>
  );
};

// Exports:
export default DecisionConfiguration;