// Packages:
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useInput from '../../lib/hooks/use-input';
import uuid from 'react-uuid';
import { useSelector, useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import {
  IconButton,
  Tooltip,
  Button,
  OutlinedInput,
  InputAdornment,
  FormControl,
  Popover,
  styled
} from '@mui/material';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import stopwords from '../../lib/stopwords.js';

// Components:
import {
  // Fileuploader,
  ExportButton,
  SearchTagsComponent,
  AddFaq,
  FAQComponent
} from '../../components';

// Redux:
import {
  getContentActions,
  updateQueryInStore
} from '../../redux/actions/contentActions';

// Styles:
import { Wrapper, SearchSection, Header } from './styles';
import Dropdown from './Dropdown';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import filterArticlesBasedOnTagSearch from '../../lib/filterArticlesBasedOnTagSearch';

const StyledFormControl = styled(FormControl)`
  width: 35%;
  font-size: 14px;
  & .MuiOutlinedInput-root {
    font-size: 14px;
  }
`;

const StyledPopover = styled(Popover)`
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  & .MuiPaper-root {
    margin-top: 0.5rem;
    padding: 1rem;
  }
`;

// Functions:
const FAQ = () => {
  const organisation_info = useSelector((state) => state.content.org_info);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const agent_type = useSelector((state) => state.content.agent_type);
  const loadingArticles = useSelector((state) => state.content.loadingArticles);
  const answer = useSelector((state) => state.content.answer)||[];
  const qna = useSelector((state) => state.content.qna)||[];
  const unverifiedIntent = useSelector(
    (state) => state.content.unverifiedIntent
  )||[];
  const unverifiedQna = useSelector((state) => state.content.unverifiedQna)||[];
  const draftQna = useSelector((state) => state.content.draftQna)||[];
  const draftIntent = useSelector((state) => state.content.draftIntent)||[];
  const dispatch = useDispatch();
  const funcContentActions = getContentActions(dispatch);

  // Constants:
  const orgid = organisation_info?.org_data?._id;

  // State:
  const [anchorEl, setAnchorEl] = useState(null);
  const { value: searchValue, setValue, bind: bindSearch } = useInput('');
  const [articles, setArticles] = useState({
    intent: [],
    qna: []
  });
  const [tags, setTags] = useState([]);
  const [listingArticles, setListingArticles] = useState(true);
  const [articleType, setArticleType] = useState([]);
  const [bit, setBit] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const [modalProps, setModalProps] = useState({
    actionButton: '',
    title: '',
    message: '',
    actionFn: null
  });

  // Ref:
  const firstUpdate = useRef(true);

  // Functions:
  const handleModalProps = useCallback((val) => {
    setModalProps(val);
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const searchFAQ = useCallback(() => {
    setTags((prev) => [...prev, { id: uuid(), value: searchValue }]);
    setValue('');
  }, [searchValue, setValue]);

  const handleTagDelete = useCallback(
    (tag) => {
      const deleteTags = tags.filter((tagNew) => tagNew.id !== tag.id);
      setTags(deleteTags);
    },
    [tags]
  );

  const handleFiltersTooltipClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFiltersTooltipClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleDropdownChange = useCallback((val) => {
    setArticleType(val);
  }, []);

  const filterByTags = () => {
    const tempArticles = {
      intent: [...draftIntent, ...unverifiedIntent, ...answer],
      qna: [...draftQna, ...unverifiedQna, ...qna]
    };
    const tempTags = tags?.map((tag) => tag?.value?.split(' ')).flat(1);
    const cleanTags = tempTags?.filter((tag) => !stopwords.includes(tag));
    const otherTags = cleanTags?.filter((tag) => !tag?.includes(':'));
    const fields = cleanTags
      ?.filter((tag) => tag?.includes(':'))
      .map((tag) => tag.split(':'));
    let paramForSearch = {};
    fields.forEach((field) => {
      if (paramForSearch[field[0]]) {
        paramForSearch[field[0].toLowerCase()].push(field[1].toLowerCase());
      } else {
        paramForSearch[field[0].toLowerCase()] = [field[1].toLowerCase()];
      }
    });
    let qnaArticles;
    let intentArticles;
    if (Object.keys(paramForSearch).length > 0) {
        qnaArticles = tempArticles?.qna?.filter((article) =>
          filterArticlesBasedOnTagSearch(article, paramForSearch)
        );

        intentArticles = tempArticles?.intent?.filter((article) =>
          filterArticlesBasedOnTagSearch(article, paramForSearch)
        );
    } 
    
    else {
      qnaArticles = [...tempArticles?.qna];
      intentArticles = [...tempArticles?.intent];
    }

    let int;
    let ka;
    if (otherTags?.length > 0) {
      otherTags.forEach((tag) => {
        ka = qnaArticles?.filter((article) =>
          [
            article?.question?.toLowerCase(),
            article?.meta?.firstLine?.toLowerCase()
          ].some(
            (a) => a && typeof a === 'string' && a?.includes(tag.toLowerCase())
          )
        );
        int = intentArticles?.filter((article) =>
          [
            article?.question?.toLowerCase(),
            article?.meta?.firstLine?.toLowerCase()
          ].some(
            (a) => a && typeof a === 'string' && a?.includes(tag.toLowerCase())
          )
        );
      });
    } else {
      int = [...intentArticles];
      ka = [...qnaArticles];
    }

    setArticles({
      intent: int,
      qna: ka
    });
  };

  useEffect(() => {
    dispatch(updateQueryInStore(''));
    let cancel = false;
    const fetchData = async () => {
      setListingArticles(true);
      await funcContentActions.fetchKnowledgeArticles(orgid);
      if (agent_type === 'supervisor' || agent_type === 'superadmin') {
        await funcContentActions.fetchUnverifiedKnowledgeArticles(orgid);
        await funcContentActions.getDrafts(orgid, userDetails?.email);
      } else {
        await funcContentActions.fetchUnverifiedKAOfSingleAgent(
          orgid,
          userDetails?.email
        );
        await funcContentActions.getDrafts(orgid, userDetails?.email);
      }
      setListingArticles(false);
    };
    fetchData().catch(console.error);
    if (cancel) return;
    return () => {
      cancel = true;
    };
  }, [agent_type, orgid, userDetails?.email, bit]);

  useDeepCompareEffect(() => {
    if (firstUpdate.current && (qna?.length > 0 || answer?.length > 0)) {
      firstUpdate.current = false;
    }
    if (!loadingArticles) {
      if (tags.length === 0 && articleType.length === 0) {
        setArticles({
          intent: [...draftIntent, ...unverifiedIntent, ...answer],
          qna: [...draftQna, ...unverifiedQna, ...qna]
        });
      } else {
        if (articleType.length > 0) {
          let tempArticles = {
            intent: [],
            qna: []
          };
          if (articleType.includes('Unverified')) {
            tempArticles = {
              intent: [...unverifiedIntent, ...tempArticles.intent],
              qna: [...unverifiedQna, ...tempArticles.qna]
            };
          }
          if (articleType.includes('Drafts')) {
            tempArticles = {
              intent: [...draftIntent, ...tempArticles.intent],
              qna: [...draftQna, ...tempArticles.qna]
            };
          }
          if (articleType.includes('Published')) {
            tempArticles = {
              intent: [...tempArticles.intent, ...answer],
              qna: [...tempArticles.qna, ...qna]
            };
          }
          setArticles(tempArticles);
          if (tags.length > 0) {
            filterByTags();
          }
        } else filterByTags();
      }
    }
  }, [
    loadingArticles,
    tags,
    qna,
    answer,
    unverifiedIntent,
    unverifiedQna,
    draftIntent,
    draftQna,
    articleType,
    articles?.qna,
    articles?.intent
  ]);

  // Return:
  return (
    <Wrapper>
      <ConfirmationDialog
        title={modalProps?.title}
        message={modalProps?.message}
        performAction={modalProps?.actionFn}
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        actionButton={modalProps?.actionButton}
      />
      <SearchSection>
        <Header>
          <StyledFormControl variant="outlined">
            <OutlinedInput
              id="search-articles"
              type="text"
              onKeyDown={(event) =>
                event.key === 'Enter' ? searchFAQ() : null
              }
              {...bindSearch}
              startAdornment={
                <InputAdornment position="start">
                  <Tooltip title="search">
                    <IconButton
                      onClick={() => searchFAQ()}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="start"
                    >
                      <SearchOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Show Advanced Filters">
                    <IconButton
                      onClick={handleFiltersTooltipClick}
                      edge="end"
                    >
                      <TuneOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
              size="small"
              placeholder="Search articles"
            />
          </StyledFormControl>

          <StyledPopover
            open={open}
            anchorEl={anchorEl}
            onClose={handleFiltersTooltipClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            elevation={3}
          >
            <h4>{"Advanced Filter Options"}</h4>
            <p>{"access:<public|private>,"} <em>{"example: access:private"}</em></p>
            <p>{"tags:<tagname>,"} <em>{"example: tags:help"}</em></p>
          </StyledPopover>

          <Dropdown
            articleType={articleType}
            handleDropdownChange={handleDropdownChange}
          />
          
          <AddFaq />
          
          <Button
            variant="contained"
            size="medium"
            startIcon={<RefreshOutlinedIcon />}
            sx={{ textTransform: 'none' }}
            onClick={() => setBit((prev) => !prev)}
          >
            Refresh
          </Button>

          {/* <Fileuploader /> */}

          <ExportButton articles={articles} />
        </Header>

        <SearchTagsComponent tags={tags} handleTagDelete={handleTagDelete} />
      </SearchSection>
      <FAQComponent
        firstUpdate={firstUpdate}
        listingArticles={listingArticles}
        setListingArticles={setListingArticles}
        articles={articles}
        handleOpenModal={handleOpenModal}
        handleModalProps={handleModalProps}
        userDetails={userDetails}
      />
    </Wrapper>
  );
};

// Exports:
export default FAQ;