import React, { useCallback, useEffect, useRef, useState } from 'react'
import ActionSelector from './actionSelector'
import { useSelector } from 'react-redux';
import { Button, CircularProgress, IconButton,TextField, Tooltip } from '@mui/material';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import DataSaverOnSharpIcon from "@mui/icons-material/DataSaverOnSharp";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { WORKFLOW_IS_DRAFT, WORKFLOW_IS_PUBLISHED, WORKFLOW_IS_UNDER_REVIEW } from '../../../constants/studioV2';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { createDraft, createEmptyAutoflow, deleteDraft, saveDraft, submitDraft } from '../../../lib/workflows/draftsOperations';
import { toast } from 'react-toastify';
import { publishDraft, rejectDraft } from '../../../lib/workflows/reviewOperations';
import { ADMIN, SUPER_ADMIN, SUPERVISOR } from '../../../constants/user';
import { isWorkflowValid } from '../../../lib/workflows/commonOperations';
import { determineWorkflowStage } from '../utils';
import { archiveWorkflow } from '../../../lib/workflows/publishedOperations';
import { TextareaAutosize } from '@material-ui/core';


const TooltipIconButton = ({ title, onClick, icon, disabled, color }) => (
    <Tooltip title={title}>
        <IconButton onClick={onClick} disabled={disabled} color={color}>
            {icon}
        </IconButton>
    </Tooltip>
);

export const TEMPLATE_AUTOFLOW = {
    workflowId: "",
    orgId: '',
    meta: {
        settings: {},
        status: WORKFLOW_IS_DRAFT,
        isArchived: false,
        owner: "",
        tags: [],
        trainingPhases: [],
        s3Link: "",
        lastEdited: Date.now(),
        workflowName: "new draft flow",
        publishedBy: "",
        archivedBy: "",
        validFrom: "",
        validTo: "",
        isAutoFlow: true,
        guidelines: []
    },
};

const AutoFLow = ({ currentRemoteAutoFlow, setCurrentRemoteAutoFlow, searchParams, setSearchParams, setBit,loadingWorkflows,setIsAutoFlow }) => {

    const agent_type = useSelector((state) => state.content.agent_type);
    const orgId = localStorage.getItem("org_id");
    const [openModal, setOpenModal] = useState(false);
    const [guidelineText, setGuidelineText] = useState('');
    const workflowId = currentRemoteAutoFlow?.workflowId
    const userDetails = useSelector((state) => state.auth.userDetails);
    const [name, setName] = useState(currentRemoteAutoFlow?.meta?.workflowName)
    const [addingNewGuideline, setAddingNewGuideline] = useState(false)
    const isAutosaving = useRef(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const isEditable = currentRemoteAutoFlow?.meta?.status === WORKFLOW_IS_DRAFT
    const [currentVersion, setCurrentVersion] = useState(null)
    const [editingIndex, setEditingIndex] = useState(null); // Track which guideline is being edited
    const [isSaved,setIsSaved]=useState(false)
    const saveTimeoutRef = useRef(null); 

    const handleGuidelineChange = (index, newValue) => {
        const updatedGuidelines = [...currentRemoteAutoFlow.meta.guidelines];
        updatedGuidelines[index] = newValue;
        setCurrentRemoteAutoFlow({
            ...currentRemoteAutoFlow,
            meta: {
                ...currentRemoteAutoFlow.meta,
                guidelines: updatedGuidelines,
            },
        });
    };
   
    const saveGuidelines = () => {
        if (!isEditable) return;
        // Add new guideline if there is text in the input and remove any empty guidelines
        const updatedGuidelines = [
            ...(currentRemoteAutoFlow.meta.guidelines || []),
            ...(guidelineText.trim() ? [guidelineText.trim()] : []),
        ].filter(g => g.trim() !== '');

        setCurrentRemoteAutoFlow({
            ...currentRemoteAutoFlow,
            meta: {
                ...currentRemoteAutoFlow.meta,
                guidelines: updatedGuidelines,
            },
        });

        setGuidelineText('');
        setAddingNewGuideline(false);
        setIsSaved(true)

        if (saveTimeoutRef.current) {
            clearTimeout(saveTimeoutRef.current);
        }
        
        saveTimeoutRef.current = setTimeout(() => {
            setIsSaved(false);
            saveTimeoutRef.current = null;
        }, 1500);
    };


    const createWorkflowUrl = useCallback((workflow) => {
        const queryParams = {
            workflowId: workflow.workflowId,
            owner: workflow.meta?.owner ?? "NA",
            status: workflow.meta?.status ?? "NA",
        };
        setSearchParams(queryParams);
    }, [searchParams]);


    const handleOpenModal = async () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
        useEffect(() => {
            return () => {
                if (saveTimeoutRef.current) {
                    clearTimeout(saveTimeoutRef.current);
                }
            };
        }, []);


    const initiateDeleteWorkflow = async () => {
        if (isDeleting === false) setIsDeleting(true);
        else setIsDeleting(false);
        const currentWorkflow = currentRemoteAutoFlow;
        if (currentWorkflow.meta.status === WORKFLOW_IS_DRAFT) {
            await deleteDraft(currentRemoteAutoFlow);
            setIsAutoFlow(prev => !prev);
            setSearchParams()
            setCurrentRemoteAutoFlow(TEMPLATE_AUTOFLOW)
            setBit((prev) => !prev);
        } else if (currentWorkflow.meta.status === WORKFLOW_IS_UNDER_REVIEW) {
            const rejectedWorkflow = await rejectDraft(currentRemoteAutoFlow);
            createWorkflowUrl(rejectedWorkflow);
            setCurrentRemoteAutoFlow(rejectedWorkflow);
            setBit((prev) => !prev);
            return;
        } else {
            const workflowToBeArchived = {
                ...currentRemoteAutoFlow,
                meta: {
                    ...currentRemoteAutoFlow.meta,
                    archivedBy: userDetails?.email ?? "",
                    validTo: Date.now()
                },
            };
            await archiveWorkflow(workflowToBeArchived);
        }
        // if (workflows.length > 1 && currentWorkflow.workflowId === workflows[0].workflowId) {
        //   createWorkflowUrl(workflows[1]);
        // } else {
        //   setBit((prev)=>!prev)
        // }

        setOpenModal(false);
    };

    const initiateCreateAutoFlow = useCallback(async () => {
        const tempWorkflow = createEmptyAutoflow(orgId, userDetails?.email);
        const draft = await createDraft(tempWorkflow);
        console.log("draft", draft)
        createWorkflowUrl(draft);
        setCurrentRemoteAutoFlow(draft);
    }, []);

    const handleDraftSubmission = async () => {
        if (!isWorkflowValid(currentRemoteAutoFlow)) {
            return;
        }
        try {
            const savedWorkflow = await saveDraft(currentRemoteAutoFlow);
            const newWorkflow = await submitDraft(savedWorkflow);
            createWorkflowUrl(newWorkflow);
            setCurrentRemoteAutoFlow(newWorkflow);
            setBit((prev) => !prev);
        } catch (error) {
            toast.error("Failed to submit Draft");
            console.error(error);
        }
    };

    const handlePublish = async () => {
        if (!isWorkflowValid(currentRemoteAutoFlow)) {
            return;
        }
        if (agent_type.toLowerCase() === SUPERVISOR || agent_type.toLowerCase() === SUPER_ADMIN || agent_type.toLowerCase() === ADMIN) {
            //   setIsFetching(true);
            try {
                const workflowToBePublished = {
                    ...currentRemoteAutoFlow,
                    meta: {
                        ...currentRemoteAutoFlow.meta,
                        publishedBy: userDetails?.email ?? "",
                        validFrom: Date.now()
                    },
                };
                const publishedData = await publishDraft(workflowToBePublished);
                setCurrentVersion(publishedData?.meta?.currentVersion)
                createWorkflowUrl(publishedData);
                setCurrentRemoteAutoFlow(publishedData);
                toast.success("Draft published successfully");
                setBit((prev) => !prev);
            } catch (error) {
                toast.error("Failed to publish Draft");
                console.error(error);
            }
            //   setIsFetching(false);
        } else {
            toast.error("Only admins can Publish a submitted Draft");
        }
    };

    const getModifiedWorkflow = useCallback(
        (currentWorkflow, name) => {
            const tags = currentWorkflow?.meta?.tags ?? []
            return {
                ...currentWorkflow,
                orgId: orgId,
                meta: {
                    ...currentWorkflow.meta,
                    workflowName: name,
                    trainingPhases: tags
                },
            };
        },
        [orgId, name, currentRemoteAutoFlow]
    );

    const onChange = useCallback((event) => {
        setName(event.target.value);
    }, []);

    const onTitleChangeKeyDown = useCallback(
        (event) => {
            if (event.key === "Enter" || event.key === "Escape") {
                event.target.blur();
                setName(event.target.value);
            }
        },
        []
    );

    const onTitleBlur = useCallback(
        async (event) => {
            const newName = event.target.value.trim();
            if (newName === "") {
                setName(newName);
            } else {
                setName(newName);
                const modifiedWorkflow = getModifiedWorkflow(
                    currentRemoteAutoFlow,
                    newName
                );
                const newDraft = await saveDraft(modifiedWorkflow);
                setCurrentRemoteAutoFlow(newDraft);
            }
        },
        [name, currentRemoteAutoFlow, setCurrentRemoteAutoFlow, setName]
    );

    const initiateAutosaveWorkflow = async (modifiedWorkflow) => {
        if (isAutosaving.current) return;
        isAutosaving.current = true;
        const saveData = await saveDraft(modifiedWorkflow);
        setCurrentRemoteAutoFlow(saveData);
        isAutosaving.current = false;
    };

    const saveWorkflow = (workflow) => {
        const modifiedWorkflow = getModifiedWorkflow(workflow, name);
        if (determineWorkflowStage(currentRemoteAutoFlow) === WORKFLOW_IS_DRAFT)
            initiateAutosaveWorkflow(modifiedWorkflow);
    };

    const createAutoFromExistingAutoflow = async () => {
        const currentRemoteWorkflowCopy = currentRemoteAutoFlow
        delete currentRemoteWorkflowCopy?.meta?.version
        const newWorkflow = {
            ...currentRemoteWorkflowCopy,
            meta: {
                ...currentRemoteWorkflowCopy.meta,
                status: WORKFLOW_IS_DRAFT,
                publishedBy: "",
                lastEdited: Date.now(),
                owner: userDetails?.email,
                s3Link: "",
                validFrom: "",
                createdBy: userDetails?.email,
                createdFrom: currentVersion
            }
        }
        const newDraft = await createDraft(newWorkflow)
        createWorkflowUrl(newDraft)
        setCurrentRemoteAutoFlow(newDraft)
        setBit(prev => !prev)
    }
    if(loadingWorkflows)
         return <div style={{margin:'auto'}}><CircularProgress/></div>

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '94%', marginTop: '1rem', gap: 4, padding: '1rem' }}>
            {
                workflowId === '' ? <div style={{ marginTop: '20%', marginLeft: '40%' }}>
                    <Button color='primary' variant='contained' onClick={initiateCreateAutoFlow}>
                        Create a new AutoFlow
                    </Button>
                </div> : <>
                    <div style={{ display: 'flex', flex: 3, boxShadow: "0 4px 12px rgba(21, 41, 56, 0.86)", borderRadius: '10px', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem', marginBottom: '0.5rem' }}>
                            <div style={{ marginLeft: '40%' }}>
                                {currentRemoteAutoFlow?.meta?.status === WORKFLOW_IS_DRAFT ? (
                                    <TextField
                                        id="workflow-name"
                                        variant="outlined"
                                        value={name}
                                        onChange={onChange}
                                        onKeyDown={onTitleChangeKeyDown}
                                        onBlur={onTitleBlur}
                                        placeholder="Please enter Autoflow name"
                                        size="small"
                                        disabled={!isEditable}
                                    />
                                ) : (
                                    <div style={{ marginLeft: 5, fontWeight: "bold", color: "gray" }}>
                                        {name}
                                    </div>
                                )}
                            </div>
                            <div>
                                {openModal &&
                                    <ConfirmationDialog
                                        title={"Delete Workflow"}
                                        message={"Do you want to delete this Autoflow? This action cannot be undone."}
                                        performAction={initiateDeleteWorkflow}
                                        openModal={openModal}
                                        handleOpenModal={handleOpenModal}
                                        handleCloseModal={handleCloseModal}
                                        actionButton={"Delete"}
                                    />}
                                <TooltipIconButton
                                    title= {currentRemoteAutoFlow?.meta?.status===WORKFLOW_IS_PUBLISHED?'Archive Workflow':currentRemoteAutoFlow?.meta?.status===WORKFLOW_IS_UNDER_REVIEW?'Reject Draft':'Delete Draft'}
                                    onClick={handleOpenModal}
                                    icon={<DeleteOutlineOutlinedIcon />}
                                    color="error"
                                />
                                <TooltipIconButton
                                    title="Submit for Review"
                                    onClick={handleDraftSubmission}
                                    icon={<DataSaverOnSharpIcon fontSize="small" />}
                                    disabled={currentRemoteAutoFlow?.meta?.status !== WORKFLOW_IS_DRAFT}
                                    color="primary"
                                />
                                {(agent_type === "supervisor" || agent_type === "superadmin" || agent_type.toLowerCase() ==='admin') && (
                                    <>
                                        <TooltipIconButton
                                            title="Publish"
                                            icon={<PublishOutlinedIcon />}
                                            onClick={async () => {
                                                const modifiedWorkflow = getModifiedWorkflow(currentRemoteAutoFlow, name);
                                                setCurrentRemoteAutoFlow(modifiedWorkflow);
                                                await handlePublish(modifiedWorkflow);
                                            }}
                                            disabled={currentRemoteAutoFlow?.meta?.status !== WORKFLOW_IS_UNDER_REVIEW}
                                            color="success"
                                        />
                                        <TooltipIconButton
                                            title="Save Workflow"
                                            onClick={() => saveWorkflow(currentRemoteAutoFlow)}
                                            icon={<SaveAsOutlinedIcon />}
                                            color="success"
                                        />
                                    </>
                                )}
                                {currentRemoteAutoFlow.meta.status === WORKFLOW_IS_PUBLISHED && (
                                    <TooltipIconButton
                                        title="Edit Workflow"
                                        onClick={createAutoFromExistingAutoflow}
                                        icon={<EditOutlinedIcon fontSize="small" />}
                                        disabled={currentRemoteAutoFlow?.meta?.status !== WORKFLOW_IS_PUBLISHED}
                                        color="success"
                                    />
                                )}
                            </div>
                        </div>
                        <div style={{ padding: '1rem', overflowY: 'auto', maxHeight: '60vh', marginLeft: '20%' }}>
                            {currentRemoteAutoFlow.meta.guidelines?.length > 0 && (
                                <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
                                    {currentRemoteAutoFlow.meta.guidelines.map((guideline, index) => (
                                        <>
                                            <div style={{ marginBottom: '0.25rem', textTransform: 'none', color: '#6a7891', fontSize: '12px' }}>Guideline {index + 1}</div>
                                            {editingIndex === index ? 
                                            <TextareaAutosize
                                                key={index}
                                                minRows={4}
                                                disabled={!isEditable}
                                                value={guideline}
                                                onChange={(e) => handleGuidelineChange(index, e.target.value)}
                                                onBlur={() => setEditingIndex(null)}
                                                style={{
                                                    marginBottom: '1rem',
                                                    padding:'10px',
                                                    border:'none',
                                                    width: '80%',
                                                    borderRadius: '10px',
                                                    backgroundColor: '#f0f2f6',
                                                    color: '#63656d',
                                                    outline: 'none',
                                                    boxShadow: 'none',
                                                    minHeight:'10px',
                                                    overflow:'auto'
                                                }}
                                            />:
                                            <TextareaAutosize
                                                key={index}
                                                onClick={() => isEditable && setEditingIndex(index)}
                                                minRows={4}
                                                disabled={!isEditable}
                                                value={guideline}
                                                style={{
                                                    boxSizing:'border-box',
                                                    marginBottom: '1rem',
                                                    padding:'10px',
                                                    border:'none',
                                                    width: '80%',
                                                    borderRadius: '10px',
                                                    backgroundColor: '#f0f2f6',
                                                    color: '#63656d',
                                                    outline: 'none',
                                                    boxShadow: 'none',
                                                    minHeight:'10px',
                                                    overflow:'auto'
                                                }}
                                            />}
                                        </>
                                    ))}
                                </div>
                            )}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Button
                                    variant="outlined"
                                    disabled={!isEditable}
                                    onClick={() => setAddingNewGuideline(true)}
                                    style={{
                                        borderRadius: '8px',
                                        width: '17%',
                                        marginBottom: '1rem',
                                        padding: 2,
                                        textTransform: 'none',
                                        cursor: 'pointer',
                                        fontSize: '12px',
                                        color: 'gray',
                                        borderColor: 'gray',
                                        '&:hover': {
                                            borderColor: 'black',
                                            backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                        },
                                    }}
                                >
                                    Add more guidelines
                                </Button>{addingNewGuideline && <>
                                    <div style={{ marginBottom: '0.25rem', textTransform: 'none', color: '#6a7891', fontSize: '12px' }}>Additional Guideline</div>
                                    <TextareaAutosize
                                        minRows={4}
                                        disabled={!isEditable}
                                        value={guidelineText}
                                        onChange={(e) => setGuidelineText(e.target.value)}
                                        style={{
                                            marginBottom: '1rem',
                                            padding:'10px',
                                            border:'none',
                                            width: '80%',
                                            borderRadius: '10px',
                                            backgroundColor: '#f0f2f6',
                                            color: '#63656d',
                                            outline: 'none',
                                            boxShadow: 'none',
                                            minHeight:'10px',
                                            overflow:'auto'
                                        }}
                                    />
 </>
                                }
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={saveGuidelines}
                                        disabled={!guidelineText.trim() && !isEditable}
                                        style={{
                                            borderRadius: '8px',
                                            width: '13%',
                                            padding: 2,
                                            marginBottom: '1rem',
                                            textTransform: 'none',
                                            cursor: 'pointer',
                                            fontSize: '12px',
                                            color: 'gray',
                                            borderColor: 'gray',
                                            '&:hover': {
                                                borderColor: 'black',
                                                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                                            },
                                        }}
                                    >
                                        Save guidelines
                                    </Button>
                                    {
                                        isSaved && <div style={{width:'20%',backgroundColor:'lightgreen',padding:'5px',borderRadius:'5px',fontSize:'12px'}}>Saved successfully.</div>
                                    }
                               
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flex: 1, borderRadius: '10px', boxShadow: "0 4px 12px rgba(21, 41, 56, 0.86)" }}>
                        <ActionSelector setCurrentRemoteAutoFlow={setCurrentRemoteAutoFlow} currentRemoteAutoFlow={currentRemoteAutoFlow} />
                    </div>
                </>}
        </div>
    )
}

export default AutoFLow;