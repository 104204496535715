// Packages:
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  memo,
} from "react";
import debounce from "lodash.debounce";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  MiniMap,
  useEdgesState,
  Controls,
  // Position
} from "react-flow-renderer";
import { toast } from "react-toastify";
import uuid from "react-uuid";
import { useDispatch, useSelector } from "react-redux";
import { useDeepCompareEffect } from "react-use";
import {
  Tooltip,
  IconButton,
  TextField,
  styled,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import SaveAsOutlinedIcon from "@mui/icons-material/SaveAsOutlined";
import DataSaverOnSharpIcon from "@mui/icons-material/DataSaverOnSharp";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
// Constants:
import {
  NODE_TYPE,
  WORKFLOW_IS_DRAFT,
  WORKFLOW_IS_PUBLISHED,
  WORKFLOW_IS_UNDER_REVIEW,
} from "../../constants/studioV2";

// Components:
import {
  Assets,
  Configuration,
  Nodes,
  Workflows,
} from "../../components/StudioV2";
import {
  shouldAutosaveWorkflow,
  prepareNodes,
  determineWorkflowStage,
  checkForParallelParent,
  findNodeWithId,
} from "./utils";

import {
  fetchCommentOnWorkflowAPI,
  addCommentOnWorkflowAPI,
  getAllWorkflows,
} from "./utils/api";
import { CommentBox } from "../../components";

// Redux:
import { setSelectedNode } from "../../redux/actions/studioActions";

// Styles:
import {
  Wrapper,
  Title,
  ButtonGroup,
  Canvas,
  LoadingCanvas,
  MainDiv,
  SideBarCollapse,
  WorkflowOperationContainer,
} from "./styles";
import {
  createDraft,
  createEmptyDraft,
  deleteDraft,
  saveDraft,
  submitDraft,
} from "../../lib/workflows/draftsOperations";
import {
  isWorkflowValid,
  loadWorkflowData,
} from "../../lib/workflows/commonOperations";
import { ADMIN, SUPERVISOR, SUPER_ADMIN } from "../../constants/user";
import {
  publishDraft,
  rejectDraft,
} from "../../lib/workflows/reviewOperations";
import { archiveWorkflow } from "../../lib/workflows/publishedOperations";
import { useNavigate, useSearchParams } from "react-router-dom";
import ROUTES from "../../constants/routes";
import InlineEdit from "./utils/InlineEdit";
import { conditionType, operators, variables } from "../../components/StudioV2/RuleBox/utils";
import AutoFLow, { TEMPLATE_AUTOFLOW } from "./Autoflow";

const StyledTextField = styled(TextField)`
  width: 30%;
  margin-left: 10px;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;
// Functions:
const Studio = () => {
  const [showOpt, setShowOpt] = useState(true);
  const [currentRemoteAutoFlow,setCurrentRemoteAutoFlow]=useState(TEMPLATE_AUTOFLOW)

  // Constants:
  const [searchParams, setSearchParams] = useSearchParams();
  const orgId = localStorage.getItem("org_id");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Ref:
  const reactFlowWrapper = useRef(null);

  // Memo:
  const nodeTypes = useMemo(
    () => ({
      [NODE_TYPE.start]: Nodes.StartNode,
      [NODE_TYPE.leaf]: Nodes.LeafNode,
      [NODE_TYPE.message]: Nodes.MessageNode,
      [NODE_TYPE.form]: Nodes.FormNode,
      [NODE_TYPE.api]: Nodes.APINode,
      [NODE_TYPE.email]: Nodes.EmailNode,
      [NODE_TYPE.decision]: Nodes.DecisionNode,
      [NODE_TYPE.ticket]: Nodes.TicketNode,
      [NODE_TYPE.shopify]: Nodes.ShopifyNode,
      [NODE_TYPE.google_sheets]: Nodes.GoogleSheetsNode,
      [NODE_TYPE.shipping]: Nodes.ShippingNode,
      [NODE_TYPE.decision]: Nodes.DecisionNode,
      [NODE_TYPE.logistics]:Nodes.LogisticsNode,
      [NODE_TYPE.choice]:Nodes.ChoiceNode,
      [NODE_TYPE.ai]:Nodes.AINode,
      [NODE_TYPE.parallel]:Nodes.ParallelNode,
      [NODE_TYPE.parallelHandler]:Nodes.ParallelHandler,
      [NODE_TYPE.ticketure]:Nodes.TicketureNode,
      [NODE_TYPE.acuity]:Nodes.AcuityNode
    }),
    []
  );

  // State:
  const userDetails = useSelector((state) => state.auth.userDetails);
  const agent_type = useSelector((state) => state.content.agent_type);
  const TEMPLATE_WORKFLOW = {
    workflowId: "",
    orgId: orgId,
    meta: {
      nodes: [],
      edges: [],
      viewport: {
        x: 0,
        y: 0,
        zoom: 1,
      },
      settings: {},
      status: WORKFLOW_IS_DRAFT,
      isArchived: false,
      owner: userDetails?.email ?? "",
      tags: [],
      trainingPhases: [],
      s3Link: "",
      lastEdited: Date.now(),
      workflowName: "new draft",
      publishedBy: "",
      archivedBy: "",
      validFrom:"",
      validTo:"",
    },
  };

  const isAutosaving = useRef(false);
  const [workflows, setWorkflows] = useState([]);
  const [currentRemoteWorkflow, setCurrentRemoteWorkflow] =
    useState(TEMPLATE_WORKFLOW);
  const [workflowStage, setWorkflowStage] = useState(WORKFLOW_IS_DRAFT);
  const [id, setID] = useState("");
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [name, setName] = useState("Untitled");
  const [currentVersion,setCurrentVersion]=useState(null)
  const [canPublish, setCanPublish] = useState(false);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isCreatingNewWorkflow, setIsCreatingNewWorkflow] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [reviewed, setReviwed] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [bit, setBit] = useState(true);
  const [open, setOpen] = useState(false);
  const [workflowComments, setWorkflowComments] = useState([]);
  const [fetchingComments, setFetchingComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [isAutoFlow,setIsAutoFlow]=useState(false)

  // Functions:
  const fetchAllWorkflows = async (orgId) => {
    const fetchedWorkflowsData = await getAllWorkflows(
      orgId,
      userDetails.email,
      agent_type
    );
    const fetchedWorkflows = fetchedWorkflowsData?.data?.Workflows;
    fetchedWorkflows.sort(
      (workflowA, workflowB) =>
        workflowB.meta.lastEdited - workflowA.meta.lastEdited
    );
    return fetchedWorkflows;
  };

  const createWorkflowUrl = useCallback((workflow) => {
    const queryParams = {
      workflowId: workflow.workflowId,
      owner: workflow.meta?.owner ?? "NA",
      status: workflow.meta?.status ?? "NA",
    };
    setSearchParams(queryParams);
  },[searchParams]);

  const fetchData = useCallback(async () => {
    if (searchParams.get("workflowId"))
      try {
        const workflowId = searchParams.get("workflowId");
        const owner = searchParams.get("owner") ?? "NA";
        const status = searchParams.get("status") ?? "NA";
        const queryParams = {
          workflowId: workflowId,
          owner: owner,
          status: status,
        };

        const workflowdata = await loadWorkflowData(
          orgId,
          workflowId,
          owner,
          status,
        );  
        const isAutoFlow=workflowdata?.meta?.isAutoFlow || false;
        if(isAutoFlow){
          setCurrentRemoteAutoFlow(workflowdata)
          setIsAutoFlow(true)
        }else{
        setWorkflowStage(determineWorkflowStage(workflowdata));
        setName(workflowdata.meta.workflowName);
        setID(workflowdata.workflowId);
        setCurrentRemoteWorkflow(workflowdata);
        setNodes(() => workflowdata.meta.nodes);
        setEdges(() => workflowdata.meta.edges);
        if (agent_type === "supervisor" || agent_type === "superadmin" || agent_type.toLowerCase() === ADMIN) {
          setCanPublish(true);
        }
        const queryString = Object.keys(queryParams)
          .map((key) => key + "=" + queryParams[key])
          .join("&");
        navigate(`${ROUTES.AGENT.WORKFLOWS_V2}?${queryString}`, {
          state: {
            initialWorkflowData: currentRemoteWorkflow,
            isNew: true,
          },
        });
      }} catch (error) {
        toast.error("Failed to load Worlflow");
        console.error(error);
      }
  }, [orgId, searchParams, userDetails?.email]);

  useEffect(() => {
    fetchData();
  }, [searchParams]);
  
  // modified workflow
  const getModifiedWorkflow = useCallback(
    (currentWorkflow, nodes, edges, name) => {

      const startNode = nodes.find(node => node.data.label === "START");
      const access = startNode?.data?.access;
      const target = startNode?.data?.target;
      const tags = startNode?.data?.tags;
      const comment = startNode?.data?.comment;
      return {
        ...currentWorkflow,
        orgId: orgId,
        meta: {
          ...currentWorkflow.meta,
          access: access??"private",
          target: target??"agent",
          workflowName: name,
          trainingPhrases: tags??[],
          tags: comment??"",
          nodes: prepareNodes(nodes, edges),
          edges,
        },
      };
     },
    [orgId,name,nodes,edges,currentRemoteWorkflow]
  );

  const getSaveReadyWorkflow = useCallback(
    (modifiedWorkflow) => {
      return {
        ...modifiedWorkflow,
        orgId: orgId,
        meta: {
          ...modifiedWorkflow.meta,
          workflowName: name,
        },
      };
    },
    [name, orgId, reactFlowInstance]
  );
  // Auto save workflow
  const initiateAutosaveWorkflow = async (modifiedWorkflow) => {
    if (isAutosaving.current) return;
    isAutosaving.current = true;
    const workflowContent = getSaveReadyWorkflow(modifiedWorkflow);
    const saveData = await saveDraft(workflowContent);
    setCurrentRemoteWorkflow(saveData);
    isAutosaving.current = false;
  };

  //publish workflow
  const handlePublish = async () => {
    if (!isWorkflowValid(currentRemoteWorkflow)) {
      return;
    }
    if (
      agent_type.toLowerCase() === SUPERVISOR ||
      agent_type.toLowerCase() === SUPER_ADMIN ||
      agent_type.toLowerCase() === ADMIN
    ) {
      setIsFetching(true);
      try {
        const workflowToBePublished = {
          ...currentRemoteWorkflow,
          meta: {
            ...currentRemoteWorkflow.meta,
            publishedBy: userDetails?.email ?? "",
            validFrom:Date.now()
          },
        };
        const publishedData = await publishDraft(workflowToBePublished);
        setCurrentVersion(publishedData?.meta?.currentVersion)
        createWorkflowUrl(publishedData);
        setCurrentRemoteWorkflow(publishedData);
        toast.success("Draft published successfully");
        setBit((prev) => !prev);
      } catch (error) {
        toast.error("Failed to publish Draft");
        console.error(error);
      }
      setIsFetching(false);
    } else {
      toast.error("Only admins can Publish a submitted Draft");
    }
  };

  // create new workflow
  const initiateCreateNewWorkflow = useCallback(async () => {
    const tempWorkflow = createEmptyDraft(orgId, userDetails?.email);
    const draft = await createDraft(tempWorkflow);
    createWorkflowUrl(draft);
    setCurrentRemoteWorkflow(draft);
  }, []);
  // delete workflow
  const initiateDeleteWorkflow = async () => {
    if (isDeleting === false) setIsDeleting(true);
    else setIsDeleting(false);
    const currentWorkflow = currentRemoteWorkflow;
    if (currentWorkflow.meta.status === WORKFLOW_IS_DRAFT) {
      await deleteDraft(currentRemoteWorkflow);
    } else if (currentWorkflow.meta.status === WORKFLOW_IS_UNDER_REVIEW) {
      const rejectedWorkflow = await rejectDraft(currentRemoteWorkflow);
      createWorkflowUrl(rejectedWorkflow);
      setCurrentRemoteWorkflow(rejectedWorkflow);
      setBit((prev) => !prev);
      return;
    } else {
      const workflowToBeArchived = {
        ...currentRemoteWorkflow,
        meta: {
          ...currentRemoteWorkflow.meta,
          archivedBy: userDetails?.email ?? "",
          validTo:Date.now()
        },
      };
      await archiveWorkflow(workflowToBeArchived);
    }
    if (
      workflows.length > 1 &&
      currentWorkflow.workflowId === workflows[0].workflowId
    ) {
      createWorkflowUrl(workflows[1]);
    } else {
      setBit((prev)=>!prev)
    }

    setOpenModal(false);
  };

  const changeCommentsArray = useCallback((val) => {
    setComments(val);
  }, []);

  const handleOpenModal = async () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    changeCommentsArray([]);
    setOpenModal(false);
  };

  const handleDraftSubmission = async () => {
    if (!isWorkflowValid(currentRemoteWorkflow)) {
      return;
    }
    try {
      const savedWorkflow= await saveDraft(currentRemoteWorkflow);
      const newWorkflow = await submitDraft(savedWorkflow);
      createWorkflowUrl(newWorkflow);
      setCurrentRemoteWorkflow(newWorkflow);
      setBit((prev) => !prev);
    } catch (error) {
      toast.error("Failed to submit Draft");
      console.error(error);
    }
  };

  const handleAddComment = useCallback(
    async (comment, changeComment) => {
      const isPublished =
        currentRemoteWorkflow.meta.status === WORKFLOW_IS_PUBLISHED;
      if (comment !== "" && !isPublished) {
        let newId = comments.length + 1;
        const d = new Date();
        let newDateTime = d.toString().substring(0, 24);
        let newComment = {
          id: newId,
          createdAt: newDateTime,
          comment: comment,
          author: userDetails?.email,
        };
        changeCommentsArray([...comments, newComment]);
        changeComment("");
        addCommentOnWorkflowAPI(orgId, id, comment, userDetails?.email);
        const modifiedWorkflow = getModifiedWorkflow(
          currentRemoteWorkflow,
          nodes,
          edges,
          name
        );
        setCurrentRemoteWorkflow(modifiedWorkflow);
        const newWorkflow = getSaveReadyWorkflow(modifiedWorkflow);
        await saveDraft(newWorkflow);
      }
    },
    [
      changeCommentsArray,
      comments,
      currentRemoteWorkflow,
      edges,
      getModifiedWorkflow,
      getSaveReadyWorkflow,
      id,
      name,
      nodes,
      orgId,
      userDetails?.email,
    ]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    changeCommentsArray([]);
  }, [changeCommentsArray]);

  const handleClickOpen = useCallback(async (orgId, workflowId) => {
    setFetchingComments(true);
    setOpen(true);
    const response = await fetchCommentOnWorkflowAPI(orgId, workflowId);
    const comments = response?.comments;
    const newComments = comments?.map((comment) => {
      const createdAt = new Date(comment?.createdAt).toUTCString();
      return { ...comment, createdAt };
    });
    setWorkflowComments(newComments);
    setFetchingComments(false);
  }, []);

  // Effects:
  useEffect(() => {
    (async () => {
      setIsFetching(true);
      if (orgId) {
        const fetchedWorkflows = await fetchAllWorkflows(orgId);
        setWorkflows(fetchedWorkflows);
      }
      setIsFetching(false);
    })();
  }, [bit, name, isDeleting]);

  useEffect(() => {
    const handleResize = debounce(() => {
      setShowOpt(window.innerWidth <= 1300);
    }, 300);
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const updateWorkflowToSave = () => {
    if (reactFlowInstance && !isAutosaving.current) {
      const modifiedWorkflow = getModifiedWorkflow(
        currentRemoteWorkflow,
        nodes,
        edges,
        name
      );
      if (
        determineWorkflowStage(currentRemoteWorkflow) === WORKFLOW_IS_DRAFT &&
        shouldAutosaveWorkflow(currentRemoteWorkflow, modifiedWorkflow)
      ) {
        initiateAutosaveWorkflow(modifiedWorkflow);
      }
    }
  };

  const debounce_workflow = useCallback(debounce(updateWorkflowToSave, 10000), [
    name,
    nodes,
    edges,
    reactFlowInstance,
    getModifiedWorkflow,
    currentRemoteWorkflow,
    initiateAutosaveWorkflow,
  ]);
  useDeepCompareEffect(() => {
    debounce_workflow();
    return () => {
      debounce_workflow.cancel();
    };
  }, [debounce_workflow]);

  const saveWorkflow = (workflow) => {
    const modifiedWorkflow = getModifiedWorkflow(workflow, nodes, edges, name);
    if (determineWorkflowStage(currentRemoteWorkflow) === WORKFLOW_IS_DRAFT)
      initiateAutosaveWorkflow(modifiedWorkflow);
  };
  // Functions:
  const onConnect = useCallback(
    (params) => {
      const sourceNode=findNodeWithId(params.source,nodes);
      const targetNode=findNodeWithId(params.target,nodes)  
      let updatedNodes = [...nodes];
      let branchName = '';
      if (sourceNode.type === 'choice'&&params.sourceHandle!=='choice-node-output-default') {
        if (!sourceNode.data.branch) {
          branchName = `branch_${params.target}`;
          updatedNodes = updatedNodes.map(node => {
            if (node.id === params.source) {
              return { ...node, data: { ...node.data,choices:[...node.data.choices,{condition: conditionType[0],isConstant: true,operator: operators[0],variableType: '',
                variable: "",
                inputValue: "",
                isNegative: "",
                id:"",
                branch:`${branchName}`
              }
              ] } };
            }
            return node;
          });
        } else {
          branchName = sourceNode.data.branch;
        }

        setNodes(updatedNodes);
      } 
      else if(sourceNode.type === 'choice'&&params.sourceHandle==='choice-node-output-default'){
        branchName="default";
      }

      if ((checkForParallelParent(params.source, nodes) && checkForParallelParent(params.target, nodes)) ||
          (sourceNode?.type === 'parallel' && checkForParallelParent(params.target, nodes))||
         (targetNode?.type === 'parallel' && checkForParallelParent(params.source, nodes))
          ){
        toast.error("Edges can not be connected")
        return;
     }
      const handleLabel =sourceNode.type==='choice'?branchName: targetNode.type==='parallelHandler'?"parallelEnd":
        params.sourceHandle === null ||
        params.sourceHandle.substring(params.sourceHandle.length - 4) === "true"
          ? "success"
          : params.sourceHandle.substring(params.sourceHandle.length - 4) === "alse"
          ? "failure"
          : "default";
  
      const label = handleLabel;
  
      setEdges((currentEdges) =>
        addEdge(
          {
            ...params,
            animated: false,
            style: { strokeWidth: 3 },
            handleLabel,
            label,
          },
          currentEdges
        )
      );
    },
    [setEdges,nodes]
  );

  const onEdgesDelete = useCallback(params => {
    const sourceNodeId = params?.[0].source;
    const targetNodeId = params?.[0].target;
    // Find the source and target nodes
    const sourceNode = findNodeWithId(sourceNodeId, nodes);
    const targetNode = findNodeWithId(targetNodeId, nodes);
  
    if (sourceNode.type === 'choice') {
      const branchName = `branch_${params?.[0].target}`;
        const updatedChoices = sourceNode.data.choices.filter(choice => choice.branch !== branchName);
        const updatedNodes = nodes.map(node => {
        if (node.id === sourceNodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              choices: updatedChoices
            }
          };
        }
        return node;
      });
  
      // Update the state with the updated nodes
      setNodes(updatedNodes);
    }
  }, [nodes, setNodes]);

  const makeNodesEquispacedAndCentered = useCallback(() => {
    if (!reactFlowWrapper.current) return;
  
    const spacingX = 250; // Horizontal spacing between nodes
    const spacingY = 150; // Vertical spacing between nodes
  
    const updatedNodes = nodes.map(node => {
      const connectedEdges = edges.filter(edge => edge.source === node.id);
      const connectedNodes = connectedEdges.map(edge => nodes.find(n => n.id === edge.target));
      const totalConnected = connectedNodes.length;
      const parentX = node.position.x;
      const parentY = node.position.y;
  
      if (node.type === 'parallelHandler') {
        const parallelNode = nodes.find(n => n.type === 'parallel' && n.data.parentId === node.data.parentId);
        if (parallelNode) {
          node.position.x = parallelNode.position.x;
          // Adjust y position based on the edge
          const edgeIndex = connectedEdges.findIndex(edge => edge.target === node.id);
          if (edgeIndex !== -1) {
            node.position.y = parentY + spacingY * (edgeIndex + 1);
          }
        }
      } else {
        if (totalConnected === 1) {
          // Rule 1: Single edge
          const connectedNode = connectedNodes[0];
          connectedNode.position.x = parentX;
          connectedNode.position.y = parentY + spacingY;
        } else if (totalConnected === 2) {
          // Rule 2: Double edge
          connectedNodes.forEach((connectedNode, index) => {
            if (index === 0) {
              connectedNode.position.x = parentX - spacingX;
            } else {
              connectedNode.position.x = parentX + spacingX;
            }
            connectedNode.position.y = parentY + spacingY;
          });
        } else if (totalConnected === 3) {
          // Rule 3: Three edges
          connectedNodes.forEach((connectedNode, index) => {
            if (index === 0) {
              connectedNode.position.x = parentX - spacingX;
            } else if (index === 1) {
              connectedNode.position.x = parentX;
            } else {
              connectedNode.position.x = parentX + spacingX;
            }
            connectedNode.position.y = parentY + spacingY;
          });
        } else if (node.type === 'parallel') {
          // Rule 4: Parallel node
          connectedNodes.forEach((connectedNode, index) => {
            connectedNode.position.x = parentX + (spacingX - (totalConnected / 2)) + index;
            connectedNode.position.y = parentY + spacingY;
          });
        }
      }
  
      return node;
    });
  
    setNodes(updatedNodes);
  }, [nodes, edges]);
  
  const createWorkflowFromExistingWorkflow=async()=>{
    const currentRemoteWorkflowCopy=currentRemoteWorkflow
    delete currentRemoteWorkflowCopy?.meta?.version
    const newWorkflow={ ...currentRemoteWorkflowCopy,
          meta: {
            ...currentRemoteWorkflowCopy.meta,
            status: WORKFLOW_IS_DRAFT,
            publishedBy: "",
            lastEdited: Date.now(),
            owner:userDetails?.email,
            s3Link:"",
            validFrom: "",
            createdBy: userDetails?.email,
            createdFrom: currentVersion
          }
     }
     const newDraft=await createDraft(newWorkflow)
     createWorkflowUrl(newDraft)
     setCurrentRemoteWorkflow(newDraft)
     setBit(prev=>!prev)
  }

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      if(!searchParams.get("workflowId")){
        toast.error("Please select a workflow or create one from the Workflows section on the left.")
        return
      }
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      if (typeof type === "undefined" || !type) return;
      const newNodeID = uuid();
      const newNode = {
        id: newNodeID,
        type,
        position: reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        }),
        data: {
          ...JSON.parse(event.dataTransfer.getData("data")),
          id: newNodeID,
          type,
        },
      };
      setNodes((currentNodes) => currentNodes.concat(newNode));
    },
    [reactFlowInstance]
  );

  const updateNode = useCallback(
    debounce((id, data) => {
      setNodes((oldNodes) =>
        oldNodes.map((node) =>
          node.id === id ? { ...node, data: { ...node.data, ...data } } : node
        )
      );
    }, 250), // Adjust debounce delay as needed
    [] // Debounce function dependencies (none needed here since debounce creates a stable function)
  );

  const deleteNode = useCallback(
    (id) => {
      setNodes((oldNodes) => oldNodes.filter((node) => node.id !== id));
      setEdges((currentEdges) =>
        currentEdges.filter((edge) => ![edge.source, edge.target].includes(id))
      );
      dispatch(setSelectedNode());
    },
    []
  );

  const handleSelectedWorkflow = useCallback(
    (selectedWorkflowId, selectedWorkflowStage) => {
      if (selectedWorkflowId === id && selectedWorkflowStage === workflowStage) return;
      const selectedWorkflow = workflows.find((workflow) => {
        const currentWorkflowStage = determineWorkflowStage(workflow);
        if (
          workflow.workflowId === selectedWorkflowId &&
          currentWorkflowStage === selectedWorkflowStage
        )
          return workflow;
      });
      if (!selectedWorkflow) return;
      createWorkflowUrl(selectedWorkflow);
    },
    [workflows, createWorkflowUrl, id, determineWorkflowStage, workflowStage]
  );

  //for sidebar
  const [openCollapse, setOpenCollapse] = React.useState(false);

  const handleDrawerOpen =useCallback(() => {
    setOpenCollapse(true);
  },[]);

  const handleDrawerClose = useCallback(() => {
    setOpenCollapse(false);
  },[]);
  const message = (
    <div>
      Are you sure you want to delete{" "}
      <b>{currentRemoteWorkflow?.meta?.workflowName}</b>?{" "}
    </div>
  );
  const actionButton = "Delete";
  // Return:
  return (
    <Wrapper>
        <FormControlLabel
                  sx={{ position:'absolute', left:!isAutoFlow?'25%':'50%',marginTop:!isAutoFlow?'1%':'-0.5%',zIndex:'10'}}
                    control={
                      <Switch checked={isAutoFlow} onChange={()=>{setIsAutoFlow(!isAutoFlow)}} name="gilad" />
                    }
                    label={isAutoFlow?'Switch to Workflow':'Auto flow'}
                  />
      {isAutoFlow?<AutoFLow currentRemoteAutoFlow={currentRemoteAutoFlow} setCurrentRemoteAutoFlow={setCurrentRemoteAutoFlow} searchParams={searchParams} setSearchParams={setSearchParams} setBit={setBit} loadingWorkflows={isFetching} setIsAutoFlow={setIsAutoFlow}/>:<>
      <Tooltip title="Workflow Menu">
        <IconButton
          onClick={handleDrawerOpen}
          sx={{
            mt: "10px",
            backgroundColor: "#FFFFFF",
            boxShadow:
              "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)",
            ...(openCollapse && { display: "none" }),
          }}
        >
          <MenuOutlinedIcon />
        </IconButton>
      </Tooltip>
      <ReactFlowProvider>
        <MainDiv>
          {openCollapse && (
            <SideBarCollapse>
              <Workflows
                handleDrawerClose={handleDrawerClose}
                openCollapse={openCollapse}
                isFetching={isFetching}
                isCreatingNewWorkflow={isCreatingNewWorkflow}
                workflows={workflows}
                currentWorkflowID={id}
                workflowStage={workflowStage}
                onClick={handleSelectedWorkflow}
                createNewWorkflow={initiateCreateNewWorkflow}
                setBit={setBit}
              />
            </SideBarCollapse>
          )}
          {currentRemoteWorkflow?.meta?.status === WORKFLOW_IS_DRAFT && (
            <Assets />
          )}
          <Canvas ref={reactFlowWrapper}>
            {isFetching ? (
              <>
                <Title>Canvas</Title>
                <LoadingCanvas>
                  <CircularProgress />
                </LoadingCanvas>
              </>
            ) : (
              <>
                <Title>
                  {open && (
                    <CommentBox
                      open={open}
                      handleClose={handleClose}
                      agent_type={agent_type}
                      documentId={id}
                      handleAddComment={handleAddComment}
                      storeComments={workflowComments}
                      loading={fetchingComments}
                      changeCommentsArray={changeCommentsArray}
                      comments={comments}
                    />
                  )}
                  <ConfirmationDialog
                    title={"Delete Workflow"}
                    message={message}
                    performAction={initiateDeleteWorkflow}
                    openModal={openModal}
                    handleOpenModal={handleOpenModal}
                    handleCloseModal={handleCloseModal}
                    actionButton={actionButton}
                  />
                  Canvas:
                  <InlineEdit title={name} name={name} setTitle={setName} getModifiedWorkflow={getModifiedWorkflow} currentRemoteWorkflow={currentRemoteWorkflow} nodes={nodes} edges={edges} setCurrentRemoteWorkflow={setCurrentRemoteWorkflow} />
                  <ButtonGroup showOpt={showOpt}>
                    {agent_type !== "endUser" && (
                      <Tooltip title="Info">
                        <IconButton onClick={() => handleClickOpen(orgId, id)}>
                          <InfoOutlinedIcon
                            color="primary"
                            sx={{ fontSize: 25 }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Delete">
                      <IconButton color="error" onClick={handleOpenModal}>
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Submit for Review">
                      <IconButton
                        disabled={
                          currentRemoteWorkflow?.meta?.status !==
                          WORKFLOW_IS_DRAFT
                        }
                        color="primary"
                        onClick={handleDraftSubmission}
                      >
                        <DataSaverOnSharpIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    {(agent_type === "supervisor" ||
                      agent_type === "superadmin" || agent_type.toLowerCase() === ADMIN) && (
                      <>
                        <Tooltip title="Publish">
                          <IconButton
                            color="success"
                            disabled={
                              currentRemoteWorkflow?.meta?.status !==
                              WORKFLOW_IS_UNDER_REVIEW
                            }
                            onClick={async () => {
                              const modifiedWorkflow = getModifiedWorkflow(
                                currentRemoteWorkflow,
                                nodes,
                                edges,
                                name
                              );
                              setCurrentRemoteWorkflow(modifiedWorkflow);
                              await handlePublish(modifiedWorkflow);
                            }}
                          >
                            <PublishOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Save Workflow">
                          <IconButton
                            color="success"
                            onClick={() => saveWorkflow(currentRemoteWorkflow)}
                          >
                            <SaveAsOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Equispace Nodes">
                          <IconButton
                            color="success"
                            onClick={makeNodesEquispacedAndCentered}
                          >
                            <VerticalAlignCenterIcon/>
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  {(currentRemoteWorkflow.meta.status === WORKFLOW_IS_PUBLISHED&&
                        <Tooltip title="Edit Workflow">
                          <IconButton
                            color="success"
                            disabled={
                              currentRemoteWorkflow?.meta?.status !==
                              WORKFLOW_IS_PUBLISHED
                            }
                            onClick={createWorkflowFromExistingWorkflow}
                          >
                            <EditOutlinedIcon fontSize='small'/>
                          </IconButton>
                        </Tooltip>)}
                  </ButtonGroup>
                </Title>
                <ReactFlow
                  onContextMenu={(e) => {
                    e.preventDefault();
                  }}
                  nodes={nodes}
                  edges={edges}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onConnect={
                    currentRemoteWorkflow?.meta?.status === WORKFLOW_IS_DRAFT
                      ? onConnect
                      : () => {}
                  }
                  onInit={setReactFlowInstance}
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  zoomOnScroll={false}
                  panOnScroll={true}
                  onEdgesDelete={onEdgesDelete}             
                  defaultZoom={1}
                  style={{ height: "95%" }}
                  nodeTypes={nodeTypes}
                  nodesConnectable={
                    currentRemoteWorkflow?.meta?.status === WORKFLOW_IS_DRAFT
                  }
                >
                 <WorkflowOperationContainer>
                   {/* <button onClick={makeNodesEquispacedAndCentered}>Equispace Nodes</button> */}

                   {/* <button onClick={undo}>Undo</button>
                   <button onClick={redo}>Redo</button> */}
                 </WorkflowOperationContainer>
                  <Controls />
                  <MiniMap />
                </ReactFlow>
              </>
            )}
          </Canvas>
          <Configuration
            updateNode={updateNode}
            deleteNode={deleteNode}
            settings={currentRemoteWorkflow?.meta?.settings}
            currentRemoteWorkflow={currentRemoteWorkflow}
            setCurrentRemoteWorkflow={setCurrentRemoteWorkflow}
            isEditable={
              currentRemoteWorkflow?.meta?.status === WORKFLOW_IS_DRAFT
            }
            setEdges={setEdges}
            setNodes={setNodes}  
          />
        </MainDiv>
      </ReactFlowProvider>
      </>}
    </Wrapper>
  );
};

// Exports:
export default memo(Studio);