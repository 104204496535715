// Packages:
import styled from 'styled-components'


// Exports:
export const Subtitle = styled.div`
  margin-top: 3.5rem;
  margin-bottom: 2.188rem;
  font-size: 16px;
  color: #333333;
`

export const Form = styled.div`
  margin-bottom: 1rem;
  padding: 1.5rem 1.5rem 1.5rem;
  font-size: 16px;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%);
  background: #ffffff;
  border-radius: 5px;
`

export const InputField = styled.div`
  margin-bottom: 1.5rem;
`;
