// Packages:
import React from 'react';
import { Tooltip, IconButton, Badge } from '@mui/material';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { styled } from '@mui/material/styles';


// Styles:
import { Wrapper } from './styles';

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    font-size: 0.6rem;
    height: 15px;
    min-width: 15px;
    width: 15px;
    top: -3px;
    right: -5px;
  }
`;


// Functions:
const MobileResourceActions = ({ setTab }) => {

    return (
        <Wrapper>
            <Tooltip title="Chat">
                <IconButton color="primary" sx={{ backgroundColor: '#FFFFFF', boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)' }} onClick={() => setTab('chat')}>
                    <StyledBadge badgeContent={4} color="error" >
                        <QuestionAnswerOutlinedIcon fontSize="small" />
                    </StyledBadge>
                </IconButton>
            </Tooltip>
        </Wrapper>
    );
};

// Exports:
export default MobileResourceActions;
