// Packages:
import styled from 'styled-components'


// Exports:
export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	gap: 0.5rem;
	width: 99%;
	height: 100%;
	margin-top: 4.5rem;
	align-items: flex-start;
	font-size: 14px;
	overflow: hidden;
`
export const Title = styled.div`
	position: relative;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
  	color: #333333;
`

export const ButtonGroup = styled.div`
	position: absolute;
	right: 0;
	display: flex;
`

export const Canvas = styled.div`
	flex-grow: 1;
	height: 98%;
	border-radius: 4px;
	overflow: hidden;
	padding: 8px;
	font-size: 0.9rem;
	text-align: center;
	box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
	background: #ffffff;
`
export const LoadingCanvas = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 70%;
	width: 100%;
`

export const MainDiv = styled.div`
	display:flex;
	height: -webkit-fill-available;
	width:100%;
	gap:0.5rem;
`
export const SideBarCollapse = styled.div`
  	width: 14.2%;
`