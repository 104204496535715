// Packages:
import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { WebSocketConnectionContext } from '../../App';

// Imports:
import { ChatBox, ResourceBox } from '../../components';
import { Iframe } from '../../components/KnowledgeArticleV2';
import { getContentActions } from '../../redux/actions/contentActions';

// Styles:
import { Wrapper } from './styles';

// Functions:
// This includes everything shown on chat Page
const Chat = () => {
  // State:
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState('chat');
  const [numberOfResources, setNumberOfResources] = useState(0);
  const [source, setSource] = useState('');
  const [pageNumber, setPageNumber] = useState(null);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const organisation_info = useSelector((state) => state.content.org_info);
  const orgId = organisation_info?.org_data?._id;

  const { changeConnection } = useContext(WebSocketConnectionContext);
  const dispatch = useDispatch();
  const contentActions = getContentActions(dispatch);

  useEffect(() => {
    changeConnection(true);
    if (!isLoggedIn)
      return () => {
        changeConnection(false);
      };
  }, [isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    const workflowId = searchParams.get("workflowId");
    const workflowName = searchParams.get("workflowName");
    const ticketId = searchParams.get("ticketId");
    if (workflowId) {
      contentActions.interact(
        orgId,
        "execute workflow from zendesk",
        {
          qna: {},
          intent: {},
          workflows: [{
            meta: {
              id: workflowId,
              title: workflowName || "workflow",
              description: workflowName,
              ticketId
            }
          }]
        },
        true
      );
    }
  }, [isLoggedIn]);

  const handleDrawerOpen = (url, pageNumber) => {
    setSource(url);
    setPageNumber(pageNumber);
  };

  const handleDrawerClose = () => {
    setSource('');
    setPageNumber(null);
  };

  // Return:
  return (
    <Wrapper>
      <ChatBox
        tab={tab}
        setTab={setTab}
        previewSource={handleDrawerOpen}
        numberOfResources={numberOfResources}
      />
      <ResourceBox
        tab={tab}
        setTab={setTab}
        previewSource={handleDrawerOpen}
        setNumberOfResources={setNumberOfResources}
        openCollapse={source?.length > 0 &&
          source.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g)}
      />
      {source?.length > 0 &&
        source.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g
        ) && (
          <Iframe
            handleDrawerClose={handleDrawerClose}
            openCollapse={source?.length > 0}
            source={source}
            pageNumber={pageNumber}
          />
        )}
    </Wrapper>
  );
};

// Exports:
export default Chat;
