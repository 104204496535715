// Packages:
import React, { useEffect, useMemo, useState } from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { CSVLink } from 'react-csv';
import { Chip , CircularProgress, Button } from '@mui/material';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import moment from 'moment';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
 

// Imports:
import { ColumnFilter } from './ColumnFilter';
import { Wrapper, Table, Header, Body, Title, Filters } from './styles';


// Components:
import Pagination from '../../Records/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getContentActions } from '../../../redux/actions/contentActions';
import { useDeepCompareEffect } from 'react-use';
import { MINUTE_DATE_FORMAT } from '../../../constants/misc';


// Functions:
const FeedbackTable = ({ hideInfo, handleFeedback, rating, startDate, endDate }) => {
  // Constants:
  const dispatch = useDispatch();
  const contentActions = getContentActions(dispatch);
  const orgid = useSelector((state) => state.content.org_info?.org_data?._id);
  const feedbackData = useSelector((state) => state.content.feedback);
  const isContentLoading = useSelector((state) => state.content.loading);
  const [isLoading, setIsLoading] = useState(isContentLoading);
  const [feedbacks, setFeedbacks] = useState(feedbackData);
  const [cols, setCols] = useState([]);
  const [columns, setColumns] = useState([]);
  const data = useMemo(() => feedbacks, [feedbacks]);
  const [ratingArray, setRatingArray] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const initialState = { 
    hiddenColumns: ['feedbackid', 'orgid'],
    sortBy: [
      {
          id: 'createdDate',
          desc: true
      }
    ] 
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    prepareRow
  } = tableInstance;
  const { pageIndex } = state;

  const handleFeedbackData = (row) => {
    setSelectedRow(row.id === selectedRow ? null : row.id);
    handleFeedback(row.original);
  };

  useEffect(() => {
    setIsLoading(isContentLoading);
  }, [isContentLoading]);

  useEffect(() => {
    contentActions.fetchFeedback(orgid);
  }, [orgid]);

  useDeepCompareEffect(() => {
    setFeedbacks(feedbackData);
    feedbackData.length > 0 && setCols(Object.keys(feedbackData[0]));
  }, [feedbackData]);

  useDeepCompareEffect(() => {
    const tempArray = cols.map((col, i) => {
      const capitalised = col.charAt(0).toUpperCase() + col.slice(1);

      return {
        Header:
          col === 'rating' ? '' : col === 'reaction' ? 'Rating' : col === 'createdDate' ? 'Created Date' : capitalised,
          accessor: col,
          Filter: ColumnFilter,
          disableSortBy: col === 'comment' ? true : (col === 'user' && hideInfo) ? true : false
      };
    });
    setColumns(tempArray);
  }, [cols]);

  useEffect(() => {
    if (feedbackData !== undefined && feedbackData !== null) {
      let tempArr = []
      rating.map((item) => 
      {
        tempArr.push(parseInt(item.charAt(0)))
      })
      setRatingArray(tempArr)
    }
  }, [rating, feedbackData]);

  useEffect(() => {
    if (feedbackData !== undefined && feedbackData !== null) {
      let filteredData = feedbackData.filter(
        (item) =>
          moment(item.createdDate).format('YYYY-MM-DD') >=
            moment(startDate).format('YYYY-MM-DD') &&
          moment(item.createdDate).format('YYYY-MM-DD') <=
            moment(endDate).format('YYYY-MM-DD')
      );
      if(ratingArray.length > 0) {
        let ratingFilteredData = filteredData.filter((item) => ratingArray.includes(item.rating))
        setFeedbacks(ratingFilteredData);
      }
      else {
        setFeedbacks(filteredData);
      }
      
    }
  }, [startDate, endDate, ratingArray, feedbackData]);

  // Return:
  return (
    <Wrapper>
      <Table {...getTableProps()}>
        {isLoading ? (
          <thead style={{ marginTop: '15rem' }}>
            <tr><th><CircularProgress /></th></tr>
          </thead>
        ) : (
          <React.Fragment>
            <Header>
              {headerGroups.map((headerGroup) => (
                  <Title {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' ⬇️'
                              : ' ⬆️'
                            : ''}
                        </span>
                      </th>
                    ))}
                  </Title>
              ))}
              {headerGroups.map((headerGroup) => (
                  <Filters {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, i) => (
                      <th key={i} {...column.getHeaderProps()}>
                        <div>
                          {column.canFilter
                            ? column.Header !== 'Comment' &&
                              column.Header !== '' &&
                              column.Header !== 'Rating'
                              ? (column.Header === 'Orgid' ||
                                  column.Header === 'Feedbackid' ||
                                  column.Header === 'User') &&
                                hideInfo
                                ? null
                                : column.render('Filter')
                              : null
                            : null}
                        </div>
                      </th>
                    ))}
                  </Filters>
              ))}
            </Header>
            <Body {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => handleFeedbackData(row)}
                    style={{backgroundColor: selectedRow === row.id ? 'lightblue' : ''}}
                  >
                    {row.cells.map((cell) => {
                      if (cell.column.Header === 'Comment') {
                        return (
                          <td {...cell.getCellProps()}>
                            { row.original.comment === '' ? 
                              <div style={{ color: "grey" }}>N/A</div> :
                              <Chip
                                sx={{
                                  cursor: 'pointer',
                                  color: '#034B03',
                                  fontWeight: '600'
                                }}
                                label="click to view"
                                icon={<QuestionAnswerOutlinedIcon />}
                                color="success"
                                size="small"
                                variant="outlined"
                              />
                            }
                          </td>
                        );
                      } 
                      
                      else if (cell.column.Header === 'User') {
                        return (
                          <td {...cell.getCellProps()}>
                            {hideInfo ? (
                              <>
                                ••{row.original.user.slice(2, 5).trim()}•••
                                {row.original.user.slice(8, 11).trim()}••
                              </>
                            ) : (
                              <>{row.original.user}</>
                            )}
                          </td>
                        );
                      } 
                      
                      else if (cell.column.Header === 'Orgid') {
                        return (
                          <td {...cell.getCellProps()}>
                            {hideInfo ? (
                              <>
                                ••{row.original.orgid.slice(2, 5).trim()}•••
                                {row.original.orgid.slice(8, 11).trim()}••
                              </>
                            ) : (
                              <>{row.original.orgid}</>
                            )}
                          </td>
                        );
                      } 
                      
                      else if (cell.column.Header === 'Type') {
                        return (
                          <td {...cell.getCellProps()}>
                            {row.original.type === 'technical issue' ? (
                              <Chip
                                label={row.original.type}
                                icon={<BugReportOutlinedIcon />}
                                color="error"
                                sx={{ fontWeight: '600' }}
                                size="small"
                                variant="outlined"
                              />
                            ) : row.original.type === 'feedback' ? (
                              <Chip
                                label={row.original.type}
                                icon={<SettingsSuggestOutlinedIcon />}
                                color="primary"
                                sx={{ fontWeight: '600' }}
                                size="small"
                                variant="outlined"
                              />
                            ) : row.original.type === 'question' ? (
                              <Chip
                                label={row.original.type}
                                icon={<QuestionMarkOutlinedIcon />}
                                color="warning"
                                sx={{ fontWeight: '600' }}
                                size="small"
                                variant="outlined"
                              />
                            ) : row.original.type === 'Agent Unavailable' ? (
                              <Chip
                                label="agent unavailable"
                                icon={<NoAccountsIcon />}
                                color="secondary"
                                sx={{ fontWeight: '600' }}
                                size="small"
                                variant="outlined"
                              />
                            ) : (
                              <div style={{ color: "grey" }}>N/A</div>
                            )}
                          </td>
                        );
                      } 
                      
                      else if (cell.column.Header === '') {
                        return (
                          <td {...cell.getCellProps()}>
                            {row.original.rating === 1 ? (
                              <SentimentVeryDissatisfiedIcon color="error" />
                            ) : row.original.rating === 2 ? (
                              <SentimentDissatisfiedIcon color="error" />
                            ) : row.original.rating === 3 ? (
                              <SentimentSatisfiedIcon color="warning" />
                            ) : row.original.rating === 4 ? (
                              <SentimentSatisfiedAltIcon color="success" />
                            ) : row.original.rating === 5 ? (
                              <SentimentVerySatisfiedIcon color="success" />
                            ) : (
                              <div style={{ color: "grey" }}>N/A</div>
                            )}
                          </td>
                        );
                      } 
                      
                      else if (cell.column.Header === 'Rating') {
                        return (
                          <td {...cell.getCellProps()}>
                            {row.original.rating === 1 ? (
                              <div
                                style={{ color: '#d32f2f', fontWeight: '500' }}
                              >
                                1/5 - Horrible
                              </div>
                            ) : row.original.rating === 2 ? (
                              <div
                                style={{ color: '#d32f2f', fontWeight: '500' }}
                              >
                                2/5 - Poor
                              </div>
                            ) : row.original.rating === 3 ? (
                              <div
                                style={{ color: '#ed6c02', fontWeight: '500' }}
                              >
                                3/5 - Neutral
                              </div>
                            ) : row.original.rating === 4 ? (
                              <div
                                style={{ color: '#2e7d32', fontWeight: '500' }}
                              >
                                4/5 - Good
                              </div>
                            ) : row.original.rating === 5 ? (
                              <div
                                style={{ color: '#2e7d32', fontWeight: '500' }}
                              >
                                5/5 - Excellent
                              </div>
                            )
                            : (
                              <div style={{ color: "grey" }}>N/A</div>
                            )}
                          </td>
                        );
                      } 

                      else if (cell.column.Header === 'Created Date') {
                        return (
                          <td {...cell.getCellProps()}>
                            {moment(row.original.createdDate).format(MINUTE_DATE_FORMAT)}
                          </td>
                        );
                      } 
                      
                      else {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
            </Body>
          </React.Fragment>
        )}
      </Table>
      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
      />
      {data !== null && feedbacks.length>0 && (
        <CSVLink
          filename={'feedback.csv'}
          data={feedbacks.map((row) => {
            let data = {
              user: row.user,
              rating: row.rating === 1 ? "1/5 - Horrible": 
                      row.rating === 2 ? "2/5 - Poor" : 
                      row.rating === 3 ? "3/5 - Neutral" : 
                      row.rating === 4 ? "4/5 - Good" :
                      row.rating === 5 ? "5/5 - Excellent" :
                      "",
              comment: row.comment,
              type: row.type,
              orgid: row.orgid,
              feedbackid: row.feedbackid
            };
            return data;
          })}
        >
          <Button 
            variant="contained"
            size="small"
            startIcon={<FileUploadOutlinedIcon />}
            sx={{ textTransform: 'none' }}
          >
            Export Data
          </Button>
        </CSVLink>
      )}
    </Wrapper>
  );
};

// Exports:
export default FeedbackTable;
