// Packages:
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, styled } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import PublishIcon from '@mui/icons-material/Publish';
import EditIcon from '@mui/icons-material/Edit';


// Styles
const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 1rem;
    font-family: 'Inter', sans-serif;
  }
  & .MuiTypography-root {
    font-family: 'Inter', sans-serif;
  }
`;

const ConfirmationDialog = ({
  title = 'Title',
  message = 'Default Message',
  performAction = () => {},
  openModal,
  handleOpenModal,
  handleCloseModal,
  actionButton
}) => {
  return (
    <StyledDialog open={openModal} onClose={() => handleCloseModal}>
      <DialogTitle sx={{ fontSize: '18px', color: '#333333' }}>
        {title}
      </DialogTitle>
      <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color={actionButton === 'Delete' ? "error" : "success"}
          startIcon={actionButton === 'Delete' ? <DeleteIcon /> : actionButton === 'Publish' ? <PublishIcon /> : <EditIcon /> }
          size="medium"
          sx={{ textTransform: 'none' }}
          onClick={() => {
            performAction();
            handleCloseModal();
          }}
        >
          {actionButton}
        </Button>
        <Button
          variant="contained"
          color={actionButton === 'Delete' ? "primary" : "error"}
          startIcon={<CancelIcon />}
          size="medium"
          sx={{ textTransform: 'none' }}
          onClick={handleCloseModal}
        >
          Cancel
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ConfirmationDialog;
