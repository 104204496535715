import { ARCHIVE_WORKFLOW_OPERATION, VERSION_INCREMENT, WORKFLOW_IS_DRAFT } from '../../constants/studioV2';
import { workflowOperations } from '../../pages/StudioV2/utils/api';
export function createDraftFromWorkflow(existingWorkflow, owner) {
  const draftVersion =
    parseFloat(existingWorkflow['meta']['version']) + VERSION_INCREMENT;
  return {
    ...existingWorkflow,
    meta: {
      ...existingWorkflow['meta'],
      status: WORKFLOW_IS_DRAFT,
      isArchived: false,
      owner: owner,
      version: draftVersion.toFixed(1),
      s3Link: ''
    }
  }
}

export async function archiveWorkflow(workflow) {
  await workflowOperations(ARCHIVE_WORKFLOW_OPERATION, workflow);
}