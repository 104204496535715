import React from 'react';
import { Radios } from './styles';

const Access = ({ onAccessChange, articleData }) => {
  return (
    <Radios onChange={onAccessChange}>
      <span
        style={{
          fontWeight: 'bold',
          marginRight: '0.75rem',
          color: '#333333'
        }}
      >
        Access:
      </span>
      <input
        type="radio"
        value="private"
        name="access"
        defaultChecked={
          articleData?.meta?.access
            ? articleData?.meta?.access === 'private'
            : true
        }
        style={{ marginRight: '0.5rem', cursor: 'pointer' }}
      />{' '}
      Private
      <input
        type="radio"
        value="public"
        name="access"
        disabled={
          articleData?.meta?.target === 'agent' ||
          articleData?.meta?.target === 'partner'
        }
        defaultChecked={articleData?.meta?.access === 'public'}
        style={{
          marginLeft: '1rem',
          marginRight: '0.5rem',
          cursor: 'pointer'
        }}
      />{' '}
      Public
    </Radios>
  );
};

export default Access;
