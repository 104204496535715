// Packages:
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Position } from 'react-flow-renderer';
import hasNodeMoved from '../../../../lib/hasNodeMoved';

// Redux:
import { setSelectedNode } from '../../../../redux/actions/studioActions';

// Styles:
import {
  StartNodeWrapper,
  StartNodeLabel,
  Body,
  Description,
  Field,
  StyledHandle
} from './styles';

// Functions:
const StartNode = ({ data }) => {
  // Constants:
  const dispatch = useDispatch();
  const { id, comment, label } = data;

  // Ref:
  const wrapperRef = useRef(null);

  // State:
  const studio = useSelector((state) => state.studio);
  const [clickOrigin, setClickOrigin] = useState({ x: 0, y: 0 });

  // Functions:
  const handleMouseDown = useCallback(
    (e) => {
      if (e.button === 2) e.stopPropagation();
      if (e.button === 0) {
        setClickOrigin({ x: e.clientX, y: e.clientY });
        dispatch(setSelectedNode(data));
      }
    },
    [data, dispatch]
  );

  const handleMouseUp = useCallback(
    (e) => {
      if (e.button === 0) {
        if (hasNodeMoved(clickOrigin, e)) return;
      }
    },
    [clickOrigin]
  );

  // Effects:
  useEffect(() => {
    wrapperRef.current.addEventListener('mousedown', handleMouseDown);
    wrapperRef.current.addEventListener('mouseup', handleMouseUp);
  }, [handleMouseDown, handleMouseUp, clickOrigin, studio.selectedNode]);

  // Return:
  return (
    <StartNodeWrapper
      ref={wrapperRef}
      style={{
        backgroundColor: studio.selectedNode?.id === id ? '#3AA640' : '#FFFFFF',
        borderColor: '#3AA640'
      }}
    >
      <StyledHandle
        type="source"
        position={Position.Bottom}
        style={{
          backgroundColor: 'green',
          bottom: '-15px'
        }}
      />
      <StartNodeLabel
        htmlFor="text"
        style={{
          color: studio.selectedNode?.id === id ? '#FFFFFF' : '#333533'
        }}
      >
        {label}
      </StartNodeLabel>
      <Body
        style={{
          color: studio.selectedNode?.id === id ? '#FFFFFF' : '#333533'
        }}
      >
        <Description>
          <Field isActive={studio.selectedNode?.id === id}>
            {comment.length === 0 ? (
              <span style={{ fontStyle: 'italic', fontWeight: 400 }}>
                Enter a reply...
              </span>
            ) : comment.length >= 30 ? (
              `${comment.slice(0, 20).trim()}..`
            ) : (
              comment
            )}
          </Field>
        </Description>
      </Body>
    </StartNodeWrapper>
  );
};

// Exports:
export default StartNode;
