// Packages:
import styled from 'styled-components';

// Exports:
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 98%;
  height: 100%;
  gap: 0.75rem;
  margin-top: 3.4rem;
  flex-grow: 1;

  @media (max-width: 1024px) {
    margin-top: 5rem;
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Icons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Article = styled.div`
  position: relative;
  width: 60%;
  height: 95%;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 16px;
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background: #ffffff;
  @media (max-width: 1024px) {
    width: 98%;
    min-height: 98%;
  }
`;

export const Information = styled.div`
  position: absolute;
  margin-left: -0.5rem;
  width: 100%;
  height: ${(props) => (props.showOptions ? 'calc(100% - 10.5rem);' : 'calc(100% - 3rem);')};
  padding: 0.5rem 1.5rem;
  overflow: hidden;
`;

export const Options = styled.div`
  position: absolute;
  bottom: 3rem;
  margin-left: -0.5rem;
  align-items: center;
  width: 100%;
  height: fit-content;
  max-height: 7.5rem;
  overflow: auto;
  padding: 0 2rem;
  border-top: 1px solid #e0e0e0;
  display: ${(props) => (props.showOptions ? 'block' : 'none')};
  padding-top: 15px;
  padding-bottom: 15px;

  @media (max-width: 520px) {
    height: 2rem;
  }
`;

export const VariableField = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Variable = styled.span`
  width: fit-content;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  font-size: 0.75rem;
  background-color: #0b7a75;
  border-radius: 4px;
  user-select: none;
`;
