// Packages:
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Position } from 'react-flow-renderer';
import hasNodeMoved from '../../../../lib/hasNodeMoved';

// Redux:
import { setSelectedNode } from '../../../../redux/actions/studioActions';

// Styles:
import { LeafNodeWrapper, LeafNodeLabel, Body, StyledHandle } from './styles';

// Functions:
const LeafNode = ({ data }) => {
  // Constants:
  const dispatch = useDispatch();
  const { id, handlePosition, label, isEndNode } = data;

  // Ref:
  const wrapperRef = useRef(null);

  // State:
  const studio = useSelector((state) => state.studio);
  const [clickOrigin, setClickOrigin] = useState({ x: 0, y: 0 });

  // Functions:
  const handleMouseDown = useCallback(
    (e) => {
      if (e.button === 2) e.stopPropagation();
      if (e.button === 0) {
        setClickOrigin({ x: e.clientX, y: e.clientY });
        dispatch(setSelectedNode(data));
      }
    },
    [data, dispatch]
  );

  const handleMouseUp = useCallback(
    (e) => {
      if (e.button === 0) {
        if (hasNodeMoved(clickOrigin, e)) return;
      }
    },
    [clickOrigin]
  );

  // Effects:
  useEffect(() => {
    wrapperRef.current.addEventListener('mousedown', handleMouseDown);
    wrapperRef.current.addEventListener('mouseup', handleMouseUp);
  }, [handleMouseDown, handleMouseUp, clickOrigin, studio.selectedNode]);

  // Return:
  return (
    <LeafNodeWrapper
      ref={wrapperRef}
      style={{
        backgroundColor:
          studio.selectedNode?.id === id
            ? isEndNode
              ? '#CE3527'
              : '#7DD181'
            : '#FEFFFE',
        borderColor: isEndNode ? '#CE3527' : '#7DD181'
      }}
    >
      {isEndNode && (
        <StyledHandle
          type="target"
          position={handlePosition ?? Position.Bottom}
          style={{ backgroundColor: 'blue', top: '-15px' }}
        />
      )}
      <LeafNodeLabel
        htmlFor="text"
        style={{
          color: studio.selectedNode?.id === id ? '#FEFFFE' : '#333533'
        }}
      >
        {label}
      </LeafNodeLabel>
      <Body>
        {!isEndNode && (
          <StyledHandle
            type="source"
            position={handlePosition ?? Position.Top}
            style={{ backgroundColor: '#65B891' }}
          />
        )}
      </Body>
    </LeafNodeWrapper>
  );
};

// Exports:
export default LeafNode;
