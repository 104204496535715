import React from 'react';
import { MdCancel } from 'react-icons/md';
import { Tag, Tags } from './styles';

const SearchTagsComponent = ({ tags, handleTagDelete }) => {
  return (
    <Tags tags={tags}>
      {tags?.map((tag) => (
        <Tag key={tag.id}>
          {tag.value}
          <MdCancel
            onClick={() => handleTagDelete(tag)}
            style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
          />
        </Tag>
      ))}
    </Tags>
  );
};

export default SearchTagsComponent;
