// Packages:
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Tabs, Tab, Tooltip, IconButton, Button } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

// Imports:
import { getContentActions } from '../../redux/actions/contentActions';
import {
  Information,
  Table,
  TrackingInformation,
  TrackingTable,
  FeedbackInformation,
  FeedbackTable,
  Filters
} from '../../components';

// Styles:
import { Container, Wrapper, Options } from './styles';
import ROUTES from '../../constants/routes';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';

// Functions:
const Info = () => {
  // State:
  const dispatch = useDispatch();
  const contentActions = getContentActions(dispatch);
  const organisation_info = useSelector((state) => state.content.org_info);
  const orgid = organisation_info?.org_data?._id;
  const reactionRecords = useSelector((state) => state.content.reactionRecords);
  const [chat, setChat] = useState('');
  const [stat, setStat] = useState('');
  const [feedback, setFeedback] = useState('');
  const [rating, setRating] = useState([]);
  const [switchInfo, setSwitchInfo] = useState('records');
  const [dateRange, setDateRange] = useState('last 4 weeks');
  const [startDate, setStartDate] = useState(moment().subtract(28, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [conversationType, setConversationType] = useState([]);
  const [type, setType] = useState([]);
  const [hideInfo, setHideInfo] = useState(true);
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate(ROUTES.AGENT.DASHBOARD);
  };

  useEffect(() => {
    contentActions.fetchReactions(orgid);
    contentActions.fetchReactionsV2(orgid, startDate, endDate);
  }, [orgid, startDate, endDate]);

  // Event Handlers:
  
  const handleChat = useCallback((data) => setChat(data), []);

  const handleStat = useCallback((data) => setStat(data), []);

  const handleFeedback = useCallback((data) => setFeedback(data), []);

  const handleDateRangeChange = useCallback((event) => setDateRange(event.target.value), []);

  const handleStartDateChange = useCallback((newValue) => setStartDate(newValue), []);

  const handleEndDateChange = useCallback((newValue) => setEndDate(newValue), []);

  const handleRatingChange = (event) => {
    const {
      target: { value },
    } = event;
    setRating(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleConversationTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setConversationType(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleTypeChange = (event) => {
    const {
      target: { value },
    } = event;
    setType(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  useEffect(() => {
    if (dateRange === 'last 4 weeks') {
      setStartDate(moment().subtract(28, 'days'));
      setEndDate(moment());
    } else if (dateRange === 'last 2 weeks') {
      setStartDate(moment().subtract(14, 'days'));
      setEndDate(moment());
    } else if (dateRange === 'last week') {
      setStartDate(moment().subtract(14, 'days'));
      setEndDate(moment().subtract(7, 'days'));
    } else if (dateRange === 'yesterday') {
      setStartDate(moment().subtract(1, 'days'));
      setEndDate(moment().subtract(1, 'days'));
    } else {
      setStartDate(moment().subtract(7, 'days'));
      setEndDate(moment());
    }
  }, [dateRange]);

  // Return:
  return (
    <Container>
      <Options>
        <Button onClick={handleButtonClick}>
          <ArrowBack style={{ fontSize: 'medium' }} />
          <span style={{ textTransform: 'capitalize' }}>Back To Dashboard</span>
        </Button>
        <Tabs
          value={switchInfo}
          onChange={(event, newValue) => setSwitchInfo(newValue)}
        >
          <Tab value="records" label="Chat Logs" sx={{ textTransform: 'none' }} />
          <Tab value="tracking" label="AI Queries" sx={{ textTransform: 'none' }} />
          <Tab value="feedback" label="Feedback" sx={{ textTransform: 'none' }} />
        </Tabs>
        {hideInfo ? (
          <Tooltip title="Show Details">
            <IconButton sx={{ ml: '1rem' }} onClick={() => setHideInfo(false)}>
              <VisibilityOffOutlinedIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Hide Details">
            <IconButton sx={{ ml: '1rem' }} onClick={() => setHideInfo(true)}>
              <VisibilityOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
         <Link to={ROUTES.AGENT.DATA_V2} style={{color:'6a6a6a',margin:15,fontWeight:500}}>V2 Records</Link>
      </Options>
      <Wrapper>
        {switchInfo === 'records' && (
          <>
            <Filters
              handleDateRangeChange={handleDateRangeChange}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              tab="records"
              conversationType={conversationType}
              handleConversationTypeChange={handleConversationTypeChange}
              />
            <Table
              handleChat={handleChat}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              hideInfo={hideInfo}
              conversationType={conversationType}
            />
            <Information chat={chat} hideInfo={hideInfo} />
          </>
        )}
        {switchInfo === 'tracking' && (
          <>
            <Filters
              handleDateRangeChange={handleDateRangeChange}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              tab="tracking"
              type={type}
              handleTypeChange={handleTypeChange}
            />
            <TrackingTable
              handleStat={handleStat}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              hideInfo={hideInfo}
              reactions={reactionRecords}
              type={type}
            />
            <TrackingInformation
              stat={stat}
              reactions={reactionRecords}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              type={type}
              hideInfo={hideInfo}
            />
          </>
        )}
        {switchInfo === 'feedback' && (
          <>
            <Filters
              handleDateRangeChange={handleDateRangeChange}
              handleStartDateChange={handleStartDateChange}
              handleEndDateChange={handleEndDateChange}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              rating={rating}
              handleRatingChange={handleRatingChange}
              tab="feedback"
            />
            <FeedbackTable
              handleFeedback={handleFeedback}
              startDate={startDate}
              endDate={endDate}
              dateRange={dateRange}
              hideInfo={hideInfo}
              rating={rating}
            />
            <FeedbackInformation 
              feedback={feedback} 
              hideInfo={hideInfo}
            />
          </>
        )}
      </Wrapper>
    </Container>
  );
};

// Exports:
export default Info;
