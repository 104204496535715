import React from 'react';

const ArticleCheckbox = ({
  isNew,
  isEdit,
  changeArticle,
  articleData,
  autosaveFn
}) => {
  return (
    isEdit && (
      <span
        style={{
          marginLeft: '2rem',
          opacity: `${
            articleData?.meta?.published === true && !isNew ? 0.5 : 1
          }`
        }}
      >
        <input
          disabled={articleData?.meta?.published === true ? !isNew : false}
          type="checkbox"
          id="isarticle"
          name="isarticle"
          style={{
            cursor: 'pointer',
            marginRight: '0.5rem',
            verticalAlign: 'middle'
          }}
          checked={articleData?.meta?.isarticle}
          onChange={(event) => {
            const newArticle = {
              ...articleData,
              meta: {
                ...articleData?.meta,
                isarticle: event.target.checked
              }
            };
            changeArticle(newArticle);
            autosaveFn(newArticle);
          }}
        />
        <label htmlFor="isarticle" style={{ verticalAlign: 'middle' }}>
          Article
        </label>
      </span>
    )
  );
};

export default ArticleCheckbox;
