import { memo, useEffect, useState } from 'react';
import './style.css'

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    const [value, setValue] = useState(globalFilter);
    
    useEffect(() => {
        const timeout = setTimeout(() => {
            setGlobalFilter(value)
        }, 400);

        return () => {
            clearTimeout(timeout);
        }
    }, [value, setGlobalFilter])

    return (
        <span>
            Search: {' '}
            <input
                value={value || ''}
                onChange={(e) => setValue(e.target.value)}
                className="search-input"
            />
        </span>
    );
}

export default memo(GlobalFilter);