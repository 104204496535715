import React, { useRef, useState, useEffect, useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import hasNodeMoved from "../../../../lib/hasNodeMoved";
import { setSelectedNode } from "../../../../redux/actions/studioActions";

const BaseNode = ({ data, children, config }) => {
  const dispatch = useDispatch();
  const { id } = data;
  const wrapperRef = useRef(null);
  const studio = useSelector((state) => state.studio);
  const [clickOrigin, setClickOrigin] = useState({ x: 0, y: 0 });

  // ... (rest of the common functionalities)
  // Functions:
  const handleMouseDown = useCallback(
    (e) => {
      if (e.button === 2) e.stopPropagation();
      if (e.button === 0) {
        setClickOrigin({ x: e.clientX, y: e.clientY });
        dispatch(setSelectedNode(data));
      }
    },
    [data, dispatch]
  );

  const handleMouseUp = useCallback(
    (e) => {
      if (e.button === 0) {
        if (hasNodeMoved(clickOrigin, e)) return;
      }
    },
    [clickOrigin]
  );

  // Effects:
  useEffect(() => {
    wrapperRef.current.addEventListener("mousedown", handleMouseDown);
    wrapperRef.current.addEventListener("mouseup", handleMouseUp);
  }, [handleMouseDown, handleMouseUp, clickOrigin, studio.selectedNode]);

  return <div ref={wrapperRef}>{children}</div>;
};

export default memo(BaseNode)
