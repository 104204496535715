// Packages:
import React from 'react';
import { useSelector } from 'react-redux';


// Styles:
import {
  Wrapper
} from './styles';


// Functions:
const Performance = () => {
  const organisation_info = useSelector((state) => state.content.org_info);
  const subdomain_name =  organisation_info?.org_data?.subdomain_name;
  const srchref = "https://datastudio.google.com/embed/reporting/eb42b46f-a29d-4835-8adb-83f76841d35c/page/p_oliypd8l1c?params=%7B%22df158%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580"+subdomain_name+".readyly.app%22%7D"


  // Return:
  return (
    <Wrapper>
        <iframe 
            width="1200" 
            height="920"
            src={srchref}
            style={{ border: '0', padding: '1rem 0', marginTop: '2rem' }}
        >
        </iframe>
    </Wrapper>
  );
};

// Exports:
export default Performance;
