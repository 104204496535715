// Packages:
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useInput from '../../lib/hooks/use-input';
import uuid from 'react-uuid';
import { useSelector, useDispatch } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import {
  IconButton,
  Tooltip,
  Button,
  OutlinedInput,
  InputAdornment,
  FormControl,
  Popover,
  styled,
  CircularProgress,
  Box
} from '@mui/material';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import stopwords from '../../lib/stopwords.js';

// Components:
import {
  SearchTagsComponent,
  AddFaq,
  FAQComponent
} from '../../components/FAQV2';

// Redux:
import {
  getContentActions,
  updateQueryInStore
} from '../../redux/actions/contentActions';

// Constants:
import {
  ARTICLE_IS_DRAFT,
  ARTICLE_IS_PUBLISHED,
  ARTICLE_IS_UNDER_REVIEW
} from '../../constants/articles';
import { END_USER } from '../../constants/user.js';

// Styles:
import { Wrapper, SearchSection, Header } from './styles';
import Dropdown from './Dropdown';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import filterArticlesBasedOnTagSearch from '../../lib/filterArticlesBasedOnTagSearch';

const StyledFormControl = styled(FormControl)`
  width: 35%;
  font-size: 14px;
  & .MuiOutlinedInput-root {
    font-size: 14px;
  }
`;

const StyledPopover = styled(Popover)`
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  & .MuiPaper-root {
    margin-top: 0.5rem;
    padding: 1rem;
  }
`;

const  generateAndDownloadCSV=(articles)=> {
  const exportData = articles.map((article, index) => ({
    'S.No': index + 1,
    CreatedDate: article?.meta?.createdDate,
    Title: article?.meta?.title,
    Status: article?.meta?.status,
    Version: article?.meta?.version,
    Access: article?.meta?.access,
    Personas: article?.meta?.customFields["personas"],
    Source: article?.meta?.source,
  }));

  const keys = Object.keys(exportData[0]);
  const headerRow = keys.join(',');
  const rows = exportData.map(article =>
    keys.map(key => article[key] || '').join(',')
  );

  const csvContent = [headerRow, ...rows].join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'articles.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

// Functions:
const FAQV2 = () => {
  const organisation_info = useSelector((state) => state.content.org_info);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const agent_type = useSelector((state) => state.content.agent_type);
  const loadingArticles = useSelector((state) => state.content.loadingArticlesV2);
  const articles = useSelector((state) => state.content.articlesV2);
  const articlesLastKey = useSelector((state) => state.content.articlesLastKey);
  const dispatch = useDispatch();
  const funcContentActions = getContentActions(dispatch);
  const isFetchingRef = useRef(false);
  const [loading, setLoading] = useState(false);
  // Constants:
  const orgid = organisation_info?.org_data?._id;

  // State:
  const [anchorEl, setAnchorEl] = useState(null);
  const { value: searchValue, setValue, bind: bindSearch } = useInput('');
  const [articlesToDisplay, setArticlesToDisplay] = useState([]);
  const [tags, setTags] = useState([]);
  const [articleType, setArticleType] = useState(['Published']);
  const [bit, setBit] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [isFetchingArticle,setIsFetchingArticle]=useState(false)

  const [modalProps, setModalProps] = useState({
    actionButton: '',
    title: '',
    message: '',
    actionFn: null
  });

  // Ref:
  const firstUpdate = useRef(true);
  const wrapperRef = useRef(null);

  // Functions:
  const handleModalProps = useCallback((val) => {
    setModalProps(val);
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const searchFAQ = useCallback(() => {
    setTags((prev) => [...prev, { id: uuid(), value: searchValue }]);
    setValue('');
  }, [searchValue, setValue]);

  const handleTagDelete = useCallback(
    (tag) => {
      const deleteTags = tags.filter((tagNew) => tagNew.id !== tag.id);
      setTags(deleteTags);
    },
    [tags]
  );

  const handleFiltersTooltipClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFiltersTooltipClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = () => {
    if (!loading) {
      setLoading(true);
    }
  };

  const open = Boolean(anchorEl);

  const handleDropdownChange = useCallback((val) => {
    setArticleType(val);
  }, []);

  const filterByTags = (filterArticles) => {
    const tempTags = tags?.map((tag) => tag?.value?.split(' ')).flat(1);
    const cleanTags = tempTags?.filter((tag) => !stopwords.includes(tag));
    const otherTags = cleanTags?.filter((tag) => !tag?.includes(':'));
    const fields = cleanTags
      ?.filter((tag) => tag?.includes(':'))
      .map((tag) => tag.split(':'));
    let paramForSearch = {};

    fields.forEach((field) => {
      if (paramForSearch[field[0]]) {
        paramForSearch[field[0].toLowerCase()].push(field[1].toLowerCase());
      } else {
        paramForSearch[field[0].toLowerCase()] = [field[1].toLowerCase()];
      }
    });

    let filteredArticles = filterArticles;

    if (Object.keys(paramForSearch).length > 0) {
        filteredArticles = filteredArticles?.filter((article) =>
          filterArticlesBasedOnTagSearch(article, paramForSearch)
        );
    }

    if (otherTags?.length > 0) {
      otherTags.forEach((tag) => {
        filteredArticles = filteredArticles?.filter((article) =>
          [
            article?.question?.toLowerCase(),
            article?.meta?.firstLine?.toLowerCase(),
            article?.meta?.title?.toLowerCase() 
          ].some(
            (a) => a && typeof a === 'string' && a?.includes(tag.toLowerCase())
          )
        );
      });
    }
    setArticlesToDisplay(filteredArticles);
  };

  useEffect(() => {
    if (!userDetails?.email || !agent_type || agent_type.toLowerCase() === END_USER) {
      return;
    }
    dispatch(updateQueryInStore(''));

    const fetchData = async () => {
      try {
        isFetchingRef.current = true;
        const lastKey = firstUpdate.current || !articlesLastKey ? null : JSON.stringify(articlesLastKey);
        await funcContentActions.loadArticles(orgid, { user: userDetails?.email, role: agent_type }, lastKey);
      } catch (error) {
        console.error(error);
      } finally {
        isFetchingRef.current = false;
      }
    };
      if (!isFetchingRef.current) {
      fetchData();
    }  
  }, [agent_type, orgid, userDetails?.email, bit]);

  useDeepCompareEffect(() => {
    if (firstUpdate.current && articles?.length > 0) {
      firstUpdate.current = false;
    }
    if (loadingArticles) {
      return;
    }
    if (tags.length === 0 && articleType.length === 0) {
      setArticlesToDisplay([...articles]);
    } else {
      if (tags.length > 0 && articleType.length > 0 ){
        let predicates = [];
        if (articleType.includes('Drafts')) {
          predicates.push((meta) => meta?.status === ARTICLE_IS_DRAFT);
        }
        if (articleType.includes('Published')) {
          predicates.push(
            (meta) => meta?.status === ARTICLE_IS_PUBLISHED && !meta?.isArchived
          );
        }
        if (articleType.includes('Under Review')) {
          predicates.push((meta) => meta?.status === ARTICLE_IS_UNDER_REVIEW);
        }
        if (articleType.includes('Archived')) {
          predicates.push((meta) => meta?.isArchived === true);
        }
        filterByTags(articles.filter((article) => {
          return predicates.some((predicate) => predicate(article?.meta))}));

      }
      else if (articleType.length > 0) {
        let predicates = [];
        if (articleType.includes('Drafts')) {
          predicates.push((meta) => meta?.status === ARTICLE_IS_DRAFT);
        }
        if (articleType.includes('Published')) {
          predicates.push(
            (meta) => meta?.status === ARTICLE_IS_PUBLISHED && !meta?.isArchived
          );
        }
        if (articleType.includes('Under Review')) {
          predicates.push((meta) => meta?.status === ARTICLE_IS_UNDER_REVIEW);
        }
        if (articleType.includes('Archived')) {
          predicates.push((meta) => meta?.isArchived === true);
        }
        setArticlesToDisplay(articles.filter((article) => {
          return predicates.some((predicate) => predicate(article?.meta))
        }));
      }
      else if (tags.length > 0) {
        filterByTags(articles);
      }
    }
  }, [
    loadingArticles,
    tags,
    articleType,
    articles
  ]);

  const shouldLoadArticles = wrapperRef.current && !loadingArticles && articlesLastKey;

  useEffect(() => { // load more search results until a scroll bar appears
    if (!shouldLoadArticles) {
      setLoading(false)
      return;
    }
    const { scrollHeight, clientHeight } = wrapperRef.current;
    if ((scrollHeight === clientHeight) || (articlesLastKey!==null && loading) ) {
      setBit(!bit);      
    }
  }, [articlesToDisplay,loading]);

  const handleScroll = () => {
    if (!shouldLoadArticles) {
      return;
    }
    const compareFloats = (f1, f2) => {
      const limit = 5; // 5 pixels
      return f2 + limit >= f1 &&
        f2 - limit <= f1;
    }
    const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;
    if (compareFloats(scrollTop + clientHeight, scrollHeight)) {
      setBit(!bit);
    }
  };

  useEffect(() => {
    if (!firstUpdate.current && articlesLastKey === null && !loading && articlesToDisplay.length>0) {
      generateAndDownloadCSV(articlesToDisplay);
    }
  }, [loading]);

  const handleRefresh = () => {
    firstUpdate.current = true;
    setArticlesToDisplay([]);
    setBit(!bit);
  }
  
  // Return:
  return (
    <Wrapper ref={wrapperRef} onScroll={handleScroll}>
      <ConfirmationDialog
        title={modalProps?.title}
        message={modalProps?.message}
        performAction={modalProps?.actionFn}
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        actionButton={modalProps?.actionButton}
      />
      <SearchSection>
        <Header>
          <StyledFormControl variant="outlined">
            <OutlinedInput
              id="search-articles"
              type="text"
              onKeyDown={(event) =>
                event.key === 'Enter' ? searchFAQ() : null
              }
              {...bindSearch}
              startAdornment={
                <InputAdornment position="start">
                  <Tooltip title="search">
                    <IconButton
                      onClick={() => searchFAQ()}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="start"
                    >
                      <SearchOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Show Advanced Filters">
                    <IconButton
                      onClick={handleFiltersTooltipClick}
                      edge="end"
                    >
                      <TuneOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
              size="small"
              placeholder="Search articles"
            />
          </StyledFormControl>

          <StyledPopover
            open={open}
            anchorEl={anchorEl}
            onClose={handleFiltersTooltipClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            elevation={3}
          >
            <h4>{"Advanced Filter Options"}</h4>
            <p>{"access:<public|private>,"} <em>{"example: access:private"}</em></p>
            <p>{"tags:<tagname>,"} <em>{"example: tags:help"}</em></p>
          </StyledPopover>

          <Dropdown
            articleType={articleType}
            handleDropdownChange={handleDropdownChange}
          />
          
          <AddFaq />
          
          <Button
            variant="contained"
            size="medium"
            startIcon={<RefreshOutlinedIcon />}
            sx={{ textTransform: 'none' }}
            onClick={handleRefresh}
          >
            Refresh
          </Button>
          <Box sx={{ m: 1, position: 'relative' }}>
        <Button
          variant="contained"
          size='medium'
          startIcon={<FileUploadOutlinedIcon />}
          disabled={loading}
          sx={{ textTransform: 'none' }}
          onClick={handleButtonClick}
        >
          Export
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              color:'#1876d2',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
        </Header>

        <SearchTagsComponent tags={tags} handleTagDelete={handleTagDelete} />
      </SearchSection>
      <FAQComponent
        articles={articlesToDisplay}
        handleOpenModal={handleOpenModal}
        handleModalProps={handleModalProps}
      />
    </Wrapper>
  );
};

// Exports:
export default FAQV2;