// Packages:
import styled from 'styled-components'


// Exports:
export const Wrapper = styled.div`
  position: relative;
  width: 20%;
  height: 100%;
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background: #ffffff;
  padding: 0.5rem;
  max-height: 730px;
  border-radius: 4px;
  margin: 0 auto;
  color: #333333;
`

export const FiltersTitle = styled.div`
  height: 50px;
  font-size: 14px;
  padding-top: 14px;
  font-weight: bold;
  margin-bottom: 20px;
`