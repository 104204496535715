// Packages:
import styled from 'styled-components'


// Exports:
export const Wrapper = styled.div`
  font-size: 14px;
  width: 7.5rem;
  height: 98%;
  color: #333333;
  padding: 0.5rem;
  font-size: 0.9rem;
  text-align: center;
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background: #ffffff;
`

export const Title = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
`

export const InputNode = styled.div`
  height: 3.125rem;
  background: #9CD089;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  margin-bottom: 1rem;
  display: flex;
  padding-left: 1rem;
  align-items: center;
  cursor: grab;
  border-color: #9CD089;
`

export const OutputNode = styled.div`
  height: 3.125rem;
  background: #F46464;
  border-radius: 4px;
  border: 1px solid #E0E0E0;
  margin-bottom: 1rem;
  display: flex;
  padding-left: 1rem;
  align-items: center;
  cursor: grab;
  border-color: #F46464;
`
