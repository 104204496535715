// Packages:
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useInput from '../../lib/hooks/use-input';
import debounce from 'lodash.debounce';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  IconButton,
  Tooltip,
  OutlinedInput,
  InputAdornment,
  FormControl,
  styled
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

// Constants:
import {
  getContentActions,
  updateQueryInStore
} from '../../redux/actions/contentActions';

// Styles:
import { useDeepCompareEffect } from 'react-use';
import makeSuggestionsArray from '../../lib/makeSuggestionsArray';
import Dropdown from '../Dropdown';

const StyledFormControl = styled(FormControl)`
  font-size: 14px;
  width: 50%;

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 520px) {
    font-size: 16px;
  }

  & .MuiOutlinedInput-root {
    font-size: 14px;
    margin-bottom: ${(props) => (props.links ? '1.5rem' : '0')};

    @media (max-width: 520px) {
      margin-bottom: 0.75rem;
      font-size: 16px;
    }
  }
`;

// Functions:
const Search = ({
  isSearchBarActive,
  isSearchDone,
  handleSearchActivity = () => {},
  handleSearchBarVisibility = () => {},
  showFallbackText,
  handleFallbackVisibility
}) => {
  // Constants:
  const dispatch = useDispatch();
  const funcContentActions = getContentActions(dispatch);

  // State:
  const qna = useSelector((state) => state.content.interactData.qna);
  const { value: searchQuery, setValue, bind: bindSearchQuery } = useInput('');
  const user = useSelector((state) => state.auth.user);
  const [suggestions, setSuggestions] = useState([]);
  const organisation_info = useSelector((state) => state.content.org_info);
  const org_id = organisation_info?.org_data?._id;
  const similarity = useSelector((state) => state.content.similarity);
  const [settingSuggestions, setSettingSuggestions] = useState(false);
  const [dialogIds, setDialogIds] = useState(null);
  const firstUpdate = useRef(true);

  // Functions:
  const setIsSearchDone = useCallback(
    (val) => handleSearchActivity(val),
    [handleSearchActivity]
  );
  const setIsSearchBarActive = useCallback(
    (val) => handleSearchBarVisibility(val),
    [handleSearchBarVisibility]
  );

  const setSuggest = useCallback(
    (
      similarity,
      faq,
      changeResourcesArray,
      user,
      isSearchDone,
      setDialogIds,
      org_id
    ) => {
      makeSuggestionsArray(
        similarity,
        faq,
        changeResourcesArray,
        user,
        isSearchDone,
        setDialogIds,
        org_id
      );
    },
    []
  );

  const changeSuggestionsArray = useCallback(
    (val) => {
      setSuggestions(val);
      if (!val || val.length === 0) {
        handleFallbackVisibility(true);
      } else handleFallbackVisibility(false);
    },
    [handleFallbackVisibility]
  );

  const updateQuery = useCallback(
    async (event) => {
      const type = event?.code || event?.type;
      dispatch(updateQueryInStore(searchQuery));
      funcContentActions.interact(org_id, searchQuery, null, false, type);
      funcContentActions.similarity(org_id, searchQuery, null);
    },
    [dispatch, org_id, searchQuery]
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.code === 'Enter') {
        updateQuery(e);
        setIsSearchDone(true);
      } else if (e.code === 'Space') {
        updateQuery(e);
      } else {
        setIsSearchDone(false);
      }
    },
    [setIsSearchDone, updateQuery]
  );

  const handleSearchClick = (e) => {
    updateQuery(e);
    setIsSearchDone(true);
  }

  const delayedLoad = useCallback(debounce(updateQuery, 2500), [searchQuery]);

  const suggestionClicked = useCallback(
    async (suggestion, e) => {
      setValue(suggestion.question);
      updateQuery(e);
      setIsSearchDone(true);
    },
    [setIsSearchDone, setValue, updateQuery]
  );

  // Effects:

  useEffect(() => {
    if (searchQuery?.trim().length > 0) setIsSearchBarActive(true);
    else {
      setIsSearchBarActive(false);
      setIsSearchDone(false);
      setSuggestions([]);
    }
  }, [setIsSearchBarActive, setIsSearchDone, searchQuery]);

  useDeepCompareEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setSettingSuggestions(true);
    if (
      qna?.question !== 'Default Fallback Intent' ||
      similarity?.length !== 0
    ) {
      setSuggest(
        similarity,
        qna,
        changeSuggestionsArray,
        user,
        isSearchDone,
        setDialogIds,
        org_id
      );
    } else {
      setSuggestions([]);
    }
    setSettingSuggestions(false);
  }, [qna?.question, similarity]);

  useEffect(() => {
    if (searchQuery?.trim().length > 0) {
      delayedLoad();
    }
    return delayedLoad.cancel;
  }, [searchQuery, delayedLoad]);

  // Return:
  return (
    <>
      <StyledFormControl variant="outlined">
        <OutlinedInput
          id="landing-search"
          type="text"
          placeholder="Describe your issue"
          {...bindSearchQuery}
          onKeyPress={(e) => handleKeyPress(e)}
          onClick={() => window.gtag('event','input_area_click')}
          links={isSearchDone.toString()}
          endAdornment={
            <InputAdornment position="end">
              <Tooltip title="search">
                <IconButton
                  onClick={(e) => [handleSearchClick(e), window.gtag('event','search_button_click')]}
                  onMouseDown={(event) => event.preventDefault()}
                  edge="end"
                >
                  <SearchOutlinedIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
      </StyledFormControl>
      <Dropdown
        suggestions={suggestions}
        isSearchBarActive={isSearchBarActive}
        showFallbackText={showFallbackText}
        isSearchDone={isSearchDone}
        suggestionClicked={suggestionClicked}
        settingSuggestions={settingSuggestions}
        dialogIds={dialogIds}
      />
    </>
  );
};

// Exports:
export default Search;
