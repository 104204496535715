// Packages:
import styled from 'styled-components'


// Exports:
export const Wrapper = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 2000;
  padding: 0.5rem 1rem;
  background-color: white;
  border-radius: 2rem;
  box-shadow: 0 1px 10px -5px;
  user-select: none;
  cursor: pointer;
  transition: all 0.25s ease;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;

  @media (max-width: 520px) {
    padding: 0.25rem;
    font-size: 16px;
  }

  @media (max-width: 360px) {
    padding: 0.25rem;
  }

  &:hover {
    transform: scale(1.05);
    transition: all 0.25s ease;
  }
`

export const Logo = styled.img`
  width: 1rem;

  @media (max-width: 520px) {
    width: 0.8rem;
  }

  @media (max-width: 360px) {
    width: 0.7rem;
  }
`

export const Title = styled.div`
  @media (max-width: 520px) {
    display: ${ props => props.isScrolling ? 'inherit' : 'none' };
  }
`
