import { Position } from "react-flow-renderer";
export const config = {
  styles: {
    wrapper: {
      default: {
        backgroundColor: "#FFFFFF",
        borderColor: "#800F45",
      },
      selected: {
        backgroundColor: "#800F45",
      },
    },
    label: {
      default: {
        color: "#333533",
      },
      selected: {
        color: "#FFFFFF",
      },
    },
    body: {
      default: {
        color: "#333533",
      },
      selected: {
        color: "#FFFFFF",
      },
    },
  },
  handleStyles: [
    {
      properties: {
        type: "target",
        position: Position.Top,
      },
      style: {
        backgroundColor: "blue",
        top: "-15px",
      },
    },
    {
      properties: {
        id: "choice-node-output-true",
        type: "source",
        position: Position.Bottom,
      },
      style: { backgroundColor: "green", left: "25%", bottom: "-15px" },
    },
    {
      properties: {
        id: "choice-node-output-default",
        type: "source",
        position: Position.Bottom,
      },
      style: { backgroundColor: "black", left: "75%", bottom: "-15px" },
    },
  ],
};
