import axios from 'axios';
import {
    API_ENDPOINT,
    ARTICLES_V2,
} from "../constants/Endpoint";
import { REQUEST_CHART_EVENT_TYPE } from '../constants/requestType';
import { REACTION_FILTER_PARAMS } from '../constants/api';
import moment from 'moment';
import { ISO_DATE_FORMAT } from '../constants/misc';
import * as qs from 'qs';

/*******************************************KNOWLEDGE ARTICLES********************************************/

export const articleListApi = async (orgid) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/articleList`,
            { orgid },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const listKA = async (orgid) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/list`,
            {
                orgid
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );

        const qna = result?.data?.response?.qna?.payload?.response?.map(
            (article) => {
                let meta;
                if (typeof article?.meta === 'string') {
                    meta = JSON.parse(article?.meta);
                } else meta = article?.meta;
                return {
                    question: article?.display_name,
                    dialog_id: article?.name,
                    meta
                };
            }
        );
        const intent = result?.data?.response?.intent?.payload?.response?.map(
            (article) => {
                let meta;
                if (typeof article?.meta === 'string') {
                    meta = JSON.parse(article?.meta);
                } else meta = article?.meta;
                return {
                    question: article?.display_name,
                    dialog_id: article?.name,
                    meta
                };
            }
        );
        return { qna, intent };
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const setArticleApi = async (article) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/setArticle`,
            { ...article, meta: { ...article.meta, published: true } },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const deleteKA = async (data) => {
    try {
        const result = await axios.delete(`${API_ENDPOINT}/delete-article`, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            },
            data
        });
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


/*******************************LIKE/DISLIKES*************************** */

export const reactOnArticleApi = async (data) => {
    try {
        const result = await axios.post(`${API_ENDPOINT}/add-reaction`, data, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const reactOnArticleApiV2 = async (data) => {
    try {
        const result = await axios.post(`${API_ENDPOINT}/analytics/createEventRecord`, data, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchReactionsApi = async (orgid) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/fetch-reaction`,
            { orgid: orgid },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchReactionsApiV2 = async (orgid, startDate, endDate, requestType, filter = {}) => {
    try {
        startDate = moment(startDate).utc().format(ISO_DATE_FORMAT);
        endDate = moment(endDate).utc().format(ISO_DATE_FORMAT);
        const result = await axios.get(
            `${API_ENDPOINT}/analytics/eventRecords/${orgid}?startDate=${startDate}&endDate=${endDate}`,
            {
                params: { requestType: requestType || REQUEST_CHART_EVENT_TYPE, filter: { ...REACTION_FILTER_PARAMS, ...filter } },
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchEventCount = async (orgid, startDate, endDate, params) => {
    try {
        startDate = moment(startDate).utc().format(ISO_DATE_FORMAT);
        endDate = moment(endDate).utc().format(ISO_DATE_FORMAT);
        const result = await axios.get(
            `${API_ENDPOINT}/analytics/eventCount/${orgid}?startDate=${startDate}&endDate=${endDate}`,
            {
                params: { ...params },
                paramsSerializer: params => {
                    return qs.stringify(params, { arrayFormat: "repeat" })
                },
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


/********************Fetch single articles****************************/

export const getSingleArticleApi = async (orgid, isarticle, dialogId) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/get_intent`,
            { orgid, isarticle, dialogId },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getSingleDraftArticleApi = async (orgid, articleId) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/getSingleDraftArticle`,
            { orgid, articleId },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getSingleUnverifiedArticleApi = async (orgid, articleId) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/getSingleUnverifiedArticle`,
            { orgid, articleId },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


/************************************ Article Operations V2 *************************************/

export const articleOperations = async (operation, articleData, user) => {
    try {
        const data = articleData;
        const params = { ...user };
        const headers = {
            'x-api-key': process.env.REACT_APP_API_KEY
        };
        const response = await axios.post(
            `${ARTICLES_V2}/${operation}`,
            data, { headers, params }
        );
        return response?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getAllArticles = async (orgId, user, startKey = null) => {
    try {
        const params = { orgId, ...user, startKey };
        const headers = {
            'x-api-key': process.env.REACT_APP_API_KEY
        };
        const response = await axios.get(
            ARTICLES_V2,
            { headers, params }
        );
        return response?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getArticle = async (orgId, article, user) => {
    try {
        const params = { orgId, ...article, ...user };
        const headers = {
            'x-api-key': process.env.REACT_APP_API_KEY
        };
        const response = await axios.get(
            `${ARTICLES_V2}/${article.articleId}`,
            { headers, params }
        );
        return response?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getArticleContent = async (orgId, article, user) => {
    try {
        const params = { orgId, ...article, ...user };
        const headers = {
            'x-api-key': process.env.REACT_APP_API_KEY
        };
        const response = await axios.get(
            `${ARTICLES_V2}/content/${article.articleId}`,
            { headers, params }
        );
        return response?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getArticleAuditLog = async (orgId, article, user) => {
    try {
        const params = { orgId, ...article, ...user };
        const headers = {
            'x-api-key': process.env.REACT_APP_API_KEY
        };
        const response = await axios.get(
            `${ARTICLES_V2}/audit/${article.articleId}`,
            { headers, params }
        );
        return response?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
