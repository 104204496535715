const trackUserActivity = (storeAPI) => (next) => (action) => {
  if (action?.type?.includes('TRACK')) {
    const activity = JSON.parse(localStorage.getItem('activity'))
      ? JSON.parse(localStorage.getItem('activity'))
      : [];

    localStorage.setItem(
      'activity',
      JSON.stringify([
        ...activity,
        {
          action: action.type
            .replace('_TRACK', '')
            .replace('_SUCCESS', '')
            .replace('_BEGIN', ''),
          datetime: new Date()
        }
      ])
    );
  }
  return next(action);
};

export default trackUserActivity;
