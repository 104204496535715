import uuid from 'react-uuid';
import moment from 'moment';
import {
  editChatRecordApi,
  editChatRecordApiV2,
  fetchRecords,
  fetchRecordsV2,
  fetchSingleChatRecord,
  loadQnaToSimilarity
} from '../../api';
import { REQUEST_CHART_SESSION_TYPE } from '../../constants/requestType';
import { partitionDateRange } from '../utils/chat';
import { ISO_DATE_FORMAT } from '../../constants/misc';

export const ChatActions = {
  ADD_CONVERSATION: 'ADD_CONVERSATION',
  CHAT_STARTED_TRACK: 'CHAT_STARTED_TRACK',
  CHAT_ENDED_TRACK: 'CHAT_ENDED_TRACK',
  START_SLACK_CONVERSATION_TRACK: 'START_SLACK_CONVERSATION_TRACK',
  STOP_SLACK_CONVERSATION_TRACK: 'STOP_SLACK_CONVERSATION_TRACK',
  START_WORKFLOW_EXECUTION: 'START_WORKFLOW_EXECUTION',
  STOP_WORKFLOW_EXECUTION: 'STOP_WORKFLOW_EXECUTION',
  START_AGENT_CHAT_WORKFLOW: 'START_AGENT_CHAT_WORKFLOW',
  STOP_AGENT_CHAT_WORKFLOW: 'STOP_AGENT_CHAT_WORKFLOW',
  CLEAR_CHAT_TRACK: 'CLEAR_CHAT_TRACK',
  CLEAR_CHAT_ERROR_MSG: 'CLEAR_CHAT_ERROR_MSG',
  UPDATE_TABLE_CHAT_RECORD: "UPDATE_TABLE_CHAT_RECORD",
  CLEAR_TABLE_CHAT_RECORDS: 'CLEAR_TABLE_CHAT_RECORDS',
  BOT_TYPING: 'BOT_TYPING',
  FETCH_CHAT_RECORDS_BEGIN: 'FETCH_CHAT_RECORDS_BEGIN',
  FETCH_CHAT_RECORDS_SUCCESS_TRACK: 'FETCH_CHAT_RECORDS_SUCCESS_TRACK',
  FETCH_BASELINE_CHAT_RECORDS_SUCCESS_TRACK: 'FETCH_BASELINE_CHAT_RECORDS_SUCCESS_TRACK',
  FETCH_CHAT_RECORD_BY_RECORD_ID_SUCCESS: 'FETCH_CHAT_RECORD_BY_RECORD_ID_SUCCESS',
  FETCH_CHAT_RECORDS_ERROR: 'FETCH_CHAT_RECORDS_ERROR',
  EDIT_CHAT_RECORDS_BEGIN: 'EDIT_CHAT_RECORDS_BEGIN',
  EDIT_CHAT_RECORDS_SUCCESS_TRACK: 'EDIT_CHAT_RECORDS_SUCCESS_TRACK',
  EDIT_CHAT_RECORDS_ERROR: 'EDIT_CHAT_RECORDS_ERROR',
  LOAD_QNA_BEGIN: 'LOAD_QNA_BEGIN',
  LOAD_QNA_SUCCESS: 'LOAD_QNA_SUCCESS',
  LOAD_QNA_ERROR: 'LOAD_QNA_ERROR',
  INCOMING_MESSAGE: 'INCOMING_MESSAGE',
  START_STOP_WORKFLOW: 'START_STOP_WORKFLOW',
  RENDER_DYNAMIC_FORM: 'RENDER_DYNAMIC_FORM',
  ADD_FEEDBACK_DATA: 'ADD_FEEDBACK_DATA'
};

export const getChatActions = (dispatch) => {
  return {
    loadQNA: (orgid, questions, answers) =>
      dispatch(loadQNA(orgid, questions, answers)),
    fetchChatRecords: (org_id) => dispatch(fetchChatRecords(org_id)),
    fetchChatRecordsV3: (org_id, startDate, endDate, requestType, filter) =>dispatch(fetchChatRecordsV3(org_id, startDate, endDate, requestType, filter)),
    fetchBaseLineChatRecordsV3: (org_id, startDate, endDate, requestType, filter) =>dispatch(fetchBaseLineChatRecordsV3(org_id, startDate, endDate, requestType, filter)),
    fetchChatRecordByRecordId: (org_id, recordId) => dispatch(fetchChatRecordByRecordId(org_id, recordId)),
    editChatRecord: (data) => dispatch(editChatRecord(data)),
    editChatRecordV2:(data)=> dispatch(editChatRecordV2(data)),
    fetchBaselineRecords: (org_id, startDate, endDate) =>dispatch(fetchChatRecordsV3(org_id, startDate, endDate)),
  };
};

export const loadQNA = (orgid, questions, meta) => {
  return async (dispatch) => {
    dispatch({ type: ChatActions.LOAD_QNA_BEGIN });
    try {
      const content = await loadQnaToSimilarity(orgid, questions, meta);
      dispatch({
        type: ChatActions.LOAD_QNA_SUCCESS
      });
    } catch (error) {
      dispatch({
        type: ChatActions.LOAD_QNA_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

export const fetchChatRecords = (org_id) => {
  return async (dispatch) => {
    dispatch({ type: ChatActions.FETCH_CHAT_RECORDS_BEGIN });
    try {
      const content = await fetchRecords(org_id);
      const records = [...content?.data?.Items];
      records.forEach((record) => delete record['org_id']);
      const payload = records.filter(item=>item.created_date && item.conversation_type);//remove the entries which doesn't have created at and conversation_type
      dispatch({
        type: ChatActions.FETCH_CHAT_RECORDS_SUCCESS_TRACK,
        payload
      });
    } catch (error) {
      dispatch({
        type: ChatActions.FETCH_CHAT_RECORDS_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

export const fetchChatRecordsV3 = (org_id, startDate, endDate, requestType, filter = {}) => {
  return async (dispatch, getState) => {
    dispatch({ type: ChatActions.FETCH_CHAT_RECORDS_BEGIN });
    try {
      const partionDates = requestType === REQUEST_CHART_SESSION_TYPE ? partitionDateRange(startDate, endDate) : [{start: startDate, end: endDate}];
      const filterLastKey = { ...filter, lastKey: getState()?.chat?.chatRecordsLastKey };
      const firstPromise = fetchRecordsV2(org_id, partionDates[0].start, partionDates[0].end, requestType, filterLastKey);
      const promises = partionDates.slice(1).map(({start, end}) => fetchRecordsV2(org_id, start, end, requestType, filter));
      const content = await Promise.all([firstPromise, ...promises]);
      let chatRecords = [];
      let lastKey = null;
      for(const record of content){
        chatRecords = [...chatRecords, ...record?.data?.data?.Records];
        lastKey = lastKey || record?.data?.data?.lastKey;
      }
      chatRecords.forEach((record) => {
        if ('recordId' in record) {
          record["record_id"] = record["recordId"];
        }
        ['tags', 'analytics'].forEach(key => {
          if (key in record) {
            record[key] = JSON.parse(record[key]);
          }
        });
        if ('created_date' in record) {
          if(requestType === REQUEST_CHART_SESSION_TYPE){
            record['created_date'] = moment(record['created_date']).format(ISO_DATE_FORMAT);
          }
          else{
            record['created_date'] = moment.utc(record['created_date']).local().format(ISO_DATE_FORMAT);
          }
        }
      });
      dispatch({
        type: ChatActions.FETCH_CHAT_RECORDS_SUCCESS_TRACK,
        payload: {chatRecords, lastKey}
      });
    } catch (error) {
      dispatch({
        type: ChatActions.FETCH_CHAT_RECORDS_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};


export const fetchBaseLineChatRecordsV3 = (org_id, startDate, endDate, requestType, filter = {}) => {
  return async (dispatch, getState) => {
    dispatch({ type: ChatActions.FETCH_CHAT_RECORDS_BEGIN });
    try {
      const partionDates = requestType === REQUEST_CHART_SESSION_TYPE ? partitionDateRange(startDate, endDate) : [{start: startDate, end: endDate}];
      const filterLastKey = { ...filter, lastKey: getState()?.chat?.chatRecordsLastKey };
      const firstPromise = fetchRecordsV2(org_id, partionDates[0].start, partionDates[0].end, requestType, filterLastKey);
      const promises = partionDates.slice(1).map(({start, end}) => fetchRecordsV2(org_id, start, end, requestType, filter));
      const content = await Promise.all([firstPromise, ...promises]);
      let chatRecords = [];
      let lastKey = null;
      for(const record of content){
        chatRecords = [...chatRecords, ...record?.data?.data?.Records];
        lastKey = lastKey || record?.data?.data?.lastKey;
      }
      chatRecords.forEach((record) => {
        if ('recordId' in record) {
          record["record_id"] = record["recordId"];
        }
        ['tags', 'analytics'].forEach(key => {
          if (key in record) {
            record[key] = JSON.parse(record[key]);
          }
        });
        if ('created_date' in record) {
          if(requestType === REQUEST_CHART_SESSION_TYPE){
            record['created_date'] = moment(record['created_date']).format(ISO_DATE_FORMAT);
          }
          else{
            record['created_date'] = moment.utc(record['created_date']).local().format(ISO_DATE_FORMAT);
          }
        }
      });
      dispatch({
        type: ChatActions.FETCH_BASELINE_CHAT_RECORDS_SUCCESS_TRACK,
        payload: {chatRecords, lastKey}
      });
    } catch (error) {
      dispatch({
        type: ChatActions.FETCH_CHAT_RECORDS_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};


export const fetchChatRecordsV2 = (org_id, startDate, endDate) => {
  return async (dispatch) => {
    dispatch({ type: ChatActions.FETCH_CHAT_RECORDS_BEGIN });
    try {
      const content = await fetchRecordsV2(org_id, startDate, endDate);
      const chatRecords = [...content?.data?.data?.Records];
      const lastKey = content?.data?.data?.lastKey;
      chatRecords.forEach((record) => {
        if ('recordId' in record) {
          record["record_id"] = record["recordId"];
        }
        ['tags', 'analytics'].forEach(key => {
          if (key in record) {
            record[key] = JSON.parse(record[key]);
          }
        });
        if ('created_date' in record) {
          record['created_date'] = moment.utc(record['created_date']).local().format(ISO_DATE_FORMAT);
        }
      });
      dispatch({
        type: ChatActions.FETCH_CHAT_RECORDS_SUCCESS_TRACK,
        payload: {chatRecords, lastKey}
      });
    } catch (error) {
      dispatch({
        type: ChatActions.FETCH_CHAT_RECORDS_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

export const fetchChatRecordByRecordId = (org_id, record_id) => async (dispatch, getState) => {
  dispatch({ type: ChatActions.FETCH_CHAT_RECORDS_BEGIN });
  const currentState = getState()?.chat?.chatRecordByRecordIds;

  if(!!currentState && Object.keys(currentState).length > 0 && record_id in currentState){
    dispatch({
      type: ChatActions.FETCH_CHAT_RECORD_BY_RECORD_ID_SUCCESS,
      payload: currentState[record_id]
    });
    return;
  }
  
  try {
    const response = await fetchSingleChatRecord(org_id, record_id)
    const payload = response?.data;
    
    ['tags', 'analytics'].forEach(key => {
      if (key in payload) {
        payload[key] = JSON.parse(payload[key]);
      }
    });
    if ('created_date' in payload) {
      payload['created_date'] = moment.utc(payload['created_date']).local().format(ISO_DATE_FORMAT);
    }

    dispatch({
      type: ChatActions.FETCH_CHAT_RECORD_BY_RECORD_ID_SUCCESS,
      payload
    });
    
  } catch (error) {
    dispatch({
      type: ChatActions.FETCH_CHAT_RECORDS_ERROR,
      payload: error?.response?.data?.message
    });
    if(error?.response?.status !== 404) throw error;
  }
}

export const editChatRecord = (data) => {
  return async (dispatch) => {
    dispatch({ type: ChatActions.EDIT_CHAT_RECORDS_BEGIN });
    try {
      const content = await editChatRecordApi(data);
      dispatch({
        type: ChatActions.EDIT_CHAT_RECORDS_SUCCESS_TRACK
      });
      if(!window.location.href.includes("v2")){
        dispatch(fetchChatRecords(data.org_id));
      }
    } catch (error) {
      dispatch({
        type: ChatActions.EDIT_CHAT_RECORDS_ERROR,
        payload: error?.response?.data?.message
      });
      throw error;
    }
  };
};

export const editChatRecordV2 = (data) => {
  return async (dispatch, getState) => {
    let chatData = getState().chat.chatRecords[data.record_id];
    dispatch({ type: ChatActions.EDIT_CHAT_RECORDS_BEGIN });
    try {
      const content = await editChatRecordApiV2(data);
      dispatch({
        type: ChatActions.EDIT_CHAT_RECORDS_SUCCESS_TRACK
      });

      dispatch(updateTableChatRecord(data));
    } catch (error) {
      dispatch({
        type: ChatActions.EDIT_CHAT_RECORDS_ERROR,
        payload: {error: error, data: {chatData, record_id: data.record_id}}
      });
      throw error;
    }
  };
};

export const addConversation = (payload) => {
  const isTemporaryUserActive = localStorage.getItem("isTemporaryUserActive")==="true";
  const date = new Date();
  if (
    (localStorage.getItem('ticketing_system') !== null &&
    localStorage.getItem('validated_user') !== null &&
    localStorage.getItem('slack_channel_id') !== null)||localStorage.getItem('temporary_user')
  ) {
    let previous_chat = localStorage.getItem('chats');
    if (previous_chat === null) {
      previous_chat =
        'FastTrack conversation ' +
        localStorage.getItem('validated_user') +
        ' ' +
        date.getUTCFullYear() +
        '-' +
        String(date.getUTCMonth() + 1).padStart(2, '0') +
        '-' +
        String(date.getUTCDate()).padStart(2, '0');
    }
    localStorage.setItem(
      'chats',
      previous_chat +
        '\n' +
        String(new Date().getUTCHours()).padStart(2, '0') +
        ':' +
        String(new Date().getUTCMinutes()).padStart(2, '0') +
        '   ' +
        payload['type'] +
        ' : ' +
        payload['message']
    );
  } else if (
    localStorage.getItem('ticketing_system') !== null &&
    (localStorage.getItem('validated_user') !== null ||
      localStorage.getItem('unauthenticated_user') !== null) &&
    payload['message'] !== 'Please wait, connecting you to a live agent!'
  ) {
    let previous_chat = localStorage.getItem('leave_message');
    if (previous_chat === null) {
      previous_chat =
        'FastTrack conversation ' +
        `${
          localStorage.getItem('unauthenticated_user') !== null
            ? localStorage.getItem('unauthenticated_user')
            : localStorage.getItem('validated_user')
        }` +
        ' ' +
        date.getUTCFullYear() +
        '-' +
        String(date.getUTCMonth() + 1).padStart(2, '0') +
        '-' +
        String(date.getUTCDate()).padStart(2, '0');
    }
    localStorage.setItem(
      'leave_message',
      previous_chat +
        '\n' +
        String(new Date().getUTCHours()).padStart(2, '0') +
        ':' +
        String(new Date().getUTCMinutes()).padStart(2, '0') +
        '   ' +
        payload['type'] +
        ' : ' +
        payload['message']
    );
  } else {
    let previous_AIchat = localStorage.getItem('AIchats');
    if (previous_AIchat === null) {
      previous_AIchat =
        'FastTrack conversation anonymous ' +
        date.getUTCFullYear() +
        '-' +
        String(date.getUTCMonth() + 1).padStart(2, '0') +
        '-' +
        String(date.getUTCDate()).padStart(2, '0');
    }
    localStorage.setItem(
      'AIchats',
      previous_AIchat +
        '\n' +
        String(new Date().getUTCHours()).padStart(2, '0') +
        ':' +
        String(new Date().getUTCMinutes()).padStart(2, '0') +
        '   ' +
        payload['type'] +
        ' : ' +
        payload['message']
    );
  }
  return {
    type: ChatActions.ADD_CONVERSATION,
    payload: {
      ...payload,
      id: uuid()
    }
  };
};

export const chatStarted = () => {
  return {
    type: ChatActions.CHAT_STARTED_TRACK
  };
};


export const addFeedbackData = (payload) =>{
  return{
    type: ChatActions.ADD_FEEDBACK_DATA,
    payload
  }
}

export const clearChat = (payload) => {
  return {
    type: ChatActions.CLEAR_CHAT_TRACK,
    payload
  };
};

export const chatEnded = () => {
  return {
    type: ChatActions.CHAT_ENDED_TRACK
  };
};

export const clearChatErrorMsg = () => {
  return {
    type: ChatActions.CLEAR_CHAT_ERROR_MSG
  };
}

export const updateTableChatRecord = (data) => {
  return {
    type: ChatActions.UPDATE_TABLE_CHAT_RECORD,
    payload: data
  }
}

export const clearTableChatRecords =  () => {
  return {
    type: ChatActions.CLEAR_TABLE_CHAT_RECORDS
  }
}

export const startSlackConversation = () => {
  return {
    type: ChatActions.START_SLACK_CONVERSATION_TRACK
  };
};

export const stopSlackConversation = () => {
  return {
    type: ChatActions.STOP_SLACK_CONVERSATION_TRACK
  };
};

export const startWorkflowExecution = () => {
  return {
    type: ChatActions.START_WORKFLOW_EXECUTION
  };
};

export const stopWorkflowExecution = () => {
  return {
    type: ChatActions.STOP_WORKFLOW_EXECUTION
  };
};

export const startAgentChatWorkflow = () => {
  return {
    type: ChatActions.START_AGENT_CHAT_WORKFLOW
  };
};

export const stopAgentChatWorkflow = () => {
  return {
    type: ChatActions.STOP_AGENT_CHAT_WORKFLOW
  };
};

export const setBotTyping = (payload) => {
  return {
    type: ChatActions.BOT_TYPING,
    payload
  };
};

export const incomingMessage = (payload) => {
  return {
    type: ChatActions.INCOMING_MESSAGE,
    payload
  };
};

/******************************Start/Stop workflow ********************/

export const startStopWorkflow = (payload) => {
  return {
    type: ChatActions.START_STOP_WORKFLOW,
    payload
  };
};

export const renderDynamicForm = (payload) => {
  return {
    type: ChatActions.RENDER_DYNAMIC_FORM,
    payload
  };
};
