const key = process.env.REACT_APP_ENDPOINT;
const websocketKey = process.env.REACT_APP_WEBSOCKET_ENDPOINT;
export const API_ENDPOINT = `https://${key}.execute-api.us-east-1.amazonaws.com/api`;

export const CREATE_CHAT_RECORD = `https://${key}.execute-api.us-east-1.amazonaws.com/api/add-record`;
export const CHAT_RECORD = `https://${key}.execute-api.us-east-1.amazonaws.com/api`;
export const CREATE_CHAT_RECORD_V2 = `https://${key}.execute-api.us-east-1.amazonaws.com/api/analytics/createChatRecord`;
export const EDIT_CHAT_RECORD_V2 = `https://${key}.execute-api.us-east-1.amazonaws.com/api/analytics/editChatRecord`;
export const CREATE_CHANNEL = `https://${key}.execute-api.us-east-1.amazonaws.com/api/createchannel`;
export const SEND_MESSAGE = `https://${key}.execute-api.us-east-1.amazonaws.com/api/sendmessage`;
export const CREATE_CONNECTION = `https://${key}.execute-api.us-east-1.amazonaws.com/api/socketurl`;
export const ARCHIVE_CHANNEL = `https://${key}.execute-api.us-east-1.amazonaws.com/api/archivechannel`;
export const CREATE_TICKET = `https://${key}.execute-api.us-east-1.amazonaws.com/api/create-tickets`;
export const NAME_MENTIONS = `https://${key}.execute-api.us-east-1.amazonaws.com/api/namementions`;

export const API_ORG_INFO = `https://${key}.execute-api.us-east-1.amazonaws.com/api/get-organisation-data`;

export const API_ORG_CUSTOMIZATION_INFO = `https://${key}.execute-api.us-east-1.amazonaws.com/api/org-customizations`;

export const INITIATE_AUTH = `https://${key}.execute-api.us-east-1.amazonaws.com/api/initiate_auth`;
export const VERIFY_AUTH = `https://${key}.execute-api.us-east-1.amazonaws.com/api/verify_auth`;

export const API_SIMILARITY_LOADQNA = `https://${key}.execute-api.us-east-1.amazonaws.com/api/load_qna`;
export const API_SIMILARITY_STATUS = `https://${key}.execute-api.us-east-1.amazonaws.com/api/status`;

export const AGENT_STATUS = `https://${key}.execute-api.us-east-1.amazonaws.com/api/agent_status`;

export const AGENT_TYPE = `https://${key}.execute-api.us-east-1.amazonaws.com/api/agent_type`;

export const ORDER_STATUS = `https://${key}.execute-api.us-east-1.amazonaws.com/api/ShopifyOrderInfo`;

export const GET_AGENTS = `https://${key}.execute-api.us-east-1.amazonaws.com/api/agents`;

export const HANDLE_AGENTS = `https://${key}.execute-api.us-east-1.amazonaws.com/api/handle_agents`;

export const CREATE_ENDUSER_ACCOUNT = `https://${key}.execute-api.us-east-1.amazonaws.com/api/create_enduseraccount`;

export const csvTemplate =
  'https://dev-readyly-fasttrack.s3.amazonaws.com/csvTemplate/sample.csv';

export const GET_IFRAME_WEBPAGE = `https://${key}.execute-api.us-east-1.amazonaws.com/api/get-iframe-webpage`;

export const SOCKET_URL = `wss://${websocketKey}.execute-api.us-east-1.amazonaws.com/api`;

export const PROCESS_OTP =   `https://${key}.execute-api.us-east-1.amazonaws.com/api/process-otp`

export const ARTICLES_V2 = `${API_ENDPOINT}/articles`;
