import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDeepCompareEffect } from 'react-use';
import createDataForExport from '../../../lib/createDataForExport';
import { Button } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';


const ExportButton = ({ articles }) => {
  const [exportData, setExportData] = useState([]);

  useDeepCompareEffect(() => {
    const [articlesExpanded, intentExpanded] = createDataForExport(articles);
    setExportData([...intentExpanded, ...articlesExpanded]);
  }, [articles]);

  return (
    (articles?.intent?.length > 0 || articles?.qna?.length > 0) && (
      <CSVLink filename={'articles.csv'} data={exportData}>
        <Button
          variant="contained"
          size="medium"
          startIcon={<FileUploadOutlinedIcon />}
          sx={{ textTransform: 'none' }}
        >
          Export
        </Button>
      </CSVLink>
    )
  );
};

export default ExportButton;
