import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import trackUserActivity from '../lib/trackUserActivity';

import authReducer from './reducers/authReducer';
import chatReducer from './reducers/chatReducer';
import contentReducer from './reducers/contentReducer';
import studioReducer from './reducers/studioReducer';

const rootReducer = combineReducers({
  content: contentReducer,
  auth: authReducer,
  chat: chatReducer,
  studio: studioReducer
});

const middlewareEnhancer = applyMiddleware(trackUserActivity, thunk);

export const store = createStore(
  rootReducer,
  composeWithDevTools(middlewareEnhancer)
);

export default store;
