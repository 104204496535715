// Packages:
import React, { useState, useEffect, memo } from "react";
import useInput from "../../../../lib/hooks/use-input";
import {
  MenuItem,
  FormControl,
  Select,
  TextField,
  styled,
} from "@mui/material";
import { MdCancel } from "react-icons/md";

// Styles:
import { FieldName, VariableField, Variable } from "../styles";
import BaseConfiguration from "../BaseConfiguration";

const StyledFormControl = styled(FormControl)`
  margin-bottom: 1rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 14px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 14px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

// Functions:
const DecisionConfiguration = ({
  node,
  updateNode,
  deleteNode,
  isEditable,
  setNodes
}) => {
  // Constants:

  const DECISIONTYPE = {
    BINARY: "BINARY",
    MULTIPLE: "MULTIPLE",
  };

  const {
    value: orderId,
    setValue: setDecisionCriteria,
    bind: bindDecisionCriteria,
  } = useInput(node.orderId ?? "");
  const [decisionType, setDecisionType] = useState(node.decisionType);
  const [multipleOptions, setMultipleOptions] = useState("");
  const [options, setOptions] = useState([]);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields,setOutputFields]=useState([])


  // Effects:
  useEffect(() => {
    setDecisionCriteria(node.orderId);
    setDecisionType(node.decisionType);
  }, [node.orderId, node.decisionType]);

  useEffect(() => {
    updateNode(node.id, {
      orderId,
      decisionType,
      options,
      dynamicFields: dynamicFields,
      outputFields:outputFields
    });
  }, [orderId, decisionType, options, dynamicFields]);

  const optionOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = multipleOptions.trim();
    if (
      (key === "," || key === "Enter") &&
      trimmedInput.length &&
      !options?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setOptions((prevState) => [...prevState, trimmedInput]);
      setMultipleOptions("");
    }
  };

  const handleOptionValueChange = (e) => {
    const { value } = e.target;
    setMultipleOptions(value);
  };

  const deleteOption = (index) => {
    setOptions((prevState) => prevState?.filter((option, i) => i !== index));
  };

  // Return:
  return (
    <BaseConfiguration
      setDynamicFields={setDynamicFields}
      node={node}
      isEditable={isEditable}
      deleteNode={deleteNode}
      outputFields={outputFields}
      setOutputFields={setOutputFields}
      setNodes={setNodes}
    >
      <FieldName>Decision Type</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="decision-type-select"
          disabled={!isEditable}
          value={decisionType}
          onChange={(e) => setDecisionType(e.target.value)}
        >
          {Object.values(DECISIONTYPE).map((functionality, index) => (
            <StyledMenuItem key={index} value={functionality}>
              {functionality}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>

      <FieldName>Decision Criteria</FieldName>
      <StyledTextField
        id="decision-criteria"
        variant="outlined"
        disabled={!isEditable}
        placeholder="Please enter decision criteria"
        {...bindDecisionCriteria}
        size="small"
        fullWidth
      />

      {decisionType === "MULTIPLE" ? (
        <>
          <FieldName>Options</FieldName>
          <VariableField>
            {options?.map((option, index) => (
              <Variable key={option}>
                {option}
                <MdCancel
                  style={{ marginLeft: "0.5rem", cursor: "pointer" }}
                  onClick={() => deleteOption(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              disabled={!isEditable}
              value={multipleOptions}
              placeholder="Enter an option"
              onKeyDown={optionOnKeyDown}
              onChange={handleOptionValueChange}
              size="small"
              fullWidth
            />
          </VariableField>
        </>
      ) : (
        <></>
      )}
    </BaseConfiguration>
  );
};

// Exports:
export default memo(DecisionConfiguration);