// Packages:
import React, { memo, useCallback, useEffect, useState } from "react";
import BaseConfiguration from "../BaseConfiguration";
import { DEFAULT_USER_DETAILS, PROMPTS } from "./utils";
import { FieldName } from "../styles";
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
} from "@mui/material";
import { toast } from "react-toastify";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import useInput from "../../../../lib/hooks/use-input";

const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  max-height: 36px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;
const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }

  & .MuiFormLabel-root {
    color: ${(props) =>
      props.messageErrorText === "Valid HTML" ? "green" : "default"};
  }

  & .MuiFormHelperText-root {
    color: ${(props) =>
      props.messageErrorText === "Valid HTML" ? "green" : "default"};
  }

  & fieldset {
    border: ${(props) =>
      props.messageErrorText === "Valid HTML" ? "1px solid green" : "default"};
  }
`;

// Functions:
const EndConfiguration = ({
  node,
  updateNode,
  deleteNode,
  isEditable,
  setNodes,
}) => {
  const [repliesExpected, setRepliesExpected] = useState(node.repliesExpected ?? false);
  const [numberOfVariables, setNumberOfVariables] = useState(node.numberOfVariables ?? 0);
  const [messageErrorText, setMessageErrorText] = useState("");
  const { value: message, setValue: setMessage, bind: bindMessage} = useInput(node.message ?? "");
  const [replies, setReplies] = useState(node.replies ?? []);
  const [userDetailsExpected,setUserDetailsExpected]=useState(node.userDetailsExpected??false)
  const [userDetails,setUserDetails]=useState(node.userDetails??DEFAULT_USER_DETAILS)
  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields,setOutputFields] = useState([])


  useEffect(() => {
    setRepliesExpected(node.repliesExpected ?? false);
    setNumberOfVariables(node.numberOfVariables ?? 0);
    setMessage(node.message ?? "");
    setReplies(node.replies ?? []);
    setUserDetails(node.userDetails??DEFAULT_USER_DETAILS)
    setUserDetailsExpected(node.userDetailsExpected??false)
  }, [
    node.repliesExpected,
    node.numberOfVariables,
    node.replies,
    node.message,
    node.userDetails,
    node.userDetailsExpected
  ]);

  useEffect(() => {
    updateNode(node.id, { repliesExpected, numberOfVariables, replies, message,userDetails,userDetailsExpected,      dynamicFields:dynamicFields, outputFields:outputFields });
  }, [repliesExpected, numberOfVariables, replies, message,userDetails,userDetailsExpected,dynamicFields]);

  useEffect(() => {
    if (!message) {
      setRepliesExpected(false);
      setNumberOfVariables(0);
      setReplies([]);
    }
  }, [message]);

  const handleMessageValidate = useCallback(() => {
    const openingTags = message.match(/<[a-z]+?>/g);
    const closingTags = message.match(/<[/][a-z]+?>/g);
    if (openingTags === null && closingTags === null) setMessageErrorText("");
    else {
      if (
        (openingTags !== null || closingTags !== null) &&
        openingTags?.length !== closingTags?.length
      )
        setMessageErrorText("Invalid HTML");
      else setMessageErrorText("Valid HTML");
    }
  }, [node?.message]);

  const handleNumberOfInputChange = useCallback((e) => {
    const value = parseInt(e.target.value, 10) || 0;
    if (value > 5) {
      toast.error("Input can not be greater than 5");
      return;
    }
    setNumberOfVariables(value);

    setReplies((prevDynamicData) => {
      const newDynamicData = [...prevDynamicData];
      if (value < newDynamicData.length) {
        newDynamicData.length = value;
      } else if (value > newDynamicData.length) {
        for (let i = newDynamicData.length + 1; i <= value; i++) {
          newDynamicData.push({
            type: null,
          });
        }
      }
      return newDynamicData;
    });
  }, []);

  const handleCheckboxChange = useCallback(() => {
    setRepliesExpected(!repliesExpected);
    setNumberOfVariables(0);
    setReplies([]);
  }, [repliesExpected]);

  const handleDataInputChange = useCallback((index, field, value) => {
    setReplies((prevData) => {
      const newData = [...prevData];
      if (field === "prompt") {
        newData[index] = {
          ...newData[index],
          type: value,
          data:
            value === "query"
              ? { query: newData[index]?.data?.query || "" }
              : { query: newData[index]?.data?.query || "", response: "" },
        };
      } else if (field === "query") {
        newData[index] = {
          ...newData[index],
          data: {
            ...newData[index].data,
            query: value,
          },
        };
      } else if (field === "auto") {
        newData[index] = {
          ...newData[index],
          data: {
            ...newData[index].data,
            response: value,
          },
        };
      }
      return newData;
    });
  }, []);

  const handleUserDetailsChange = useCallback(
    (index, value) => {
      const isDynamic = dynamicFields.includes(value);
      const newValue = isDynamic
        ? { fieldName: value, value: null }
        : { fieldName: null, value:value };
  
      setUserDetails((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          key: newData[index].key,
          value: newValue
        };
        return newData;
      });
    },
    [dynamicFields]
  );

  // Return:
  return (
    <BaseConfiguration
      setDynamicFields={setDynamicFields}
      node={node}
      deleteNode={deleteNode}
      isEditable={isEditable}
      outputFields={outputFields}
      setNodes={setNodes}
      setOutputFields={setOutputFields}
    >
      <FieldName>Message</FieldName>
      <StyledTextField
        id="message"
        variant="outlined"
        disabled={!isEditable}
        error={
          messageErrorText.length === 0 || messageErrorText === "Valid HTML"
            ? false
            : true
        }
        label={
          messageErrorText.length === 0
            ? null
            : messageErrorText === "Valid HTML"
            ? "Success"
            : "Error"
        }
        helperText={messageErrorText}
        placeholder="Enter a message / HTML to be displayed to the user"
        {...bindMessage}
        multiline
        rows={5}
        size="small"
        fullWidth
        messageErrorText={messageErrorText}
      />
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        variant="contained"
        size="small"
        startIcon={<TaskAltOutlinedIcon />}
        sx={{ textTransform: "none", mb: "1rem" }}
        onClick={() => handleMessageValidate()}
      >
        Validate HTML
      </Button>
    </div>
      {message && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <input
              type="checkbox"
              style={{ marginRight: "0.35rem" }}
              disabled={!isEditable}
              checked={repliesExpected}
              onChange={handleCheckboxChange}
            />
            <div style={{ fontSize: "0.8rem" }}>Replies expected:</div>
            <input
              type="number"
              min="0"
              disabled={!repliesExpected || !isEditable}
              style={{
                width: "3rem",
                marginLeft: "0.25rem",
              }}
              value={numberOfVariables}
              onChange={handleNumberOfInputChange}
            />
          </div>
          {repliesExpected && (
            <>
              {repliesExpected && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {replies.map((_, index) => (
                    <div style={{border:'2px solid #f0f0f0' ,padding:'0.25rem',marginBottom:'0.5rem',borderRadius:'0.5rem'}}>
                      <StyledFormControl
                        variant="filled"
                        size="small"
                        fullWidth
                      >
                        <InputLabel id={`prompt-${index}`}>Prompt</InputLabel>
                        <Select
                          id={`prompt-${index}`}
                          labelId={`prompt-${index}`}
                          disabled={!isEditable}
                          value={replies[index].type}
                          onChange={(e) =>
                            handleDataInputChange(
                              index,
                              "prompt",
                              e.target.value
                            )
                          }
                        >
                          {Object.values(PROMPTS).map((prompt, index) => (
                            <StyledMenuItem key={index} value={prompt}>
                              {prompt}
                            </StyledMenuItem>
                          ))}
                        </Select>
                      </StyledFormControl>
                      {replies[index].type && (
                        <>
                          <StyledTextField
                            type="text"
                            placeholder="Enter your query"
                            disabled={!isEditable}
                            fullWidth
                            value={replies[index]?.data?.query}
                            size="small"
                            style={{ padding: 0 }}
                            onChange={(e) =>
                              handleDataInputChange(
                                index,
                                "query",
                                e.target.value
                              )
                            }
                          />
                          {replies[index].type === "auto" && (
                            <StyledTextField
                              type="text"
                              placeholder="Enter Response"
                              disabled={!isEditable}
                              fullWidth
                              value={replies[index]?.data?.response}
                              size="small"
                              style={{ padding: 0 }}
                              onChange={(e) =>
                                handleDataInputChange(
                                  index,
                                  "auto",
                                  e.target.value
                                )
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}
        <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <input
              type="checkbox"
              style={{ marginRight: "0.35rem" }}
              disabled={!isEditable}
              checked={userDetailsExpected}
              onChange={(e)=>setUserDetailsExpected(e.target.checked)}
            />
            <div style={{ fontSize: "0.8rem" }}>User Details Expected</div>
          </div>
          {userDetailsExpected && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {userDetails.map((_, index) => (
            <div key={index} style={{ display: "flex", flexDirection: "row" }}>
              <StyledTextField
                type="text"
                placeholder="Variable"
                disabled={true}
                value={userDetails[index].key}
                size="small"
                style={{ padding: 0 }}
              />
              <Autocomplete
                options={dynamicFields}
                disabled={!isEditable}
                value={userDetails[index].value.fieldName??userDetails[index].value.value}
                fullWidth
                freeSolo={userDetails[index].key==='personas'}
                onChange={(_, value) =>    handleUserDetailsChange(index,value)}
                onInputChange={(event, value) => handleUserDetailsChange(index,value)}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder="Value"
                    variant="outlined"
                    size="small"
                    style={{ padding: 0 }}
                  />
                )}
              />
            </div>
          ))}
        </div>
      )}
    </BaseConfiguration>
  );
};

// Exports:
export default memo(EndConfiguration);