// Packages:
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/auth';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


// Imports:
import { HiOutlineMail } from 'react-icons/hi';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { BsKeyFill } from 'react-icons/bs';


// Styles:
import { Wrapper, Tag, InputArea, Input } from '../Login/styles';

import { Subtitle, Form, InputField } from './styles';

// Functions:
const ForgotPassword = () => {
  // Constants:
  const navigate = useNavigate();
  const auth = useAuth();

  // State:
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  // Functions:
  const sendcode = useCallback(
    (event) => {
      event.preventDefault();
      auth.sendCode(email);
    },
    [auth, email]
  );

  const changepassword = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        await auth.validatePassword(email, code, password);
      } catch (error) {
        console.error(error);
      }
    },
    [auth, code, email, password]
  );

  // Return:
  return (
    <Wrapper>
      <Subtitle>Powering speed and accuracy.</Subtitle>
      <Form>
        <Tooltip title="Back">
          <IconButton
            sx={{ mb: '1.5rem', color: '#333333' }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <InputField>
          <Tag>Email address</Tag>
          <InputArea>
            <Input
              type="text"
              placeholder="Enter Your Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
            <HiOutlineMail style={{ color: '#333333'}}/>
          </InputArea>
        </InputField>
        <Button
          variant="contained"
          size="medium"
          startIcon={<SendIcon />}
          onClick={sendcode}
          sx={{ textTransform: 'none', mb: '2rem' }}
        >
          Send OTP
        </Button>
        <InputField>
          <Tag>OTP</Tag>
          <InputArea>
            <Input
              type="text"
              placeholder="Enter Received OTP"
              onChange={(e) => setCode(e.target.value)}
            />
            <BsKeyFill style={{ color: '#333333'}}/>
          </InputArea>
        </InputField>
        <InputField>
          <Tag>Password</Tag>
          <InputArea>
            <Input
              type={isPasswordVisible ? 'text' : 'password'}
              placeholder="Enter New Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {isPasswordVisible ? (
              <AiOutlineEye
                style={{ cursor: 'pointer', color: '#333333' }}
                onClick={() => setIsPasswordVisible(false)}
              />
            ) : (
              <AiOutlineEyeInvisible
                style={{ cursor: 'pointer', color: '#333333' }}
                onClick={() => setIsPasswordVisible(true)}
              />
            )}
          </InputArea>
        </InputField>
        <Button
          variant="contained"
          size="medium"
          startIcon={<SaveIcon />}
          onClick={changepassword}
          sx={{ textTransform: 'none', mb: '2rem' }}
        >
          Change Password
        </Button>
      </Form>
    </Wrapper>
  );
};

// Exports:
export default ForgotPassword;
