import uuid from "react-uuid";
import { articleOperations } from "../../api";

import {
  ARTICLE_IS_DRAFT,
  FIRST_DRAFT_VERSION,
  CREATE_DRAFT_OPERATION,
  SAVE_DRAFT_OPERATION,
  DELETE_DRAFT_OPERATION,
  SUBMIT_DRAFT_OPERATION
} from '../../constants/articles';

export function createEmptyDraft(orgId, createdBy) {
  const now = new Date().toISOString();
  return {
    articleId: uuid(),
    orgId,
    meta: {
      status: ARTICLE_IS_DRAFT,
      isSubmitted: false,
      importFromSource: false,
      createdBy,
      title: 'New draft',
      formattedText: '<p></p>',
      firstLine: '',
      target: 'agent',
      access: 'private',
      tags: [],
      trainingPhrases: [],
      synonyms: [],
      customFields: {},
      source: '',
      s3Link: '',
      createdDate: now,
      lastModifiedDate: now
    }
  }
}

export async function createDraft(article, user) {
  const response = await articleOperations(CREATE_DRAFT_OPERATION, article, user);
  return response?.data;
}

export async function saveDraft(article, user) {
  const response = await articleOperations(SAVE_DRAFT_OPERATION, article, user);
  return response?.data;
}

export async function deleteDraft(article, user) {
  await articleOperations(DELETE_DRAFT_OPERATION, article, user);
}

export async function submitDraft(article, user) {
  const response = await articleOperations(SUBMIT_DRAFT_OPERATION, article, user);
  return response?.data;
}