import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { useCallback } from 'react';

const StyledFormControl = styled(FormControl)`
  width: 120px;
  & .MuiFormLabel-root {
    font-size: 14px;
  }
  & .MuiOutlinedInput-root {
    font-size: 14px;
  }
  & .MuiListItemText-primary {
    font-size: 14px;
    line-height: 1;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  width: 120px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

export default function Dropdown({ type, changeType }) {
  const names = ['Boolean', 'String', 'Array'];

  const handleChange = useCallback(
    (event) => {
      changeType(event.target.value);
    },
    [changeType]
  );

  return (
    <StyledFormControl size="small">
      <InputLabel>Type</InputLabel>
      <Select
        value={type}
        onChange={handleChange}
      >
        {names.map((name) => (
          <StyledMenuItem key={name} value={name.toLowerCase()}>
            <ListItemText primary={name} sx={{ fontSize: '14px' }} />
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}