import React, { useState, useEffect, memo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Tooltip,
  Button,
  styled,
  Select,
  MenuItem,
  ListItemText,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { toast } from "react-toastify";

//constants
import {
  boolean,
  conditionDescription,
  conditionType,
  isNegativeValues,
  operatorMapping,
  operators,
  variables,
} from "./utils";

//styles
import { ChoiceContainer, FieldContainer, FieldName } from "./style";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 1rem;
    max-width: 70%;
    width: 100%;
    max-height: 50%;
    height: 100%;
  }

  & .MuiTypography-root {
    font-family: "Inter", sans-serif;
  }

  & .MuiDialogActions-root {
    padding: 20px 24px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const RuleBox = ({
  ruleOpen,
  handleRuleClose,
  settings,
  setCurrentRemoteWorkflow,
  isEditable,
  dynamicFields,
  rule,
  updateRules,
  rules,
  index,
  updateNode,
  node,
  setRules,
  outputFields
}) => {
  // State:
  const [condition, setCondition] = useState(rule?.condition??conditionType[0]);
  const [isConstant, setIsConstant] = useState(rule?.isConstant??false);
  const [operator, setOperator] = useState(rule?.operator??operators[0]);
  const [variableType, setVariableType] = useState(rule?.variableType?? '');
  const [variable, setVariable] = useState(rule?.variable??'');
  const [inputValue, setInputValue] = useState(rule?.inputValue??null);
  const [isNegative, setIsNegative] = useState(rule?.isNegative===false?"":rule.isNegative??'');

  useEffect(()=>{
    setCondition(rule?.condition ?? conditionType[0])
    setIsConstant(rule?.isConstant ?? true)
    setOperator(rule?.operator ??operators[0])
    setVariableType(rule?.variableType ??'')
    setVariable(rule?.variable ?? null)
    setInputValue(rule?.inputValue ?? null)
    setIsNegative(rule?.isNegative ==='NOT' ?'NOT' :"")
  },[rule.condition,rule.isConstant,rule.operator,rule.variableType,rule.variable,rule.inputValue,rule.isNegative])

  //Event handlers
  const handleConditionChange = (event) => {
    setCondition(event.target.value);
  };
  const handleOperatorChange = (event) => {
    setOperator(event.target.value);
  };
  const handleNegationChange = (event) => {
    setIsNegative(event.target.value);
  };
  const handleVariableTypeChange = (event) => {
    setVariableType(event.target.value);
  };
  const handleVariableChange = (event, value) => {
    setVariable(value);
  };

  const handleInputValueChange = (event, value) => {
    setInputValue(value);
  };
  const handleConstantChnage = (event) => {
    setIsConstant(event.target.value === "True");
  };

  // Event Handler:
  const handleSaveSettings = () => {
    if(!variable || !operator ){
      toast.error("Please select all the relavent fields")
      return;
    }
    const newRule = {
      ...rule,
      condition,
      isConstant,
      operator,
      variableType,
      variable,
      inputValue,
      isNegative:isNegative==='NOT'?'NOT':false,
      label:`${isNegative==='NOT'?'!':''}${variable}${operator}${inputValue===''?'empty-string':inputValue}`
    };
    const updatedRules = [...rules]; 
    updatedRules[index] = newRule; 
    setRules(updatedRules); 
    updateNode(node.id, { choices: updatedRules,dynamicFields,outputFields }); 
    handleRuleClose();
  };
  
  return (
    <StyledDialog open={ruleOpen} onClose={handleRuleClose}>
      <DialogTitle sx={{ mb: "1rem", fontSize: "1.25rem", color: "#333333" }}>
        Condition for Rule
        <Tooltip title="close">
          <IconButton
            onClick={handleRuleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent style={{ width: "100%", padding: 4 }}>
        <Select
          style={{ height: 60, padding: 5 }}
          value={condition}
          disabled={!isEditable}
          onChange={handleConditionChange}
        >
          {conditionType.map((condition) => (
            <StyledMenuItem key={condition} value={condition}>
              <ListItemText
                primary={condition}
                secondary={conditionDescription[condition]}
              />
            </StyledMenuItem>
          ))}
        </Select>
        <ChoiceContainer>
          <FieldContainer style={{ flex: 1 / 3 }}>
            <FieldName>NOT</FieldName>
            <Select
              style={{ height: 37 }}
              value={isNegative ? "NOT" : ""}
              disabled={!isEditable}
              onChange={handleNegationChange}
            >
              {isNegativeValues.map((option) => (
                <StyledMenuItem key={option} value={option}>
                  <ListItemText primary={option} style={{ height: '1.5rem' }} />
                </StyledMenuItem>
              ))}
            </Select>
          </FieldContainer>
          <FieldContainer style={{ flex: 1 }}>
            <FieldName>Variable</FieldName>
            <Autocomplete
              options={dynamicFields}
              disabled={!isEditable}
              value={variable}
              fullWidth
              onChange={handleVariableChange}
              onInputChange={handleVariableChange}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  placeholder="Select"
                  variant="outlined"
                  size="small"
                  style={{ padding: 0 }}
                />
              )}
            />
          </FieldContainer>
          <FieldContainer style={{ flex: 1 / 3 }}>
            <FieldName>Operator</FieldName>
            <Select
              style={{ height: 37 }}
              value={operator}
              placeholder="Select"
              disabled={!isEditable}
              onChange={handleOperatorChange}
            >
              {Object.entries(operatorMapping).map(([key, value]) => (
                <StyledMenuItem key={key} value={key}>
                  <ListItemText primary={value} />
                </StyledMenuItem>
              ))}
            </Select>
          </FieldContainer>
          {operator !== '?' && (
            <>
              <FieldContainer style={{ flex: 1 / 2 }}>
                <FieldName>Value</FieldName>
                <Select
                  style={{ height: 37 }}
                  value={variableType}
                  disabled={!isEditable}
                  onChange={handleVariableTypeChange}
                >
                  {(operator==='~'?["String"]:variables).map((value) => (
                    <StyledMenuItem key={value} value={value}>
                      <ListItemText primary={value} />
                    </StyledMenuItem>
                  ))}
                </Select>
              </FieldContainer>
              <FieldContainer style={{ flex: 1 }}>
                <FieldName>Constant</FieldName>
                <Select
                  style={{ height: 37 }}
                  value={isConstant ? "True" : "False"}
                  onChange={handleConstantChnage}
                  disabled={!isEditable}
                >
                  {boolean.map((boolean) => (
                    <StyledMenuItem key={boolean} value={boolean}>
                      <ListItemText primary={boolean} />
                    </StyledMenuItem>
                  ))}
                </Select>
              </FieldContainer>
            </>
          )}
          {isConstant && variableType !== 'Boolean' && operator !== '?' ? (
            <FieldContainer style={{ flex: 1 }}>
              <FieldName>Input Value</FieldName>
              <StyledTextField
                id="choice-node-input-value"
                disabled={!isEditable}
                type={variableType && variableType.length > 0 ? variableType === 'Numeric' ? 'number' : variableType.toLowerCase() : 'text'}
                variant="outlined"
                placeholder="Enter value"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                size="small"
                fullWidth
              />
            </FieldContainer>
          ) : (
            <FieldContainer style={{ flex: 1 }}>
              <FieldName>Input Value</FieldName>
              <Autocomplete
                options={(variableType === 'Boolean' || operator === '?') ? boolean : dynamicFields}
                disabled={!isEditable}
                value={inputValue}
                fullWidth
                onChange={(event, value) => setInputValue(value)}
                onInputChange={(event, value) => setInputValue(value)}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    placeholder="Select"
                    variant="outlined"
                    size="small"
                    style={{ padding: 0 }}
                  />
                )}
              />
            </FieldContainer>
          )}
        </ChoiceContainer>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          size="medium"
          disabled={!isEditable}
          startIcon={<SaveOutlinedIcon />}
          sx={{ textTransform: "none" }}
          color="success"
          onClick={handleSaveSettings}
        >
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

// Exports:
export default memo(RuleBox);