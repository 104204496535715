// Packages:
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import sanitizeHtml from 'sanitize-html';
import { IconButton, Chip } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import Tooltip from '@mui/material/Tooltip';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import VerifiedIcon from '@mui/icons-material/Verified';
import DraftsIcon from '@mui/icons-material/Drafts';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ErrorIcon from '@mui/icons-material/Error';
import PublicIcon from '@mui/icons-material/Public';
import SecurityIcon from '@mui/icons-material/Security';

// Constants:

// Styles:
import {
  Wrapper,
  Title,
  Body,
  Actions,
  FeedbackMessage,
  Header,
  Badge,
  StyledLink,
  ExportLogsLink
} from './styles';

import ROUTES from '../../../constants/routes';
import { getContentActions } from '../../../redux/actions/contentActions';
import { urlify } from '../../../lib/urlify';
import { downloadEntityLogs, saveLogs } from '../../../lib/downloadEntityLogs';

// Functions:
const FAQResult = ({ article, title, deleteArticle }) => {
  // State:
  const query = useSelector((state) => state.content.query);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const orgid = useSelector((state) => state.content.org_info?.org_data?._id);
  const [likeStatus, setLikeStatus] = useState(0); // TS: <-1 | 0 | 1>
  const articleLogsRef = useRef(null);

  const dispatch = useDispatch();
  const contentActions = getContentActions(dispatch);

  const params = {
    published: article?.meta?.published
  };

  if (article?.meta?.published) {
    params['dialog_id'] = article?.dialog_id?.replaceAll('/', '^');
  } else {
    params['articleId'] = article?.articleId;
  }
  params['isarticle'] = article?.meta?.isarticle;

  const queryString = Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&');

  const addReaction = async (type) => {
    try {
      const data = {
        orgid,
        user_email: `${userDetails?.email || 'anonymous'}`,
        type,
        subtype:
          article.name ||
          article?.['response']?.['payload']?.['response']?.['dialog_id']
            ? article?.meta?.isarticle
            : 'similarity',
        dialog_id:
          article.name ||
          article?.['response']?.['payload']?.['response']?.['dialog_id'] ||
          '',
        title,
        query
      };
      await contentActions.reactOnArticle(data);
    } catch (error) {
      console.error(error);
    }
  };

  const exportLogs = async () => {
    if (articleLogsRef.current) {
      saveLogs('article', article?.meta?.articleId, articleLogsRef.current);
      return;
    }
    const response = await downloadEntityLogs(orgid, 'article', article?.meta?.articleId);
    if (response) {
      articleLogsRef.current = response;
    }
  };

  // Return:
  return (
    <Wrapper>
      <div style={{ width: '100%' }}>
        <Header>
          <StyledLink
            to={`${ROUTES.KNOWLEDGE_ARTICLE}/${queryString}`}
            state={{ isEdit: false, isNew: false }}
            key={article.id}
            showaslink={true.toString()}
          >
            {title}
          </StyledLink>
          <Badge>
            {' '}
            {article?.meta?.published && (
              <Chip icon={<VerifiedIcon />} label="Published" color="success" sx={{ ml: '0.2rem', fontSize: '12px' }} size="small" />
            )}
            {article?.meta?.published === undefined &&
              article?.meta?.isReviewed === undefined && (
                <Chip icon={<DraftsIcon />} label="In draft" sx={{ ml: '0.2rem', fontSize: '12px' }} />
              )}
            {article?.meta?.published === false &&
              article?.meta?.isReviewed === false && (
                <Chip icon={<AccessTimeFilledIcon />} label="Unpublished" color="warning" sx={{ ml: '0.2rem', fontSize: '12px' }} size="small" />
              )}
            {article?.meta?.published === false &&
              article?.meta?.isReviewed === true && (
                <ErrorIcon
                  sx={{ fontSize: 16, color: '#C71D1A', ml: '0.1rem' }}
                />
              )}
          </Badge>
          <Badge>
            {' '}
            {article?.meta?.access === "public" && (
              <Chip icon={<PublicIcon />} label="Public" color="primary" sx={{ ml: '0.2rem', fontSize: '12px' }} size="small" />
            )}
            {article?.meta?.access === "private" && (
              <Chip icon={<SecurityIcon />} label="Private" color="error" sx={{ ml: '0.2rem', fontSize: '12px' }} size="small" />
            )}
          </Badge>
        </Header>
        <Body>
          {
            <div>
              {sanitizeHtml(
                 urlify(article?.meta?.firstLine?.replace(/(?:\\n)/g, ' ')),
                {
                  allowedTags: [],
                  allowedAttributes: {}
                }
              )}
            </div>
          }
          <Actions>
            <div>
              <Tooltip title="Delete Article">
                <IconButton
                  sx={{ color: '#C71D1A' }}
                  onClick={() => deleteArticle(article)}
                >
                  <DeleteOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
            <span
              style={{
                marginLeft: '1rem',
                fontSize: '0.9rem',
                marginRight: '0.5rem'
              }}
            >
              Was this helpful?
            </span>
            <div>
              {likeStatus === 1 ? (
                <Tooltip title="Undo">
                  <IconButton
                    sx={{ color: '#109648' }}
                    onClick={() => setLikeStatus(0)}
                  >
                    <ThumbUpIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Like">
                  <IconButton
                    onClick={() => {
                      addReaction('like');
                      setLikeStatus(1);
                    }}
                  >
                    <ThumbUpOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {likeStatus === -1 ? (
                <Tooltip title="Undo">
                  <IconButton
                    sx={{ color: '#ED474A' }}
                    onClick={() => setLikeStatus(0)}
                  >
                    <ThumbDownIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Dislike">
                  <IconButton
                    onClick={() => {
                      addReaction('dislike');
                      setLikeStatus(-1);
                    }}
                  >
                    <ThumbDownOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div style={{ marginLeft: '1rem' }}>
              <FeedbackMessage
                style={{
                  color: likeStatus === 1 ? '#109648' : 'inherit',
                  fontSize: '0.9rem'
                }}
              >
                {likeStatus === 1 ? (
                  'Glad it helped!'
                ) : likeStatus === -1 ? (
                  <>
                    Sorry... Talk to
                    <Link
                      to={ROUTES.CUSTOMER_SERVICE}
                      style={{ marginLeft: '0.25rem' }}
                    >
                      customer service
                    </Link>
                  </>
                ) : (
                  ''
                )}
              </FeedbackMessage>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <ExportLogsLink onClick={exportLogs}>Changelog</ExportLogsLink>
            </div>
          </Actions>
        </Body>
      </div>
    </Wrapper>
  );
};

// Exports:
export default FAQResult;