// Packages:
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Position } from 'react-flow-renderer';
import hasNodeMoved from '../../../../lib/hasNodeMoved';

// Redux:
import { setSelectedNode } from '../../../../redux/actions/studioActions';

// Styles:
import {
  GoogleSheetsNodeWrapper,
  GoogleSheetsNodeLabel,
  Body,
  Description,
  Field,
  StyledHandle
} from './styles';

// Functions:
const GoogleSheetsNode = ({ data }) => {
  // Constants:
  const dispatch = useDispatch();
  const { id, sheet, googleSheetsFunctionality, label } = data;

  // Ref:
  const wrapperRef = useRef(null);

  // State:
  const studio = useSelector((state) => state.studio);
  const [clickOrigin, setClickOrigin] = useState({ x: 0, y: 0 });

  // Functions:
  const handleMouseDown = useCallback(
    (e) => {
      if (e.button === 2) e.stopPropagation();
      if (e.button === 0) {
        setClickOrigin({ x: e.clientX, y: e.clientY });
        dispatch(setSelectedNode(data));
      }
    },
    [data, dispatch]
  );

  const handleMouseUp = useCallback(
    (e) => {
      if (e.button === 0) {
        if (hasNodeMoved(clickOrigin, e)) return;
      }
    },
    [clickOrigin]
  );

  // Effects:
  useEffect(() => {
    wrapperRef.current.addEventListener('mousedown', handleMouseDown);
    wrapperRef.current.addEventListener('mouseup', handleMouseUp);
  }, [handleMouseDown, handleMouseUp, clickOrigin, studio.selectedNode]);

  // Return:
  return (
    <GoogleSheetsNodeWrapper
      ref={wrapperRef}
      style={{
        backgroundColor: studio.selectedNode?.id === id ? '#188038' : '#FFFFFF',
        borderColor: '#188038'
      }}
    >
      <StyledHandle
        type="target"
        position={Position.Top}
        style={{ backgroundColor: 'blue', top: '-15px' }}
      />
      <StyledHandle
        id="google-sheets-node-output-true"
        type="source"
        position={Position.Bottom}
        style={{
          backgroundColor: 'green',
          bottom: '-15px',
          left: '25%'
        }}
      />
      <StyledHandle
        id="google-sheets-node-output-false"
        type="source"
        position={Position.Bottom}
        style={{
          backgroundColor: 'red',
          bottom: '-15px',
          left: '75%'
        }}
      />
      <GoogleSheetsNodeLabel
        htmlFor="text"
        style={{
          color: studio.selectedNode?.id === id ? '#FFFFFF' : '#333533'
        }}
      >
        {label}
      </GoogleSheetsNodeLabel>
      <Body
        style={{
          color: studio.selectedNode?.id === id ? '#FFFFFF' : '#333533'
        }}
      >
        <Description>
          <Field isActive={studio.selectedNode?.id === id}>
            {!googleSheetsFunctionality ? (
              <span style={{ fontStyle: 'italic', fontWeight: 400 }}>
                Functionality
              </span>
            ) : (
              googleSheetsFunctionality
            )}
          </Field>
        </Description>
        <Description>
          <Field isActive={studio.selectedNode?.id === id}>
            {!sheet ? (
              <span style={{ fontStyle: 'italic', fontWeight: 400 }}>
                Enter a sheet name...
              </span>
            ) : (
              sheet
            )}
          </Field>
        </Description>
      </Body>
    </GoogleSheetsNodeWrapper>
  );
};

// Exports:
export default GoogleSheetsNode;
