export const HELP_PHRASES = [
  "i need help",
  "need help",
  "help needed",
  "talk to agent",
  "talk to human agent",
  "connect to human agent",
  "connect to an agent",
  "i want speak to human agent",
  "i want speak to an agent",
  "i need to talk to someone",
  "i need to talk to real human",
  "i want to speak with human agent"
];

export const ASK_AGAIN_PHRASE =
  "I'm sorry, I don't have that information. If you need help with something specific, please ask me another question or type \"need help\" into the chat to connect with our support team.";

export const NEED_HELP_INTENT = "Connect_Agent";  