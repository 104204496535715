// Packages:
import React from 'react'
import styled from 'styled-components'


// Imports:
import CHATBOT from '../../../assets/chatbot'


// Styles:
const Avatar = styled.span`
  display: inline-block;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #F4F4F9;
  border-radius: 50%;
  user-select: none;
  
  @media (max-width: 520px) {
    width: 30px;
    height: 30px;
  }

  @media (max-width: 360px) {
    width: 25px;
    height: 25px;
  }

  &:hover {
    transform: scale(1.05);
  }
`

const Icon = styled.img`
  width: 24px;
  padding-top: 7.5px;

  @media (max-width: 520px) {
    width: 19px;
    padding-top: 4.5px;
  }
`


// Functions:
const BotAvatar = () => (
  <Avatar>
    <a href='http://readyly.com/' target='_blank'>
      <Icon src={ CHATBOT } />
    </a>
  </Avatar>
)


// Exports:
export default BotAvatar
