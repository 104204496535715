// Packages:
import React from 'react'
import {
    OutlinedInput,
    InputAdornment,
    FormControl,
    styled,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    InputLabel
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { CONVERSATION_TYPE, SENTIMENT_TYPE } from '../../../constants/chat';


// Styles:
const StyledFormControl = styled(FormControl)`
  font-size: 14px;
  background: #FFFFFF;
  & .MuiOutlinedInput-root {
    font-size: 14px;
  }
`;


// Functions:
export const ColumnFilter = ({ column }) => {
    // Constants:
    const { filterValue, setFilter, Header } = column
    // Return:
    return (
        <span>
            <ConditionalRenderingFilter Header={Header} filterValue={filterValue} setFilter={setFilter} />
        </span>
    )
}


function ConditionalRenderingFilter({ Header, filterValue, setFilter }) {
    if(Header === "Sentiment"){
        return (
            <>
                <FormControl variant="outlined" >
                    <Select
                        multiple
                        id="sentiments-outlined"
                        value={filterValue || []}
                        onChange={(e) => setFilter(e.target.value?.length == 0 ? "" : e.target.value)}
                        sx={{ minWidth: 100, height: 37, backgroundColor: "white", maxWidth: 120 }}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {Object.entries(SENTIMENT_TYPE).map(([key, value]) => (
                            <MenuItem key={key} value={value}>
                                <Checkbox checked={!!filterValue?.includes(value)} />
                                <ListItemText primary={value} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </>
        )
    }
    else if(Header === "Outcome"){
        return (
            <>
                <FormControl variant="outlined" >
                    <Select
                        multiple
                        id="outcome-outlined"
                        value={filterValue || []}
                        onChange={(e) => setFilter(e.target.value?.length == 0 ? "" : e.target.value)}
                        sx={{ minWidth: 100, height: 37, backgroundColor: "white", maxWidth: 120 }}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {Object.entries(CONVERSATION_TYPE).map(([key, value]) => (
                            <MenuItem key={key} value={value}>
                                <Checkbox checked={!!filterValue?.includes(value)} />
                                <ListItemText primary={value} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </>
        )
    }
    else{
        return (
            <>
                <StyledFormControl variant="outlined">
                    <OutlinedInput
                        type="text"
                        value={filterValue || ''}
                        onChange={(e) => setFilter(e.target.value)}
                        placeholder="Filter"
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchOutlinedIcon sx={{ fontSize: '20px' }} />
                            </InputAdornment>
                        }
                        size="small"
                    />
                </StyledFormControl>
            </>
        )
    }
}