// Packages:
import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';
import useInput from '../../../../lib/hooks/use-input';
import { TextField, styled, Button } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import FormInputs from './FormInputs';

// Styles:
import { FieldName } from './styles';

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }

  & .MuiFormLabel-root {
    color: ${props => props.messageErrorText === "Valid HTML" ? "green" : "default"};
  }

  & .MuiFormHelperText-root {
    color: ${props => props.messageErrorText === "Valid HTML" ? "green" : "default"};
  }

  & fieldset {
    border: ${props => props.messageErrorText === "Valid HTML" ? "1px solid green" : "default"};
  }
`;

// Functions:
const MessageConfiguration = ({ node, updateNode, deleteNode }) => {
  // Constants:
  const DEFAULT_OUTPUT = () => ({
    id: uuid(),
    field: '',
    type: 'STRING',
    message: ''
  });

  // State:
  const { value: message, setValue: setMessage, bind: bindMessage } = useInput(node.message ?? '');
  const { value: formName, setValue: setFormName, bind: bindFormName } = useInput(node.formName ?? '');
  const [expectResponse, setExpectResponse] = useState(node.expectResponse);
  const { value: responsesExpected, setValue: setResponsesExpected } = useInput(node.responsesExpected);
  const [outputs, setOutputs] = useState(node.outputs);
  const [messageErrorText, setMessageErrorText] = useState("")

  // Effects:
  useEffect(() => {
    setMessage(node.message);
    setFormName(node.formName);
    setExpectResponse(node.expectResponse);
    setResponsesExpected(node.responsesExpected);
    setOutputs(node.outputs);
  }, [node.message, node.formName, node.expectResponse, node.responsesExpected, node.outputs]);

  useEffect(() => {
    updateNode(node.id, {
      message,
      formName,
      expectResponse,
      responsesExpected,
      outputs
    });
  }, [message, formName, expectResponse, responsesExpected, outputs]);

  const updateOutput = (value) => {
    setOutputs(value);
  };

  const handleMessageValidate = () => {
    const openingTags = message.match(/<[a-z]+?>/g)
    const closingTags = message.match(/<[/][a-z]+?>/g)
    if(openingTags === null && closingTags === null)
      setMessageErrorText("")
    else {
      if( (openingTags!== null || closingTags!== null) && openingTags?.length!==closingTags?.length)
        setMessageErrorText("Invalid HTML")
      else
        setMessageErrorText("Valid HTML")
    }
  }

  

  // Return:
  return (
    <div>
      <FieldName>Message</FieldName>
      <StyledTextField
        id="message"
        variant="outlined"
        error = {messageErrorText.length === 0 || messageErrorText === "Valid HTML" ? false : true }
        label = {messageErrorText.length === 0 ? null : messageErrorText === "Valid HTML" ? "Success" : "Error" }
        helperText={messageErrorText}
        placeholder="Enter a message / HTML to be displayed to the user"
        {...bindMessage}
        multiline
        rows={5}
        size="small"
        fullWidth
        messageErrorText={messageErrorText}
      />
      
      <Button
        variant="contained"
        size="small"
        startIcon={<TaskAltOutlinedIcon />}
        sx={{ textTransform: 'none', mb: '1rem' }}
        onClick={() => handleMessageValidate()}
      >
        Validate HTML
      </Button>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="checkbox"
          style={{ marginRight: '0.35rem' }}
          checked={expectResponse}
          onChange={(e) => {
            if (!e.target.checked && expectResponse) {
              setOutputs([]);
              setResponsesExpected(0);
            }
            setExpectResponse(e.target.checked);
          }}
        />
        <div style={{ fontSize: '0.8rem' }}>Responses expected:</div>
        <input
          type="number"
          min="0"
          disabled={!expectResponse}
          style={{
            width: '3rem',
            marginLeft: '0.25rem'
          }}
          value={responsesExpected}
          onChange={(event) => {
            event.preventDefault();
            event.stopPropagation();
            const newOutputsLength = parseInt(event.currentTarget.value);
            setResponsesExpected(newOutputsLength);
            const currentOutputsLength = outputs.length;
            // console.log(currentOutputsLength, newOutputsLength)
            if (newOutputsLength === currentOutputsLength + 1)
              setOutputs([...outputs, DEFAULT_OUTPUT()]);
            if (newOutputsLength === currentOutputsLength - 1)
              setOutputs([...outputs].slice(0, -1));
          }}
        />
      </div>
      {expectResponse && (
        <>
          <div style={{ width: '100%', height: '1rem' }} />
          <FieldName>Form Name</FieldName>
          <StyledTextField
            id="message"
            variant="outlined"
            placeholder="Enter a form name"
            {...bindFormName}
            multiline
            size="small"
            fullWidth
          />
          <FieldName>Variables To Save</FieldName>
        </>
      )}
      {Object.values(outputs).map((output, i) => (
        <FormInputs
          key={output.id}
          outputs={outputs}
          output={output}
          i={i}
          updateOutput={updateOutput}
        />
      ))}
      <Button
        variant="contained"
        color="error"
        size="small"
        startIcon={<DeleteOutlineOutlinedIcon />}
        sx={{ textTransform: 'none', mt: '1rem' }}
        onClick={() => deleteNode(node.id)}
      >
        Delete Node
      </Button>
    </div>
  );
};

// Exports:
export default MessageConfiguration;