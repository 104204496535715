import { FormControl, MenuItem } from '@mui/material';
import styled from 'styled-components'

export const StyledFormControl = styled(FormControl)`
  margin-top: 1rem;
  text-align: left;
  & .MuiFormLabel-root {
    font-size: 14px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 14px;
  }
  & .MuiFormGroup-root {
    margin-left: 1rem;
    font-size: 14px;
    & .MuiTypography-root {
      font-size: 14px;
    }
  }
  &. MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: red;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;