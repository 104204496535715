import { Position } from 'react-flow-renderer';

export const config = {
  styles: {
    wrapper: {
      default: {
        backgroundColor: "#FFFFFF",
        borderColor: '#235789'
      },
      selected: {
        backgroundColor: '#235789'
      },
    },
    label: {
      default: {
        color: "#333533",
      },
      selected: {
        color: "#FFFFFF",
      },
    },
    body: {
      default: {
        color: "#333533",
      },
      selected: {
        color: "#FFFFFF",
      },
    },
  },
  handleStyles: [
    {
      properties: {
        type:"target",
        position:Position.Top
      },
      style: {
        backgroundColor: "blue",
        top: '-15px'
      },
    },
    {
      properties: {
        id:"api-node-output-true",
        type:"source",
        position:Position.Bottom,
      },
      style: {
          backgroundColor: 'green',
          bottom: '-15px',
          left: '25%'
      },
    },
    {
      properties: {
        id:"api-node-output-false",
        type:"source",
        position:Position.Bottom,
      },
      style: {
        backgroundColor: '#ED1C24',
        bottom: '-15px',
        left: '75%'
      },
    },

  ],
};