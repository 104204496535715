// Packages:
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
import { CSVLink } from 'react-csv';
import { Chip, CircularProgress, Button } from '@mui/material';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import CallMissedOutlinedIcon from '@mui/icons-material/CallMissedOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';


// Components:
import Pagination from '../../Records/Pagination';


// Imports:
import { ColumnFilter } from './ColumnFilter';
import { Wrapper, RecordsTable, Header, Body, Title, Filters } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getContentActions } from '../../../redux/actions/contentActions';
import { useDeepCompareEffect } from 'react-use';
import { clearChatErrorMsg, getChatActions } from '../../../redux/actions/chatActions';
import { TableSortLabel, Snackbar, Grow } from '@mui/material';
import { EVENTS_TOOLTIPS } from '../../../constants/tooltips';
import { REQUEST_EVENTS_TYPE } from '../../../constants/requestType';
import eventsConfig from '../../../config/infov2/events_table.json'
import { filterEventsData } from '../../../utils/tableFilters';
import { sortingDateFunction } from '../../../utils/tableSort';

const varPageSize = 10;
const initialState = {
  sortBy: [
    {
      id: 'Created',
      desc: true
    }
  ]
};

// Functions:
const TrackingTable = (props) => {
  // State:
  const dispatch = useDispatch();
  const contentActions = getContentActions(dispatch);
  const chatActions = getChatActions(dispatch);
  const reactions = useSelector((state) => state.content.reactionRecordsV2) || [];
  const lastKey = useSelector((state) => state.content?.reactionRecordsLastKey);
  const organisation_info = useSelector((state) => state.content.org_info);
  const orgid = organisation_info?.org_data?._id;
  const [records, setRecords] = useState(reactions);
  const [cols, setCols] = useState([]);
  const [columns, setColumns] = useState([]);
  const [exportData, setExportData] = useState(records);
  const data = useMemo(() => records, [records]);
  const isLoading = useSelector((state) => state.content.loading);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pageCount, setPageCount] = useState(Math.ceil(reactions.length / varPageSize) || 1);
  const errorMsg = useSelector(state => state.chat.error);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState,
      autoResetPage: false,
      autoResetFilters: false,
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      manualGlobalFilter: true,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
      pageCount: pageCount
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    prepareRow
  } = tableInstance;

  const { pageIndex, pageSize, sortBy, filters } = state;

  const onPageChange = useCallback((newVal, records) => {
    setExportData(records);
    let data = records.slice(newVal * pageSize, (newVal + 1) * pageSize);
    const pageCount = Math.ceil(records.length / pageSize);
    setPageCount(pageCount || 1);
    if (newVal > pageCount - 2 && lastKey && !isLoading) {
      contentActions.fetchReactionsV2(orgid, props.startDate, props.endDate, REQUEST_EVENTS_TYPE);
    }
    return data;
  }, [pageIndex, lastKey, isLoading])

  // Functions:

  useEffect(() => {
    if (reactions?.length && !lastKey) return;
    contentActions.fetchReactionsV2(orgid, props.startDate, props.endDate, REQUEST_EVENTS_TYPE);
  }, [orgid]);

  useDeepCompareEffect(() => {
    if (reactions) {
      setRecords(reactions);
      setCols(['event_type', 'created_date', 'recordId']);
    }
  }, [reactions]);

  useDeepCompareEffect(() => {
    const tempArray = cols.map((col) => {
      const words = col.split('_');
      const capitalised = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );
      const { headerMap } = eventsConfig;
      return {
        Header:
          col in headerMap
            ? headerMap[col]
            : capitalised.join(' '),
        accessor: (row) =>
          col === 'created_date'
            ? row.created_date.substring(0, 10) +
            ' ' +
            row.created_date.substring(11, 16)
            : row[col],
        Filter: ColumnFilter,
        disableSortBy: (col === "created_date") ? false : true,
      };
    });
    setColumns(tempArray);
  }, [cols, props.hideInfo]);

  useEffect(() => {
    if (reactions) {
      let filteredData = reactions;
      if (props.type.length > 0) {
        let typeFilteredData = filteredData.filter((item) =>
          props.type.includes(item.type)
        );
        setRecords(typeFilteredData);
      } else {
        setRecords(filteredData);
      }
    }
  }, [props.type]);

  useEffect(() => {
    if (reactions?.length) {
      let filterReactions = filterEventsData(reactions, filters);
      let sortedReactions = sortingDateFunction(filterReactions, sortBy) || [];
      let reaction = onPageChange(pageIndex, sortedReactions) || [];
      setRecords(reaction);
    }
  }, [sortBy, filters, reactions, pageIndex])
  
  useEffect(() => {
    gotoPage(0);
  }, [filters])

  const handleStats = (row) => {
    row?.original?.recordId && chatActions.fetchChatRecordByRecordId(orgid, row?.original?.recordId);
    setSelectedRow(row.id === selectedRow ? null : row.id);
    props.handleStat(row.original);
  }

  // Return:
  return (
    <Wrapper>
      <RecordsTable {...getTableProps()}>
        {isLoading ? (
          <thead style={{ marginTop: '15rem' }}>
            <tr>
              <th>
                <CircularProgress />
              </th>
            </tr>
          </thead>
        ) : (
          <>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={!!errorMsg}
              autoHideDuration={1500}
              message={errorMsg}
              TransitionComponent={Grow}
              onClose={() => {
                dispatch(clearChatErrorMsg())
              }}
              style={{
                width: '100%',
              }}
              ContentProps={{
                sx: {
                  backgroundColor: "#e53935"
                }
              }}
            />
            <Header>
              {headerGroups.map((headerGroup) => (
                <Title {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps({ title: undefined }))}>
                      {column.disableSortBy ? (
                        column.render('Header')
                      ) : (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                          {...column.getSortByToggleProps()} title={EVENTS_TOOLTIPS[column.Header]}
                        >
                          {column.render('Header')}
                        </TableSortLabel>
                      )}
                    </th>
                  ))}
                </Title>
              ))}
              {headerGroups.map((headerGroup) => (
                <Filters {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div>
                        {column.canFilter
                          ? column.Header !== 'Query'
                            ? column.Header === 'User' && props.hideInfo
                              ? null
                              : column.render('Filter')
                            : null
                          : null}
                      </div>
                    </th>
                  ))}
                </Filters>
              ))}
            </Header>
            {page && page.length > 0 ? (<>
              <Body {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} onClick={() => handleStats(row)} style={{ backgroundColor: selectedRow === row.id ? 'lightblue' : '' }}>
                      {row.cells.map((cell) => {
                        if (cell.column.Header === 'Event') {
                          return (
                            <td {...cell.getCellProps()}>
                              {row.original.event_type === 'like' ? (
                                <Chip
                                  label={row.original.event_type}
                                  icon={<ThumbUpOutlinedIcon />}
                                  color="success"
                                  sx={{ color: '#3F9820', fontWeight: '600' }}
                                  size="small"
                                  variant="outlined"
                                />
                              ) : row.original.event_type === 'dislike' ? (
                                <Chip
                                  label={row.original.event_type}
                                  icon={<ThumbDownOutlinedIcon />}
                                  color="error"
                                  sx={{ color: '#C71D1A', fontWeight: '600' }}
                                  size="small"
                                  variant="outlined"
                                />
                              ) : (
                                <Chip
                                  label={row.original.event_type}
                                  icon={<CallMissedOutlinedIcon />}
                                  color="warning"
                                  sx={{ color: '#D76A03', fontWeight: '600' }}
                                  size="small"
                                  variant="outlined"
                                />
                              )}
                            </td>
                          );
                        } else if (cell.column.Header === 'Created') {
                          return (
                            <td {...cell.getCellProps()}>
                              {row.original.created_date.substring(0, 10) +
                                ' ' +
                                row.original.created_date.substring(11, 16)}
                            </td>
                          );
                        } else {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </Body></>) : <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}><h2 style={{ textAlign: "center" }}>Sorry! No Content in the Given time range</h2></div>}
          </>
        )}
      </RecordsTable>

      <Pagination
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
        isLastPage={!!(!(pageIndex < pageCount - 1) && !lastKey)}
      />

      {exportData !== null && (
        <CSVLink
          filename={'tracking.csv'}
          data={exportData.map((row) => {
            let data = {
              user_email: row.user_email,
              event: row.event_type,
              created_date: row.created_date,
              session_id: row.recordId
            };
            return data;
          })}
        >
          <Button
            variant="contained"
            size="small"
            startIcon={<FileUploadOutlinedIcon />}
            sx={{ textTransform: 'none' }}
          >
            Export Data
          </Button>
        </CSVLink>
      )}
    </Wrapper>
  );
};

// Exports:
export default TrackingTable;
