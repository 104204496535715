export const conditionType = ["Simple"];
export const conditionDescription = {
  Simple: "Evaluates a single conditional statement",
  //   AND: " All true statements yield true.",
  //   OR: " Any true statements yield true.",
};
export const operators = ["?",">", ">=", "<", "<=", "="];
export const variables = ["Numeric", "String","Boolean"];
export const boolean = ["True", "False"];
export const isNegativeValues=["","NOT"];

export const operatorMapping={
  '?':'isPresent',
  '>':'greater than',
  '>=':'greater than equal to',
  '<':'less than',
  '<=':'less than equal to',
  '=':'equals',
  '~':'matches'
}