export const DEFAULT_AI_PROMPT = {
  key: "aiPrompt",
  value: {
    fieldName: null,
    value: null,
  },
};
export const AI_ACTIONS = {
  GENERATE_AI_RESPONSE: "GENERATE AI RESPONSE",
  GENERATE_AI_RESPONSE_FROM_KB:"GENERATE AI RESPONSE FROM KB"
};

export const AI_RESPONSE_FIELDS = ['ai_response']
