// Packages:
import React, { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WebSocketConnectionContext } from '../../../App';
import { getContentActions } from '../../../redux/actions/contentActions';

// Styles:
import { Wrapper, SearchInput } from './styles';

// Function:
const ResourceSearch = () => {
  const dispatch = useDispatch();
  const contentActions = getContentActions(dispatch);
  const orgid = useSelector((state) => state.content.org_info?.org_data?._id);
  const { openConnection } = useContext(WebSocketConnectionContext);
  const [reply, setReply] = useState('');

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setReply('');
      try {
        await contentActions.interact(orgid, reply, null, false);
        await contentActions.similarity(orgid, reply, null);
      } catch (error) {
        console.error(error);
      }
    },
    [orgid, reply]
  );

  // Return:
  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <SearchInput
          autoFocus={false}
          value={reply}
          placeholder="Search for resources..."
          onChange={(e) => {
            setReply(e.target.value);
          }}
        />
      </form>
    </Wrapper>
  );
};

// Exports:
export default ResourceSearch;
