import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import { Button } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { toast } from 'react-toastify';

import { createDraft, createEmptyDraft } from '../../../lib/articles/draftsOperations';

const AddFaq = () => {
  const navigate = useNavigate();

  const orgInfo = useSelector((state) => state.content.org_info);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const role = useSelector((state) => state.content.agent_type);

  const orgId = orgInfo?.org_data?._id;
  const userEmail = userDetails?.email;

  const createArticle = async () => {
    const draft = createEmptyDraft(orgId, userDetails?.email);

    try {
      const response = await createDraft(draft, { user: userEmail, role: role });

      const query = `articleId=${response.articleId}&status=${response.meta.status}`;

      navigate(`${ROUTES.KNOWLEDGE_ARTICLE_V2}?${query}`, {
        state: { initialArticleData: response, isEdit: true }
      });
    } catch (error) {
      console.error(error);
      toast.error("Failed to create a new article");
    }
  };

  return (
    <Button
      variant="contained"
      size="medium"
      startIcon={<AddOutlinedIcon />}
      sx={{ textTransform: 'none' }}
      onClick={createArticle}
    >
      Add Knowledge
    </Button>
  );
};

export default AddFaq;
