import styled from 'styled-components';

export const Radios = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
`;
