// Packages:
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// Exports:
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 5px;
  margin-bottom: 10px;
  background: #f4f4f9;
  padding: 12px;
  padding-left: 20px;
  padding-top: 20px;
  border-radius: 0.25rem;
  align-items: baseline;
`;

export const StyledLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  color: #2f80ed;

  @media (max-width: 520px) {
    font-size: 16px;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #2f80ed;
  cursor: pointer;
  user-select: none;

  @media (max-width: 520px) {
    font-size: 16px;
  }
`;

export const Body = styled.div`
  margin-top: 10px;
  line-height: 1.2rem;
  overflow: hidden;
  user-select: none;
  transition: all 0.5s ease;
  font-size: 14px;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 520px) {
    font-size: 16px;
  }
`;

export const Actions = styled.div`
  width: 100%;
  margin-top: 10px;
  font-size: 1.25rem;
  color: #333333;
  display: flex;
  align-items: center;
`;

export const FeedbackMessage = styled.span`
  font-weight: 600;
`;

export const Badge = styled.span`
  color: grey;
  padding-left: 5px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

export const ExportLogsLink = styled.span`
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: #2f80ed;
  }
`;
