// Packages:
import styled from 'styled-components'


// Exports:
export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background: #ffffff;
  height: 100%;
  padding: 0.5rem;
  border-radius: 4px;
  max-height: 730px;
  color: #333333;
`

export const RecordsTable = styled.table`
  width: 100%;
  height: 600px;
  text-align: center;
  font-size: 14px;
  transform: translateY(0px);
  margin: 0 auto;
  border-collapse: collapse;

  td {
    padding: 7px 6px;
  }

  tr {
    min-height: 48px;
    overflow: hidden;
  }
`

export const Header = styled.thead`
  background: #ffffff;
`
export const Body = styled.tbody`
  tr:nth-child(even) {
    background: #F4F4F9;
  }

  tr:hover {
    background: #e8f0fe;
    cursor: pointer;
    transition: all 0.25s ease;
  }
`

export const Title = styled.tr`
  th {
    height: 50px;
    padding: 12px 6px;
    cursor: pointer;
  }
`

export const Filters = styled.tr`
  th {
    height: 50px;
    padding: 7px 5px;
    background: #F4F4F9;
  }
`

export const FilterInput = styled.input`
  height: 28px;
  width: fit-content; 
  padding-left: 5px;
  border: 1px solid rgba(0,0,0,.1); 
  border-bottom: 2px solid #a9a9a9;
  background: #fff;

  &:hover {
    border-bottom: 2px solid #000000;
    transition: all .25s ease;
  }

  &:focus {
    outline: none;
    border-bottom: 2px solid #000000;
    transition: all .25s ease;
  }
`