import { articleOperations } from '../../api';
import {
  ARTICLE_IS_DRAFT,
  ARCHIVE_ARTICLE_OPERATION,
} from '../../constants/articles';

export function createDraftFromArticle(existingArticle, darftCreator) {
  const now = new Date().toISOString();

  const newDraft =  {
    ...existingArticle,
    meta: {
      ...existingArticle['meta'],
      status: ARTICLE_IS_DRAFT,
      isSubmitted: false,
      createdBy: darftCreator,
      s3Link: '',
      createdFrom: existingArticle.meta.version,
      createdDate: now,
      lastModifiedDate: now
    }
  };

  const fieldsToRemove = [
    'publishedBy',
    'archivedBy',
    'archivedReason',
    'validFrom',
    'validTo'
  ];

  for (const field of fieldsToRemove) {
    if (newDraft.meta.hasOwnProperty(field)) {
      delete newDraft.meta[field]
    }
  }

  return newDraft;
}

export async function archiveArticle(article, user) {
  await articleOperations(ARCHIVE_ARTICLE_OPERATION, article, user);
}