// Packages:
import React from 'react';
import { useState } from 'react';
import { Title } from './styles';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import Articles from '../Articles';
import { deleteArticle } from '../../../lib/articles/commonOperations';

// Functions:
const FAQComponent = ({
  articles,
  handleOpenModal,
  handleModalProps
}) => {
  const userDetails = useSelector((state) => state.auth.userDetails);
  const agent_type = useSelector((state) => state.content.agent_type);
  const loadingArticles = useSelector((state) => state.content.loadingArticlesV2);

  const [isDeletingArticle, setIsDeletingArticle] = useState(false);

  const deleteFn = (article) => {
    handleOpenModal();
    handleModalProps({
      actionButton: 'Delete',
      title: 'Delete Article',
      message:article?.meta?.isArchived? 'This Article is Already Archived! Are you sure you want to Permanently delete this article?':'Are you sure you want to delete this article?',
      actionFn: async () => {
        setIsDeletingArticle(true);
        try {
          await deleteArticle(article, userDetails?.email, agent_type)
        } catch(error) {
          toast.error("Failed to delete article")
          console.error(error);
        }
        setIsDeletingArticle(false);
      }
    });
  };

  return (
    <>
      {(!isDeletingArticle && articles?.length > 0) && (
        <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", width:"90%"}}>
          <Articles
            articlesArray={articles}
            deleteArticle={deleteFn}
          />
        </div>)}
      {(isDeletingArticle || loadingArticles) &&
        <CircularProgress sx={{ mt: '5rem', mb: '5rem' }} />}
    </>
  )
};

export default FAQComponent;
