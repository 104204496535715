// Packages:
import styled from 'styled-components';

// Exports:
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;


export const Form = styled.div`
  font-size: 14px;
  margin-bottom: 1rem;
`;

export const FieldName = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
  font-weight: 600;
  `;