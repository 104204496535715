const nameMappings = {
    "FETCH ORDER DETAILS": "order_details",
    "EDIT ADDRESS": "edit_address",
    "CHANGE SHIPPING TYPE": "change_shipping_type",
    "CREATE RETURN SHIPMENT": "create_return_shipment",
    "PRINT SHIPPING LABEL": "print_shipping_label",
    "ADD MANUAL ORDER": "add_manual_order",
    "order_details": "FETCH ORDER DETAILS",
    "edit_address": "EDIT ADDRESS",
    "change_shipping_type": "CHANGE SHIPPING TYPE",
    "create_return_shipment": "CREATE RETURN SHIPMENT",
    "print_shipping_label": "PRINT SHIPPING LABEL",
    "add_manual_order": "ADD MANUAL ORDER"
};
export const changeName = (val) => nameMappings[val] ?? "";

export const DEFAULT_OUTPUT =  {
  key:"order_id",
  value:{
    fieldName:"order_id",
    value:null
  }
}

export const SHIPPING_RESPONSE_FIELDS = {
  "SHIPPING EASY": [
    "order_details.shipping_details",
    "order_details.shipping_address_details.shipping_address",
    "order_details.shipping_address_details",
    "order_details.is_tracking_details_present"
  ],
};

export const DEFAULT_OUTPUT_APIKEY={
      key: "apiKey",
      value: {
        fieldName: null,
        value: null,
      },
    }

export const DEFAULT_OUTPUT_APISECRET={
      key: "apiSecret",
      value: {
        fieldName: null,
        value: null,
      },
    }

export const DEFAULT_OUTPUT_EMAIL={
    key: "email",
    value: {
      fieldName: null,
      value: null,
    },
  }    

export const CARRIER = {
    SHIPPING_EASY: "SHIPPING EASY",
  };
 
export  const SHIPPINGFUNCTIONALITY = {
    FETCH_ORDER_DETAILS: "FETCH ORDER DETAILS",
    EDIT_ADDRESS: "EDIT ADDRESS",
    CHANGE_SHIPPING_TYPE: "CHANGE SHIPPING TYPE",
    CREATE_RETURN_SHIPMENT: "CREATE RETURN SHIPMENT",
    PRINT_SHIPPING_LABEL: "PRINT SHIPPING LABEL",
    ADD_MANUAL_ORDER: "ADD MANUAL ORDER",
  };  

export  const SHIPPINGTYPE = {
    FIRST_CLASS_MAIL: "FIRST CLASS MAIL",
    PRIORITY_MAIL: "PRIORITY MAIL",
    PRIORITY_MAIL_EXPRESS: "PRIORITY MAIL EXPRESS",
    MEDIA_MAIL: "MEDIA MAIL",
    PARCEL_SELECT: "PARCEL SELECT",
  };  