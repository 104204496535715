import { MenuItem, Select, styled, TextField,IconButton, } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FieldName, Variable, VariableField } from "./style";
import { MdCancel } from "react-icons/md";
import actionsData from "./config.json";
import DeleteIcon from '@mui/icons-material/Delete';
import { WORKFLOW_IS_DRAFT } from "../../../constants/studioV2";

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;
const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 1rem;
  height: 2.4rem;
  font-size: 14px;
`;
const ActionList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  backgroundColor: '#fff',
  color: '#000',
  border: '1px solid #ddd',
  marginBottom:'1rem',
  borderRadius:'10px'
}));

const ActionListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0rem 1rem',
  borderBottom: '1px solid #ddd',
  fontSize:'12px',
  '&:last-of-type': {
    borderBottom: 'none',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  '&:hover': {
    backgroundColor: '#f9f9f9',
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: '#000',
}));

const ActionSelector = ({ currentRemoteAutoFlow, setCurrentRemoteAutoFlow }) => {
  const [currentLevel, setCurrentLevel] = useState(actionsData); // Tracks the current set of options at any level
  const [selectedActions, setSelectedActions] = useState(currentRemoteAutoFlow.meta.tools || []); // Store array of action paths as strings
  const [currentActionPath, setCurrentActionPath] = useState([]); // Track the current action path being built
  const [isActionSelected, setIsActionSelected] = useState(false);
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState(currentRemoteAutoFlow.meta.tags || []);
  const [access, setAccess] = useState(currentRemoteAutoFlow.meta.access || "private");
  const [target, setTarget] = useState(currentRemoteAutoFlow.meta.target || "agent");
  const [editingIndex, setEditingIndex] = useState(null);
  const isEditable=currentRemoteAutoFlow?.meta?.status===WORKFLOW_IS_DRAFT

  useEffect(() => {
    setCurrentRemoteAutoFlow((prev) => ({
      ...prev,
      meta: {
        ...prev.meta,
        tools: selectedActions,
        tags,
        access,
        target,
      },
    }));
  }, [selectedActions, tags, access, target, setCurrentRemoteAutoFlow]);

  const handleSelect = (key) => {
    let newPath = [...currentActionPath];
    if (editingIndex !== null) {
      newPath[newPath.length - 1] = key;    
    } else {
      newPath = [...newPath, key];
    }

    if (typeof currentLevel[key] === "object" && Object.keys(currentLevel[key]).length > 0) {
      setCurrentActionPath(newPath);
      setCurrentLevel(currentLevel[key]);
    } else {
      const fullPath = newPath.join(".");
      if (editingIndex !== null) {
        const updatedActions = [...selectedActions];
        updatedActions[editingIndex] = fullPath;
        setSelectedActions(updatedActions);
        setEditingIndex(null);
      } else {
        setSelectedActions([...selectedActions, fullPath]);
      }
      setIsActionSelected(false);
      setCurrentActionPath([]);
      setCurrentLevel(actionsData);
    }
  };

  const showFirstDropdown = () => {
    setIsActionSelected(true);
  };

  const resetSelection = () => {
    setCurrentLevel(actionsData);
    setCurrentActionPath([]);
    setIsActionSelected(false);
    setEditingIndex(null);
  };

  const goBack = () => {
    const newPath = currentActionPath.slice(0, -1);
    setCurrentActionPath(newPath);

    if (newPath.length === 0) {
      setCurrentLevel(actionsData);
    } else {
      let prevLevel = actionsData;
      newPath.forEach((key) => {
        prevLevel = prevLevel[key];
      });
      setCurrentLevel(prevLevel);
    }
  };

  const editAction = (index) => {
    setEditingIndex(index);
    const actionToEdit = selectedActions[index];
    const actionParts = actionToEdit.split(".");
    setCurrentActionPath(actionParts);
    let newLevel = actionsData;
    for (let i = 0; i < actionParts.length - 1; i++) {
      newLevel = newLevel[actionParts[i]];
    }
    setCurrentLevel(newLevel);
    setIsActionSelected(true);
  };

  const deleteAction = (index) => {
    setSelectedActions((prevState) => prevState.filter((_, i) => i !== index));
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  const handleTagValueChange = (e) => {
    const { value } = e.target;
    setTagInput(value);
  };

  const tagOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = tagInput.trim();
    if (
      (key === "," || key === "Enter") &&
      trimmedInput.length &&
      !tags.includes(trimmedInput)
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setTagInput("");
    }
  };

  return (
    <div style={{ width: "100%", padding: "2%", overflow: "auto" }}>
      <FieldName>Target User</FieldName>
      <StyledSelect disabled={!isEditable} fullWidth value={target} onChange={(e) => setTarget(e.target.value)}>
        <MenuItem value="endUser">endUser</MenuItem>
        <MenuItem value="agent">agent</MenuItem>
        <MenuItem value="admin">admin</MenuItem>
        <MenuItem value="superadmin">superadmin</MenuItem>
        <MenuItem value="supervisor">supervisor</MenuItem>
      </StyledSelect>

      <FieldName>Access</FieldName>
      <StyledSelect disabled={!isEditable} fullWidth value={access} onChange={(e) => setAccess(e.target.value)}>
        <MenuItem value="public">public</MenuItem>
        <MenuItem value="private">private</MenuItem>
      </StyledSelect>

      <FieldName>Sample Triggers</FieldName>
      <VariableField>
        {tags.map((tag, index) => (
          <Variable key={tag}>
            {tag}
            <MdCancel style={{ marginLeft: "0.5rem", cursor: "pointer" }} onClick={() => deleteTag(index)} />
          </Variable>
        ))}
      </VariableField>

      <StyledTextField
        type="text"
        id="start-sample-trigger"
        variant="outlined"
        value={tagInput}
        placeholder="Enter a sample trigger"
        onKeyDown={tagOnKeyDown}
        onChange={handleTagValueChange}
        size="small"
        fullWidth
        disabled={!isEditable}
      />

      <FieldName>Actions</FieldName>
      <ActionList>
      {selectedActions.map((action, index) => (
        <ActionListItem key={index}>
          {action}
          <StyledIconButton disabled={!isEditable} onClick={() => deleteAction(index)} aria-label="delete">
            <DeleteIcon />
          </StyledIconButton>
        </ActionListItem>
      ))}
    </ActionList>

      {!isActionSelected && <button disabled={!isEditable} onClick={showFirstDropdown}>Select an Action</button>}

      {isActionSelected && (
        <div>
          <button disabled={!isEditable} onClick={resetSelection}>Reset</button>

          {currentActionPath.length > 0 && <button onClick={goBack}>Go Back</button>}

          <ul>
            {Object.keys(currentLevel).map((key) => (
              <li key={key} style={{padding:2}}>
                <button disabled={!isEditable} onClick={() => handleSelect(key)}>{key}</button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ActionSelector;