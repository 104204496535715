import React, { useMemo, useRef, useState } from 'react';
import useInput from '../../../lib/hooks/use-input';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import StyleIcon from '@mui/icons-material/Style';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TextField, styled, Button, Chip, Snackbar, Grow, Box, Tooltip, Switch } from '@mui/material';
import parse from 'html-react-parser';

// Imports:
import {
  Wrapper,
  Info,
  InfoTitle,
  InfoWrapper,
  TagsWrapper,
  TagsContainer,
  TagTitle,
  Tags
} from './styles';

import uuid from 'react-uuid';
import { clearChatErrorMsg, getChatActions } from '../../../redux/actions/chatActions';
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { isDateValid } from '../../../utils';

// Styles
const StyledTextField = styled(TextField)`
  font-size: 14px;
  width: 150px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

function convertUTCToLocalText(text, dateString) {
  if(!isDateValid(dateString)) return text;
  const regex = /(\d{2}:\d{2})(.*?)(?=\n|$)/g;

  function convertTime(match, time, rest) {
    const date = new Date(dateString).toISOString().slice(0, 10);
    const dateTimeUTC = `${date}T${time}:00Z`;

    const utcDate = new Date(dateTimeUTC);
    const localTime = utcDate.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
    return `${localTime}${rest}`;
  }

  return text.replace(regex, convertTime);
}

// Functions:
const Information = (chatDataProp) => {
  // State:
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const dispatch = useDispatch();
  const chatActions = getChatActions(dispatch);
  const organisation_info = useSelector((state) => state.content.org_info);
  const org_id = organisation_info?.org_data?._id;
  const { value: searchValue, setValue, bind: bindSearch } = useInput('');
  const [chatData, setChatData] = useState(chatDataProp.chat);
  const [tags, setTags] = useState(
    chatDataProp?.chat?.tags?.length > 0 ? chatDataProp.chat.tags : []
  );
  const [startDate, setStartDate] = useState(chatDataProp?.startDate)
  const [endDate, setEndDate] = useState(chatDataProp?.endDate)
  const [open, setOpen] = useState(false);
  const [notesValue, setNotesValue] = useState(chatDataProp?.chat?.notes ?? '');
  const errorMsg = useSelector(state => state.chat.error);
  const [timezoneSwitch, setTimezoneSwitch] = useState(true);
  const chatRecord = useMemo(() => {
    if (timezoneSwitch && chatData) {
      return convertUTCToLocalText(chatData?.record, chatData?.created_date);
    }
    return chatData?.record;
  }, [chatData, timezoneSwitch]);
  // Functions:

  useDeepCompareEffect(() => {
    setChatData(chatDataProp.chat);
    setTags(chatDataProp.chat.tags);
    setStartDate(chatDataProp.startDate)
    setEndDate(chatDataProp.endDate)
    setNotesValue(chatDataProp?.chat?.notes ?? '')
  }, [chatDataProp.chat]);

  const addTag = () => {
    setTags([...tags, { id: uuid(), value: searchValue }]);
    setValue('');
  };

  const deleteTag = (id) => {
    const newTags = tags.filter((tag) => tag.id !== id);
    setTags(newTags);
    setValue('');
  };

  const editTags = async () => {
    try {
      await chatActions.editChatRecord({
        org_id,
        record_id: chatData?.record_id,
        tags
      });
      await chatActions.editChatRecordV2({
        org_id,
        record_id: chatData?.record_id,
        tags,
        notes: notesValue,
        startDate,
        endDate
      });
    } catch (error) {
      console.error(error);
    }
  };

  // Return:
  return (
    <Wrapper>
      <Box>
        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Timezone: </span>
        <Tooltip title={timezoneSwitch ? timezone : "UTC"}>
          <Switch onChange={() => setTimezoneSwitch(!timezoneSwitch)} checked={timezoneSwitch} />
        </Tooltip>
      </Box>
      <Info>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!errorMsg}
          autoHideDuration={1500}
          message={errorMsg}
          TransitionComponent={Grow}
          onClose={() => {
            dispatch(clearChatErrorMsg())
          }}
          style={{
            width: '100%',
          }}
          ContentProps={{
            sx: {
              backgroundColor: "#e53935"
            }
          }}
        />
        <InfoTitle>Session Review
          {
            chatData?.record && <Button
              variant="contained"
              size="small"
              startIcon={<ContentCopyIcon />}
              onClick={() => {
                let clipboardData = '';
                if (chatData?.record_id && chatData?.user_email && chatData?.record) {
                  clipboardData = `
                    Session ID: ${chatData?.record_id}
                    User ID: ${chatData?.user_email}

                    ${chatRecord}
                  `.replace(/  +/g, '');
                }
                navigator.clipboard.writeText(clipboardData || '');
                setOpen(true);
              }}
              sx={{
                textTransform: 'none',
                marginLeft: '0.5rem'
              }}> Copy
            </Button>
          }
        </InfoTitle>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          autoHideDuration={400}
          message="Copied!"
          onClose={() => {
            setOpen(false);
          }}
        />

        <InfoWrapper>
          <div>
            {chatData?.record ? (
              <>
                <div style={{ marginBottom: '0.5rem' }}>
                  <div>
                    <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                      Session ID:
                    </span>
                    <span>
                      {chatDataProp.hideInfo
                        ? '••' +
                        chatData.record_id.slice(2, 5).trim() +
                        '•••' +
                        chatData.record_id.slice(8, 11).trim() +
                        '••'
                        : chatData.record_id}
                    </span>
                  </div>
                  <div>
                    <span style={{ fontWeight: 'bold', marginRight: '1rem' }}>
                      User ID:
                    </span>
                    <span>
                      {chatDataProp.hideInfo ? (
                        <>••{chatData?.user_email?.slice(2, 5).trim()}•••{chatData?.user_email?.slice(8, 11).trim()}••</>
                      )
                        : (<>{chatData?.user_email}</>)
                      }
                    </span>
                  </div>
                </div>
                <div>{parse(chatRecord?.split('\n').join('<br />'))}</div>
              </>
            ) : (
              'Select a row to display record information'
            )}
          </div>
        </InfoWrapper>
        {chatData && (
          <TagsWrapper style={{ paddingTop: '0' }}>
            <TagsContainer>
              <TagTitle>Tags:</TagTitle>
              <Tags>
                {typeof tags !== 'string' &&
                  tags?.map((tag) => (
                    <Chip key={tag.id} label={tag.value} variant="outlined" onDelete={() => deleteTag(tag.id)} />
                  ))}
              </Tags>

              <StyledTextField
                id="records-tags"
                variant="outlined"
                placeholder="Type & Press Enter"
                onKeyDown={(event) => (event.key === 'Enter' && !!event.target.value ? addTag() : null)}
                {...bindSearch}
                size="small"
              />

              <Button
                variant="contained"
                size="small"
                startIcon={<StyleIcon />}
                sx={{
                  textTransform: 'none',
                  marginLeft: '0.5rem'
                }}
                onClick={editTags}
              >
                Save
              </Button>
              <TextField
                placeholder='Notes'
                fullWidth
                multiline
                value={notesValue}
                onChange={e => setNotesValue(e.target.value)}
                margin='none'
              />
            </TagsContainer>
          </TagsWrapper>
        )}
      </Info>
    </Wrapper>
  );
};

// Exports:
export default Information;
