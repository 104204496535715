// Packages:
import React from 'react';
import { useSelector } from 'react-redux';
import { Resources, ResourceSearch } from '../';

// Styles:
import { Wrapper } from './styles';

// Functions:
const ResourceBox = ({ tab, setTab, setNumberOfResources, openCollapse, previewSource }) => {
  const agent_type_info = useSelector((state) => state.content.agent_type);
  const chat = useSelector((state) => state.chat);
  // state

  // Return:
  return (
    <Wrapper tab={tab} openCollapse={openCollapse}>
      <Resources
        tab={tab}
        setTab={setTab}
        previewSource={previewSource}
        setNumberOfResources={setNumberOfResources}
      />
      {agent_type_info !== 'endUser' && chat?.agentChatWorkflowStarted && (
        <ResourceSearch />
      )}
    </Wrapper>
  );
};

// Exports:
export default ResourceBox;
