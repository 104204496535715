// Packages:
import React, { useState } from 'react';
import { Tags, TagsContainer } from './styles';
import { TextField, styled, Button, Chip } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

// Styles:
const StyledTextField = styled(TextField)`
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const TagTypeInputComponent = ({
  fieldName,
  path,
  articleData,
  deleteTag,
  addTag,
  changeTag,
  tag,
  placeholder
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      {!show && path?.length === 0 ? (
        <Button
          size="small"
          variant="contained"
          startIcon={<AddOutlinedIcon />}
          sx={{
            textTransform: 'none',
            marginBottom: '5px',
            marginRight: '10px'
          }}
          onClick={() => setShow(true)}
        >
          Add {fieldName}
        </Button>
      ) : (
        <TagsContainer>
          <span
            style={{
              fontWeight: 'bold',
              marginRight: '0.75rem',
              color: '#333333'
            }}
          >
            {fieldName}:
          </span>
          <Tags>
            {path?.map((tag) => (
              <Chip key={tag.id} label={tag.desc} variant="outlined" onDelete={() => deleteTag(tag.id)} />
            ))}
          </Tags>
          <StyledTextField
            id="article-tags"
            variant="outlined"
            placeholder={placeholder}
            value={tag}
            onChange={(e) => changeTag(e.target.value)}
            onKeyDown={(event) => (event.key === 'Enter' ? addTag() : null)}
            size="small"
          />
          <Button
            size="small"
            color="error"
            variant="contained"
            startIcon={<RemoveOutlinedIcon />}
            sx={{ textTransform: 'none', marginLeft: '10px' }}
            onClick={() => setShow(false)}
          >
            Remove Field
          </Button>
        </TagsContainer>
      )}
    </>
  );
};

export default TagTypeInputComponent;
