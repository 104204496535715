import axios from 'axios';
import {
    API_ORG_CUSTOMIZATION_INFO,
    API_ORG_INFO,
} from "../constants/Endpoint";


/*************************** GETTING ORGANISATION CUSTOMIZATION IFNO ************************* */

export const getOrgCustomizationData = async (org) => {
    try {
        const result = await axios.get(`${API_ORG_CUSTOMIZATION_INFO}/${org}`, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        return result?.data?.org_data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/***************************GETTING ORGANISATION IFNO************************* */

export const getOrgData = async (org) => {
    try {
        const result = await axios.get(`${API_ORG_INFO}`, {
            params: { subdomain: `${org}` },
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
