// Packages:
import React, { useState } from 'react';
import {
  TextField,
  styled,
  FormControl,
  MenuItem,
  Select
} from '@mui/material';
import { MdCancel } from 'react-icons/md';

// Styles:
import { VariableField, Variable } from './styles';
import InputPair from './InputPair';
import uuid from 'react-uuid';

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const StyledFormControl = styled(FormControl)`
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

// Functions:
const FormInputs = ({ outputs, output, i, updateOutput }) => {
  // Constants:
  const OUTPUTTYPE = {
    STRING: 'STRING',
    BOOLEAN: 'BOOLEAN',
    MULTIPLE: 'MULTIPLE',
    NAME: 'NAME',
    ADDRESS: 'ADDRESS',
    EMAIL: 'EMAIL',
    PHONE_NUMBER: 'PHONE NUMBER',
    FORM: 'FORM'
  };

  // State:
  const [multipleOptions, setMultipleOptions] = useState('');
  const [options, setOptions] = useState([]);

  const optionOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = multipleOptions.trim();
    if (
      (key === ',' || key === 'Enter') &&
      trimmedInput.length &&
      !options?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setOptions((prevState) => [...prevState, trimmedInput]);
      setMultipleOptions('');
      const tempOutputs = [...outputs];
      tempOutputs[i]['values'] = [...options, trimmedInput];
      updateOutput(tempOutputs);
    }
  };

  const handleOptionValueChange = (e) => {
    const { value } = e.target;
    setMultipleOptions(value);
  };

  const deleteOption = (index) => {
    setOptions((prevState) => prevState.filter((option, i) => i !== index));
    const tempOutputs = [...outputs];
    tempOutputs[i]['values'] = options.filter((option, i) => i !== index);
    updateOutput(tempOutputs);
  };

  // Return:
  return (
    <div>
      <div key={i}>
        <div style={{ width: '100%', display: 'flex' }}>
          {output.type !== 'NAME' && output.type !== 'ADDRESS' && (
            <StyledTextField
              id="table-name"
              variant="outlined"
              placeholder="Name"
              value={output.field}
              onChange={(event) => {
                event.preventDefault();
                event.stopPropagation();
                const newOutputs = [...outputs];
                newOutputs[i] = {
                  ...output,
                  field: event.currentTarget.value
                };
                updateOutput(newOutputs);
              }}
              size="small"
              sx={{ mb: '0' }}
            />
          )}

          <StyledFormControl size="small" fullWidth>
            <Select
              id="table-type"
              value={output.type}
              onChange={(event) => {
                let newOutputs = [...outputs];
                if (event.target.value === 'NAME') {
                  newOutputs[i] = {
                    ...output,
                    type: event.target.value,
                    fields: [
                      {
                        id: uuid(),
                        field: '',
                        type: 'NAME',
                        message: 'Enter first Name'
                      },
                      {
                        id: uuid(),
                        field: '',
                        type: 'NAME',
                        message: 'Enter last Name'
                      }
                    ]
                  };
                  delete newOutputs[i]['message'];
                  delete newOutputs[i]['field'];
                } else if (event.target.value === 'ADDRESS') {
                  newOutputs[i] = {
                    ...output,
                    type: event.target.value,
                    fields: [
                      {
                        id: uuid(),
                        field: '',
                        type: 'ADDRESS',
                        message: 'Enter address line 1'
                      },
                      {
                        id: uuid(),
                        field: '',
                        type: 'ADDRESS',
                        message: 'Enter address line 2'
                      },
                      {
                        id: uuid(),
                        field: '',
                        type: 'ADDRESS',
                        message: 'Enter city'
                      },
                      {
                        id: uuid(),
                        field: '',
                        type: 'ADDRESS',
                        message: 'Enter state'
                      },
                      {
                        id: uuid(),
                        field: '',
                        type: 'ADDRESS',
                        message: 'Enter postal code'
                      }
                    ]
                  };
                  delete newOutputs[i]['message'];
                  delete newOutputs[i]['field'];
                } else {
                  newOutputs[i] = {
                    ...output,
                    type: event.target.value
                  };
                }
                updateOutput(newOutputs);
              }}
            >
              {Object.values(OUTPUTTYPE).map((type, index) => (
                <StyledMenuItem key={index} value={type}>
                  {type}
                </StyledMenuItem>
              ))}
            </Select>
          </StyledFormControl>

          {output.type !== 'NAME' && output.type !== 'ADDRESS' && output.type !== 'PHONE NUMBER' && output.type !== 'FORM' && (
            <StyledTextField
              id="table-message"
              variant="outlined"
              placeholder="Message"
              value={output.message}
              onChange={(event) => {
                event.preventDefault();
                event.stopPropagation();
                const newOutputs = [...outputs];
                newOutputs[i] = {
                  ...output,
                  message: event.currentTarget.value
                };
                updateOutput(newOutputs);
              }}
              size="small"
              sx={{ mb: '0' }}
            />
          )}
        </div>

        {output.type === 'MULTIPLE' && (
          <VariableField>
            {options.map((option, index) => (
              <Variable key={index}>
                {option}
                <MdCancel
                  style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                  onClick={() => deleteOption(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              value={multipleOptions}
              placeholder="Enter an option"
              onKeyDown={optionOnKeyDown}
              onChange={handleOptionValueChange}
              size="small"
              sx={{ marginBottom: '0' }}
              fullWidth
            />
          </VariableField>
        )}

        {(output.type === 'NAME' || output.type === 'ADDRESS') && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingLeft: '5px'
              }}
            >
              {output?.fields?.map((item) => (
                <InputPair
                  key={item.id}
                  item={item}
                  outputs={outputs}
                  output={output}
                  updateOutput={updateOutput}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

// Exports:
export default FormInputs;
