import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import uuid from 'react-uuid';
import {
    NAME_MENTIONS,
    API_SIMILARITY_LOADQNA,
    API_SIMILARITY_STATUS,
    GET_IFRAME_WEBPAGE,
    ORDER_STATUS,
    API_ENDPOINT,
    CREATE_ENDUSER_ACCOUNT,
} from "../constants/Endpoint";
toast.configure();


export const nameMentions = async (id) => {
    try {
        const data = { userId: id , orgId: localStorage.getItem('org_id')};
        const response = await axios.post(`${NAME_MENTIONS}`, data, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        return response;
    } catch (error) {
        throw error;
    }
};

/********************************************** */

// export async function setMessage(message, setLastMessage) {
//   setLastMessage(message);
//   return message;
// }

/*async function signUp(email, fullName, nickName) {
  const params = {
    username: email,
    password: getRandomString(30),
    attributes: {
      name: fullName,
      nickname: nickName
      
    }
  };
  await Auth.signUp(params);
}
function getRandomString(bytes) {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join('');
}
function intToHex(nr) {
  return nr.toString(16).padStart(2, '0');
}*/

/**********************************INTERACT*********************************************/

export const fetchResource = async (orgid, incomingQuery, role, personas = []) => {
    const query = incomingQuery.toLowerCase();
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/interactLayer`,
            {
                orgid,
                query,
                role,
                personas
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


/*************************SIMILARITY***********************************/

export const fetchSimilarity = async (orgid, incomingQuery, role, personas) => {
    const query = incomingQuery.toLowerCase();
    try {
        const listOfArticles = await axios.post(
            `${API_ENDPOINT}//suggestions`,
            {
                orgid: orgid,
                query: query,
                role,
                personas
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        let similarity = listOfArticles?.data?.response;
        let suggestions = [];
        Object.keys(similarity).forEach((k, i) => {
            if (similarity[k]['meta'] !== '') {
                suggestions = [
                    ...suggestions,
                    {
                        id: uuid(),
                        question: `${k}`,
                        meta: similarity[k]['meta'],
                        similarity: similarity[k]['similarity']
                    }
                ];
            }
        });
        return suggestions;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const loadQnaToSimilarity = async (orgid, questions, meta) => {
    try {
        const response = await axios.post(
            `${API_SIMILARITY_LOADQNA}`,
            {
                orgid,
                questions,
                meta
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return response?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const checkSimilarityStatus = async (orgid) => {
    try {
        const response = await axios.post(
            `${API_SIMILARITY_STATUS}`,
            {
                orgid
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return response?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/****************************Track Data************************ */

export const trackActivity = async (activity) => {
    try {
        const result = await axios.post(`${API_ENDPOINT}/activity-add`, activity, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchTrackingData = async (orgid) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/fetch-activity`,
            { orgid },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


/*******************************Workflows*************************** */

export const getIframeWebpage = async (fetchOptions) => {
    try {
        const result = await axios.get(GET_IFRAME_WEBPAGE, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            },
            params: {
                fetchOptions
            }
        });
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchShopifyOrderStatus = async (orderId) => {
    try {
        const response = await axios.post(
            ORDER_STATUS,
            {
                orderId: orderId
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return response?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/************************Create mapping of dialog id****************** */
export const addDialogidMappingAPI = async (orgid, articleId, dialog_id) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/saveDialogId`,
            {
                orgid,
                articleId,
                dialog_id
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchDialogidMappingAPI = async (orgid, articleId) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/getDialogId`,
            {
                orgid,
                articleId: String(articleId)
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const createEnduserAccount = async (email) => {
    try {
        const response = await axios.post(
            CREATE_ENDUSER_ACCOUNT,
            {
                email: email
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        if (response.data.statusCode === 200) {
            toast.success('Account created successfully');
        } else {
            toast.error(response.data.body?.message);
        }
        return response?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/*****************************CRUD operations on unverified articles ******************* */

export const setUnverifiedarticlesAPI = async (article) => {
    const published = false;
    const isReviewed = article?.meta?.isReviewed || false;
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/setUnverifiedArticle`,
            {
                ...article,
                meta: { ...article.meta, published, isReviewed }
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getUnverifiedArticlesAPI = async (orgid) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/getUnverifiedArticles`,
            {
                orgid
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const getUnverifiedArticlesOfSingleAgentAPI = async (
    orgid,
    createdBy
) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/getUnverifiedArticlesOfSingleAgent`,
            {
                orgid,
                createdBy
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const deleteUnverifiedArticlesAPI = async (data) => {
    try {
        const result = await axios.delete(
            `${API_ENDPOINT}/deleteUnverifiedArticle`,
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                },
                data
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/*****************************Autosave ******************* */


export const getDraftsAPI = async (orgid, createdBy) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/getDrafts`,
            {
                orgid,
                createdBy
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const autosaveAPI = async (article) => {
    const meta = { ...article?.meta };
    delete meta['published'];
    delete meta['isReviewed'];
    const data = {
        ...article,
        meta
    };
    try {
        const result = await axios.post(`${API_ENDPOINT}/autosave`, data, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const deleteDraftAPI = async (data) => {
    try {
        const result = await axios.delete(`${API_ENDPOINT}/deleteDraft`, {
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            },
            data
        });
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


/******************Supervisor comments on articles *************** */

export const addCommentAPI = async (orgid, articleId, comment, author) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/addCommentOnArticle `,
            {
                orgid,
                articleId,
                comment,
                author
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchCommentAPI = async (orgid, articleId) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/fetchCommentOnArticle`,
            {
                orgid,
                articleId
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/******************Feedback *************** */

export const addFeedbackAPI = async (orgid, user, rating, comment, type) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/addFeedback `,
            {
                orgid,
                user,
                rating,
                comment,
                type
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchFeedbackAPI = async (orgid) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/getFeedback`,
            {
                orgid
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchFeedbackAPIV2 = async (orgid, startDate, endDate) => {
    try {
        const result = await axios.get(
            `${API_ENDPOINT}/analytics/eventRecords/${orgid}?startDate=${startDate}&endDate=${endDate}`,
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/******************GET ONGOING CONVERSATIONS ******************/

export const getOngoingConversations = async (orgid) => {
    try {
        const result = await axios.post(
            `${API_ENDPOINT}/getOngoingConversations`,
            { orgid },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return result.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/************************************ Log Entity Changes *************************************/

export const logEntityChangesAPI = async (orgid, entity, changeData) => {
    const url = `${API_ENDPOINT}/log-changes`;
    const headers = {
        'x-api-key': process.env.REACT_APP_API_KEY,
    };
    const data = {
        orgid, entity,
        meta: changeData
    };
    try {
        const response = await axios.post(url, data, { headers });
        return response;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

/************************************ Get Entity Logs *************************************/

export const getEntityLogsAPI = async (orgid, entity, entityid) => {
    const url = `${API_ENDPOINT}/get-logs`;
    const options = {
        headers: { 'x-api-key': process.env.REACT_APP_API_KEY },
        params: { orgid, entity, entityid }
    };
    try {
        const response = await axios.get(url, options);
        return response.data;
    } catch (error) {
        console.error(error);
        if (error.response?.status === 404) {
            return { error: `No logs found for ${entity}.` };
        }
        return { error: `Failed to get logs for ${entity}.` }
    }
};