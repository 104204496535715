const createDataForExport = (articles) => {
  const intentExpanded = articles?.intent?.map((element) => {
    const tags =
      typeof element?.meta?.tags === 'object'
        ? element?.meta?.tags?.map((tag) => tag.desc).join(',')
        : '';
    const tempSynonyms =
      typeof element?.meta?.synonyms === 'object'
        ? element?.meta?.synonyms?.filter((syn) => syn.key !== '')
        : '';
    const synonyms =
      typeof tempSynonyms === 'object'
        ? tempSynonyms?.map((synonym) => synonym.key).join(',')
        : '';
    const training_phrases = element?.training_phrases?.join(',');
    const formattedText =
      typeof element?.meta !== 'string' &&
      typeof element?.meta?.formattedText !== 'object'
        ? element?.meta?.formattedText
        : element?.message;
    const isarticle = element?.meta?.isarticle;

    const obj = {
      ...element,
      ...element.meta,
      message: ':)',
      tags,
      training_phrases,
      formattedText,
      isarticle,
      synonyms,
      access: element?.meta?.access || 'public',
      target: element?.meta?.target || 'endUser'
    };
    delete obj['meta'];
    delete obj['name'];
    return obj;
  });

  const articlesExpanded = articles?.qna?.map((element) => {
    const tags =
      typeof element?.meta?.tags === 'object'
        ? element?.meta?.tags?.map((tag) => tag.desc).join(',')
        : '';
    const tempSynonyms =
      typeof element?.meta?.synonyms === 'object'
        ? element?.meta?.synonyms?.filter((syn) => syn.key !== '')
        : '';
    const synonyms =
      typeof tempSynonyms === 'object'
        ? tempSynonyms?.map((synonym) => synonym.key).join(',')
        : '';
    const training_phrases = element?.training_phrases?.join(',');
    const formattedText =
      typeof element?.meta !== 'string' &&
      typeof element?.meta?.formattedText !== 'object'
        ? element?.meta?.formattedText
        : element?.message;
    const isarticle = element?.meta?.isarticle;

    const obj = {
      ...element,
      ...element.meta,
      message: '',
      tags,
      training_phrases,
      formattedText,
      isarticle,
      synonyms,
      access: element?.meta?.access || 'private',
      target: element?.meta?.target || 'endUser'
    };
    delete obj['meta'];
    delete obj['name'];
    return obj;
  });

  return [articlesExpanded ?? [], intentExpanded ?? []];
};

export default createDataForExport;
