// Packages:
import React, { createContext, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./utils/auth";
import { Helmet } from "react-helmet";
import useWebSocket from "react-use-websocket";

// Components:
import { Navbar, Footer, NewMessageAlert } from "./components";
import AllRoutes from "./routes";

//auth
import { Auth } from "aws-amplify";
import {
  setUserDetails,
  setAuthenticationStatus,
  creatingTicket,
  archivingChannel,
  setUserStatus,
  setAgentCustomerChatStatus,
} from "./redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import {
  getContentActions,
  handleAgentStatus,
  fetchAgentType,
  setOrderStatus,
} from "./redux/actions/contentActions";
import { trackActivity } from "./api";
import uuid from "react-uuid";
import Toast from "./lib/Toast";
import { SOCKET_URL } from "./constants/Endpoint";
import {
  addConversation,
  incomingMessage,
  setBotTyping,
} from "./redux/actions/chatActions";

// Styles:
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WebSocketConnectionContext = createContext();

// Functions:
const App = () => {
  // Constants:
  const MINUTE_MS = 1800000;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [user, setUser] = useState(false);
  const dispatch = useDispatch();
  const funcContentActions = getContentActions(dispatch);
  const domain = window.location.href;

  const organisation_info = useSelector((state) => state.content.org_info);
  const query = useSelector((state) => state.content.query);
  const agentType = useSelector((state) => state.content.agent_type);
  const agentPersonas = useSelector((state) => state.content.agent_personas);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const org_id = organisation_info?.org_data?._id;
  const TITLE = organisation_info?.org_data?.title;
  const FAVICON = organisation_info?.org_data?.favicon_url;
  const [openConnection, setOpenConnection] = useState(false);

  const changeConnection = useCallback((val) => {
    // console.log('Called this function with val', val);
    setOpenConnection(val);
  }, []);

  const { sendJsonMessage, getWebSocket } = useWebSocket(
    SOCKET_URL,
    {
      share: true,
      onOpen: () => {
        console.log("Connection opened");
      },
      onClose: () => dispatch(setAgentCustomerChatStatus(false)),
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      onReconnectStop: (numAttempts) => {
        dispatch(
          addConversation({
            type: "bot",
            subtype: "received",
            message:
              "Couldn't connect you to a live agent, please leave a message in the chat and we'll get back to you by email.",
          })
        )
      },
      onMessage: (event) => processMessages(event),
    },
    openConnection
  );

  const processMessages = async (event) => {
    const response = JSON.parse(event.data);
    if (Object.keys(response).includes("similarity")) {
      // console.log({ 1: response });
      await funcContentActions.similarity(org_id, null, response?.similarity);
    } else if (Object.keys(response).includes("qna")) {
      // console.log({ 2: response });
      await funcContentActions.interact(org_id, null, response, true, "Enter");
      sendJsonMessage({
        action: "suggestions",
        orgid: org_id,
        role: agentType ?? "endUser",
        personas: agentPersonas ?? [],
        query
      });
    } else if (Object.keys(response).includes("messageData")) {
      // console.log({ 3: response });
      if (
        Object.keys(response?.messageData).includes("message") &&
        typeof response?.messageData?.message === "object" &&
        Object.keys(response.messageData?.message).includes("order")
      ) {
        dispatch(setOrderStatus(response.messageData?.message.order));
        dispatch(incomingMessage(response));
      } else {
        dispatch(incomingMessage(response));
      }
    } else {
      // console.log({ 4: response });
      if (
        response?.systemMessage === "Sorry, No Agent is available at the moment"
      ) {
        dispatch(
          addConversation({
            type: "bot",
            subtype: "received",
            message:
              "Our agents are either offline or with another customer. You can wait for an agent to respond, or leave a message in the chat and we'll get back to you by email.",
          })
        );
        await funcContentActions.addFeedback(
          org_id,
          userDetails?.email || "anonymous",
          0,
          "All agents are either offline or with another customer",
          "Agent Unavailable"
        );
      }
      dispatch(incomingMessage(response));
    }
    dispatch(setBotTyping(false));
  };

  let subdomain = domain.split("//")[1].split(".")[0];
  const setAuthStatus = (authenticated) => {
    setIsAuthenticated(authenticated);
  };

  const changeUser = (user) => {
    setUser(user);
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const activity = {
        orgid: org_id,
        recordid: `${
          user?.username?.slice(0, 8) || uuid()
        }${new Date().toISOString()}`,
        user_email: `${user?.attributes?.email || "anonymous"}`,
        record: JSON.parse(localStorage.getItem("activity")),
      };
      await trackActivity(activity);
    }, MINUTE_MS);

    return () => {
      clearInterval(interval);
      localStorage.removeItem("activity");
    };
  }, [org_id, user?.attributes?.email, user?.username]);

  useEffect(() => {
    const authenticate = async () => {
      try {
        //console.log(subdomain[0])
        await funcContentActions.getOrganisationInfo(
          subdomain.includes('localhost') ? 'help' : subdomain
        );
        await funcContentActions.getOrganisationCustomizationInfo(
          subdomain.includes('localhost') ? 'help' : subdomain
        );
        //this is to send the previous tickets that, if the user just left/closed the chat abruptly
        if (localStorage.getItem("chats") !== null) {
          dispatch(
            creatingTicket(
              localStorage.getItem("chats"),
              localStorage.getItem("ticketing_system"),
              localStorage.getItem("validated_user"),
              localStorage.getItem("slack_channel_id"),
              "Agent",
              true,
              agentType
            )
          );
        }
        if (localStorage.getItem("AIchats") !== null) {
          dispatch(
            creatingTicket(
              localStorage.getItem("AIchats"),
              "default",
              "anonymous",
              Math.random().toString(36).slice(2),
              "Testing", 
              true,
              agentType
            )
          );
        }
        if (localStorage.getItem("leave_message") !== null) {
          dispatch(
            creatingTicket(
              localStorage.getItem("leave_message"),
              localStorage.getItem("ticketing_system"),
              `${
                localStorage.getItem("unauthenticated_user") !== null
                  ? localStorage.getItem("unauthenticated_user")
                  : localStorage.getItem("validated_user")
              }`,
              Math.random().toString(36).slice(2),
              "Leave Message",
              true,
              agentType
            )
          );
        }
        if (localStorage.getItem("slack_channel_id") !== null) {
          dispatch(archivingChannel(localStorage.getItem("slack_channel_id")));
        }
        await Auth.currentSession();
        //console.log("session : "+session)
        const user = await Auth.currentAuthenticatedUser();
        dispatch(setUserDetails(user["attributes"]));
        setUser(user);
        setAuthStatus(true);
        dispatch(setAuthenticationStatus(true));
      } catch (err) {
        console.error(err);
      }
      setIsAuthenticating(false);
    };
    authenticate();
  }, []);

  useEffect(() => {
    if (userDetails?.email) {
      dispatch(
        setUserStatus({
          org_id,
          role: "agent",
          isAuthenticated: true,
        })
      );
      dispatch(
        handleAgentStatus(
          "get",
          localStorage.getItem("org_id"),
          userDetails["email"],
          ""
        )
      );
      dispatch(
        fetchAgentType(localStorage.getItem("org_id"), userDetails["email"])
      );
    }
  }, [org_id, userDetails?.email]);

  return (
    !isAuthenticating && (
      <Wrapper>
        <Helmet>
          <title>
            {domain.includes("customer-service")
              ? TITLE + " | Customer Service"
              : domain.includes("need-help")
              ? TITLE + " | Need Help"
              : TITLE}
          </title>
          <link rel="icon" href={FAVICON} />
        </Helmet>
        <Toast />

        <Router>
          <WebSocketConnectionContext.Provider
            value={{ openConnection, changeConnection, sendJsonMessage }}
          >
            <AuthProvider>
              <NewMessageAlert />
              <Navbar
                isAuthenticated={isAuthenticated}
                user={user}
                setAuthStatus={setAuthStatus}
                changeUser={changeUser}
              />
              <AllRoutes
                isAuthenticated={isAuthenticated}
                user={user}
                setAuthStatus={setAuthStatus}
                changeUser={changeUser}
              />
            </AuthProvider>
            <Footer />
          </WebSocketConnectionContext.Provider>
        </Router>
      </Wrapper>
    )
  );
};

// Exports:
export default App;
