// Packages:
import styled, { keyframes } from 'styled-components'

// Exports:
const fadeInAndRiseUp = keyframes`
  from {
    filter: opacity(0);
    transform: translateY(-10px);
  }
  to {
    filter: opacity(1);
    transform: translateY(0);
  }
`


// Exports:
export const Wrapper = styled.div`
  position: relative;
  width: 30%;
  height: 100%;
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background: #ffffff;
  padding: 0.5rem;
  max-height: 730px;
  border-radius: 4px;
  margin: 0 auto;
  color: #333333;
`

export const Info = styled.div`
  height: 100%;
`

export const InfoTitle = styled.div`
  height: 50px;
  font-size: 14px;
  padding-top: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`

export const InfoWrapper = styled.div`
  max-height: 70%;
  overflow: auto;
  animation: ${ fadeInAndRiseUp } 1s ease;
  font-size: 14px;
  text-align: left;
  padding: 10px;
  line-height: 1.5rem;
  border-radius: 0.25rem;
  margin-bottom: 10px;
`

export const GapsTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    border-collapse: collapse;
    padding: 5px;
    text-align: left;
  }
`