import React, { memo, useCallback, useEffect } from "react";
import { TextField } from "@mui/material";
import styled from "styled-components";

const StyledTextField = styled(TextField)`
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const UserMessageCheckbox = ({sendUserUpdate,setSendUserUpdate,setUserMessage,userMessage,isEditable}) => {

  const handleCheckboxChange =useCallback((event) => {
    setSendUserUpdate(event.target.checked);
    if(sendUserUpdate!=true)
      setUserMessage('')
  },[]);

  const handleTextInputChange =useCallback((event) => {
    setUserMessage(event.target.value);
  },[]);

  return (
    <>
      <div style={{display:'flex',flexDirection:'row'}}>
      <input
        type="checkbox"
        checked={sendUserUpdate}
        style={{ marginRight: "0.35rem" }}
        disabled={!isEditable}
        onChange={handleCheckboxChange}
      />
      <div style={{ fontSize: "0.8rem" }}>Send User Update</div>
      </div>
      <StyledTextField
        placeholder="Enter Message to be displayed"
        variant="outlined"
        fullWidth
        value={userMessage}
        size="small" 
        margin="dense" 
        onChange={handleTextInputChange}
        disabled={!sendUserUpdate||!isEditable}
      />
    </>
  );
};

export default memo(UserMessageCheckbox);
