import sessionConfig from '../config/infov2/session_table.json';

const { headerToPropSession } = sessionConfig;

export function sortingDateFunction(record, sortBy) {
    if(!sortBy?.length) return record;
    let {id, desc} = sortBy[0];
    return record.sort((rec1, rec2) => {
        if(desc){
            return new Date(rec2[headerToPropSession[id]]) - new Date(rec1[headerToPropSession[id]]);
        }
        else{
            return new Date(rec1[headerToPropSession[id]]) - new Date(rec2[headerToPropSession[id]]);
        }
    })
}