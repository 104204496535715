// Packages:
import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Tooltip,
  Button,
  styled
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import { TailSpin } from 'react-loader-spinner';

// Styles:
import { Comment } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getContentActions } from '../../../redux/actions/contentActions';
import { useDeepCompareEffect } from 'react-use';
import { ADMIN } from '../../../constants/user';
// import { setUserDetails } from '../../../redux/actions/authActions';
// import uuid from 'react-uuid';

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 1rem;
    max-width: 75%;
    width: 100%;
    max-height: 50%;
    height: 100%;
  }

  & .MuiTypography-root {
    font-family: 'Inter', sans-serif;
  }

  & .MuiDialogActions-root {
    padding: 20px 24px;
  }
`;

// Functions:
const CommentBox = ({
  open,
  handleClose,
  agent_type,
  documentId,
  handleAddComment,
  storeComments = [],
  loading,
  changeCommentsArray,
  comments
}) => {
  // State:
  const [comment, setComment] = useState('');

  const changeComment = useCallback((val) => {
    setComment(val);
  }, []);

  useDeepCompareEffect(() => {
    changeCommentsArray([...storeComments, ...comments]);
  }, [storeComments]);

  // Return:
  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ mb: '1rem', fontSize: '1.25rem', color: '#333333' }}>
        {agent_type === 'supervisor' || agent_type === 'superadmin' || agent_type.toLowerCase() === ADMIN
          ? 'Comments'
          : 'Supervisor Comments'}
        <Tooltip title="close">
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <div>
          {loading ? (
            <TailSpin
              height="17"
              width="17"
              color="#5a5a5a"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{}}
              wrapperClass=""
            />
          ) : (
            comments?.map((item) => (
              <Comment key={item.id}>
                {item?.createdAt} : {item?.comment}{' '}
                <span
                  style={{
                    color: 'grey',
                    fontSize: '8px',
                    paddingLeft: '5px'
                  }}
                >
                  {item?.author}
                </span>
              </Comment>
            ))
          )}
        </div>
        {(agent_type === 'supervisor' || agent_type === 'superadmin' || agent_type.toLowerCase() === ADMIN) && (
          <TextField
            id="outlined-multiline-static"
            label="Write your comment"
            multiline
            rows={4}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            sx={{ width: '100%', mt: '1rem' }}
          />
        )}
      </DialogContent>

      {(agent_type === 'supervisor' || agent_type === 'superadmin' ||  agent_type.toLowerCase() === ADMIN) && (
        <DialogActions>
          <Button
            variant="contained"
            size="medium"
            startIcon={<AddCommentOutlinedIcon />}
            sx={{ textTransform: 'none' }}
            color="primary"
            onClick={() => {
              handleAddComment(comment, changeComment);
            }}
          >
            Comment
          </Button>
        </DialogActions>
      )}
    </StyledDialog>
  );
};

// Exports:
export default CommentBox;
