// Packages:
import React, { useEffect } from 'react';
import { FormGenerator } from '../../../../pages';
import useInput from '../../../../lib/hooks/use-input';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {
    Button
  } from '@mui/material';


// Functions:
const MessageConfiguration = ({ node, updateNode, deleteNode }) => {
  // State:
  const { value: formName, setValue: setFormName, bind: bindFormName } = useInput(node.formName ?? '');
  const { value: formDescription, setValue: setFormDescription, bind: bindFormDescription } = useInput(node.formDescription ?? '');

  // Effects:
  useEffect(() => {
    setFormName(node.formName);
    setFormDescription(node.formDescription);
  }, [node.formName, node.formDescription]);

  useEffect(() => {
    updateNode(node.id, {
      formName,
      formDescription
    });
  }, [formName, formDescription]);
  

  // Return:
  return (
    <div>
      <FormGenerator bindFormName={bindFormName} bindFormDescription={bindFormDescription} />
      <Button
        variant="contained"
        color="error"
        size="small"
        startIcon={<DeleteOutlineOutlinedIcon />}
        sx={{ textTransform: 'none', mt: '1rem' }}
        onClick={() => deleteNode(node.id)}
      >
        Delete Node
      </Button>
    </div>
  );
};

// Exports:
export default MessageConfiguration;