// Packages:
import styled from 'styled-components';

// Exports:
export const Wrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  width: 99%;
  height: 98%;
  margin-top: 5rem;
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px,
    rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background: #ffffff;
  color: #333333;
  height: 98%;
  padding: 0.5rem;
  overflow-y: scroll;
  border-radius: 4px;
  overflow: hidden;
`;

export const AgentsTable = styled.table`
  width: 100%;
  height: 82%;
  text-align: center;
  font-size: 14px;
  transform: translateY(0px);
  overflow: hidden;
  margin: 0 auto;
  border-collapse: collapse;

  td {
    padding: 7px 6px;
  }

  tr {
    min-height: 48px;
    overflow: hidden;
  }
`;

export const Header = styled.thead`
  background: #ffffff;
`;
export const Body = styled.tbody`
  tr:nth-child(even) {
    background: #f4f4f9;
  }
`;

export const Title = styled.tr`
  th {
    color: #333333;
    height: 50px;
    padding: 12px 6px;
    cursor: pointer;
  }
`;

export const Filters = styled.tr`
  th {
    height: 50px;
    padding: 7px 5px;
    background: #f4f4f9;
  }
`;

export const Heading = styled.div`
  margin-bottom: 0.75rem;
  margin-top: 0.5rem;
  gap: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;