// Packages:
import React, { memo } from "react";
import { useSelector } from "react-redux";


// Styles:
import { Body, Description, Field, NodeLabel, NodeWrapper, StyledHandle } from "../styles";
import BaseNode from "../BaseNode";
import { config } from "./config";
import useNodeStyles from "../../../../hooks/useNodeStyles";

const EmailNode = ({ data }) => {
  // Constants:
  const { id, fromEmail, subject, body, label } = data;
  // State:
  const studio = useSelector((state) => state.studio);

  const isSelected = useSelector(
    (state) => state.studio.selectedNode?.id === id
  );

  const {computedLabelStyle,computedBodyStyle,computedWrapperStyle}=useNodeStyles({isSelected,config})


  // Return:
  return (
    <BaseNode data={data} config={config}>
      <NodeWrapper style={computedWrapperStyle} backgroundColor={'#FEFFFE'} borderColor={'#D43558'}>
      {config.handleStyles.map((handle, index) => (
        <StyledHandle
            key={index}
            id={handle.properties.id}
            type={handle.properties.type}
            position={handle.properties.position}
            style={handle.style}
        />
        ))}
        <NodeLabel htmlFor="text" style={computedLabelStyle}>
          {label}
        </NodeLabel>
        <Body style={computedBodyStyle}>
          <Description>
          <Field isActive={studio.selectedNode?.id === id} activeColor={'#871D34'} inActiveColor={'#D43558'}>
          {(fromEmail?.value?.fieldName !== null || fromEmail?.value?.value !== null) ? (
             `${fromEmail?.value?.fieldName ?? ''} ${fromEmail?.value?.value ?? ''}`
               ) : (
             <span style={{ fontStyle: 'italic', fontWeight: 400 }}>
                  From...
             </span>
           )}
            </Field>
          </Description>
          <Description>
            <Field isActive={studio.selectedNode?.id === id} activeColor={'#871D34'} inActiveColor={'#D43558'}>
          {(subject?.value?.fieldName !== null || subject?.value?.value !== null) ? (
             `${subject?.value?.fieldName ?? ''} ${subject?.value?.value ?? ''}`
               ) : (
             <span style={{ fontStyle: 'italic', fontWeight: 400 }}>
                  Write a subject...
             </span>
           )}
        </Field>
          </Description>
          <Description>
            <Field isActive={studio.selectedNode?.id === id} activeColor={'#871D34'} inActiveColor={'#D43558'}>
              {body?.length === 0 ? (
                <span style={{ fontStyle: "italic", fontWeight: 400 }}>
                  Body of the email...
                </span>
              ) : body?.length >= 30 ? (
                `${body?.slice(0, 25).trim()}..`
              ) : (
                body
              )}
            </Field>
          </Description>
        </Body>
      </NodeWrapper>
    </BaseNode>
  );
};

// Exports:
export default memo(EmailNode);