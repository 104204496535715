// Packages:
import styled from 'styled-components'


// Exports:
export const Title = styled.div`
  padding-top: 1rem;
  padding-left: 1.5rem;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
`

export const IFrameContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const IFrameElement = styled.iframe`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
`;