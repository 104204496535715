// Packages:
import styled, { keyframes } from 'styled-components';

// Exports:
const wave = keyframes`
  0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-0.25rem);
	}
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 93.5%;
  font-size: 14px;

  @media (max-width: 1024px) {
    height: 92%;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  text-align: center;
  font-weight: bold;
  padding: 10px 0;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
`;

export const FlexButton = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding-right: 0.5rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Title = styled.div`
  padding-left: 1rem;
`;

export const Container = styled.div`
  height: 100%;
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  align-self: ${(props) =>
    props.subtype === 'received' ? 'flex-start' : 'flex-end'};
  max-width: 85%;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 30px;
  white-space: pre-line;

  @media (max-width: 768px) {
    line-height: inherit;
  }
`;

export const SystemMessage = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  max-width: 85%;
  color: #5f5f5f;
  padding: 12px;
  line-height: 25px;
  white-space: pre-line;

  @media (max-width: 768px) {
    line-height: inherit;
  }
`;

export const Reply = styled.span`
  display: inline-block;
  height: 100%;
  margin-top: -2px;
  margin-left: 1rem;
  padding: 0.5rem 0.75rem;
  box-decoration-break: clone;
  background: ${(props) => props.subtype === 'received' ? '#f4f4f9' : '#CACAE2'};
  border-radius: 0.5rem;

  @media (max-width: 768px) {
    padding-left: 10px;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 0.5rem;
    height: auto;
    margin-left: 0.5rem;
  }
`;

export const Conversations = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;

  @media (max-width: 768px) {
    padding: 1rem 0.75rem;
  }
`;

export const LoadingDot = styled.div`
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background: #8bb3c5;
  animation: ${wave} 1.3s linear infinite;

  &:nth-child(2) {
    animation-delay: -1.1s;
  }

  &:nth-child(3) {
    animation-delay: -0.9s;
  }
`;

export const Footer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: fit-content;
  font-weight: normal;
  color: #5f5f5f;

  @media (max-width: 768px) {
    height: 2rem;
  }
`;

export const FeedbackMessage = styled.span`
  font-weight: 600;
`;

export const SourceSpan = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #2f80ed;
  cursor: pointer;
  text-decoration: underline;
`;

export const SourceAnchor = styled.a`
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #2f80ed;
  cursor: pointer;
  text-decoration: underline;
`;

export const VerificationUrlContainer = styled.span`
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  margin-right: 2rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  background:#d1dbfb;
`;