import produce from 'immer';
import uuid from 'react-uuid';
import { authActions } from '../actions/authActions';

const initialState = {
  user: {},
  userDetails: null,
  isLoggedIn: false,
  agentCustomerChatOn: false,
  Session: null,
  email: null,
  channelId: null,
  loading: false,
  error: null,
  lastMessage: null
};

const authReducer = produce((draft, action) => {
  const isTemporaryUserActive =localStorage.getItem("isTemporaryUserActive");
  switch (action.type) {
    case authActions.SIGN_IN_ATTEMPT_BEGIN:
      draft.loading = true;
      draft.userPool = null;
      return;
    case authActions.SIGN_IN_ATTEMPT_SUCCESS_TRACK:
      if(isTemporaryUserActive==='true'){
        draft.Session=null;
        draft.email=action.payload['email'];
        draft.loading = false;
      }
      else{
        draft.Session = action.payload['data']['data']['Session'];
        draft.email = action.payload['data']['email'];
        draft.loading = false;
      }
      return;
    case authActions.REFRESH_SESSION:
      draft.Session = action.payload['data']['data']['Session'];
      draft.loading = false;
      return;
    case authActions.SIGN_IN_ATTEMPT_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case authActions.OTP_VERIFY_BEGIN:
      draft.loading = true;
      return;
    case authActions.OTP_VERIFY_SUCCESS:
      draft.agentCustomerChatOn = true;
      draft.loading = false;
      return;
    case authActions.OTP_VERIFY_ERROR:
      draft.agentCustomerChatOn = false;
      draft.loading = false;
      draft.error = action.payload;
      return;
    case authActions.CREATE_CHANNEL_BEGIN:
      draft.channelId = null;
      draft.loading = true;
      return;
    case authActions.CREATE_CHANNEL_SUCCESS:
      draft.loading = false;
      return;
    case authActions.CREATE_CHANNEL_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case authActions.SET_CHANNEL_ID:
      draft.channelId = action.payload['data']['body'];
      return;
    case authActions.SEND_MESSAGE_BEGIN:
      draft.loading = true;
      return;
    case authActions.SEND_MESSAGE_SUCCESS:
      draft.loading = false;
      return;
    case authActions.SEND_MESSAGE_ERROR:
      draft.loading = false;
      draft.error = action.payload;
      return;
    case authActions.SET_LAST_MESSAGE:
      draft.lastMessage = action.payload;
      return;
    case authActions.SET_USER_DETAILS:
      draft.userDetails = action.payload;
      return;
    case authActions.SET_USER_STATUS:
      draft.user = { ...action.payload, id: draft?.userDetails?.sub || uuid() };
      return;
    case authActions.SET_AUTH_STATUS:
      draft.isLoggedIn = action.payload;
      return;
    case authActions.SET_AGENT_CUSTOMER_CHAT_STATUS:
      draft.agentCustomerChatOn = action.payload;
      return;
    default:
      return;
  }
}, initialState);

export default authReducer;
