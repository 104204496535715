import React from 'react';
import { ARTICLE_IS_DRAFT } from '../../../constants/articles';

const ImportCheckbox = ({
  isEdit,
  changeArticle,
  articleData
}) => {
  return (
    isEdit && (
      <span
        style={ { marginLeft: '2rem' } }
        title="Import pdf and html files directly from source"
      >
        <input
          disabled={articleData?.meta?.status !== ARTICLE_IS_DRAFT}
          type="checkbox"
          id="isarticle"
          name="isarticle"
          style={{
            cursor: 'pointer',
            marginRight: '0.5rem',
            verticalAlign: 'middle'
          }}
          checked={articleData?.meta?.importFromSource}
          onChange={(event) => {
            const newArticle = {
              ...articleData,
              meta: {
                ...articleData?.meta,
                importFromSource: event.target.checked
              }
            };
            changeArticle(newArticle);
          }}
        />
        <label htmlFor="isarticle" style={{ verticalAlign: 'middle' }}>
          Import
        </label>
      </span>
    )
  );
};

export default ImportCheckbox;
