import React from 'react';

const BooleanField = ({ field, value, onChange }) => {
  return (
    <input
      type="checkbox"
      id={field}
      name={field}
      style={{
        cursor: 'pointer',
        verticalAlign: 'middle'
      }}
      checked={value}
      onChange={(event) => onChange(field, event.target.checked)}
    />
  );
};

export default BooleanField;
