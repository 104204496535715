// Packages:
import React, { useEffect, useState } from 'react'
import {
    Drawer,
    IconButton,
    Tooltip,
    styled,
    CircularProgress
  } from '@mui/material';
import { getIframeWebpage } from '../../../api';
import isResponseOpaque from '../../../lib/isResponseOpaque';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';


// Styles:
import {
    Title,
    IFrameElement, 
    IFrameContainer
  } from './styles'
  
const StyledDrawer = styled(Drawer)`
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    & .MuiPaper-root {
        width: 39%;
        top: 74.5px;
        right: 17px;
        height: 88.5%;
        border: 0;
        box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    }
`;


// Functions:
const Iframe = (props) => { 
    // State: 
    const [isExternalURLOpaque, setIsExternalURLOpaque] = useState(true);
    const [iframeSrcDoc, setIframeSrcDoc] = useState(null);

    // Effect:
    useEffect(() => {
        const url = props.articleData?.meta?.source;
        (async () => {
          if (
            !url ||
            !url?.match(
              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g
            )
          )
            return;
          const isURLOpaque = await isResponseOpaque(url);
          const urlObject = new URL(url);
          setIsExternalURLOpaque(isURLOpaque);
          const stringifiedFetchOptions = JSON.stringify({
            targetURL: url,
            type: 'DOCUMENT',
            puppeteerOptions: {
              baseURL: urlObject.protocol + urlObject.hostname
            }
          });
          const pageContent = await getIframeWebpage(stringifiedFetchOptions);
          if (!pageContent) return;
          setIframeSrcDoc(pageContent);
        })();
      }, [props.articleData?.meta?.source]);

    // Return:
    return (
        <StyledDrawer variant="persistent" anchor="right" open={props.openCollapse}>
            <Title>
                Original Page
                <Tooltip title="Close">
                    <IconButton onClick={props.handleDrawerClose} sx={{ position: 'absolute', right: '0.5rem' }}>
                        <MenuOpenOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </Title>
            <IFrameContainer>
                {(props.articleData?.meta?.source && !isExternalURLOpaque) || iframeSrcDoc ? (
                    isExternalURLOpaque ? (
                        <IFrameElement srcDoc={iframeSrcDoc} />
                    ) : (
                        <IFrameElement src={props.articleData?.meta?.source} />
                    )
                ) : (
                    <CircularProgress />
                )}
            </IFrameContainer>
        </StyledDrawer>
    )
}


// Exports:
export default Iframe
