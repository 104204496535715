// Packages:
import React, { useCallback } from 'react';
import { 
  MenuItem,
  FormControl,
  Select,
  TextField,
  InputLabel,
  ListItemText,
  Checkbox,
  OutlinedInput,
  styled,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// Styles:
import { Wrapper, FiltersTitle } from './styles';

const StyledFormControl = styled(FormControl)`
  margin-top: 1rem;
  & .MuiFormLabel-root {
    font-size: 14px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 14px;
  }
  & .MuiFormGroup-root {
    margin-left: 1rem;
    font-size: 14px;
    & .MuiTypography-root {
      font-size: 14px;
    }
  }
  &. MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: red;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledDesktopDatePicker = styled(DesktopDatePicker)`
  &. MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 14px;
  }
  & .MuiFormControl-root {
    margin-top: 1rem;
  }
`;


// Constants:
const ratings = ['1 - Horrible', '2 - Poor', '3 - Neutral', '4 - Good', '5 - Excellent'];
const conversationTypes = ['AI', 'Agent', 'Agent Offline', 'Leave Message'];
const types = ['like', 'dislike', 'mishit'];


// Functions:
const Filters = (props) => {

  // Event Handlers:

  const handleDateRangeChange = useCallback((event) => props.handleDateRangeChange(event), []);

  const handleStartDateChange = useCallback((newValue) => {
    if(newValue>props.endDate){
      alert("Startdate should be smaller than endDate")
      return;
    }
    props.handleStartDateChange(newValue)
  }, []);

  // const handleEndDateChange = useCallback((newValue) => props.handleEndDateChange(newValue), []);

  const handleEndDateChange = useCallback((newValue) => {
    if(newValue<props.startDate){
      alert("End Date should be Greater than startDate")
      return;
    }
    props.handleEndDateChange(newValue)
  }, []);

  const handleRatingChange = useCallback((event) => props.handleRatingChange(event), []);

  const handleConversationTypeChange = useCallback((event) => props.handleConversationTypeChange(event), []);

  const handleTypeChange = useCallback((event) => props.handleTypeChange(event), []);

  // Return:
  return (
    <Wrapper>
      <FiltersTitle>Filters</FiltersTitle>
      <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div style={{ marginTop: '2rem' }}>
            <StyledDesktopDatePicker
              variant="inline"
              label="Start Date"
              value={props.startDate}
              onChange={handleStartDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div style={{ marginTop: '1rem' }}>
            <StyledDesktopDatePicker
              label="End Date"
              value={props.endDate}
              onChange={handleEndDateChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </LocalizationProvider>

        <StyledFormControl fullWidth>
          <InputLabel id="date-range">Date Range</InputLabel>
          <Select
            autoWidth={false}
            id="date-range"
            value={props.dateRange}
            onChange={(event) => handleDateRangeChange(event)}
            label="Date Range"
            sx={{ fontSize: 14 }}
          >
            <StyledMenuItem value="last 4 weeks" key="last 4 weeks">Last 4 Weeks</StyledMenuItem>
            <StyledMenuItem value="last 2 weeks" key="last 2 weeks">Last 2 Weeks</StyledMenuItem>
            <StyledMenuItem value="last week" key="last week">Last Week</StyledMenuItem>
            <StyledMenuItem value="yesterday" key="yesterday">Yesterday</StyledMenuItem>
            <StyledMenuItem value="this week" key="this week">This Week</StyledMenuItem>
          </Select>
        </StyledFormControl>

        {props.tab === "records" && (<StyledFormControl fullWidth>
          <InputLabel>Conversation Type</InputLabel>
          <Select
            multiple
            autoWidth={false}
            value={props.conversationType}
            onChange={handleConversationTypeChange}
            input={<OutlinedInput label="Conversation Type" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {conversationTypes.map((name) => (
              <StyledMenuItem key={name} value={name}>
                <Checkbox checked={props.conversationType.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </StyledMenuItem>
            ))}
          </Select>
        </StyledFormControl>)}

        {props.tab === "tracking" && (<StyledFormControl fullWidth>
          <InputLabel>Type</InputLabel>
          <Select
            multiple
            value={props.type}
            onChange={handleTypeChange}
            input={<OutlinedInput label="Type" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {types.map((name) => (
              <StyledMenuItem key={name} value={name}>
                <Checkbox checked={props.type.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </StyledMenuItem>
            ))}
          </Select>
        </StyledFormControl>)}

        {props.tab === "feedback" && (<StyledFormControl fullWidth>
          <InputLabel>Rating</InputLabel>
          <Select
            multiple
            value={props.rating}
            onChange={handleRatingChange}
            input={<OutlinedInput label="Rating" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {ratings.map((name) => (
              <StyledMenuItem key={name} value={name}>
                <Checkbox checked={props.rating.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </StyledMenuItem>
            ))}
          </Select>
        </StyledFormControl>)}
      </>
    </Wrapper>
  );
};


// Exports:
export default Filters;