import React from 'react';
import FAQResult from '../FAQResult';
import { ARTICLE_IS_PUBLISHED } from '../../../constants/articles';

const Articles = ({ articlesArray = [], deleteArticle = () => {} }) => {
  const filterCallback = (article) => article?.articleId && article?.orgId && article?.meta;
  const mapCallback = (article) => {
    const meta = article.meta;
    const part = article.meta?.status === ARTICLE_IS_PUBLISHED
      ? article.meta?.version
      : article.meta?.createdBy;
    const key = `${article.articleId}-${meta?.status}-${part}`;
    return (
      <div
        style={{ width: "50%" }}
        key={key}
      >
        <FAQResult
          article={article}
          title={meta?.title || ''}
          deleteArticle={(article) => deleteArticle(article)}
        />
      </div>
    );
  };

  return (
    <>
      {articlesArray?.filter(filterCallback).map(mapCallback)}
    </>
  );
};

export default Articles;
