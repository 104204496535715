import deleteArticleFunction from '../../../lib/operationsOnKnowledgeArticles/published/deleteArticle';
import deleteUnverifiedArticle from '../../../lib/operationsOnKnowledgeArticles/unpublished/deleteUnverifiedArticle';

const dltArticle = async (
  article,
  articles,
  agent_type,
  orgid,
  deleteKA,
  deleteUnverifiedKA,
  deleteDraftKA,
  userDetails
) => {
  try {
    if (
      Object.keys(article?.meta).includes('published') &&
      article?.meta?.published === true
    ) {
      const data = {
        dialog_id: article.dialog_id,
        isarticle: article?.meta?.isarticle,
        orgid,
        target: article?.meta?.target,
        access: article?.meta?.access,
        articleId: article?.meta?.articleId
      };
      await deleteArticleFunction(data, deleteKA, userDetails);
    } else if (
      Object.keys(article?.meta).includes('published') &&
      article?.meta?.published === false
    ) {
      let data;
      if (agent_type === 'supervisor' || agent_type === 'superadmin') {
        data = {
          orgid,
          target: article?.meta?.target,
          access: article?.meta?.access,
          articleId: article?.articleId
        };
      } else {
        data = {
          orgid,
          target: article?.meta?.target,
          access: article?.meta?.access,
          articleId: article?.articleId,
          createdBy: article?.createdBy
        };
      }

      await deleteUnverifiedArticle(data, deleteUnverifiedKA);
    } else if (article?.meta?.published === undefined) {
      const data = {
        orgid,
        target: article?.meta?.target,
        access: article?.meta?.access,
        articleId: article?.articleId,
        createdBy: article?.createdBy
      };
      await deleteDraftKA(data);
    }
  } catch (error) {
    console.error(error);
  }
};
export default dltArticle;