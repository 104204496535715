import styled from 'styled-components';

export const TableHeader = styled.th`
  font-size: 12px;
`;

export const TableData = styled.td`
  border: none;
  text-align: left;
  padding: 0;
`;