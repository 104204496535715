// Packages:
import React from 'react';
import {
    TextField,
    styled
  } from '@mui/material';


// Styles:
import {
    Wrapper,
    FieldName
  } from './styles';

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;  
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;


// Functions:

const Info = ({ bindFormName, bindFormDescription }) => {

  return (
    <Wrapper>
        <FieldName>Name</FieldName>
        <StyledTextField
            variant="outlined"
            placeholder="Enter a form title"
            {...bindFormName}
            fullWidth
            size="small"
        />

        <FieldName>Description</FieldName>
        <StyledTextField
            variant="outlined"
            placeholder="Enter a form description"
            {...bindFormDescription}
            size="small"
            fullWidth
        />
    </Wrapper>
  );
};

// Exports:
export default Info;