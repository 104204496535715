// Packages:
import React, { useState, useEffect } from 'react';
import useInput from '../../../../lib/hooks/use-input';
import { Button, TextField, styled } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// Imports:
import { MdCancel } from 'react-icons/md';

// Styles:
import { FieldName, VariableField, Variable } from './styles';

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

// Functions:

const StartConfiguration = ({ node, updateNode, deleteNode }) => {
  // State:
  const {
    value: comment,
    setValue: setComment,
    bind: bindComment
  } = useInput(node.comment ?? '');
  const [tagInput, setTagInput] = useState('');
  const [tags, setTags] = useState(node.tags);

  // console.log(node)

  // Effects:
  useEffect(() => {
    setComment(node.comment);
    setTags(node.tags);
  }, [node.comment, node.tags]);

  useEffect(() => {
    updateNode(node.id, { comment, tags });
  }, [comment, tags]);

  const handleTagValueChange = (e) => {
    const { value } = e.target;
    setTagInput(value);
  };

  const tagOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = tagInput.trim();
    if (
      (key === ',' || key === 'Enter') &&
      trimmedInput.length &&
      !tags?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setTagInput('');
    }
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  // Return:
  return (
    <div>
      <FieldName>Reply</FieldName>
      <StyledTextField
        id="start-reply"
        variant="outlined"
        placeholder="Enter a reply"
        {...bindComment}
        size="small"
        fullWidth
      />

      <FieldName>Sample Triggers</FieldName>
      <VariableField>
        {tags.map((tag, index) => (
          <Variable key={tag}>
            {tag}
            <MdCancel
              style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
              onClick={() => deleteTag(index)}
            />
          </Variable>
        ))}
        <StyledTextField
          type="text"
          id="start-sample-trigger"
          variant="outlined"
          value={tagInput}
          placeholder="Enter a sample trigger"
          onKeyDown={tagOnKeyDown}
          onChange={handleTagValueChange}
          size="small"
          fullWidth
        />
      </VariableField>

      <Button
        variant="contained"
        color="error"
        size="small"
        startIcon={<DeleteOutlineOutlinedIcon />}
        sx={{ textTransform: 'none', mt: '1rem' }}
        onClick={() => deleteNode(node.id)}
      >
        Delete Node
      </Button>
    </div>
  );
};

// Exports:
export default StartConfiguration;