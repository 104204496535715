// Packages:
import React, { useState, useEffect } from 'react';
import {
  TextField,
  styled,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  InputLabel,
} from '@mui/material';
import { creatingTicket } from '../../../redux/actions/authActions';
import {  renderDynamicForm } from '../../../redux/actions/chatActions';
import { useDispatch, useSelector } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import data from './dashlane_form.json';

// Styles
import { Wrapper, Title, Subtitle, Group } from './styles';
import { setBotTyping } from '../../../redux/actions/chatActions';

const StyledTextField = styled(TextField)`
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  & .MuiOutlinedInput-input {
    background-color: #ffffff;
    font-size: 14px;
  }
`;

const StyledFormControl = styled(FormControl)`
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  & .MuiOutlinedInput-root {
    font-size: 14px;
    background-color: #ffffff;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledFormLabel = styled(FormLabel)`
  font-size: 14px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-size: 14px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

// Functions:
const DynamicChatForm = () => {

    //console.log(data.ticket_fields);
    //console.log(data.ticket_forms[0].end_user_conditions);
    const [email, setEmail] = useState('');
    const agent_type_info = useSelector((state) => state.content.agent_type);
    const dispatch = useDispatch();
    const [activeFields, setActiveFields] = useState(() => {
        let tempState = {};
        data?.ticket_fields?.forEach((single) => (tempState[single?.id] = false));
        return tempState;
      });
    const [formFieldValues, setFormFieldValues] = useState(() => {
        let tempState = {};
        data?.ticket_fields?.forEach((single) => (tempState[single?.id] = ''));
        return tempState;
      });
    const [formFieldTitles, setFormFieldTitles] = useState(() => {
        let tempState = {};
        data?.ticket_fields?.forEach((single) => (tempState[single?.id] = single?.title_in_portal));
        return tempState;
      });

    const [formFieldName, setFormFieldName] = useState(() => {
        let tempState = {};
        data?.ticket_fields?.forEach((single) => (tempState[single?.id] = ''));
        return tempState;
      });
      //console.log(activeFields);
    //console.log(data.ticket_fields);
    const filteredFields = data?.ticket_fields?.filter(
        (item) =>
          item.type.toLowerCase() === 'tagger'
          ||
          item.type.toLowerCase() === 'description'||
          item.type.toLowerCase() === 'subject'||
          item.type.toLowerCase() === 'text'||
          item.type.toLowerCase() === 'integer'
      );
    //console.log(filteredFields);
 
  const handleSubmit = () => {
    let message='';
    if(email.trim()==="")
    {
        alert('Email is required');
    }
    else{
    for (const key in formFieldValues) {
        if(formFieldValues[key]!=="")
        {
        
        message=message+`${formFieldTitles[key]}: ${formFieldName[key]}\n`
        //console.log(message);
        }
    }

    dispatch(
        creatingTicket(
          message,
          localStorage.getItem('ticketing_system'),
          email.trim(),
          '',
          'Leave Message',
          false,
          localStorage.getItem('org_id'),
          agent_type_info
        )
      );

      dispatch(renderDynamicForm(false));
}
  };

  const handleChange = (value,id,name) => {
    //console.log(value,id);
    //setDrop(event.target.value);
    setFormFieldValues((prev) => {
        let temp = { ...prev };
        temp[id] = value;
        return temp;
      })
      setFormFieldName((prev) => {
        let temp = { ...prev };
        temp[id] = name;
        return temp;
      })
      const parentChildFields = data?.ticket_forms[0]?.end_user_conditions?.filter(
        (item) =>
          item.value === formFieldValues[id]
      );
      //console.log('parent_child_fields', parentChildFields);
      // eslint-disable-next-line array-callback-return
      parentChildFields[0]?.child_fields.map((data, index) =>{
        //console.log(data?.id);
        activeFields[data?.id]=false
      });
    const childFields = data?.ticket_forms[0]?.end_user_conditions?.filter(
        (item) =>
          item.value === value
      );
      //console.log('child_fields', childFields);
      // eslint-disable-next-line array-callback-return
      childFields[0]?.child_fields.map((data, index) =>{
        //console.log(data?.id);
        activeFields[data?.id]=true
      });

      
        
    //setFormState();
  };

  return (
    <Wrapper>
      <Title>{'Leave Message'}</Title>
      <Subtitle>Please enter the details and submit</Subtitle>
      <InputLabel sx={{ fontWeight: 'bold' }}>Email address</InputLabel>
      <StyledTextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event)=>{ setEmail(event.target.value);}}
          />
      {filteredFields.map((data, index) =>
      data?.raw_title_in_portal === 'How can we help you today?' ?(
            <><InputLabel sx={{ fontWeight: 'bold' }}>{data.title_in_portal}</InputLabel>
              <Select
                  id={data.id}
                  value={formFieldValues[data.id]}
                  fullWidth
                  onChange={(event,action)=>{ handleChange(event.target.value,data.id, action?.props?.children)}}
              >
                  {data.custom_field_options.map((options, index) => <MenuItem value={options.value}>{options.name}</MenuItem>
                  )}
              </Select></>
        ):data?.type?.toLowerCase() === 'tagger' ?(
        <>{activeFields[data?.id] && <InputLabel sx={{ fontWeight: 'bold' }}>{data.title_in_portal}</InputLabel>}
       {activeFields[data?.id] &&  <Select id={data.id}
                      value={formFieldValues[data.id]}
                      fullWidth
                      onChange={(event, action)=>{ handleChange(event.target.value,data.id, action?.props?.children)}}
                  >
                      {data.custom_field_options.map((options, index) => <MenuItem value={options.value} name={options.name}>{options.name}</MenuItem>
                      )}
                  </Select>}</>
  )
  :data?.type?.toLowerCase() === 'text' ?(
    <>{activeFields[data?.id] && <InputLabel sx={{ fontWeight: 'bold' }}>{data.title_in_portal}</InputLabel>}
   {activeFields[data?.id] &&  <StyledTextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event)=>handleChange(event.target.value,data.id,event.target.value)}
          />}</>
)
:data?.type?.toLowerCase() === 'integer' ?(
    <>{activeFields[data?.id] && <InputLabel sx={{ fontWeight: 'bold' }}>{data.title_in_portal}</InputLabel>}
   {activeFields[data?.id] && <StyledTextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event)=>handleChange(event.target.value,data.id,event.target.value)}
          />}</>
):data?.type?.toLowerCase() === 'subject' ?(
        <><InputLabel sx={{ fontWeight: 'bold' }}>{data.title_in_portal}</InputLabel>
        <StyledTextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event)=>handleChange(event.target.value,data.id,event.target.value)}
          /></>
      ):
      data?.type?.toLowerCase() === 'description' ?(
        <><InputLabel sx={{ fontWeight: 'bold' }}>{data.title_in_portal}</InputLabel>
        <StyledTextField
            variant="outlined"
            size="small"
            fullWidth
            onChange={(event)=>handleChange(event.target.value,data.id,event.target.value)}
            
          /></>
      ): (
        <></>
      ))}
        <Button
          variant="contained"
          startIcon={<SendIcon />}
          sx={{
            textTransform: 'none',
            marginTop: '12px'
          }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
    </Wrapper>
  );
};

// Exports:
export default DynamicChatForm;
