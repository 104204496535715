// Packages:
import styled from 'styled-components';

// Exports:
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 2.438rem;

  @media (max-width: 1024px) {
    width: 98%;
  }
`;

export const Subtitle = styled.div`
  margin-bottom: 2.188rem;
  font-size: 16px;
  color: #333333;
`;

export const Form = styled.div`
  margin-bottom: 1rem;
  padding: 2.25rem 1.5rem 4rem;
  font-size: 16px;
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background: #ffffff;
  border-radius: 5px;
`;

export const InputField = styled.div`
  margin-bottom: 2rem;
`;

export const Tag = styled.div`
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  color: #828282;

  @media (max-width: 1024px) {
    font-size: 0.775rem;
  }

  @media (max-width: 520px) {
    font-size: 0.675rem;
  }
`;

export const InputArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 26.875rem;
  padding: 0.75rem 1rem;
  font-size: 1.25rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #e8f0fe;

  @media (max-width: 1024px) {
    font-size: 1.15rem;
    width: 25rem;
  }

  @media (max-width: 520px) {
    font-size: 1rem;
    width: 19rem;
  }
`;

export const Input = styled.input`
  width: calc(100% - 1rem);
  font-family: 'Inter', sans-serif;
  background-color: transparent;
  border: none;
  outline: none;

  @media (max-width: 520px) {
    font-size: 0.75rem;
  }
`;

export const Checkbox = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 2.5px;
  cursor: pointer;
`;
