// Packages:
import React, { memo, useEffect, useMemo, useState } from "react";
import useInput from "../../../../lib/hooks/use-input";
import {
  MenuItem,
  FormControl,
  Select,
  styled,
} from "@mui/material";
// Styles:
import { FieldName } from "../styles";
import uuid from "react-uuid";
import FormInputs from "./FormInputs";
import UploadJsonFile from "./UploadJsonFile";
import UserMessageCheckbox from "../../Components/UserMessageCheckbox";
import AutocompleteGlobal from "../../Components/AutocompleteGlobal";
import BaseConfiguration from "../BaseConfiguration";

const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const DEFAULT_OUTPUT_BASE_URL = {
  key: "sheet_baseurl",
  value: {
    fieldName: null,
    value: null,
  },
}
const DEFAULT_OUTPUT_SHEET_NAME = {
  key: "sheet_name",
  value: {
    fieldName: null,
    value: null,
  },
}

const changeName = (i) => {
  switch (i) {
    case OPERATION.CREATE_NEW_ENTRY:
      return "CREATE NEW ENTRY";
    case OPERATION.DELETE_ENTRY_FROM_SHEET:
      return "DELETE ENTRY FROM SHEET";
    case OPERATION.READ_FROM_SHEET:
      return "READ FROM SHEET";
    case OPERATION.UPDATE_ENTRY:
      return "UPDATE ENTRY";
  }
};

const OPERATION = {
  CREATE_NEW_ENTRY: "create",
  READ_FROM_SHEET: "read",
  DELETE_ENTRY_FROM_SHEET: "delete",
  UPDATE_ENTRY: "update",
};
// Functions:
const GoogleSheetsConfiguration = ({
  node,
  updateNode,
  deleteNode,
  isEditable,
  currentRemoteWorkflow,
  setNodes
}) => {
  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields,setOutputFields] = useState([])
  const inputData = useMemo(() => {
    const input = currentRemoteWorkflow?.meta?.settings?.input || [];
    const secrets = currentRemoteWorkflow?.meta?.settings?.secrets || [];
    return [
      ...input.map((val) => `${val.variableName}`),
      ...secrets.map((val) => `${val.key}`)
    ];
  }, [currentRemoteWorkflow]);
  const DEFAULT_OUTPUT = () => [
    {
      id: uuid(),
      // key: "",
      // value: "",
    },
  ];
  const {
    value: operation,
    setValue: setOperation,
    bind: bindOperation,
  } = useInput(node.operation ?? "");


  const [sheet_name,setSheetName]=useState(node.sheet_name??DEFAULT_OUTPUT_SHEET_NAME)
  const [ sheet_baseurl, setSheetBaseUrl] = useState(node.sheet_baseurl ??DEFAULT_OUTPUT_BASE_URL);
  const [expectResponse, setExpectResponse] = useState(node.expectResponse??false);
  const [data, setData] = useState(node.data ?? [DEFAULT_OUTPUT()]);
  const { value: responsesExpected, setValue: setResponsesExpected } = useInput(
    node.responsesExpected??0
  );
  const {
    value: credentials,
    setValue: setCredentials,
    bind: bindCredentials,
  } = useInput(node.credentials??null);

  const [expectFilterResponse, setExpectFliterResponse] = useState(
    node.expectFilterResponse ?? false
  );
  const [filter, setFilter] = useState(node.filter ?? [DEFAULT_OUTPUT()]);
  const {
    value: filterResponsesExpected,
    setValue: setFilterResponsesExpected,
  } = useInput(node.filterResponsesExpected??0);

  const [sendUserUpdate, setSendUserUpdate] = useState(node.sendUserUpdate??false);
  const [userMessage, setUserMessage] = useState(node.userMessage??"");
  // Effects:

  const updateOutput = (value) => {
    setData(value);
  };
  const updateFilter = (value) => {
    setFilter(value);
  };


  useEffect(() => {
    setOperation(node.operation??"")
    setSheetName(node.sheet_name??DEFAULT_OUTPUT_SHEET_NAME)
    setSheetBaseUrl(node.sheet_baseurl??DEFAULT_OUTPUT_BASE_URL)
    setCredentials(node.credentials??null)
    setFilterResponsesExpected(node.filterResponsesExpected??0)
    setExpectFliterResponse(node.expectFilterResponse??false)
    setResponsesExpected(node.responsesExpected??0)
    setExpectResponse(node.expectResponse??false)
    setData(node.data??[DEFAULT_OUTPUT])
    setFilter(node.filter??[DEFAULT_OUTPUT])
    setUserMessage(node.userMessage??"")
    setSendUserUpdate(node.sendUserUpdate??false)
  }, [ node.operation, node.sheet_name?.value?.fieldName,node.sheet_name?.value?.value,node.sheet_baseurl?.value?.fieldName,node.sheet_baseurl?.value?.value,node.credentials,node.filterResponsesExpected,node.expectFilterResponse,node.responsesExpected,node.expectResponse,node.data,node.filter,node.userMessage,node.sendUserUpdate])

  useEffect(() => {
    updateNode(node.id, {
      expectResponse,
      responsesExpected,
      data,
      expectFilterResponse,
      filterResponsesExpected,
      filter,
      operation,
      sheet_name,
      credentials,
      sheet_baseurl,
      userMessage,
      sendUserUpdate,
      dynamicFields:dynamicFields,
      outputFields:outputFields
    });
  }, [
    expectResponse,
    responsesExpected,
    data,
    expectFilterResponse,
    filterResponsesExpected,
    filter,
    operation,
    sheet_name?.value?.fieldName,
    sheet_name?.value?.value,
    credentials,
    sheet_baseurl?.value?.fieldName,,
    userMessage?.value?.value,
    sendUserUpdate,
    dynamicFields
  ]);


  return (
    <BaseConfiguration setDynamicFields={setDynamicFields} node={node} isEditable={isEditable} deleteNode={deleteNode} outputFields={outputFields} setOutputFields={setOutputFields} setNodes={setNodes}>
      <FieldName>Functionality</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="google-sheets-functionality-select"
          disabled={!isEditable}
          value={operation}
          {...bindOperation}
          onChange={(e) => setOperation(e.target.value)}
        >
          {Object.values(OPERATION).map((functionality, index) => (
            <StyledMenuItem key={index} value={functionality}>
              {changeName(functionality)}
            </StyledMenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <UploadJsonFile
        isEditable={isEditable}
        fileData={credentials}
        setFileData={setCredentials}
        bindData={bindCredentials}
      />
      <AutocompleteGlobal 
           Title="BASE URL"
           dynamicFields={inputData}
           data={sheet_baseurl} 
           setData={setSheetBaseUrl}
           DEFAULT_OUTPUT={DEFAULT_OUTPUT_BASE_URL}
           isEditable={isEditable}
      />
       <AutocompleteGlobal 
           Title="SHEET NAME"
           dynamicFields={inputData}
           data={sheet_name} 
           setData={setSheetName}
           DEFAULT_OUTPUT={DEFAULT_OUTPUT_SHEET_NAME}
           isEditable={isEditable}
      />
      {(operation == OPERATION.UPDATE_ENTRY ||
        operation === OPERATION.CREATE_NEW_ENTRY) && (
        <>
          <FieldName>Enter Data</FieldName>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0.75rem",
            }}
          >
            <input
              type="checkbox"
              disabled={!isEditable}
              style={{ marginRight: "0.35rem" }}
              checked={expectResponse}
              onChange={(e) => {
                if (!e.target.checked && expectResponse) {
                  setData([]);
                  setResponsesExpected(0);
                }
                setExpectResponse(e.target.checked);
              }}
            />
            <div style={{ fontSize: "0.8rem" }}>Enter columns and Data:</div>
            <input
              type="number"
              min="0"
              disabled={!expectResponse||!isEditable}
              style={{
                width: "3rem",
                marginLeft: "0.25rem",
              }}
              value={responsesExpected}
              onChange={(event) => {
                event.preventDefault();
                event.stopPropagation();
                const newDataLength = parseInt(event.currentTarget.value);
                setResponsesExpected(newDataLength);
                const currentDataLength = data.length;
                if (newDataLength === currentDataLength + 1)
                  setData([...data, []]);
                if (newDataLength === currentDataLength - 1)
                  setData([...data].slice(0, -1));
              }}
            />
          </div>
          {responsesExpected > 0 &&
            Object.values(data).map((output, i) => (
              <FormInputs
                key={output.id}
                outputs={data}
                isEditable={isEditable}
                output={output}
                i={i}
                updateOutput={updateOutput}
                dynamicFields={dynamicFields}
              />
            ))}{" "}
        </>
      )}
      {operation != OPERATION.CREATE_NEW_ENTRY && (
        <>
          <FieldName>Enter Filter</FieldName>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0.75rem",
            }}
          >
            <input
              type="checkbox"
              disabled={!isEditable}
              style={{ marginRight: "0.35rem" }}
              checked={expectFilterResponse}
              onChange={(e) => {
                if (!e.target.checked && expectFilterResponse) {
                  setFilter([]);
                  setFilterResponsesExpected(0);
                }
                setExpectFliterResponse(e.target.checked);
              }}
            />
            <div style={{ fontSize: "0.8rem" }}>Enter columns and Data:</div>
            <input
              type="number"
              min="0"
              disabled={!expectFilterResponse||!isEditable}
              style={{
                width: "3rem",
                marginLeft: "0.25rem",
              }}
              value={filterResponsesExpected}
              onChange={(event) => {
                event.preventDefault();
                event.stopPropagation();
                const newDataLength = parseInt(event.currentTarget.value);
                setFilterResponsesExpected(newDataLength);
                const currentDataLength = filter.length;
                if (newDataLength === currentDataLength + 1)
                  setFilter([...filter, []]);
                if (newDataLength === currentDataLength - 1)
                  setFilter([...filter].slice(0, -1));
              }}
            />
          </div>

          {filterResponsesExpected > 0 &&
            Object.values(filter).map((output, i) => (
              <FormInputs
                key={output.id}
                outputs={filter}
                isEditable={isEditable}
                output={output}
                i={i}
                updateOutput={updateFilter}
                dynamicFields={dynamicFields}
              />
            ))}
        </>
      )}

      <UserMessageCheckbox sendUserUpdate={sendUserUpdate} setSendUserUpdate={setSendUserUpdate} setUserMessage={setUserMessage} userMessage={userMessage} isEditable={isEditable}/>
    </BaseConfiguration>
  );
};

// Exports:
export default memo(GoogleSheetsConfiguration)