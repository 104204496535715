const createQuestionsMetaArrays = (articles, articleDialogId) => {
  if (articleDialogId) {
    const questions = articles?.map((article) => {
      if (article?.dialog_id) {
        if (article.dialog_id !== articleDialogId) {
          return article?.display_name || article?.question || '';
        }
      } else {
        if (article.name !== articleDialogId) {
          return article?.display_name || article?.question || '';
        }
      }
    });
    const metas = articles?.map((article) => {
      let returnValue;
      if (article?.dialog_id) {
        if (article.dialog_id !== articleDialogId) {
          returnValue = article?.meta;
        }
      } else {
        if (article.name !== articleDialogId) {
          returnValue = article?.meta;
        }
      }
      return returnValue;
    });

    return [
      questions.filter((ques) => ques !== undefined),
      metas.filter((meta) => meta !== undefined)
    ];
  } else {
    const questions = articles?.map((article) => {
      return article?.question || '';
    });
    const metas = articles?.map((article) => {
      return article?.meta;
    });

    return [questions, metas];
  }
};

export default createQuestionsMetaArrays;
