// Packages:
import React, { useEffect, useState,memo } from 'react';
import { Route, Routes } from 'react-router-dom';

// Constants:
import ROUTES from './constants/routes';

// Components:
import {
  Login,
  ForgotPassword,
  Landing,
  Chat,
  KnowledgeArticle,
  KnowledgeArticleV2,
  Studio,
  Performance,
  FAQ,
  Info,
  InfoV3,
  Agents,
  PageNotFound,
  SecurityCredentials,
  FAQV2,
  StudioV2,
  InfoV2
} from './pages';
import RequireAuth from './components/RequireAuth';

// Functions:
const AllRoutes = ({ isAuthenticated, user, setAuthStatus, changeUser }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    setUserInfo(user);
  }, [user]);

  return (
    <Routes>
      {/* <Route path={ROUTES.INDEX} element={<Landing />} /> */}
      <Route path={ROUTES.INDEX} element={<Chat />} />
      <Route path={ROUTES.KNOWLEDGE_ARTICLE} element={<KnowledgeArticle />} />
      <Route path={ROUTES.KNOWLEDGE_ARTICLE_V2} element={<KnowledgeArticleV2 />} />
      <Route path={ROUTES.NEED_HELP} element={<KnowledgeArticle />} />
      <Route path={ROUTES.NEED_HELP_V2} element={<KnowledgeArticleV2 />} />
      <Route
            path={ROUTES.AGENT.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route path={ROUTES.AGENT.LOGIN} element={<Login />} />
          <Route path={ROUTES.ADMIN.ACCOUNTS} element={<RequireAuth isAuthenticated={isLoggedIn}><Agents /></RequireAuth>} />
          <Route path={ROUTES.AGENT.AI_AGENT} element={<RequireAuth isAuthenticated={isLoggedIn}><Chat /></RequireAuth>} />
          <Route path={ROUTES.AGENT.DATA} element={<RequireAuth isAuthenticated={isLoggedIn}><Info /></RequireAuth>} />
          <Route path={ROUTES.AGENT.DASHBOARD} element={<RequireAuth isAuthenticated={isLoggedIn}><InfoV3 /></RequireAuth>} />
          <Route path={ROUTES.AGENT.DATA_V2} element={<RequireAuth isAuthenticated={isLoggedIn}><InfoV2 /></RequireAuth>} />
          <Route path={ROUTES.AGENT.KNOWLEDGE} element={<RequireAuth isAuthenticated={isLoggedIn}><FAQ /></RequireAuth>} exact />
          <Route path={ROUTES.AGENT.KNOWLEDGE_V2} element={<RequireAuth isAuthenticated={isLoggedIn}><FAQV2 /></RequireAuth>} exact />
          <Route path={ROUTES.AGENT.WORKFLOWS} element={<RequireAuth isAuthenticated={isLoggedIn}><Studio /></RequireAuth>} />
          <Route path={ROUTES.AGENT.WORKFLOWS_V2} element={<RequireAuth isAuthenticated={isLoggedIn}><StudioV2 /></RequireAuth>} />
          <Route path={ROUTES.AGENT.PERFORMANCE} element={<RequireAuth isAuthenticated={isLoggedIn}><Performance /></RequireAuth>} />
          <Route path={ROUTES.AGENT.SECURITY_CREDENTIALS} element={<RequireAuth isAuthenticated={isLoggedIn}><SecurityCredentials /></RequireAuth>} />
          <Route path={ROUTES.CUSTOMER_SERVICE} element={<Chat />} />
          <Route path={ROUTES.AGENT.LOGIN} element={<Login />} />
          <Route
            path={ROUTES.AGENT.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
      <Route path={ROUTES.NOT_FOUND} element={<PageNotFound />} />
      <Route path={'*'} element={<PageNotFound />} />
    </Routes>
  );
}

// Exports:
export default memo(AllRoutes);
