// Packages:
import React from 'react'
import { useSelector } from 'react-redux'


// Constants:
import { NODE_TYPE } from '../../../constants/studio'


// Components:
import APIConfiguration from './APIConfiguration'
import MessageConfiguration from './MessageConfiguration'
import FormConfiguration from './FormConfiguration'
import EmailConfiguration from './EmailConfiguration'
import TicketConfiguration from './TicketConfiguration'
import DecisionConfiguration from './DecisionConfiguration'
import ShopifyConfiguration from './ShopifyConfiguration'
import GoogleSheetsConfiguration from './GoogleSheetsConfiguration'
import ShippingConfiguration from './ShippingConfiguration'
import StartConfiguration from './StartConfiguration'
import EndConfiguration from './EndConfiguration'

// Styles:
import {
  Wrapper,
  Title
} from './styles'


// Functions:
const Configuration = ({ updateNode, deleteNode, settings }) => {
  // State:
  const studio = useSelector(state => state.studio)  

  const switchConfiguration = (node) => {
    switch(node?.type) {
      case NODE_TYPE.start:
        return <StartConfiguration node={ node } updateNode={ updateNode } deleteNode={ deleteNode } />
      case NODE_TYPE.api:
        return <APIConfiguration node={ node } updateNode={ updateNode } deleteNode={ deleteNode } />
      case NODE_TYPE.message:
        return <MessageConfiguration node={ node } updateNode={ updateNode } deleteNode={ deleteNode } />
      case NODE_TYPE.form:
        return <FormConfiguration node={ node } updateNode={ updateNode } deleteNode={ deleteNode } />
      case NODE_TYPE.email:
        return <EmailConfiguration node={ node } updateNode={ updateNode } deleteNode={ deleteNode } />
      case NODE_TYPE.ticket:
        return <TicketConfiguration node={ node } updateNode={ updateNode } deleteNode={ deleteNode } settings={ settings } />
      case NODE_TYPE.shopify:
        return <ShopifyConfiguration node={ node } updateNode={ updateNode } deleteNode={ deleteNode } />
      case NODE_TYPE.decision:
        return <DecisionConfiguration node={ node } updateNode={ updateNode } deleteNode={ deleteNode } />
      case NODE_TYPE.google_sheets:
        return <GoogleSheetsConfiguration node={ node } updateNode={ updateNode } deleteNode={ deleteNode } />
      case NODE_TYPE.shipping:
        return <ShippingConfiguration node={ node } updateNode={ updateNode } deleteNode={ deleteNode } />
      case NODE_TYPE.end:
        return <EndConfiguration node={ node } deleteNode={ deleteNode } />
        default:
        return <span style={{ padding:'0.5rem' }}>Select a node to get started</span>
    }
  }

  // Return:
  return (
    <Wrapper>
      <Title>Configuration</Title>
      <div style={{ textAlign: 'left', padding: '0.5rem' }}>
        { switchConfiguration(studio.selectedNode) }
      </div>
    </Wrapper>
  )
}


// Exports:
export default Configuration
