// Packages:
import React from 'react';

import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import UserChart from './UserChart'
import styled from 'styled-components';
// Imports:
import {
  Wrapper,
  Info,
  InfoTitle, 
} from './styles';

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; /* Adjust as needed */
`;

const ChartWrapper = styled.div`
  height: 300px; /* Set a fixed height for the chart */
  width: 100%;
  white-space: nowrap; /* Prevent labels from wrapping */
`;

const CircularPage = (props) => {
  const isContentLoading = useSelector((state) => state.chat.loading);

  return (
    <Wrapper>
      {isContentLoading ? (
        <CircularProgress />
      ) : (
        <Info>
          <ChartContainer>
            <InfoTitle sx={{ marginBottom: "1rem", textAlign: "left" }}>
              Queries by Topic
            </InfoTitle>
            <ChartWrapper>
              <UserChart queryType="intent" persona = {props.persona} startDate = {props.startDate} endDate = {props.endDate} topicFilters={props.topicFilters} profile={props.profile} />
            </ChartWrapper>
          </ChartContainer>
          <ChartContainer>
            <InfoTitle sx={{ textAlign: "left" }}>Queries by Sentiment</InfoTitle>
            <ChartWrapper>
              <UserChart queryType="sentiment"  persona = {props.persona} startDate = {props.startDate} endDate = {props.endDate} topicFilters={props.topicFilters} profile={props.profile}/>
            </ChartWrapper>
          </ChartContainer>
        </Info>
      )}
    </Wrapper>
  );
};

// Exports:
export default CircularPage;