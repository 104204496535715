// Packages:
import React, { useCallback, useContext, useState } from 'react';
import { Snackbar, Alert, AlertTitle, Slide, Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import MessageIcon from '@mui/icons-material/Message';
import { SOCKET_URL } from '../../constants/Endpoint';
import useWebSocket from 'react-use-websocket';
import { WebSocketConnectionContext } from '../../App';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../constants/routes';
import { clearChat } from '../../redux/actions/chatActions';

// Styles:
const TransitionUp = (props) => {
  return <Slide {...props} direction="down" />;
};

// Functions:
const NewMessageAlert = () => {
  // State:
  const [alert, setAlert] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const orgid = useSelector((state) => state.content.org_info?.org_data?._id);
  const user_details = useSelector((state) => state.auth.userDetails);
  const messageReceived = useSelector((state) => state.chat.incomingMessage);
  const { openConnection, changeConnection } = useContext(
    WebSocketConnectionContext
  );

  const { sendJsonMessage, getWebSocket, lastJsonMessage } = useWebSocket(
    SOCKET_URL,
    {
      share: true,
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
      onMessage: (event) => processMessages(event)
    },
    openConnection
  );

  const processMessages = (event) => {
    const response = JSON.parse(event.data);
    // console.log('printing in alert', response, 'lastJSONmsg', lastJsonMessage);
    if (
      response?.pushNotification?.includes(
        'You have new messages from a customer!'
      )
    ) {
      setAlert(true);
    }
    if (
      response?.pushNotification?.includes(
        'You have new messages from a customer!'
      ) &&
      lastJsonMessage?.pushNotification?.includes(
        'You have new messages from a customer!'
      ) &&
      response?.customerConnectionId === lastJsonMessage?.customerConnectionId
    ) {
      setAlert(false);
    }
  };

  // Event Handlers:
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert(false);
  };

  const handleAccept = useCallback(() => {
    // console.log('acceptRequest', openConnection);
    const acceptChat = {
      action: 'createChat',
      orgid,
      customerConnectionId: messageReceived?.customerConnectionId,
      agentEmail: user_details?.['email']
    };
    sendJsonMessage(acceptChat);
    dispatch(clearChat([]));
    setAlert(false);
    navigate(ROUTES.AGENT.AI_AGENT);
  }, [messageReceived?.customerConnectionId, orgid, sendJsonMessage]);

  // Return:
  return (
    <Snackbar
      open={alert}
      autoHideDuration={30000}
      onClose={handleAlertClose}
      TransitionComponent={TransitionUp}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        icon={<MessageIcon sx={{ color: '#333333' }} />}
        elevation={3}
        onClose={handleAlertClose}
        severity="success"
        sx={{ backgroundColor: '#FFFFFF', color: '#333333' }}
      >
        <AlertTitle sx={{ mb: '10px' }}>New Message</AlertTitle>
        <span>You have new messages from a customer!</span>
        <Button
          variant="contained"
          color="error"
          size="small"
          startIcon={<CancelIcon />}
          onClick={handleAlertClose}
          sx={{
            textTransform: 'none',
            marginLeft: '18px',
            marginRight: '8px',
            fontWeight: '500'
          }}
        >
          Ignore
        </Button>
        <Button
          variant="contained"
          size="small"
          startIcon={<SendIcon />}
          onClick={handleAccept}
          sx={{ textTransform: 'none' }}
        >
          Accept
        </Button>
      </Alert>
    </Snackbar>
  );
};

// Exports:
export default NewMessageAlert;
