import { articleOperations } from '../../api';
import {
  REJECT_DRAFT_OPERATION,
  PUBLISH_DRAFT_OPERATION
} from '../../constants/articles';

export async function rejectDraft(article, user) {
  await articleOperations(REJECT_DRAFT_OPERATION, article, user);
}

export async function publishDraft(article, user) {
  const response = await articleOperations(PUBLISH_DRAFT_OPERATION, article, user);
  return response?.data;
}