// Packages:
import React, { useState } from 'react'


// Imports:
import LOGO from '../../assets/logo'


// Styles:
import {
  Wrapper,
  Title,
  Logo
} from './styles'


// Functions:
// TODO: The reason we make a div behave like an a tag is because there is
// global styling attached to a. In the future, it needs to be removed and
// assigned to a class instead.

const Footer = () => {
  const [isScrolling, setIsScrolling] = useState(false)
  
  return (
    <div 
      onClick={
        () => {
          let a = document.createElement('a')
          a.target= '_blank'
          a.href= 'https://www.readyly.com'
          a.click()
        }
      }
      onScroll={() => setIsScrolling(true)}
    >
      <Wrapper><Title isScrolling={isScrolling}>Powered by</Title> <Logo src={ LOGO } /></Wrapper>
    </div>
  )
}


// Exports:
export default Footer
