// Packages:
import React, { useState } from 'react';
import MultipleChoice from '../MultipleChoice';
import Dropdown from '../Dropdown';
import {
    TextField,
    Checkbox,
    Tooltip,
    IconButton,
    FormControl,
    Select,
    MenuItem,
    Button,
    styled
  } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import uuid from 'react-uuid';


// Styles:
const StyledTextField = styled(TextField)`
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
  & .MuiInput-input {
    font-size: 14px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  max-height: 36px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledFormControl = styled(FormControl)`
  & .MuiFormLabel-root {
    font-size: 14px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 14px;
  }
`;


// Functions:
const Checkboxes = ({option, handleDeleteOption}) => {
  // State:
  const [ optionValue, setOptionValue ] = useState(option?.option)
  const DEFAULT_INPUT = () => ({ id: uuid(), question: 'Untitled Sub-question', type: 'Short answer' })
  const DEFAULT_OPTION = () => ({ id: uuid(), option: 'Option' })
  const [ inputs, setInputs ] = useState([])
  const [ options, setOptions ] = useState([{ id: uuid(), option: 'Option' }])
  const INPUT_TYPE = {
    MULTIPLE_CHOICE: 'Multiple choice',
    DROPDOWN: 'Drop-down',
    CHECKBOXES: 'Checkboxes',
    SHORT_ANSWER: 'Short answer',
    PARAGRAPH: 'Paragraph'
  };

  const handleDeleteSubOption = () => setOptions([ ...options ].slice(0, -1));

  // Return:
  return (
      <>
      <div style={{ display: 'flex', gap: '5px', marginBottom: '5px', alignItems: 'center', alignItems: 'center' }}>
          <Checkbox disabled size="small" />
          <StyledTextField 
              variant="standard" 
              sx={{ width: '100%' }}
              placeholder="Option"
              value={optionValue}
              onChange={ (e) => setOptionValue(e.target.value) }
          />
          <Tooltip title="Add sub-question">
            <IconButton onClick={ () => setInputs([ ...inputs, DEFAULT_INPUT() ]) }>
              <AddOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete option">
            <IconButton onClick={handleDeleteOption}>
              <ClearOutlinedIcon />
            </IconButton>
          </Tooltip>
      </div>
      { inputs.map((input, i) => (
        <>
        <div key={i} style={{ display: 'flex', gap: '10px', marginBottom: '1rem', paddingLeft: '5px' }}>
        <StyledTextField
            variant="outlined"
            placeholder="Sub-question"
            value={input.question}
            onChange={ event => {
                event.preventDefault()
                event.stopPropagation()
                const newInputs = [ ...inputs ]
                newInputs[i] = { ...input, question: event.currentTarget.value }
                setInputs(newInputs)
              }}
            size="small"
            fullWidth
        />
        <StyledFormControl key={i} fullWidth>
            <Select
                size="small"
                value={input.type}
                onChange={ event => {
                    event.preventDefault()
                    event.stopPropagation()
                    const newInputs = [ ...inputs ]
                    newInputs[i] = { ...input, type: event.target.value }
                    setInputs(newInputs)
                  }}
            >
                {Object.values(INPUT_TYPE).map((type, index) => (
                    <StyledMenuItem key={index} value={type}>
                        {type}
                    </StyledMenuItem>
                ))}
            </Select>
        </StyledFormControl>
        <Tooltip title="Delete sub-question">
            <IconButton onClick={ () => setInputs([ ...inputs ].slice(0, -1)) }>
              <ClearOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div style={{ marginBottom: '1rem', paddingLeft: '5px' }}>
          { input.type === "Short answer" && (
            <StyledTextField
              disabled
              variant="outlined"
              value="Short-answer text"
              fullWidth
              size="small"
            />
          ) 
        }
        { input.type === "Paragraph" && (
            <StyledTextField
              disabled
              variant="outlined"
              value="Long-answer text"
              fullWidth
              size="small"
            />
          ) 
        }
        { input.type === "Checkboxes" && ( 
          <>
            {options.map((option, i) => 
              <Checkboxes key={i} option={option} handleDeleteOption={handleDeleteSubOption} />
            )}
            <Button 
              startIcon={<AddOutlinedIcon />} 
              sx={{ textTransform: 'none', mb: '10px' }}
              onClick={() => setOptions([ ...options, DEFAULT_OPTION() ])}
            >
              Add option
            </Button>
          </>
          ) 
        }
        { input.type === "Multiple choice" && ( 
          <>
            {options.map((option, i) => 
              <MultipleChoice key={i} option={option} handleDeleteOption={handleDeleteSubOption} />
            )}
            <Button 
              startIcon={<AddOutlinedIcon />} 
              sx={{ textTransform: 'none', mb: '10px' }}
              onClick={() => setOptions([ ...options, DEFAULT_OPTION() ])}
            >
              Add option
            </Button>
          </>
          ) 
        }
        { input.type === "Drop-down" && ( 
          <>
            {options.map((option, i) => 
              <Dropdown key={i} option={option} handleDeleteOption={handleDeleteSubOption} />
            )}
            <Button 
              startIcon={<AddOutlinedIcon />} 
              sx={{ textTransform: 'none', mb: '10px' }}
              onClick={() => setOptions([ ...options, DEFAULT_OPTION() ])}
            >
              Add option
            </Button>
          </>
          ) 
        }
        </div>
        </>
      ))   
      }
      </>
  );
};

// Exports:
export default Checkboxes;
