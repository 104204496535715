// Packages:
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import sanitizeHtml from 'sanitize-html';
import { IconButton, Chip } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import Tooltip from '@mui/material/Tooltip';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import VerifiedIcon from '@mui/icons-material/Verified';
import DraftsIcon from '@mui/icons-material/Drafts';
import PublicIcon from '@mui/icons-material/Public';
import SecurityIcon from '@mui/icons-material/Security';
import ArchiveIcon from '@mui/icons-material/Archive';
import RateReviewIcon from '@mui/icons-material/RateReview';

// Constants:
import {
  ARTICLE_IS_DRAFT,
  ARTICLE_IS_UNDER_REVIEW,
  ARTICLE_IS_PUBLISHED,
  ACCESS_IS_PUBLIC,
  ACCESS_IS_PRIVATE
} from '../../../constants/articles';

// Styles:
import {
  Wrapper,
  Body,
  Actions,
  FeedbackMessage,
  Header,
  Badge,
  StyledLink,
  ExportLogsLink
} from './styles';

import ROUTES from '../../../constants/routes';
import { getContentActions } from '../../../redux/actions/contentActions';
import { urlify } from '../../../lib/urlify';
import { downloadArticleLogs, saveLogs } from '../../../lib/downloadEntityLogs';

const StatusBadge = ({ status, isArchived }) => {
  if (status === ARTICLE_IS_PUBLISHED) {
    return (
      <Chip
        icon={isArchived ? <ArchiveIcon /> : <VerifiedIcon />}
        label={isArchived ? 'Archived' : 'Published'}
        color={isArchived ? "warning" : "success"}
        sx={{ ml: '0.2rem', fontSize: '12px' }}
        size="small" />
    );
  }
  if (status === ARTICLE_IS_DRAFT || status === ARTICLE_IS_UNDER_REVIEW) {
    const isDraft = status === ARTICLE_IS_DRAFT;
    return (
      <Chip
        icon={isDraft ? <DraftsIcon /> : <RateReviewIcon />}
        label={isDraft ? 'In draft' : 'In Review'}
        sx={{ ml: '0.2rem', fontSize: '12px' }}
        size="small" />
    );
  }
  return null;
};

const AccessBadge = ({ access }) => {
  if (access !== ACCESS_IS_PRIVATE && access !== ACCESS_IS_PUBLIC) {
    return null;
  }
  const isPublic = access === ACCESS_IS_PUBLIC;
  return (
    <Chip
      icon={isPublic ? <PublicIcon /> : <SecurityIcon />}
      label={isPublic ? "Public" : "Private"}
      color={isPublic ? "primary" : "error"}
      sx={{ ml: '0.2rem', fontSize: '12px' }}
      size="small" />
  );
};

// Functions:
const FAQResult = ({ article, title, deleteArticle }) => {
  // State:
  const query = useSelector((state) => state.content.query);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const agentType = useSelector((state) => state.content.agent_type);
  const orgid = useSelector((state) => state.content.org_info?.org_data?._id);
  const [likeStatus, setLikeStatus] = useState(0); // TS: <-1 | 0 | 1>
  const articleLogsRef = useRef(null);

  const dispatch = useDispatch();
  const contentActions = getContentActions(dispatch);

  const addReaction = async (type) => {
    try {
      const data = {
        orgid,
        user_email: userDetails?.email || 'anonymous',
        type,
        subtype: 'article',
        article_id: article.articleId,
        title,
        query
      };
      await contentActions.reactOnArticle(data);
    } catch (error) {
      console.error(error);
    }
  };

  const exportLogs = async () => {
    if (articleLogsRef.current) {
      saveLogs('article', article?.meta?.articleId, articleLogsRef.current);
      return;
    }
    const response = await downloadArticleLogs(orgid, {
      articleId: article?.articleId ?? "NA",
      version: article?.meta?.version
    }, { user: userDetails?.email, role: agentType });
    if (response) {
      articleLogsRef.current = response;
    }
  };

  const queryParams = {
    articleId: article?.articleId,
    status: article?.meta?.status ?? 'NA',
  };
  switch(article?.meta?.status) {
    case ARTICLE_IS_PUBLISHED:
      queryParams.version = article?.meta?.version ?? 'NA';
      break;
    case ARTICLE_IS_UNDER_REVIEW:
      queryParams.createdBy = article?.meta?.createdBy ?? 'NA';
      break;
    default: // for drafts, user === createdBy
      break;
  }
  const queryString = Object.keys(queryParams)
    .map((key) => key + '=' +queryParams[key])
    .join('&');

  // Return:
  return (
    <Wrapper>
      <div style={{ width: '100%' }}>
        <Header>
          <StyledLink
            to={`${ROUTES.KNOWLEDGE_ARTICLE_V2}?${queryString}`}
            state={{ initialArticleData: article, isNew: false, isEdit: false }}
            key={article.id}
            showaslink={true.toString()}
          >
            {title}
          </StyledLink>
          {article?.meta?.status === ARTICLE_IS_PUBLISHED &&
            <Badge>
              {' '}
              <Chip
                label={`v${article?.meta?.version ?? '0.1'}`}
                sx={{ ml: '0.2rem', fontSize: '12px' }}
                size="small" />
            </Badge>}
          <Badge>
            {' '}
            <StatusBadge status={article?.meta?.status} isArchived={article?.meta?.isArchived} />
          </Badge>
          <Badge>
            {' '}
            <AccessBadge access={article?.meta?.access} />
          </Badge>
        </Header>
        <Body>
          {
            <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
              {sanitizeHtml(
                urlify(article?.meta?.firstLine?.replace(/(?:\\n)/g, ' ')),
                {
                  allowedTags: [],
                  allowedAttributes: {}
                }
              ) || (<br/>)}
            </div>
          }
          <Actions>
            <div>
              <Tooltip title="Delete Article">
                <IconButton
                  sx={{ color: '#C71D1A' }}
                  onClick={() => deleteArticle(article)}
                >
                  <DeleteOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
            <span
              style={{
                marginLeft: '1rem',
                fontSize: '0.9rem',
                marginRight: '0.5rem'
              }}
            >
              Was this helpful?
            </span>
            <div>
              {likeStatus === 1 ? (
                <Tooltip title="Undo">
                  <IconButton
                    sx={{ color: '#109648' }}
                    onClick={() => setLikeStatus(0)}
                  >
                    <ThumbUpIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Like">
                  <IconButton
                    onClick={() => {
                      addReaction('like');
                      setLikeStatus(1);
                    }}
                  >
                    <ThumbUpOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {likeStatus === -1 ? (
                <Tooltip title="Undo">
                  <IconButton
                    sx={{ color: '#ED474A' }}
                    onClick={() => setLikeStatus(0)}
                  >
                    <ThumbDownIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Dislike">
                  <IconButton
                    onClick={() => {
                      addReaction('dislike');
                      setLikeStatus(-1);
                    }}
                  >
                    <ThumbDownOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div style={{ marginLeft: '1rem' }}>
              <FeedbackMessage
                style={{
                  color: likeStatus === 1 ? '#109648' : 'inherit',
                  fontSize: '0.9rem'
                }}
              >
                {likeStatus === 1 ? (
                  'Glad it helped!'
                ) : likeStatus === -1 ? (
                  <>
                    Sorry... Talk to
                    <Link
                      to={ROUTES.CUSTOMER_SERVICE}
                      style={{ marginLeft: '0.25rem' }}
                    >
                      customer service
                    </Link>
                  </>
                ) : (
                  ''
                )}
              </FeedbackMessage>
            </div>
            {article?.meta?.status === ARTICLE_IS_PUBLISHED && (
              <div style={{ marginLeft: 'auto' }}>
                <ExportLogsLink onClick={exportLogs}>Changelog</ExportLogsLink>
              </div>)}
          </Actions>
        </Body>
      </div>
    </Wrapper>
  );
};

// Exports:
export default FAQResult;
