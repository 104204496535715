// Exports:
export const NODE_TYPE = Object.freeze({
  start:'start',
  leaf: 'leaf',
  message: 'message',
  form: 'form',
  api: 'api',
  email: 'email',
  ticket: 'ticket',
  shopify: 'shopify',
  decision: 'decision',
  google_sheets: 'google_sheets',
  shipping: 'shipping',
  end: 'end'
})

export const EDGE_TYPE = {
  custom: 'custom',
}

export const WORKFLOW_STAGES = Object.freeze({
  DRAFT: 'DRAFT',
  UP_FOR_REVIEW: 'UP_FOR_REVIEW',
  REVIEWED_BUT_NEEDS_CHANGES: 'REVIEWED_BUT_NEEDS_CHANGES',
  PUBLISHED: 'PUBLISHED'
})
