// Packages:
import React, { useCallback, useRef, useState } from 'react';
import { 
  MenuItem,
  FormControl,
  Select,
  TextField,
  InputLabel,
  ListItemText,
  Checkbox,
  OutlinedInput,
  styled,
  Button
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getChatActions } from '../../../redux/actions/chatActions';

// Styles:
import { Wrapper, FiltersTitle } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getContentActions } from '../../../redux/actions/contentActions';
import moment from 'moment';
import useFilterHook from '../../../hooks/useFilterHook';
import { REQUEST_EVENTS_TYPE, REQUEST_GAP_TYPE, REQUEST_SESSION_TYPE } from '../../../constants/requestType';

const StyledFormControl = styled(FormControl)`
  margin-top: 1rem;
  & .MuiFormLabel-root {
    font-size: 14px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 14px;
  }
  & .MuiFormGroup-root {
    margin-left: 1rem;
    font-size: 14px;
    & .MuiTypography-root {
      font-size: 14px;
    }
  }
  &. MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: red;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledDesktopDatePicker = styled(DesktopDatePicker)`
  &. MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 14px;
  }
  & .MuiFormControl-root {
    margin-top: 1rem;
  }
`;


// Constants:
const ratings = ['1 - Horrible', '2 - Poor', '3 - Neutral', '4 - Good', '5 - Excellent'];
const conversationTypes = ['AI', 'Agent', 'Agent Offline', 'Leave Message'];
const types = ['like', 'dislike', 'mishit'];


// Functions:
const Filters = (props) => {
  const { handleTypeChange, handleProfileChange, handleRatingChange } = props;
  const profiles = useSelector(state => state.content.profiles);

  const dispatch = useDispatch();
  const chatActions = getChatActions(dispatch)
  const contentActions = getContentActions(dispatch);
  const { handleStartDateChange, handleEndDateChange, handleDateRangeChange, applyFilterFuncExecution, applyFilterDisable } = useFilterHook(props)
  
  const handleApplyFilter = () =>{
    // if date doesnot changes it should not fetch records even when click on apply filter
    applyFilterFuncExecution(() => {
      if(props.tab === "records") chatActions.fetchChatRecordsV3(props.orgId, props.startDate, props.endDate, REQUEST_SESSION_TYPE);
      else if(props.tab === "feedback") chatActions.fetchChatRecordsV3(props.orgId, props.startDate, props.endDate, REQUEST_SESSION_TYPE);
      else if(props.tab === "gaps") contentActions.fetchReactionsV2(props.orgId, props.startDate, props.endDate, REQUEST_GAP_TYPE);
      else if(props.tab === "tracking") contentActions.fetchReactionsV2(props.orgId, props.startDate, props.endDate, REQUEST_EVENTS_TYPE);
    });
  };
  // Return:
  return (
    <Wrapper>
      <FiltersTitle>Filters</FiltersTitle>
      <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div style={{ marginTop: '2rem' }}>
            <StyledDesktopDatePicker
              variant="inline"
              label="Start Date"
              value={props.startDate}
              onChange={handleStartDateChange}
              maxDate={props.endDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div style={{ marginTop: '1rem' }}>
            <StyledDesktopDatePicker
              label="End Date"
              value={props.endDate}
              onChange={handleEndDateChange}
              minDate={props.startDate}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </LocalizationProvider>

        <StyledFormControl fullWidth>
          <InputLabel id="date-range">Date Range</InputLabel>
          <Select
            autoWidth={false}
            id="date-range"
            value={props.dateRange}
            onChange={(event) => handleDateRangeChange(event)}
            label="Date Range"
            sx={{ fontSize: 14 }}
          >
            <StyledMenuItem value="custom" key="custom">Custom</StyledMenuItem>
            <StyledMenuItem value="this month" key="this month">1 Month</StyledMenuItem>
            <StyledMenuItem value="last 2 weeks" key="last 2 weeks">2 Weeks</StyledMenuItem>
            <StyledMenuItem value="this week" key="this week">7 Days</StyledMenuItem>
            <StyledMenuItem value="yesterday" key="yesterday">Yesterday</StyledMenuItem>
            <StyledMenuItem value="today" key="today">Today</StyledMenuItem>
          </Select>
        </StyledFormControl>

        {props.tab === "records" && (<StyledFormControl fullWidth>
          <InputLabel>Profile</InputLabel>
          <Select
            multiple
            autoWidth={false}
            value={props.profile}
            onChange={handleProfileChange}
            input={<OutlinedInput label="Profile" />}
            renderValue={(selected) => selected.join(', ')}
          >
            <StyledMenuItem value={"All"}>
              <Checkbox checked={props?.profile?.indexOf("All") > -1} />
              <ListItemText primary={"All"} />
            </StyledMenuItem>
            {profiles.map((name) => (
              <StyledMenuItem key={name} value={name}>
                <Checkbox checked={props?.profile?.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </StyledMenuItem>
            ))}
          </Select>
        </StyledFormControl>)}

        {props.tab === "tracking" && (<StyledFormControl fullWidth>
          <InputLabel>Type</InputLabel>
          <Select
            multiple
            value={props.type}
            onChange={handleTypeChange}
            input={<OutlinedInput label="Type" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {types.map((name) => (
              <StyledMenuItem key={name} value={name}>
                <Checkbox checked={props.type.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </StyledMenuItem>
            ))}
          </Select>
        </StyledFormControl>)}

        {props.tab === "feedback" && (<StyledFormControl fullWidth>
          <InputLabel>Rating</InputLabel>
          <Select
            multiple
            value={props.rating}
            onChange={handleRatingChange}
            input={<OutlinedInput label="Rating" />}
            renderValue={(selected) => selected.join(', ')}
          >
            {ratings.map((name) => (
              <StyledMenuItem key={name} value={name}>
                <Checkbox checked={props.rating.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </StyledMenuItem>
            ))}
          </Select>
        </StyledFormControl>)}

        <Button 
            variant="contained"
            size="large"
            sx={{ textTransform: 'none', marginTop: '15px' }}
            onClick={()=>handleApplyFilter()}
            disabled={applyFilterDisable}
          >
            Apply Filters
        </Button>
      </>
    </Wrapper>
  );
};

// Exports:
export default Filters;