// Packages:
import React, { memo,useState, useEffect } from "react";
// Styles:
import BaseConfiguration from "../BaseConfiguration";

// Functions:
const ParallelConfiguration = ({
  node,
  updateNode,
  deleteNode,
  isEditable,
  setNodes
}) => {

  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields,setOutputFields]=useState([])

  useEffect(() => {
    updateNode(node.id, { dynamicFields: dynamicFields, outputFields: outputFields });
  }, [dynamicFields]);

  // Return:
  return (
    <BaseConfiguration
      setDynamicFields={setDynamicFields}
      node={node}
      isEditable={isEditable}
      deleteNode={deleteNode}
      outputFields={outputFields}
      setOutputFields={setOutputFields}
      dynamicFields={dynamicFields}
      setNodes={setNodes}
    >
    </BaseConfiguration>
  );
};

// Exports:
export default memo(ParallelConfiguration);