import { NODE_TYPE } from "../../../constants/studioV2";

export const getDynamicFields = (previousNode, label = '') => {
    let fields = [];
    const { type, data } = previousNode || {};

    switch (type) {
        case NODE_TYPE.message: {
            const { outputs = [], dynamicFields = [] } = data || {};
            const messageFields = outputs.filter(({ field }) => field).flatMap(({ type, field }) =>
              type === 'EMAIL' ? [field, `${field}_domain`] : [field]
            );
            fields = [...messageFields, ...dynamicFields];
            break;
        }
        case NODE_TYPE.api: {
            const { responseMapping = [], dynamicFields = [] } = data || {};
            const apiFields = responseMapping.filter(({ key }) => key).map(({ key }) => key);
            fields = [...apiFields, ...dynamicFields];
            break;
        }
        default: {
            fields = data?.dynamicFields ?? [];
            break;
        }
    }
    if (label === 'failure') {
        fields = [...fields, 'Error', 'Cause'];
    }
    return fields;
};

export const getOutputFields = (previousNode, label = '') => {
    let fields = [];
    const { type, data } = previousNode || {};

    switch (type) {
        case NODE_TYPE.message: {
            const { outputs = [], outputFields = [] } = data || {};
            const messageFields = outputs.filter(({ field }) => field).flatMap(({ type, field }) =>
              type === 'EMAIL' ? [field, `${field}_domain`] : [field]
            );
            const targetFields = label === 'failure' ? ['Error', 'Cause'] : messageFields;
            fields = [...outputFields, { id: previousNode.id, values: [...targetFields] }];
            break;
        }
        case NODE_TYPE.api: {
            const { responseMapping = [], outputFields = [] } = data || {};
            const apiFields = responseMapping.filter(({ key }) => key).map(({ key }) => key);
            const targetFields = label === 'failure' ? ['Error', 'Cause'] : apiFields;
            fields = [...outputFields, { id: previousNode.id, values: [...targetFields] }];
            break;
        }
        default: {
            const { outputFields = [] } = data || {};
            const targetFields = label === 'failure' ? ['Error', 'Cause'] : [];
            fields = [...outputFields, { id: previousNode.id, values: [...targetFields] }];
        }
    }

    if (label === 'failure') {
        const existingIndex = fields.findIndex(item => item.id === previousNode.id);
        if (existingIndex === -1) {
            fields.push({ id: previousNode.id, values: ['Error', 'Cause'] });
        } else {
            const existingItem = fields[existingIndex];
            fields[existingIndex] = {
                ...existingItem,
                values: [...existingItem.values, 'Error', 'Cause']
            };
        }
    }

    return fields;
};