// Packages:
import React from 'react';
import { Chip } from '@mui/material';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';


// Imports:
import {
  Wrapper,
  Info,
  InfoTitle,
  InfoWrapper,
  FeedbackTable
} from './styles';




// Functions:
const FeedackInformation = ({ feedback, hideInfo }) => {

  // Return:
  return (
    <Wrapper>
      <Info>
        <InfoTitle>Feedback Information</InfoTitle>
        <InfoWrapper>
          <div>
              {feedback ? (
                <FeedbackTable>
                  <tr>
                    <th>User:</th>
                    <td>
                      { hideInfo ? 
                          "••"+feedback.user.slice(2, 5).trim()+"•••"+feedback.user.slice(8, 11).trim()+"••" : 
                          feedback.user 
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Org Id:</th>
                    <td>
                      { hideInfo ? 
                          "••"+feedback.orgid.slice(2, 5).trim()+"•••"+feedback.orgid.slice(8, 11).trim()+"••" : 
                          feedback.orgid 
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Rating:</th>
                    <td>
                      {feedback.rating === 1 ? (
                              <div style={{ color: '#d32f2f', fontWeight: '500' }}>
                                1/5 - Horrible
                              </div>
                            ) : feedback.rating === 2 ? (
                              <div style={{ color: '#d32f2f', fontWeight: '500' }}>
                                2/5 - Poor
                              </div>
                            ) : feedback.rating === 3 ? (
                              <div style={{ color: '#ed6c02', fontWeight: '500' }}>
                                3/5 - Neutral
                              </div>
                            ) : feedback.rating === 4 ? (
                              <div style={{ color: '#2e7d32', fontWeight: '500' }}>
                                4/5 - Good
                              </div>
                            ) : feedback.rating === 5 ? (
                              <div style={{ color: '#2e7d32', fontWeight: '500' }}>
                                5/5 - Excellent
                              </div>
                            ) : (
                              <div style={{ color: "grey" }}>N/A</div>
                            )}
                    </td>
                  </tr>
                  <tr>
                    <th>Reaction:</th>
                    <td>
                      {feedback.reaction === 1 ? (
                        <SentimentVeryDissatisfiedIcon color="error" sx={{ verticalAlign: 'middle' }} />
                        ) : feedback.reaction === 2 ? (
                          <SentimentDissatisfiedIcon color="error" sx={{ verticalAlign: 'middle' }} />
                          ) : feedback.reaction === 3 ? (
                            <SentimentSatisfiedIcon color="warning" sx={{ verticalAlign: 'middle' }} />
                            ) : feedback.reaction === 4 ? (
                              <SentimentSatisfiedAltIcon color="success" sx={{ verticalAlign: 'middle' }} />
                            ) : feedback.reaction === 5 ? (
                              <SentimentVerySatisfiedIcon color="success" sx={{ verticalAlign: 'middle' }} />
                            ) : (
                              <div style={{ color: "grey" }}>N/A</div>
                            )}
                    </td>
                  </tr>
                  <tr>
                    <th>Comment:</th>
                    <td>{ feedback.comment === '' ? <div style={{ color: "grey" }}>N/A</div> : feedback.comment }</td>
                  </tr>
                  <tr>
                    <th>Type:</th>
                    <td>
                      {feedback.type === 'technical issue' ? (
                        <Chip
                          label={feedback.type}
                          icon={<BugReportOutlinedIcon />}
                          color="error"
                          sx={{ fontWeight: '600' }}
                          size="small"
                          variant="outlined"
                        />
                        ) : feedback.type === 'feedback' ? (
                              <Chip
                                label={feedback.type}
                                icon={<SettingsSuggestOutlinedIcon />}
                                color="primary"
                                sx={{ fontWeight: '600' }}
                                size="small"
                                variant="outlined"
                              />
                            ) : feedback.type === 'question' ? (
                              <Chip
                                label={feedback.type}
                                icon={<QuestionMarkOutlinedIcon />}
                                color="warning"
                                sx={{ fontWeight: '600' }}
                                size="small"
                                variant="outlined"
                              />
                            ) : feedback.type === 'Agent Unavailable' ? (
                              <Chip
                                label="agent unavailable"
                                icon={<NoAccountsIcon />}
                                color="secondary"
                                sx={{ fontWeight: '600' }}
                                size="small"
                                variant="outlined"
                              />
                            ) : (
                              <div style={{ color: "grey" }}>N/A</div>
                            )}
                    </td>
                  </tr>
                </FeedbackTable>
              ) : (
                'Select a row to display feedback information'
              )}
          </div>
        </InfoWrapper>
      </Info>
    </Wrapper>
  );
};

// Exports:
export default FeedackInformation;
