import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import ROUTES from '../../../constants/routes';
import { getContentActions } from '../../../redux/actions/contentActions';
import { Button } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const AddFaq = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.auth.userDetails);
  const orgid = useSelector((state) => state.content.org_info?.org_data?._id);
  const funcContentActions = getContentActions(dispatch);

  return (
    <Button
      variant="contained"
      size="medium"
      startIcon={<AddOutlinedIcon />}
      sx={{ textTransform: 'none' }}
      onClick={() => {
        const payload = {
          orgid,
          articleId: uuid(),
          createdBy: userDetails?.email,
          question: '',
          answer: '',
          name: '',
          isarticle: false,
          trainingPhrases: [],
          meta: {
            access: 'public',
            target: 'endUser',
            tags: [],
            source: '',
            isarticle: false,
            formattedText: '<p></p>',
            synonyms: [
              { id: uuid(), key: '', description: '' },
              { id: uuid(), key: '', description: '' }
            ]
          }
        };

        navigate(`${ROUTES.KNOWLEDGE_ARTICLE}/published=new`, {
          state: { isEdit: true, isNew: true }
        });
      }}
    >
      Add Knowledge
    </Button>
  );
};

export default AddFaq;