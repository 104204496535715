// Packages:
import axios from 'axios';

// Constants:
import { API_ENDPOINT } from '../../../constants/Endpoint';

// Functions:
import {
  prepareWorkflowForAutosave,
  prepareWorkflowForPublishing,
  prepareWorkflowForSubmitForReview,
  prepareWorkflowToSetAsReviewed
} from './';

export const autosaveWorkflow = async (newWorkflow) => {
  try {
    const _workflow = prepareWorkflowForAutosave(newWorkflow);
    const result = await axios.post(
      `${API_ENDPOINT}/autosaveWorkflow`,
      _workflow,
      {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY
        }
      }
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getDraftWorkflows = async (orgID, createdBy) => {
  try {
    const result = await axios.post(
      `${API_ENDPOINT}/getDraftWorkflows`,
      {
        orgID,
        createdBy: createdBy
      },
      {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY
        }
      }
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createNewWorkflow = async (workflow, opts) => {
  try {
    if (opts?.isTest) {
      // const data = require('./fixtures/createNewWorkflow.json')
      // const result = { data }
      // return result?.data;
    }
    const result = await axios.post(
      `${API_ENDPOINT}/autosaveWorkflow`,
      workflow,
      {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY
        }
      }
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getRemoteWorkflows = async (orgID, createdBy, agent_type) => {
  try {
    const result = await axios.post(
      `${API_ENDPOINT}/fetchWorkflows`,
      {
        orgID: orgID,
        createdBy: createdBy,
        agent_type: agent_type
      },
      {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY
        }
      }
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const submitWorkflowForReview = async (newWorkflow, opts) => {
  try {
    if (opts?.isTest) {
      // const data = require('./fixtures/submitWorkflowForReview.json')
      // const result = { data }
      // return result?.data;
    }
    const _workflow = prepareWorkflowForSubmitForReview(newWorkflow);
    const result = await axios.post(`${API_ENDPOINT}/editWorkflow`, _workflow, {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY
      }
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const setWorkflowAsReviewed = async (newWorkflow) => {
  try {
    const _workflow = prepareWorkflowToSetAsReviewed(newWorkflow);
    const result = await axios.post(`${API_ENDPOINT}/editWorkflow`, _workflow, {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY
      }
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const publishWorkflow = async (newWorkflow) => {
  try {
    const _workflow = prepareWorkflowForPublishing(newWorkflow);
    if (!_workflow.isReviewed) return;
    const result = await axios.post(
      `${API_ENDPOINT}/state-machine-engine`,
      _workflow,
      {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY
        }
      }
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteWorkflow = async (orgID, workflowid) => {
  try {
    if (!orgID || !workflowid) return;
    const result = await axios.delete(`${API_ENDPOINT}/state-machine-engine`, {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY
      },
      data: {
        orgid: orgID,
        workflowid: workflowid
      }
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteDraftWorkflow = async (orgID, workflowID) => {
  try {
    if (!orgID || !workflowID) return;
    const result = await axios.delete(`${API_ENDPOINT}/deleteDraftWorkflow`, {
      headers: {
        'x-api-key': process.env.REACT_APP_API_KEY
      },
      data: {
        orgID,
        workflowid: workflowID
      }
    });
    return result?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/******************Supervisor comments on articles *************** */

export const addCommentOnWorkflowAPI = async (
  orgid,
  workflowid,
  comment,
  author
) => {
  try {
    const result = await axios.post(
      `${API_ENDPOINT}/addCommentOnWorkflow `,
      {
        orgid,
        workflowid,
        comment,
        author
      },
      {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY
        }
      }
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchCommentOnWorkflowAPI = async (orgid, workflowid) => {
  try {
    const result = await axios.post(
      `${API_ENDPOINT}/fetchCommentOnWorkflow`,
      {
        orgid,
        workflowid
      },
      {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY
        }
      }
    );
    return result?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
