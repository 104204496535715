export const DEFAULT_OUTPUT_TOEMAIL = {
    key: "toEmail",
    value: {
      fieldName: null,
      value: null,
    },
  };
  
  export const DEFAULT_OUTPUT_FROMEMAIL = {
    key: "fromEmail",
    value: {
      fieldName: null,
      value: null,
    },
  };
  
  export const DEFAULT_OUTPUT_SUBJECT = {
    key: "subject",
    value: {
      fieldName: null,
      value: null,
    },
  };