// Packages:
import React, { useState } from 'react';
import { Tooltip, IconButton, Badge } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { styled } from '@mui/material/styles';


// Styles:
import { Wrapper } from './styles';

const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    font-size: 0.6rem;
    height: 15px;
    min-width: 15px;
    width: 15px;
    top: -3px;
    right: -5px;
  }
`;


// Functions:
const MobileChatActions = ({ createTicket, leaveChat, handleClickOpen, setTab, numberOfResources }) => {
    
    return (
        <Wrapper>
            <Tooltip title="Resources">
                <IconButton color="warning" sx={{ backgroundColor: '#FFFFFF', boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)' }} onClick={() => setTab('resources')}>
                    <StyledBadge badgeContent={numberOfResources} color="error" >
                        <LightbulbOutlinedIcon fontSize="small" />
                    </StyledBadge>
                </IconButton>
            </Tooltip>
            <Tooltip title="Leave Chat">
                <IconButton color="error" onClick={() => [createTicket(), leaveChat(), handleClickOpen()]} sx={{ backgroundColor: '#FFFFFF', boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)' }}>
                    <LogoutIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Wrapper>
    );
};

// Exports:
export default MobileChatActions;
