export const DEFAULT_OUTPUT_ORDERID = {
  key: "orderId",
  value: {
    fieldName: null,
    value: null,
  },
};

export const DEFAULT_OUTPUT_EMAIL={
  key: "email",
  value: {
    fieldName: null,
    value: null,
  },
}

export const DEFAULT_OUTPUT_ADDRESS={
  key: "address",
  value: {
    fieldName: null,
    value: null,
  },
}

export const DEFAULT_OUTPUT =  {
key:"order_id",
value:{
  fieldName:"order_id",
  value:null
}
}
export const SHOPIFYFUNCTIONALITY={
  order_fulfillment_details:'ORDER FULFILLMENT DETAILS',
  order_details:'ORDER DETAILS'
}
export const SHOPIFY_RESPONSE_FIELDS = {
  "order_details": [
    "shipping_details",
    "shipping_address_details",
    "order_status",
    "is_tracking_details_present",
    "details_matched",
    "tracking_company",
    "tracking_url"
  ],
  "order_fulfillment_details":[]
};