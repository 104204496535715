import styled from 'styled-components';

export const Tags = styled.div`
  display: ${(props) => (props.tags.length > 0 ? 'flex' : 'none')};
  flex-direction: row;
  font-size: 14px;
  margin-bottom: 0.5rem;

  @media (max-width: 520px) {
    font-size: 16px;
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 2px 1px -1px rgb(0 0 0 / 12%);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
`;
