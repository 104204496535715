// Packages:
import React, { memo } from 'react';
import {  useSelector } from 'react-redux';
import { Position } from 'react-flow-renderer';
// Styles:
import { LeafNodeWrapper, LeafNodeLabel, Body, StyledHandle } from './styles';
import BaseNode from '../BaseNode';

const config = {
  // style={{
  //   backgroundColor:
  //     studio.selectedNode?.id === id
  //       ? isEndNode
  //         ? '#CE3527'
  //         : '#7DD181'
  //       : '#FEFFFE',
  //   borderColor: isEndNode ? '#CE3527' : '#7DD181'
  // }}
  styles: {
    wrapper: {
      default: {
        backgroundColor: "#FFFFFF",
        borderColor: "#4F5450",
      },
      selected: {
        backgroundColor: "#4F5450",
      },
    },
    label: {
      default: {
        color: "#333533",
      },
      selected: {
        color: "#FFFFFF",
      },
    },
  },
  handleStyles: [
    {
      properties: {
        type: "target",
        position: Position.Top,
      },
      style: {
        backgroundColor: "blue",
        top: "-15px",
      },
    },
    {
      properties: {
        type: "source",
        position: Position.Bottom,
      },
      style: { backgroundColor: "green", bottom: "-15px" },
    },
  ],
};

// Functions:
const LeafNode = ({ data }) => {
  // Constants:
  const { id, handlePosition, label, isEndNode } = data;
  // State:
  const studio = useSelector((state) => state.studio);

  // Return:
  return (
    <BaseNode data={data}>
    <LeafNodeWrapper
      style={{
        backgroundColor:
          studio.selectedNode?.id === id
            ? isEndNode
              ? '#CE3527'
              : '#7DD181'
            : '#FEFFFE',
        borderColor: isEndNode ? '#CE3527' : '#7DD181'
      }}
    >
      {isEndNode && (
        <StyledHandle
          type="target"
          position={handlePosition ?? Position.Bottom}
          style={{ backgroundColor: 'blue', top: '-15px' }}
        />
      )}
      <LeafNodeLabel
        htmlFor="text"
        style={{
          color: studio.selectedNode?.id === id ? '#FEFFFE' : '#333533'
        }}
      >
        {label}
      </LeafNodeLabel>
      <Body>
        {!isEndNode && (
          <StyledHandle
            type="source"
            position={handlePosition ?? Position.Top}
            style={{ backgroundColor: '#65B891' }}
          />
        )}
      </Body>
    </LeafNodeWrapper>
  </BaseNode>
  );
};

// Exports:
export default memo(LeafNode);
