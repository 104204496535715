import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { TailSpin } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Autosave, GoBack, Header } from './styles';

const GoBackComponent = ({ isEdit, autosaveFn, articleData, autosaving }) => {
  const loading = useSelector((state) => state.content.loading);
  const navigate = useNavigate();

  const handleClick = async () => {
    navigate(-1);
    if (isEdit && !Object.keys(articleData?.meta).includes('published')) {
      autosaveFn(articleData);
    }
    localStorage.removeItem('openedArticle');
  };

  return (
    <Header>
      <GoBack onClick={() => !autosaving && !loading && handleClick()}>
        <MdArrowBack
          title="Back"
          style={{ marginRight: '0.25rem', color: '#333333' }}
        />
        <span
          style={{
            fontWeight: 600,
            fontSize: '0.9rem',
            color: '#333333'
          }}
        >
          Go back
        </span>
      </GoBack>
      {isEdit && autosaving && (
        <Autosave
          onClick={() => {
            if (isEdit) {
              autosaveFn(articleData);
            }
          }}
        >
          <TailSpin
            height="17"
            width="17"
            color="#5a5a5a"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
          <span
            style={{
              paddingLeft: '4px'
            }}
          >
            Autosaving...
          </span>
        </Autosave>
      )}
    </Header>
  );
};

export default GoBackComponent;
