// Packages:
import React, { useState } from 'react';
import {
    TextField,
    FormControl,
    MenuItem,
    Select,
    Button,
    styled
  } from '@mui/material';
  import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
  import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
  import uuid from 'react-uuid'


//Components:
import MultipleChoice from '../../components/FormGenerator/MultipleChoice/index';
import Dropdown from '../../components/FormGenerator/Dropdown/index';
import Checkboxes from '../../components/FormGenerator/Checkboxes/index';
import Info from '../../components/FormGenerator/Info/index';


// Styles:
import {
  Wrapper,
  FieldName,
  Form
} from './styles';

const StyledTextField = styled(TextField)`
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 14px;
  max-height: 36px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const StyledFormControl = styled(FormControl)`
  & .MuiFormLabel-root {
    font-size: 14px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 14px;
  }
`;


// Functions:
const FormGenerator = ({ bindFormName, bindFormDescription }) => {
    // Constants:
    const DEFAULT_INPUT = () => ({ id: uuid(), question: 'Untitled Question', type: 'Short answer' })
    const DEFAULT_OPTION = () => ({ id: uuid(), option: 'Option' })
    const [ inputs, setInputs ] = useState([{ id: uuid(), question: 'Untitled Question', type: 'Short answer' }])
    const [ options, setOptions ] = useState([{ id: uuid(), option: 'Option' }])
    const INPUT_TYPE = {
        MULTIPLE_CHOICE: 'Multiple choice',
        DROPDOWN: 'Drop-down',
        CHECKBOXES: 'Checkboxes',
        SHORT_ANSWER: 'Short answer',
        PARAGRAPH: 'Paragraph'
      };

    const handleDeleteOption = () => setOptions([ ...options ].slice(0, -1));

    // Return:
    return (
        <Wrapper>
        <Info bindFormName={bindFormName} bindFormDescription={bindFormDescription} />
        <FieldName>Question(s)</FieldName>
        {inputs?.map((input, i) => 
            (<Form key={i}>
                <div style={{ display: 'flex', gap: '10px', marginBottom: '1rem' }}>
                    <StyledTextField
                        variant="outlined"
                        placeholder="Question"
                        value={input.question}
                        onChange={ event => {
                            event.preventDefault()
                            event.stopPropagation()
                            const newInputs = [ ...inputs ]
                            newInputs[i] = { ...input, question: event.currentTarget.value }
                            setInputs(newInputs)
                          }}
                        size="small"
                        fullWidth
                    />
                    <StyledFormControl fullWidth>
                        <Select
                            size="small"
                            value={input.type}
                            onChange={ event => {
                                event.preventDefault()
                                event.stopPropagation()
                                const newInputs = [ ...inputs ]
                                newInputs[i] = { ...input, type: event.target.value }
                                setInputs(newInputs)
                              }}
                        >
                            {Object.values(INPUT_TYPE).map((type, index) => (
                                <StyledMenuItem key={index} value={type}>
                                    {type}
                                </StyledMenuItem>
                            ))}
                        </Select>
                    </StyledFormControl>
                </div>  
                <div>
                  { input.type === "Multiple choice" && ( 
                    <>
                      {options.map((option, i) => 
                        <MultipleChoice key={i} option={option} handleDeleteOption={handleDeleteOption} />
                      )}
                      <Button 
                        startIcon={<AddOutlinedIcon />} 
                        sx={{ textTransform: 'none' }}
                        onClick={() => setOptions([ ...options, DEFAULT_OPTION() ])}
                      >
                        Add option
                      </Button>
                    </>
                    ) 
                  }
                  { input.type === "Drop-down" && ( 
                    <>
                      {options.map((option, i) => 
                        <Dropdown key={i} option={option} handleDeleteOption={handleDeleteOption} />
                      )}
                      <Button 
                        startIcon={<AddOutlinedIcon />} 
                        sx={{ textTransform: 'none' }}
                        onClick={() => setOptions([ ...options, DEFAULT_OPTION() ])}
                      >
                        Add option
                      </Button>
                    </>
                    ) 
                  }
                  { input.type === "Checkboxes" && ( 
                      <>
                      {options.map((option, i) => 
                        <Checkboxes key={i} option={option} handleDeleteOption={handleDeleteOption} />
                      )}
                      <Button 
                        startIcon={<AddOutlinedIcon />} 
                        sx={{ textTransform: 'none' }}
                        onClick={() => setOptions([ ...options, DEFAULT_OPTION() ])}
                      >
                        Add option
                      </Button>
                    </>
                    ) 
                  }
                  { input.type === "Short answer" && (
                      <StyledTextField
                        disabled
                        variant="outlined"
                        value="Short-answer text"
                        fullWidth
                        size="small"
                      />
                    ) 
                  }
                  { input.type === "Paragraph" && (
                      <StyledTextField
                        disabled
                        variant="outlined"
                        value="Long-answer text"
                        fullWidth
                        size="small"
                      />
                    ) 
                  }
                </div> 
            </Form>)
        )}
        <div style={{ display: 'flex', gap: '10px', marginBottom: '1rem' }}>
          <Button 
              variant="contained" 
              size="small"
              startIcon={<AddOutlinedIcon />} 
              onClick={ () => setInputs([ ...inputs, DEFAULT_INPUT() ]) }
              sx={{ textTransform: 'none' }}
          >
              Add question
          </Button>
          <Button 
            variant="contained"
            size="small"
            startIcon={<DeleteOutlineOutlinedIcon />} 
            onClick={ () => setInputs([ ...inputs ].slice(0, -1)) }
            color="error"
            sx={{ textTransform: 'none' }}
          >
            Delete question
          </Button>
        </div>
    </Wrapper>
);};

// Exports:
export default FormGenerator;