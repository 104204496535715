// Packages:
import React, { memo } from "react";
import { TextField, styled, FormControl, Autocomplete } from "@mui/material";
import { useState } from "react";

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

// Functions:
const FormInputs = ({
  outputs,
  output,
  i,
  updateOutput,
  dynamicFields,
  isEditable,
}) => {
  outputs = outputs ?? [];

  const [outputValue, setOutputValue] = useState(
    output?.value?.value ?? output?.value?.fieldName
  );
  const handleInputChange = (e, value) => {
    setOutputValue(value);

    const newOutputs = [...outputs];
    const isDynamic = dynamicFields?.some((f) => f === value);

    const newValue = {
      fieldName: null,
      value: null,
    };

    if (isDynamic) {
      newOutputs[i] = {
        ...output,
        value: {
          ...newValue,
          [isDynamic ? "fieldName" : "value"]: value,
        },
      };
    } else {
      newValue.value = value;
      newOutputs[i] = {
        ...output,
        value: newValue,
      };
    }

    updateOutput(newOutputs);
  };

  const handleOutputValue = (e, value) => {
    setOutputValue(value);

    const newOutputs = [...outputs];
    const isDynamic = dynamicFields?.some((f) => f === value);

    const newValue = {
      fieldName: null,
      value: null,
    };

    if (!isDynamic) {
      newOutputs[i] = {
        ...output,
        value: {
          ...newValue,
          [isDynamic ? "fieldName" : "value"]: value,
        },
      };
    } else {
      newValue.field = value;
      newOutputs[i] = {
        ...output,
        value: newValue,
      };
    }

    updateOutput(newOutputs);
  };
  // Return:
  return (
        <div key={i} style={{ width: "100%", display: "flex" }}>
          <StyledTextField
            id="table-name"
            variant="outlined"
            placeholder="Column Name"
            disabled={!isEditable}
            value={output.key}
            onChange={(event) => {
              event.preventDefault();
              event.stopPropagation();
              const newOutputs = [...outputs];
              newOutputs[i] = {
                ...output,
                key: event.currentTarget.value,
              };
              updateOutput(newOutputs);
            }}
            size="small"
            sx={{ mb: "0" }}
          />
          <Autocomplete
            options={dynamicFields}
            disabled={!isEditable}
            value={output?.value?.fieldName??output?.value?.value}
            fullWidth
            freeSolo
            onChange={(e, value) => handleInputChange(e, value)}
            onInputChange={(e, value) => handleOutputValue(e, value)}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder="Select or Enter Custum Value"
                variant="outlined"
                size="small"
                style={{ padding: 0 }}
                sx={{ mb: "0" }}
              />
            )}
          />
        </div>
  );
};

// Exports:
export default memo(FormInputs);
