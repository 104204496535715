// Packages:
import React, { useCallback } from 'react'
import { Position } from 'react-flow-renderer'
import uuid from 'react-uuid'


// Styles:
import {
  Wrapper,
  Title
} from './styles'

import {
  LeafNodeWrapper,
  LeafNodeLabel
} from '../Nodes/LeafNode/styles'

import {
  APINodeLabel,
  APINodeWrapper
} from '../Nodes/APINode/styles'

import {
  MessageNodeLabel,
  MessageNodeWrapper
} from '../Nodes/MessageNode/styles'

import {
  FormNodeLabel,
  FormNodeWrapper
} from '../Nodes/FormNode/styles'

import {
  EmailNodeLabel,
  EmailNodeWrapper
} from '../Nodes/EmailNode/styles'

import {
  TicketNodeLabel,
  TicketNodeWrapper
} from '../Nodes/TicketNode/styles'

import {StartNodeLabel,StartNodeWrapper } from '../Nodes/StartNode/styles'
import { DecisionNodeLabel, DecisionNodeWrapper } from '../Nodes/DecisionNode/styles'
import {ShopifyNodeLabel,ShopifyNodeWrapper } from '../Nodes/ShopifyNode/styles'
import {GoogleSheetsNodeLabel,GoogleSheetsNodeWrapper } from '../Nodes/GoogleSheetsNode/styles'
import {ShippingNodeLabel,ShippingNodeWrapper } from '../Nodes/ShippingNode/styles'


// Constants:
const DEFAULT_TEMPLATE = `{
  "_success": {
    "_on": "true",
    "_output": {
      "variableName": "res.body.variableName"
    }
  },
  "_failure": {
    "_on": "res.status != 200",
    "_output": {
      "error": "res.error || res.status.error"
    }
  }
}`


// Functions:
const Assets = () => {
  // Functions:
  const onDragStart = useCallback((event, nodeType, data) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.setData('data', JSON.stringify(data))
    event.dataTransfer.effectAllowed = 'move'
  }, [])

  // Return:
  return (
    <Wrapper>
      <Title>Asset</Title>
      <StartNodeWrapper
        style={{
          marginBottom: '1rem',
          paddingBottom: '0.5rem',
          textAlign: 'center',
          border: '2px solid #3AA640',
          cursor: 'grab'
        }}
        onDragStart={ event => onDragStart(event, 'start', {
          comment: '',
          tags: [],
          label: 'START'
        })}
        draggable
      >
        <StartNodeLabel>START</StartNodeLabel>
      </StartNodeWrapper>
      <APINodeWrapper
        style={{
          marginBottom: '1rem',
          paddingBottom: '0.5rem',
          textAlign: 'center',
          border: '2px solid #7721E8',
          cursor: 'grab'
        }}
        onDragStart={ event => onDragStart(event, 'api', {
          inputs: [ { id: uuid(), key: '', description: '' } ],
          endpoint: '',
          template: DEFAULT_TEMPLATE,
          label: 'API'
        })}
        draggable
      >
        <APINodeLabel>API</APINodeLabel>
      </APINodeWrapper>
      <MessageNodeWrapper
        style={{
          marginBottom: '1rem',
          paddingBottom: '0.5rem',
          textAlign: 'center',
          border: '2px solid #401FFF',
          cursor: 'grab'
        }}
        onDragStart={ event => onDragStart(event, 'message', {
          message: '',
          formName: '',
          expectResponse: false,
          responsesExpected: 0,
          outputs: [],
          options: [],
          label: 'MESSAGE'
        })}
        draggable
      >
        <MessageNodeLabel>MESSAGE</MessageNodeLabel>
      </MessageNodeWrapper>
      <FormNodeWrapper
        style={{
          marginBottom: '1rem',
          paddingBottom: '0.5rem',
          textAlign: 'center', 
          cursor: 'grab'
        }}
        onDragStart={ event => onDragStart(event, 'form', {
          formName: '',
          formDescription: '',
          label: 'FORM'
        })}
        draggable
      >
        <FormNodeLabel>FORM</FormNodeLabel>
      </FormNodeWrapper>
      <EmailNodeWrapper
        style={{
          minWidth: 'unset',
          marginBottom: '1rem',
          paddingBottom: '0.5rem',
          textAlign: 'center',
          border: '2px solid #D43558',
          cursor: 'grab'
        }}
        onDragStart={ event => onDragStart(event, 'email', {
          from: '',
          subject: '',
          body: '',
          label: 'EMAIL'
        })}
        draggable
      >
        <EmailNodeLabel>EMAIL</EmailNodeLabel>
      </EmailNodeWrapper>
      <ShopifyNodeWrapper
        style={{
          marginBottom: '1rem',
          paddingBottom: '0.5rem',
          textAlign: 'center',
          border: '2px solid #008060',
          cursor: 'grab'
        }}
        onDragStart={ event => onDragStart(event, 'shopify', {
          shopifyFunctionality: 'FETCH ORDER STATUS',
          label: 'SHOPIFY'
        })}
        draggable
      >
        <ShopifyNodeLabel>SHOPIFY</ShopifyNodeLabel>
      </ShopifyNodeWrapper>
      <TicketNodeWrapper
        style={{
          minWidth: 'unset',
          marginBottom: '1rem',
          paddingBottom: '0.5rem',
          textAlign: 'center',
          border: '2px solid #235789',
          cursor: 'grab'
        }}
        onDragStart={ event => onDragStart(event, 'ticket', {
          ticketChannel: 'ZENDESK',
          ticketAction: 'CREATE',
          subject: '',
          comment: '',
          tags: [],
          informationToExtract: [],
          macros: [],
          includedSearchTerms: [],
          excludedSearchTerms: [],
          customFields: '',
          channel: 'NONE',
          status: 'NONE',
          multipleStatus: ['NONE'],
          label: 'TICKET'
        })}
        draggable
      >
        <TicketNodeLabel>TICKET</TicketNodeLabel>
      </TicketNodeWrapper>
      <DecisionNodeWrapper
        style={{
          marginBottom: '1rem',
          paddingBottom: '0.5rem',
          textAlign: 'center',
          border: '2px solid #FE7920',
          cursor: 'grab'
        }}
        onDragStart={ event => onDragStart(event, 'decision', {
          orderId: '',
          decisionType: 'BINARY',
          options: [],
          label: 'DECISION'
        })}
        draggable
      >
        <DecisionNodeLabel>DECISION</DecisionNodeLabel>
      </DecisionNodeWrapper>
      <GoogleSheetsNodeWrapper
        style={{
          marginBottom: '1rem',
          paddingBottom: '0.5rem',
          textAlign: 'center',
          border: '2px solid #188038',
          cursor: 'grab'
        }}
        onDragStart={ event => onDragStart(event, 'google_sheets', {
          googleSheetsFunctionality: 'VERIFY FROM SHEET',
          sheet: '',
          document: '',
          title: '',
          key: '',
          label: 'GOOGLE SHEETS'
        })}
        draggable
      >
        <GoogleSheetsNodeLabel>GOOGLE SHEETS</GoogleSheetsNodeLabel>
      </GoogleSheetsNodeWrapper>
      <ShippingNodeWrapper
        style={{
          marginBottom: '1rem',
          paddingBottom: '0.5rem',
          textAlign: 'center',
          border: '2px solid #747458',
          cursor: 'grab'
        }}
        onDragStart={ event => onDragStart(event, 'shipping', {
          carrier: 'SHIPPING EASY',
          shippingFunctionality: 'FETCH ORDER',
          shippingType: 'FIRST CLASS MAIL',
          label: 'SHIPPING'
        })}
        draggable
      >
        <ShippingNodeLabel>SHIPPING</ShippingNodeLabel>
      </ShippingNodeWrapper>
      <LeafNodeWrapper
        style={{
          marginBottom: '1rem',
          paddingBottom: '0.5rem',
          textAlign: 'center',
          border: '2px solid #DB4C40',
          cursor: 'grab'
        }}
        onDragStart={ event => onDragStart(event, 'leaf', {
          handlePosition: Position.Top,
          label: 'END',
          isEndNode: true
        })}
        draggable
      >
        <LeafNodeLabel>END</LeafNodeLabel>
      </LeafNodeWrapper>
    </Wrapper>
  )
}


// Exports:
export default Assets