const filterArticlesBasedOnTagSearch = (article, paramForSearch) => {
  const meta = article?.meta;
  let found = false;
  if (!meta) return found;
  Object.keys(paramForSearch).forEach((param) => {
    if (param === 'tags') {
      meta.tags.forEach((tag) => {
        if (
          tag.desc !== '' &&
          paramForSearch['tags']?.includes(tag.desc.trim().toLowerCase())
        )
          found = true;
      });
    } else if (param === 'access') {
      if (paramForSearch['access'].includes(meta['access']?.toLowerCase()))
        found = true;
    } else if (param === 'target') {
      if (paramForSearch['target'].includes(meta['target']?.toLowerCase()))
        found = true;
    }
  });
  return found;
};

export default filterArticlesBasedOnTagSearch;
