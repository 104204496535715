import React, { useCallback, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { recordsFilteredByPersonas, recordsFilteredByProfile } from "../utils";
import { selectSessionItems } from "../../../redux/selectors/chatSelectors";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export const options = {
  plugins: {
    title: {
      display: false,
      text: 'Sentiment Chart',
    },
    tooltip: {
      filter: item => item.dataset.data[item.dataIndex] > 0
    },
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        boxWidth: 20,
        boxHeight: 18,
        padding: 20
      },
    }
  },
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  layout: {
      padding: 25
  }
};


export default function UserStackedChart({ startDate, endDate, persona, profile }) {
  const chatRecords = useSelector(selectSessionItems, shallowEqual);
  const personas = useSelector(state => state.content.personas);
  const [labels, setVLabels] = useState([]);
  const [positiveSentiments, setPVValues] = useState([]);
  const [negativeSentiments, setNVValues] = useState([]);
  const [neutralSentiments, setNUValues] = useState([]);
  const [total, setTValues] = useState([]);
  
  const handleLabels = useCallback((data) => setVLabels(data), []);
  const handlePValues = useCallback((data) => setPVValues(data), []);
  const handleNValues = useCallback((data) => setNVValues(data), []);
  const handleNUValues = useCallback((data) => setNUValues(data), []);
  const handleTValues = useCallback((data) => setTValues(data), []);

  useEffect(()=>{
    const diff = moment(endDate).diff(startDate, 'days');
    let filteredRecords = recordsFilteredByPersonas(chatRecords, personas, persona);
    filteredRecords = recordsFilteredByProfile(filteredRecords, profile);

    const periodTypes = {
        '0': 'hour',
        '1': 'day',
        '31': 'month',
        '365': 'year',
    };

    let periodType = periodTypes['0']; // Default aggregation function
    Object.keys(periodTypes).forEach(key => {
        if (diff > parseInt(key)) {
            periodType = periodTypes[key];
        }
    });
    
    aggregateDataByPeriod(startDate, endDate, periodType, filteredRecords, handleLabels, handlePValues, handleNValues, handleNUValues, handleTValues)
  }, [chatRecords]);

  
  // const backgroundColors = [
  //   "rgb(6, 6, 6)",
  //   "rgb(169, 169, 169)",
  //   "rgb(233, 233, 233)"
  // ];

  const data = {
    labels,
    datasets: [
      {
        type: "line",
        label: "Total Sessions",
        borderColor: "rgb(0,0,0)",
        borderWidth: 2,
        fill: false,
        data: total,
        pointRadius: 0,
        hidden: true,
      },
      {
        label: "Positive Sentiment",
        borderColor: "black",
        backgroundColor: 'rgb(233, 233, 233)',
        borderWidth: 2,
        fill: false,
        data: positiveSentiments,
        stack: "Stack 0",
      },
      {
        label: "Negative Sentiment",
        backgroundColor: 'black',
        data: negativeSentiments,
        borderColor: "black",
        borderWidth: 2,
        stack: "Stack 0",
      },
      {
        label: "Neutral Sentiment",
        backgroundColor: 'grey',
        data: neutralSentiments,
        borderColor: "black",
        borderWidth: 2,
        stack: 'Stack 0',
      },
    ],
  };

  return <Bar options={options} data={data} />;
}


function aggregateDataByPeriod(startDate, endDate, periodType, chatRecords, handleLabels,handlePValues,handleNValues,handleNUValues,handleTValues, handleSortedValues) {
  let currentDate = moment(startDate).startOf(periodType);
  let labels = [];
  let positiveSentiments = []; 
  let negativeSentiments = [];
  let neutralSentiments = [];
  let total = [];
  let dateFormat = '';

  const periodFormats = {
      'year': 'YYYY',
      'month': 'MMM YYYY',
      'day': 'MMM D, YYYY',
      'hour': 'MMM D, YYYY HH:mm'
  };

  dateFormat = periodFormats[periodType] || 'MMM D, YYYY HH:mm';

  while (currentDate.isSameOrBefore(endDate)) {
    const start = moment(currentDate).startOf(periodType);
    const end = moment(currentDate).endOf(periodType);
    aggregateData(start, end, chatRecords, labels, positiveSentiments, negativeSentiments, neutralSentiments, total);
    labels.push(start.format(dateFormat));
    currentDate.add(1, periodType);
  }


  handleLabels(labels);
  handlePValues(positiveSentiments);
  handleNValues(negativeSentiments);
  handleNUValues(neutralSentiments);
  handleTValues(total);
}


function aggregateData(start, end, chatRecords, labels, positiveSentiments, negativeSentiments, neutralSentiments, total) {
  let pos = 0;
  let neg = 0;
  let neu = 0;
  let tot = 0;
  for (let i = 0; i < chatRecords?.length || 0; i++) {
    const record = chatRecords[i];
    if (moment(record.created_date).isBetween(start, end, null, '[]')) {
      let input = record.analytics.sentiment[0];
      let temp = input?.split('\n')[0] === '' ? 'Positive' : input?.split('\n')[0] || "N/A";
      if (temp === 'Positive') pos++;
      else if (temp === 'Negative') neg++;
      else if (temp === 'Neutral') neu++;
      tot++;
    }
  }

  positiveSentiments.push(pos);
  negativeSentiments.push(neg);
  neutralSentiments.push(neu);
  total.push(tot);
}
