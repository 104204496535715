// Packages:
import React, { memo } from 'react';
import { Tooltip, IconButton} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';


// Styles:
import {
    Wrapper,
    PageInput
  } from './styles';


// Functions:
const Pagination = ({ 
    gotoPage,
    canPreviousPage, 
    canNextPage, 
    previousPage, 
    nextPage, 
    pageIndex,
    pageOptions,
    pageCount,
    isLastPage
}) => {
  // const pageCount = Math.ceil(records.length / pageSize);
  // Return:
  return (
    <Wrapper>
        <Tooltip title="First">
          <span>
            <IconButton disabled={!canPreviousPage} onClick={() => gotoPage(0)}>
              <FirstPageIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Previous">
          <span>
            <IconButton disabled={!canPreviousPage} onClick={() => previousPage()}>
              <NavigateBeforeIcon />
            </IconButton>
          </span>         
        </Tooltip>
        <span
          style={{
            fontSize: '0.8rem',
            marginLeft: '0.5rem',
            marginRight: '0.5rem'
          }}
        >
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageCount}
            </strong>{' '}
          </span>
        </span>
        <Tooltip title="Next">
          <span>
            <IconButton disabled={isLastPage} onClick={() => gotoPage(pageIndex+1)}>
              <NavigateNextIcon />
            </IconButton>
          </span> 
        </Tooltip>
        <Tooltip title="Last">
          <span>
            <IconButton disabled={isLastPage} onClick={() => gotoPage(pageCount-1)}>
              <LastPageIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Wrapper>
  )
}


// Exports:
export default memo(Pagination);
