// Packages:
import React from 'react';
import { FaqColumn, FAQs, Title } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getContentActions } from '../../../redux/actions/contentActions';
import dltArticle from './dltArticle';
import Articles from '../Articles';
import { CircularProgress, Divider } from '@mui/material';


// Functions:
const FAQComponent = ({
  listingArticles,
  setListingArticles,
  articles,
  handleOpenModal,
  handleModalProps,
  userDetails
}) => {
  const dispatch = useDispatch();
  const funcContentActions = getContentActions(dispatch);
  const orgid = useSelector((state) => state.content.org_info?.org_data?._id);
  const agent_type = useSelector((state) => state.content.agent_type);
  const loadingArticles = useSelector((state) => state.content.loadingArticles);

  return loadingArticles || listingArticles ? (
    <CircularProgress sx={{ mt: '5rem' }} />
  ) : (
    <FAQs>
      <FaqColumn>
        <Title>Quick Answers</Title>
        <Articles
          articlesArray={articles?.intent}
          deleteArticle={(article) => {
            handleOpenModal();
            handleModalProps({
              actionButton: 'Delete',
              title: 'Delete Article',
              message: `Are you sure you want to delete this article?`,
              actionFn: async () => {
                setListingArticles(true);
                await dltArticle(
                  article,
                  articles,
                  agent_type,
                  orgid,
                  funcContentActions.deleteKnowledgeArticle,
                  funcContentActions.deleteUnverifiedKnowledgeArticle,
                  funcContentActions.deleteDraft,
                  userDetails
                );
                setListingArticles(false);
              }
            });
          }}
        />
      </FaqColumn>
      <Divider orientation="vertical" />
      <FaqColumn>
        <Title>Knowledge Articles</Title>
        <Articles
          articlesArray={articles?.qna}
          deleteArticle={(article) => {
            handleOpenModal();
            handleModalProps({
              actionButton: 'Delete',
              title: 'Delete Article',
              message: 'Are you sure you want to delete this article?',
              actionFn: async () => {
                setListingArticles(true);
                await dltArticle(
                  article,
                  articles,
                  agent_type,
                  orgid,
                  funcContentActions.deleteKnowledgeArticle,
                  funcContentActions.deleteUnverifiedKnowledgeArticle,
                  funcContentActions.deleteDraft
                );
                setListingArticles(false);
              }
            });
          }}
        />
      </FaqColumn>
    </FAQs>
  );
};

export default FAQComponent;