// Packages:
import React, { useState, useMemo, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  styled,
} from "@mui/material";
import { Link } from "react-router-dom";

import { CSVLink } from "react-csv";
import { CircularProgress, Button } from "@mui/material";
// Imports:
import { clearTableChatRecords, getChatActions } from "../../../redux/actions/chatActions";
import { Wrapper } from "./styles/styles";
import { StyledFormControl, StyledMenuItem } from "./styles/formStyles";
import UserChart from "./UserChart";
import UserStackedChart from "./UserStackedChart";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ROUTES from "../../../constants/routes";
import EscalationBreakDownChart from "./EscalationChart";
import UserBreakDownChart from "./UserBreakDownChart";
import { clearTableReactionRecords } from "../../../redux/actions/contentActions";
import { selectSessionItems } from "../../../redux/selectors/chatSelectors";
import Filters from "./Filters";
import "./styles/css/style.css"

function transformRecord(row) {
  return {
    created_date: row?.created_date,
    record_id: row?.record_id,
    conversation_type: row?.conversation_type,
    user_email: row?.user_email,
    sentiment: row?.analytics?.sentiment?.[0],
    topics: row?.analytics?.intent?.[0]?.replace(/"/g, '""') || '',
  };
}

const formControlStyles = {
  minWidth: "200px",
  display: 'flex',
  flexDirection: 'row',
  width: '10vw',
  borderWidth: '0 1px 1px 0',
  borderStyle: 'solid',
  borderColor: '#00000010',
  boxShadow: '1px 2px 5px 2px #0000001A',
  borderRadius: '2rem',
  overflow: 'visible',
  marginBottom: '1px'
};

const inputSelectStyles = { 
  width: "100%", 
  borderRadius: '2rem', 
  maxHeight: '50%', 
  color: '#999999', 
  border: 'none', 
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
  {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
  {
    border: 0,
  },
  "& .MuiSelect-select": {
    fontSize: '15px',
  }
};

// Functions:
const HistogramPage = (props) => {
  // Constants:
  const dispatch = useDispatch();
  const chatActions = getChatActions(dispatch);
  const organisation_info = useSelector((state) => state.content.org_info);
  const org_id = organisation_info?.org_data?._id;
  const chatRecords = useSelector(selectSessionItems, shallowEqual);
  const isContentLoading = useSelector((state) => state.chat.loading);
  const [isLoading, setIsLoading] = useState(true);
  // State:
  const [records, setRecords] = useState(chatRecords);

  const csvData = useMemo(() => {
    return records.map(row => transformRecord(row));
  }, [records]);
  
  // Effects:
  useEffect(() => {
    setIsLoading(isContentLoading);
  }, [isContentLoading]);

  useEffect(()=>{
    setRecords(chatRecords);
  }, [chatRecords])

  const [chartType, setchartType] = useState("topic breakdown chart");

  return (
    <Wrapper>
    {/* Loading indicator */}
    {isLoading ? (
      <CircularProgress  sx={{ mt: '21rem', ml: '20rem' }} />
    ) : (
      <>
      {/* Chart Type Selector */}
      <div className="chart-dropdown">
        {
          <ChatSelectDropDown 
            chartType={chartType} 
            setchartType={setchartType} 
          />
        }
        {
          chartType === "topic breakdown chart" &&
          <Filters 
            topicFilters={props.topicFilters}
            setTopicFilters={props.setTopicFilters}
          />
        }
      </div>

          {/* Render selected chart based on chartType */}
          <RenderChart 
            chartType={chartType} 
            properties={props}
            topicFilters={props.topicFilters}
          />

          {/* Export Data Button */}
          {chatRecords && (
            <p style={{textAlign: "center"}}>
              {csvData && <CSVLink
                filename={"records.csv"}
                data={csvData}
              >
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<FileUploadOutlinedIcon />}
                  sx={{ textTransform: "none" }}
                >
                  Export Data
                </Button>
              </CSVLink>}
              {"  "}
              <Link rel="noreferrer" to={ROUTES.AGENT.DATA_V2} onClick={(event) => {
                  sessionStorage.setItem('startDate', props.startDate);
                  sessionStorage.setItem('endDate', props.endDate);
                  sessionStorage.setItem('topicFilters', JSON.stringify(props.topicFilters));
                  dispatch(clearTableChatRecords());
                  dispatch(clearTableReactionRecords());
              }} style={{color:'6a6a6a',margin:'1rem',fontWeight:500}}>View Records</Link>
            </p>
          )}
        </>
      )}
    </Wrapper>
  );
};


// Render selected chart based on chartType
function RenderChart({ chartType, properties, topicFilters }){
  switch ( chartType ) {
    case "topic breakdown chart":
      return <UserChart 
        startDate={properties.startDate} 
        endDate={properties.endDate} 
        costPerTicket={properties.costPerTicket} 
        persona={properties.persona}
        profile={properties.profile}
        topicFilters={topicFilters}
      />; 
    
    case "sentiment breakdown chart":
      return <UserStackedChart
        startDate={properties.startDate}
        endDate={properties.endDate}
        persona={properties.persona}
        profile={properties.profile}
        />;
    
    case "escalation breakdown chart":
      return <EscalationBreakDownChart
        startDate={properties.startDate}
        endDate={properties.endDate}
        persona={properties.persona}
        profile={properties.profile}
        />;

    case "user breakdown chart":
      return <UserBreakDownChart 
        startDate={properties.startDate}
        endDate={properties.endDate}
        persona={properties.persona}
        profile={properties.profile}
      />
    
    default:
      return <></>;
  }
}

function ChatSelectDropDown({ chartType, setchartType }){
  return (
    <span>
      <StyledFormControl
        sx={formControlStyles}
      >
        <Select
          autoWidth={false}
          id="date-range"
          value={chartType}
          onChange={(event) => setchartType(event.target.value)}
          sx={inputSelectStyles}
        >
          {/* Options */}
          <StyledMenuItem
            value="topic breakdown chart"
            key="topic breakdown chart"
          >
            Topic Breakdown
          </StyledMenuItem>
          <StyledMenuItem
            value="sentiment breakdown chart"
            key="sentiment breakdown chart"
          >
            Sentiment Breakdown
          </StyledMenuItem>
          
          <StyledMenuItem
            value="escalation breakdown chart"
            key="escalation breakdown chart"
          >
            Escalation Breakdown
          </StyledMenuItem>
          
          <StyledMenuItem
            value="user breakdown chart"
            key="user breakdown chart"
          >
            User Breakdown
          </StyledMenuItem>
        </Select>
      </StyledFormControl>
    </span>
  )
}

// Exports:
export default HistogramPage;
