import styled from 'styled-components';

export const DescriptionArea = styled.div`
  min-height: 100%;
  max-height: 150rem;
  padding: 0.75rem;
  border-radius: 0.25rem;
  background: ${(props) => (props.isEdit ? '#F4F4F9;' : 'transparent')};
  outline: none;
  border: none;
`;

export const Description = styled.div`
  min-height: 10rem;
  max-height: 15rem;
  font-size: 14px;

  @media (max-width: 520px) {
    font-size: 16px;
  }
`;
