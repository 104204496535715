// Packages:
import React, { memo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
  Button,
  FormControlLabel,
  Checkbox,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useEffect } from "react";

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    padding: 1rem;
    width: 25%;
  }

  & .MuiTypography-root {
    font-family: "Inter", sans-serif;
  }

  & .MuiDialogActions-root {
    padding: 20px 24px;
    display: flex;
    margin: 0 auto;
  }
`;

// Functions:
const SettingsBox = ({
  settingsOpen,
  handleSettingsClose,
  settings,
  setCurrentRemoteWorkflow,
  isEditable,
}) => {
  // State:
  const [daysErrorText, setDaysErrorText] = useState("");
  const [hoursErrorText, setHoursErrorText] = useState("");
  const [minsErrorText, setMinsErrorText] = useState("");
  const [data, setData] = useState({
    checked: settings?.checked,
    days: settings?.days,
    hours: settings?.hours,
    mins: settings?.mins,
    verification_url: settings?.verification_url,
  });

  // Event Handler:
  const handleSaveSettings = () => {
    // setCurrentRemoteWorkflow((prev) => ({ ...prev, settings: data }));
    // setCurrentRemoteWorkflow((prev) => ( { ...prev, settings: data }));
    setCurrentRemoteWorkflow((prev) => ({
      ...prev,
      meta: {
        ...prev.meta,
        settings: data,
      },
    }));
    handleSettingsClose();
  };

  useEffect(() => {
    if (
      data.checked &&
      data.days === "" &&
      data.hours === "" &&
      data.mins === ""
    ) {
      setDaysErrorText("All fields cannot be empty");
      setHoursErrorText("All fields cannot be empty");
      setMinsErrorText("All fields cannot be empty");
    } else {
      setDaysErrorText("");
      setHoursErrorText("");
      setMinsErrorText("");
      if (data.days < 0)
        setDaysErrorText("Number of days must be greater than 0");
      else setDaysErrorText("");
      if (data.hours < 0 || data.hours > 23)
        setHoursErrorText(
          "Number of hours must be greater than 0 and less than 24"
        );
      else setHoursErrorText("");
      if (data.mins < 0 || data.mins > 59)
        setMinsErrorText(
          "Number of minutes must be greater than 0 and less than 60"
        );
      else setMinsErrorText("");
    }
  }, [data.checked,data.days,data.hours,data.mins]);

  // Return:
  return (
    <StyledDialog open={settingsOpen} onClose={handleSettingsClose}>
      <DialogTitle sx={{ mb: "1rem", fontSize: "1.25rem", color: "#333333" }}>
        Settings
        <Tooltip title="close">
          <IconButton
            onClick={handleSettingsClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <TextField
          size="small"
          disabled={!isEditable}
          placeholder="Enter verification URL"
          value={data.verification_url}
          onChange={(e) =>
            setData({ ...data, verification_url: e.target.value })
          }
          fullWidth
          sx={{ marginBottom: "1rem" }}
        />
        <FormControlLabel
          control={
            <Checkbox
              disabled={!isEditable}
              checked={data.checked}
              onChange={(e) => setData({ ...data, checked: e.target.checked })}
            />
          }
          label="Periodic"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          {data.checked && (
            <>
              <TextField
                error={daysErrorText.length === 0 ? false : true}
                label={daysErrorText.length === 0 ? null : "Error"}
                helperText={daysErrorText}
                disabled={!isEditable}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">days</InputAdornment>
                  ),
                }}
                type="number"
                placeholder="0"
                value={data.days}
                onChange={(e) => setData({ ...data, days: e.target.value })}
                fullWidth
              />
              <TextField
                error={hoursErrorText.length === 0 ? false : true}
                label={hoursErrorText.length === 0 ? null : "Error"}
                helperText={hoursErrorText}
                disabled={!isEditable}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">hours</InputAdornment>
                  ),
                }}
                type="number"
                placeholder="0"
                value={data.hours}
                onChange={(e) => setData({ ...data, hours: e.target.value })}
                fullWidth
              />
              <TextField
                error={minsErrorText.length === 0 ? false : true}
                label={minsErrorText.length === 0 ? null : "Error"}
                helperText={minsErrorText}
                disabled={!isEditable}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">mins</InputAdornment>
                  ),
                }}
                type="number"
                placeholder="0"
                value={data.mins}
                onChange={(e) => setData({ ...data, mins: e.target.value })}
                fullWidth
              />
            </>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          size="medium"
          disabled={!isEditable}
          startIcon={<SaveOutlinedIcon />}
          sx={{ textTransform: "none" }}
          color="success"
          onClick={() => handleSaveSettings()}
        >
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

// Exports:
export default memo(SettingsBox)
