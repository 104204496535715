// Packages:
import React, { useState } from 'react';
import {
    TextField,
    InputAdornment,
    Button,
    styled
  } from '@mui/material';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';


// Styles:
import {
  Wrapper,
  Subtitle,
  Form,
  Tag,
  InputField
} from './styles';

const StyledTextField = styled(TextField)`
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  & .MuiOutlinedInput-input {
    background-color: #FFFFFF;
    font-size: 14px;
  }
`;


// Functions:
const SecurityCredentials = () => {
    // Constants:
    const org_security_creds = [
        {
            field: 'shipping-easy api key',
            value: ''
        },
        {
            field: 'shipping-easy signature key',
            value: ''
        },
        {
            field: 'shipping-easy address change bearer token',
            value: ''
        },
        {
            field: 'shipping-easy type change bearer token',
            value: ''
        }
    ]   

    const [data, setData] = useState(org_security_creds)
    const [readOnly, setReadOnly] = useState(false)


    // Event Handlers: 
    const handleSaveSecurityCredentials = () => {
        data.map((item, i) => {
            if (item.value !== '') 
                setReadOnly(true)
        })
    }

    // Return:
    return (
        <Wrapper>
        <Subtitle>Please save your security credentials below.</Subtitle>
        <Form>
            {data.map((item, i) => (
                <InputField key={i}>
                    <Tag>Enter your {item.field}</Tag>
                    <StyledTextField
                        disabled={readOnly}
                        placeholder={item.field}
                        fullWidth
                        value={ readOnly ? "•••••••"+item.value.substring(item.value.length-5) : item.value}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><KeyOutlinedIcon /></InputAdornment>
                        }}
                        onChange={ event => {
                            event.preventDefault()
                            event.stopPropagation()
                            const newData = [ ...data ]
                            newData[i] = { ...item, value: event.currentTarget.value }
                            setData(newData)
                          }}
                    />
                </InputField>
            ))}
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }} >
                <Button
                    disabled={!readOnly}
                    variant="contained"
                    size="medium"
                    startIcon={<EditOutlinedIcon />}
                    sx={{ textTransform: 'none' }}
                    onClick={() => setReadOnly(false)}
                >
                    Edit
                </Button>
                <Button
                    disabled={readOnly}
                    variant="contained"
                    color="success"
                    size="medium"
                    startIcon={<SaveOutlinedIcon />}
                    sx={{ textTransform: 'none' }}
                    onClick={() => handleSaveSecurityCredentials()}
                >
                    Save
                </Button>
            </div>
        </Form>
    </Wrapper>
);};

// Exports:
export default SecurityCredentials;
