// Exports:
//NODE_TYPE
export const NODE_TYPE = Object.freeze({
  start:'start',
  leaf: 'leaf',
  message: 'message',
  form: 'form',
  api: 'api',
  email: 'email',
  ticket: 'ticket',
  shopify: 'shopify',
  decision: 'decision',
  google_sheets: 'google_sheets',
  shipping: 'shipping',
  end: 'end',
  logistics:'logistics',
  choice:'choice',
  ai:'ai',
  parallel:'parallel',
  parallelHandler:'parallelHandler',
  ticketure:'ticketure',
  acuity:'acuity'
})

export const EDGE_TYPE = {
  custom: 'custom',
}

export const FIRST_DRAFT_VERSION = '0.0'; // version of a new article's first draft
export const VERSION_INCREMENT = 0.1;

export const WORKFLOW_STAGES = Object.freeze({
  DRAFT: 'DRAFT',
  UP_FOR_REVIEW: 'UP_FOR_REVIEW',
  REVIEWED_BUT_NEEDS_CHANGES: 'REVIEWED_BUT_NEEDS_CHANGES',
  PUBLISHED: 'PUBLISHED'
})

//DRAFT_OPERATIONS
export const CREATE_DRAFT_OPERATION = 'createDraft';
export const SAVE_DRAFT_OPERATION = 'saveDraft';
export const DELETE_DRAFT_OPERATION = 'deleteDraft';
export const SUBMIT_DRAFT_OPERATION = 'submitDraft';
export const REJECT_DRAFT_OPERATION = 'rejectDraft';
export const PUBLISH_DRAFT_OPERATION = 'publishDraft';
export const ARCHIVE_WORKFLOW_OPERATION = 'archive';

// WORKFLOW SATGES
export const WORKFLOW_IS_DRAFT='DRAFT'
export const WORKFLOW_IS_UNDER_REVIEW='UNDER_REVIEW'
export const WORKFLOW_IS_PUBLISHED='PUBLISHED'


// ACCESS
export const ACCESS_IS_PUBLIC='public'
export const ACCESS_IS_PRIVATE='private'

//TARGET USER
export const TARGET_IS_ENDUSER='endUser'
export const TARGET_IS_AGENT='agent'
export const TARGET_IS_PARTNER='partner'