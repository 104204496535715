import { Position } from "react-flow-renderer";

export const config = {
    styles: {
      wrapper: {
        default: {
          backgroundColor: "#FFFFFF",
          borderColor: "#188038",
        },
        selected: {
          backgroundColor: "#188038",
        },
      },
      label: {
        default: {
          color: "#333533",
        },
        selected: {
          color: "#FFFFFF",
        },
      },
      body: {
        default: {
          color: "#333533",
        },
        selected: {
          color: "#FFFFFF",
        },
      },
    },
    handleStyles: [
      {
        properties: {
          type: "target",
          position: Position.Top,
        },
        style: {
          backgroundColor: "blue",
          top: "-15px",
        },
      },
      {
        properties: {
          id: "google-sheets-node-output-true",
          type: "source",
          position: Position.Bottom,
        },
        style: { backgroundColor: "green", left: "25%", bottom: "-15px" },
      },
      {
        properties: {
          id: "google-sheets-node-output-false",
          type: "source",
          position: Position.Bottom,
        },
        style: { backgroundColor: "red", left: "75%", bottom: "-15px" },
      },
    ],
  };