// Packages:
import styled from 'styled-components';
import Color from 'color';

// Exports:
export const StyledButton = styled.button`
  padding: 0.625rem 1rem;
  background-color: ${(props) =>
    props.isDisabled
      ? props.backgroundColor
        ? Color(props.backgroundColor).lighten(0.5).string()
        : '#97C1F9'
      : props.backgroundColor ?? '#2F80ED'};
  font-size: 0.9rem;
  color: #ffffff;
  border: unset;
  border-radius: 45px;
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  width: fit-content;
  box-shadow: 0px 3px 20px rgba(47, 128, 237, 0.25);
  transition: all 0.25s ease;

  &:hover {
    background-color: ${(props) =>
      props.isDisabled
        ? props.backgroundColor
          ? Color(props.backgroundColor).lighten(0.5).string()
          : '#97C1F9'
        : props.backgroundColor
        ? Color(props.backgroundColor).darken(0.5).string()
        : '#001D4F'};
    transform: ${(props) => (props.isDisabled ? 'scale(1)' : 'scale(1.03)')};
  }

  &:active {
    transform: ${(props) => (props.isDisabled ? 'scale(1)' : 'scale(0.97)')};
  }

  @media (max-width: 520px) {
    padding: 0.5rem 0.75rem;
    font-size: 16px;
  }
`;
