// Packages:
import styled from 'styled-components';

// Exports:
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  margin-top: 4rem;
  overflow-y: auto;
`;

export const SearchSection = styled.div`
  width: 99%;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  display: flex; 
  margin-bottom: 10px;
  width: 100%; 
  justify-content: center; 
  align-items: center;
  gap: 0.5rem;
`;


export const Tag = styled.div`
  display: flex;
  align-items: center;
  background: #f4f4f9;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 2px 1px -1px rgb(0 0 0 / 12%);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const Loader = styled.div`
  margin-left: 2rem;
`;
