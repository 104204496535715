// Packages:
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Tooltip
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { blue } from '@mui/material/colors';
import { getOngoingConversations } from '../../../api';
import { useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Refresh } from './styles';

// Styles:
const StyledDialog = styled(Dialog)`
  & .MuiTypography-root {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    @media (max-width: 520px) {
      font-size: 1rem;
    }
  }

  & .MuiTypography-body1 {
    font-size: 14px;
    @media (max-width: 520px) {
      font-size: 16px;
    }
  }

  & .MuiPaper-root {
    padding: 2rem;
    align-items: center;

    @media (max-width: 520px) {
      padding: 1rem;
    }
  }
`;

// Functions:
const AgentList = ({ open, handleClose }) => {
  const orgid = useSelector((state) => state.content.org_info?.org_data?._id);
  const [emails, setEmails] = useState([]);
  const [bit, setBit] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getOngoingConversations(orgid);
      setEmails(data);
    };

    fetchData().catch(console.error);
  }, [orgid, bit]);

  // Return:
  return (
    <StyledDialog open={open} onClose={handleClose}>
      <Tooltip title="Refresh">
        <Refresh onClick={() => setBit((prev) => !prev)}>
          <RefreshIcon />
        </Refresh>
      </Tooltip>
      <DialogTitle>
        {emails?.length > 0
          ? `The following agents are currently engaged in a conversation`
          : `Currently no agents are engaged in conversation.`}
      </DialogTitle>
      <List sx={{ pt: 0 }}>
        {emails?.map((email) => (
          <ListItem key={email}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={email} />
          </ListItem>
        ))}
      </List>
    </StyledDialog>
  );
};

// Exports:
export default AgentList;
