import moment from "moment";
import { ISO_DATE_FORMAT } from "../../constants/misc";

export const formatRecords = (records) => {
    return records?.reduce((acc, record) => ({ ...acc, [record?.recordId]: record }), {})
}

export function partitionDateRange(startDate, endDate) {
    const partitions = [];
    let currentStart = moment(startDate);
    const finalEnd = moment(endDate);

    while (currentStart.isBefore(finalEnd)) {
        let currentEnd = moment(currentStart).add(1, 'months');
        if (currentEnd.isAfter(finalEnd)) {
            currentEnd = finalEnd;
        }
        partitions.push({
            start: currentStart.format(ISO_DATE_FORMAT),
            end: currentEnd.format(ISO_DATE_FORMAT)
        });
        currentStart = currentEnd;
    }

    return partitions;
}