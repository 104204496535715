const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const DEFAULT_FILTER_PARAMS = {
    limit: 4000, 
    sort: false, 
    lastKey: {}
}

export const CHAT_FILTER_PARAMS = {
    ...DEFAULT_FILTER_PARAMS,
    limit: 900
}

export const REACTION_FILTER_PARAMS = {
    ...DEFAULT_FILTER_PARAMS,
    limit: 4500
}

export const CHART_CHAT_FILTER_PARAMS = {
    ...DEFAULT_FILTER_PARAMS,
    timezone
}