// Packages:
import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  IconButton,
  FormControl,
  FormControlLabel,
  FormLabel,
  Tooltip,
  Snackbar,
  Alert,
  Slide,
  Button
} from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SendIcon from '@mui/icons-material/Send';


// Styles:
import { Subtitle, Title, RatingText } from './styles';
import { getContentActions } from '../../../redux/actions/contentActions';
import { useDispatch, useSelector } from 'react-redux';

const StyledDialog = styled(Dialog)`
  font-family: 'Inter', sans-serif;
  & .MuiTypography-root {
    font-family: 'Inter', sans-serif;

    @media (max-width: 520px) {
      font-size: 1rem;
    }
  }
  & .MuiFormControl-root {
    margin-bottom: 1rem;
    margin-top: 1rem;

    @media (max-width: 520px) {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    & .MuiFormLabel-root {
      font-family: 'Inter', sans-serif;
      font-size: 0.9rem;

      @media (max-width: 520px) {
        font-size: 0.75rem;
      }
    }

    & .MuiFormLabel-label {
      @media (max-width: 520px) {
        font-size: 0.75rem;
      }
    }
  }
  & .MuiPaper-root {
    padding: 3rem;
    align-items: center;

    @media (max-width: 520px) {
      padding: 1rem;
    }
  }

  & .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & .MuiTypography-root {
    margin-bottom: 0;
  }

  & .MuiButtonBase-root {
    & .MuiSvgIcon-root {
      @media (max-width: 520px) {
        width: 0.75em;
        height: 0.75em;
      }
    }
  }
`;

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled
  }
}));

const StyledTextField = styled(TextField)`
  font-family: 'Inter', sans-serif;
  & .MuiOutlinedInput-root {
    font-family: 'Inter', sans-serif;
    color: #333333;
    font-size: 0.9rem;

    @media (max-width: 520px) {
      font-size: 0.75rem;
    }
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  font-family: 'Inter', sans-serif;
  & .MuiTypography-root {
    font-family: 'Inter', sans-serif;
    color: #333333;
    font-size: 0.9rem;

    @media (max-width: 520px) {
      font-size: 0.75rem;
    }
  }

  & .MuiSvgIcon-root {
    @media (max-width: 520px) {
      width: 0.75em;
      height: 0.75em;
    }
  }

  & .MuiRadio-root {
    @media (max-width: 520px) {
      padding: 5px;
    }
  }
`;

// Constants:
const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" fontSize="large" />,
    label: 'Horrible'
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" fontSize="large" />,
    label: 'Poor'
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" fontSize="large" />,
    label: 'Okay'
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" fontSize="large" />,
    label: 'Good'
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" fontSize="large" />,
    label: 'Excellent'
  }
};

// Functions:
const IconContainer = (props) => {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired
};

const TransitionDown = (props) => {
  return <Slide {...props} direction="up" />;
}

const FeedbackForm = ({ open, handleClose, handleSubmitClose, createTicket_V2 }) => {
  // State:
  const dispatch = useDispatch();
  const contentActions = getContentActions(dispatch);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const orgid = useSelector((state) => state.content.org_info?.org_data?._id);
  const [transition, setTransition] = useState(undefined);
  const [value, setValue] = useState(3);
  const [feedback, setFeedback] = useState({
    rating: '',
    comment: '',
    type: ''
  });
  const [hover, setHover] = useState(-1);
  const [alert, setAlert] = useState(false);


  // Event Handlers:
  function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${
      customIcons[value].label
    }`;
  }

  const handleFeeback = useCallback(
    (event) => {
      setFeedback({ ...feedback, comment: event.target.value });
    },
    [feedback]
  );

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlert(false);
  };

  const handleFeedbackTypeChange = useCallback(
    (event) => {
      setFeedback({ ...feedback, type: event.target.value });
    },
    [feedback]
  );

  const handleSendFeedback = useCallback((Transition) => {
    setTransition(() => Transition);
    setAlert(true);
    const email = userDetails?.email || 'anonymous';
    contentActions.addFeedback(
      orgid,
      email,
      feedback?.rating,
      feedback?.comment,
      feedback?.type
    );
    let feedbackRecord = {
      "comment": feedback?.comment,
      "rating": feedback?.rating,
      "type": feedback?.type
    }
    createTicket_V2(feedbackRecord)
    handleSubmitClose();
  }, [
    // contentActions,
    feedback?.comment,
    feedback?.rating,
    feedback?.type,
    handleSubmitClose,
    orgid,
    // userDetails?.email
  ]);

  return (
    <>
      <Snackbar 
        open={alert} 
        autoHideDuration={5000} 
        onClose={handleAlertClose}
        TransitionComponent={TransitionDown}
        anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
      >
        <Alert icon={<CheckCircleIcon />} variant="filled" onClose={handleAlertClose} severity="success" sx={{ backgroundColor: '#0B7A75' }}>
          Feedback submitted successfully. Thank You!
        </Alert>
      </Snackbar>
      <StyledDialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ mb: '2.5rem', fontSize: '1.5rem', color: '#333333' }}>
          Send us your feedback!
          <Tooltip title="close">
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <Subtitle>
            Do you have a suggestion or other feedback?
            <br />
            Rate your experience and let us know in the field below.
          </Subtitle>
          <Title style={{ color: '#333333' }}>How was your experience?</Title>
          <StyledRating
            name="highlight-selected-only"
            IconContainerComponent={IconContainer}
            getLabelText={getLabelText}
            highlightSelectedOnly
            value={value}
            defaultValue={3}
            sx={{ mb: '0.75rem' }}
            onChange={(event, newValue) => {
              setValue(newValue);
              setFeedback({ ...feedback, rating: newValue });
            }}
            onChangeActive={(event, newHover) => {
              setHover(newHover);
            }}
          />
          {value !== null && (
            <RatingText>
              {customIcons[hover !== -1 ? hover : value].label}
            </RatingText>
          )}
          <StyledTextField
            id="outlined-multiline-static"
            label="Write your experience"
            multiline
            rows={4}
            onChange={handleFeeback}
            sx={{ width: '100%' }}
          />
          <FormControl>
            <FormLabel>Feedback Type</FormLabel>
            <StyledRadioGroup row onChange={handleFeedbackTypeChange}>
              <FormControlLabel
                value="feedback"
                control={<Radio />}
                label="Feedback"
              />
              <FormControlLabel
                value="question"
                control={<Radio />}
                label="Question"
              />
              <FormControlLabel
                value="technical issue"
                control={<Radio />}
                label="Technical Issue"
              />
            </StyledRadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button 
            variant="contained"
            size="medium"
            startIcon={<SendIcon />}
            sx={{ textTransform: 'none' }}
            onClick={handleSendFeedback}
          >
            Send Feedback
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

// Exports:
export default FeedbackForm;
