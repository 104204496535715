export const CONVERSATION_TYPE = {
    CONVERSATION_TYPE_AI: "Resolved",
    CONVERSATION_TYPE_AGENT: "Handoff",
    CONVERSATION_TYPE_TICKET: "Ticket",
}

export const SENTIMENT_TYPE = {
    SENTIMENT_TYPE_POSITIVE: "Positive",
    SENTIMENT_TYPE_NEGATIVE: "Negative",
    SENTIMENT_TYPE_NEUTRAL: "Neutral"
}