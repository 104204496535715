import { toast } from "react-toastify";
import {
  ARTICLE_IS_DRAFT,
  ARTICLE_IS_PUBLISHED,
  ARTICLE_IS_UNDER_REVIEW
} from "../../constants/articles";
import { VALID_URL_REGEX } from "../../constants/regex";
import { ADMIN, SUPERVISOR, SUPER_ADMIN } from '../../constants/user';
import { deleteDraft } from "./draftsOperations";
import { archiveArticle } from "./publishedOperations";

const isAdmin = (agentType) => agentType === SUPERVISOR || agentType === SUPER_ADMIN || agentType.toLowerCase() === ADMIN;

export async function isArticleValid(article) {
  if (!article?.articleId || !article?.orgId || !article?.meta) {
    toast.error("Missing or malformed article data");
    return false;
  }
  if (article.meta.title?.length < 1) {
    toast.error("Title cannot be empty");
    return false;
  }
  if (!article.meta.target) {
    toast.error("Please select the target user for this article");
    return false;
  }
  if (!article.meta.access) {
    toast.error("Please select the access level for this article");
    return false;
  }
  if (article.meta.source?.length > 0 && !VALID_URL_REGEX.test(article.meta.source ?? '')) {
    toast.error("Source should be a valid URL or empty");
    return false;
  }
  if (!article.meta.importFromSource) {
    if (article.meta.formattedText?.length < 1) {
      toast.error('Description cannot be empty');
      return false;
    }
  } else {
    if (article.meta.source?.length < 1) {
      toast.error("Source should not be empty if 'Import' option is checked");
      return false;
    }
  }
  return true;
}

export async function deleteArticle(article, agentEmail, agentType) {
  if (!article?.articleId || !article?.orgId || !article?.meta) {
    toast.error('Missing or malformed article data!');
    return;
  }
  if (!agentEmail || !agentType) {
    toast.error('You need to be logged in to delete articles');
    return;
  }

  const user = {
    user: agentEmail,
    role: agentType
  };

  switch(article.meta.status) {
    case ARTICLE_IS_PUBLISHED:
      if (!isAdmin(agentType)) {
        toast.error('Only admins can delete published articles!');
        return;
      }
      if (article.meta.isArchived) {
        await archiveArticle(article,user)
        toast.info("Please refresh the list!")
        return;
      }
      await archiveArticle({
        ...article,
        meta: { ...article.meta, archivedBy: agentEmail }
      }, user);
      toast.info("Please refresh the list!")
      return;
    case ARTICLE_IS_DRAFT:
      if (article.meta.createdBy !== agentEmail) {
        toast.error('Drafts can only be deleted by their creator!');
        return;
      }
      await deleteDraft(article, user);
      toast.info("Please refresh the list!")
      return;
    case ARTICLE_IS_UNDER_REVIEW:
      if (article.meta.createdBy !== agentEmail && !isAdmin(agentType)) {
        toast.error('Submitted drafts can only be deleted by their creator or admins.');
        return;
      }
      await deleteDraft(article, user);
      toast.info("Please refresh the list!")
      return;
    default:
      toast.error('Missing or malformed article data!');
      return;
  }
}