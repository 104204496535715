import React, { useCallback, useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { shallowEqual, useSelector } from "react-redux";
import { applyTopicFilters, extractCoreText, recordsFilteredByPersonas, recordsFilteredByProfile } from "../utils";
import { CONVERSATION_TYPE } from "../../../constants/chat";
import { selectSessionItems } from "../../../redux/selectors/chatSelectors";
import chartConfig from '../../../config/infov3/charts.json'

const { backgroundColors } = chartConfig;
ChartJS.register(ArcElement, Tooltip, Legend);

const { CONVERSATION_TYPE_AI } = CONVERSATION_TYPE
const calculatePercentageSafely = (numerator, denominator) => {
  return denominator !== 0 ? (numerator / denominator) * 100 : 0;
};

function extractSentiments(input) {
  return input?.split("\n")[0] === "" ? "Positive" : input?.split("\n")[0] || "N/A";
}

let sentimentColorMap = {
  "Positive":"#1ED700",
  "Neutral":"#098FC8",
  "Negative":"#FF1F00",
}

function sortBothArrays(keys, values) {
  const combined = keys.map((key, index) => ({ key, value: values[index] }));
  combined.sort((a, b) => a?.key - b?.key);

  let sortedKeys = [];
  let sortedValues = [];
  for (const item of combined){
    sortedKeys.push(item.key);
    sortedValues.push(item.value)
  }
  return { sortedKeys, sortedValues };
}

function useChartData(topicFilteredRecords, queryType) {
  return useMemo(() => {
    let labels = [];
    let values = [];
    const sentimentColor = [];
    const itemCounts = {};

    for (const record of topicFilteredRecords) {
      if(record?.["analytics"]?.[queryType]?.length > 0){
        const item = queryType === "sentiment"
          ? extractSentiments(record["analytics"][queryType][0])
          : record["analytics"][queryType][0];
        itemCounts[item] = (itemCounts[item] || 0) + 1;
      }
    }

    Object.entries(itemCounts).forEach(([item, count]) => {
      if (count == 0) return; 
      labels.push(extractCoreText(item));
      values.push(count);
      if (queryType === "sentiment") {
        sentimentColor.push(sentimentColorMap[item] || "grey");
      }
    });
    if(queryType !== "sentiment") {
      let { sortedKeys: orderedValues, sortedValues: orderedLabels } = sortBothArrays(values, labels);
      labels = orderedLabels;
      values = orderedValues;
    }
    return { labels, values, sentimentColor };
  }, [topicFilteredRecords, queryType]);
}

function tooltipCallback(filteredRecords, queryType, values) {
  return (context) => {
    const label = context.label;
    const filteredData = queryType === "sentiment"
      ? filteredRecords.filter(item => extractSentiments(item["analytics"]["sentiment"][0]) === label)
      : filteredRecords.filter(item => item.analytics.intent?.[0]?.includes(label));
    const totalSessionsInIntent = filteredData?.length;
    const aiTypeConversation = filteredData.filter(item => item.conversation_type === CONVERSATION_TYPE_AI).length;

    const totalSessions = values.reduce((acc, curr) => acc + curr, 0);
    const percentageSessionInIntent = calculatePercentageSafely(totalSessionsInIntent, totalSessions).toFixed(2);
    const fcrPercentageInIntent = calculatePercentageSafely(
      aiTypeConversation,
      totalSessionsInIntent
    ).toFixed(2);

    return [
      `Percent Queries: ${percentageSessionInIntent}%`,
      `Sessions: ${totalSessionsInIntent}`,
      `FCR: ${fcrPercentageInIntent}%`,
    ];
  };
}

const UserChart = ({ queryType, persona, topicFilters, profile }) => {
  const personas = useSelector(state => state.content.personas);
  const chatRecords = useSelector(selectSessionItems, shallowEqual);
  let filteredRecords = useMemo(() => {
    let personaRecords = recordsFilteredByPersonas(chatRecords, personas, persona);
    return recordsFilteredByProfile(personaRecords, profile);
  }, [chatRecords]);
  const topicFilteredRecords = useMemo(() => applyTopicFilters(filteredRecords, topicFilters) ,[filteredRecords, topicFilters]);
  const { labels, values, sentimentColor } = useChartData(topicFilteredRecords, queryType, persona, personas, topicFilters);
  const tooltipCallbackMemo = useCallback(tooltipCallback(topicFilteredRecords, queryType, values),  [topicFilteredRecords, queryType, values]);
  const reverseLabels = useMemo(() => labels.reverse(), [labels]);
  const reverseValues = useMemo(() => values.reverse(), [values]);
  const reverseSentimentColor = useMemo(() => sentimentColor.reverse(), [sentimentColor]);

  const data = {
    labels: reverseLabels,
    datasets: [
      {
        label: "No of sessions",
        data: reverseValues,
        backgroundColor: queryType === "sentiment" ? 
        reverseSentimentColor : 
        backgroundColors,
        
        borderColor: [
          "rgb(0,0,0)",
          // Add more colors if needed
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = useMemo(() => ({
    maintainAspectRatio: false,
    aspectRatio: 2,
    plugins: {
      tooltip: { callbacks: { label: tooltipCallbackMemo } },
      legend: {
        display: false,
        position: "right",
        align: "start",
        labels: {
          fontSize: 10,
          usePointStyle: false,
          boxWidth: 10,
          padding: 20,
          generateLabels: (chart) =>
            chart.data.labels.map((label, index) => ({
              text: `${label}`,
              fillStyle: chart.data.datasets[0].backgroundColor[index],
            })),
        },
      },
    },
  }), [tooltipCallbackMemo]);


  return (
    <div style={{display:"flex",flexDirection:"column"}} >
      <div style={{ height: "160px", marginBottom:"10px" }}>
        <Doughnut data={data} options={options} />
      </div>
      <div style={{ height: "140px", overflow:"auto" }}>
      <ul style={{ listStyleType: 'none' }}>
          {labels.map((label, index) => {
            const colorIndex = index % data.datasets[0].backgroundColor.length;
            return (
              <li key={label} style={{ textAlign: 'center', textWrap: 'pretty' }}>
                <span
                  style={{
                    display: 'inline-block',
                    width: '10px', 
                    height: '10px', 
                    marginRight: '5px',
                    backgroundColor: data.datasets[0].backgroundColor[colorIndex],
                    textWrap: 'pretty'
                  }}
                />
                {label}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default UserChart;