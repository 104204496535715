// Packages:
import styled from 'styled-components'


// Exports:
export const Wrapper = styled.div`
  width: 60%;
  height: 95%;
  padding: 0.5rem;
  font-size: 14px;
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background: #ffffff;

  @media (max-width: 1024px) {
    width: 95%;
    height: 90%;
  }

  @media (max-width: 768px) {
    display: ${(props) => (props.tab === 'chat' ? '' : 'none')};
    font-size: 16px;
  }

  @media (max-width: 520px) {
    height: 85%;
  }
`
