// Packages:
import styled from 'styled-components'
import { Handle } from 'react-flow-renderer'


// Exports:
export const APINodeWrapper = styled.div`
  position: relative;
  background-color: #FEFFFE;
  border: 2px solid #7721E8;
  border-radius: 0.25rem;
  box-shadow: 0px 5px 20px -10px dimgrey;
  cursor: pointer;
  transition: all 0.25s ease;

  &:active {
    cursor: grabbing;
  }
`

export const APINodeLabel = styled.div`
  width: 100%;
  padding: 0rem 2.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.15rem;
  color: #333533;
  font-size: 0.75rem;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.5px;
  transition: all 0.25s ease;
`

export const Body = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.25rem 0.5rem;
  padding-bottom: 0.75rem;
`

export const Description = styled.div`
  display: flex;
  align-items: center;
  margin: 0.2rem 0.25rem;
  font-weight: 500;
  font-size: 0.8rem;
`

export const Field = styled.span`
  margin: 0 0.2rem;
  padding: 0.1rem 0.2rem;
  padding-right: 0.25rem;
  background: ${ props => props.isActive ? '#491094' : '#7721E8' };
  font-weight: 600;
  color: white;
  border-radius: 3px;
`

export const StyledHandle = styled(Handle)`
  z-index: 2;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #0B7A75;
  border: none;
`
