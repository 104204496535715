// Packages:
import React, { useEffect, useState } from 'react';
import {
  Button,
  MenuItem,
  FormControl,
  Select,
  styled
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


// Styles:
import {
  FieldName
} from './styles';

const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

// Functions:
const ShopifyConfiguration = ({ node, updateNode, deleteNode }) => {
  // Constants:
  const SHOPIFYFUNCTIONALITY = {
    FETCH_ORDER_STATUS:'FETCH ORDER STATUS',
    EDIT_SHIPPING_ADDRESS: 'EDIT SHIPPING ADDRESS',
    REFUND_THE_ORDER: 'REFUND THE ORDER'
  }

  // State:
  const [ shopifyFunctionality, setShopifyFunctionality ] = useState(node.shopifyFunctionality)

  // Effects:
  useEffect(() => {
    setShopifyFunctionality(node.shopifyFunctionality)
  }, [node.shopifyFunctionality])

  useEffect(() => {
    updateNode(node.id, { shopifyFunctionality })
  }, [ shopifyFunctionality ])

  // Return:
  return (
    <div>
      <FieldName>Functionality</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="shopify-functionality-select"
          value={ shopifyFunctionality }
          onChange={ e => setShopifyFunctionality(e.target.value) }
        >
          {
           Object.values(SHOPIFYFUNCTIONALITY).map((functionality, index) => (
             <StyledMenuItem key={index} value={functionality}>
               {functionality}
             </StyledMenuItem>
           ))
         }
        </Select>
      </StyledFormControl>

      <Button
        variant="contained"
        color="error"
        size="small"
        startIcon={<DeleteOutlineOutlinedIcon />}
        sx={{ textTransform: 'none', mt: '1rem' }}
        onClick={ () => deleteNode(node.id) }
      >
        Delete Node
      </Button>
    </div>
  );
};

// Exports:
export default ShopifyConfiguration;