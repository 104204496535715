// Packages:
import styled from 'styled-components'


// Exports:
export const Wrapper = styled.div`
  max-width: 100%;
`

export const SearchInput = styled.input`
  position: relative;
  font-size: 14px;
  width: 100%;
  height: 3rem;
  padding: 20px;
  color: #656565;
  box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%);
  border: 1px solid #c2c2c2;
  border-radius: 4px;

  @media (max-width: 768px) {
    height: 2.5rem;
    font-size: 16px;
  }

  @media (max-width: 360px) {
    height: 2rem;
  }

  &:hover {
    background: #e8f0fe;
    border: 1px solid #2f80ed;
  }

  &:focus {
    outline: none;
    color: #000000;
    background: #e8f0fe;
    border: 1px solid #2f80ed;
  }
`
