import React, { useState } from "react";
import {
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import { encryptData } from "./encrypt";

const Secrets = ({ secrets, setSecrets, isEditable }) => {
  const [newSecret, setNewSecret] = useState({ key: "", value: "" });
  const [editingIndex, setEditingIndex] = useState(null);
  const [isAddingNewSecret, setIsAddingNewSecret] = useState(false);
  const [error, setError] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(null); // 'edit' or 'delete'
  const [selectedSecretIndex, setSelectedSecretIndex] = useState(null); // index of selected secret

  const handleAddNewSecret = () => {
    setIsAddingNewSecret(true);
    setNewSecret({ key: "", value: "" });
    setError("");
  };

  const handleSaveSecret = async() => {
    if (!newSecret.key) return;

    const isDuplicate = secrets.some(
      (secret, index) => secret.key === newSecret.key && index !== editingIndex
    );

    if (isDuplicate) {
      setError("A secret with this key already exists.");
      return;
    }
    const encryptedValue = await encryptData(newSecret.value,process.env.REACT_APP_ENCRYPTION_KEY)
    if (editingIndex !== null) {
      const updatedSecrets = secrets.map((secret, index) =>
        index === editingIndex
          ? { key: newSecret.key, value: encryptedValue }
          : secret
      );
      setSecrets(updatedSecrets);
      setEditingIndex(null);
    } else {
      setSecrets([...secrets, { key: newSecret.key, value: encryptedValue }]);
    }

    setNewSecret({ key: "", value: "" });
    setIsAddingNewSecret(false);
    setError("");
  };

  const handleEditSecret = (index) => {
    setEditingIndex(index);
    setNewSecret({ key: secrets[index].key, value: "" });
    setIsAddingNewSecret(true);
    setError("");
  };

  const handleDeleteSecret = (index) => {
    const updatedSecrets = secrets.filter((_, i) => i !== index);
    setSecrets(updatedSecrets);
  };

  const handleOpenDialog = (action, index) => {
    setDialogAction(action);
    setSelectedSecretIndex(index);
    setDialogOpen(true);
  };

  const handleConfirmDialog = () => {
    setDialogOpen(false);

    if (dialogAction === "edit") {
      handleEditSecret(selectedSecretIndex);
    } else if (dialogAction === "delete") {
      handleDeleteSecret(selectedSecretIndex);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleAddNewSecret}
        sx={{ marginBottom: 1, textTransform: 'none' }}
        disabled={editingIndex!==null || !isEditable}
      >
        Add New Secret
      </Button>

      {isAddingNewSecret && (
        <Box mt={2} mb={2}>
          <Box
            sx={{ display: "flex", flexDirection: "row", marginBottom: "1rem" }}
          >
            <TextField
              label="Secret_Key"
              value={newSecret.key}
              onChange={(e) => setNewSecret({ ...newSecret, key: e.target.value })}
              style={{ width: "40%" }}
              size="small"
              error={Boolean(error)}
              helperText={error}
              disabled={editingIndex !== null || !isEditable}
            />
            <TextField
              label="Secret_Value"
              value={newSecret.value}
              onChange={(e) =>
                setNewSecret({ ...newSecret, value: e.target.value })
              }
              style={{ width: "60%" }}
              size="small"
              disabled={!isEditable}
            />
          </Box>
          <Button
            variant="contained"
            onClick={handleSaveSecret}
            disabled={!newSecret.value || !newSecret.key || !isEditable}
          >
            {editingIndex !== null ? "Save Changes" : "Save Secret"}
          </Button>
        </Box>
      )}

      {secrets.length > 0 && (
        <Box mt={2} mb={2}>
          <Typography variant="h6">Saved Secrets</Typography>
          {secrets.map((secret, index) => (
            <Box
              key={index}
              mt={2}
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "5px",
                justifyContent: "space-between",
                backgroundColor: "lightgray",
                padding:'4px'
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton size="small" style={{cursor:'default'}}>
                  <LockIcon sx={{fontSize:'16px'}}/>
                </IconButton>
                <Typography fontSize='16px'>{secret.key}</Typography>
              </Box>
              <Box>
                <IconButton
                  size="small"
                  onClick={() => handleOpenDialog("edit", index)}
                  disabled={editingIndex === index || !isEditable}
                >
                  <EditIcon sx={{fontSize:'16px'}}/>
                </IconButton>
                <IconButton onClick={() => handleOpenDialog("delete", index)}
                  disabled={editingIndex === index || !isEditable}>
                  <DeleteIcon sx={{fontSize:'16px'}} />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      )}

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>
          {dialogAction === "delete" ? "Confirm Deletion" : "Confirm Edit"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {dialogAction} the secret:{" "}
            <strong>{secrets[selectedSecretIndex]?.key}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmDialog} color="primary">
            {dialogAction === "delete" ? "Delete" : "Edit"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Secrets;