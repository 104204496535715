import React from 'react';

const PageNotFound = () => {
  return (
    <div id="wrapper">
      <div
        style={{
          display: 'flex',
          'flexDirection': 'column',
          'alignItems': 'center'
        }}
      >
        <h1>404</h1>
        <h3>This page could not be found</h3>
      </div>
    </div>
  );
};

export default PageNotFound;
