// Packages:
import React, { useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components:
import { ChatConversation, Reply } from "../";
import { WebSocketConnectionContext } from "../../../App";
import {
  addConversation,
  clearChat,
  startSlackConversation,
  startStopWorkflow,
  stopAgentChatWorkflow,
  stopWorkflowExecution,
} from "../../../redux/actions/chatActions";
import {
  clearContent,
  contentActions,
} from "../../../redux/actions/contentActions";

// Styles:
import { Wrapper } from "./styles";

// Functions:

const ChatBox = ({ tab, setTab, numberOfResources, previewSource }) => {
  const chats = useSelector((state) => state.chat.chats);
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(
      addConversation({
        type: "bot",
        subtype: "received",
        message: "OK! First, please provide your email address.",
      })
    );
    localStorage.setItem("isEamilFieldActive", true);
    dispatch(startSlackConversation());
  }, [dispatch]);

  const leaveChat = useCallback(() => {
    dispatch(clearContent());
    dispatch({
      type: contentActions.SET_WORKFLOWS,
      workflows: [],
    });
    dispatch(
      clearChat([
        {
          id: "0",
          type: "bot",
          subtype: "received",
          message:
            "Hello! I’m an AI Agent. This is a recorded chat. How can I help you?",
        },
      ])
    );
    dispatch(startStopWorkflow(false));
    dispatch(stopWorkflowExecution());
    dispatch(stopAgentChatWorkflow());
  }, [dispatch]);

  return (
    <Wrapper tab={tab}>
      <ChatConversation
        leaveChat={leaveChat}
        setTab={setTab}
        numberOfResources={numberOfResources}
        handleClick={handleClick}
        previewSource={previewSource}
      />
      {!chats[chats.length - 1]?.formFields && <Reply />}
    </Wrapper>
  );
};

// Exports:
export default ChatBox;
