export const PROMPTS = {
  query: "query",
  auto:"auto"
};

export const DEFAULT_USER_DETAILS = [{
  key:"email",
  value:{
    fieldName:null,
    value:null
  }
},
{
  key:"fullname",
  value:{
    fieldName:null,
    value:null
  }
},
{
  key:"personas",
  value:{
    fieldName:null,
    value:null
  }
}
];  