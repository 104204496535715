import React, { useCallback, useEffect, useRef, useState } from 'react';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import Editor from '@draft-js-plugins/editor';
import { EditorState, ContentState  } from 'draft-js';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import Scrollbars from 'react-custom-scrollbars-2';
import parse from 'html-react-parser';
import { urlify } from '../../../lib/urlify';
import sanitizeHtml from 'sanitize-html';

import { Description, DescriptionArea } from './styles';

const hashtagPlugin = createHashtagPlugin();
const linkifyPlugin = createLinkifyPlugin();

const plugins = [linkifyPlugin, hashtagPlugin];

const DescriptionComponent = ({
  isEdit,
  articleData,
  changeArticle,
  articleBody,
  changeArticleBody
}) => {
  const descriptionAreaRef = useRef(null);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(convertFromHTML('<p></p>'))
  );

  const handleChange = useCallback((editorState) => {
    setEditorState(editorState);
  }, []);

  const readOnly = articleData?.meta?.importFromSource;

  useEffect(() => {
    if (articleData && Object.keys(articleData)?.length > 0) {
      let sanitizedArticleBody = urlify(articleBody);
      sanitizedArticleBody = articleBody?.split('<p></p>')?.filter((item)=>item!=='').join("\r\n");
      sanitizedArticleBody = sanitizedArticleBody?.split('<p>')?.filter((item)=>item!=='').join("");
      sanitizedArticleBody = sanitizedArticleBody?.split('</p>')?.filter((item)=>item!=='').join("\r\n");
      sanitizedArticleBody = sanitizedArticleBody?.replaceAll('</br>',"\r\n");
      const createContentFromString = ContentState.createFromText(sanitizedArticleBody);
      setEditorState(EditorState.createWithContent(createContentFromString));
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      let content = convertToHTML(editorState.getCurrentContent());
      let currentContentAsHTML = urlify(content);
      const newArticleData = {
        ...articleData,
        meta: {
          ...articleData?.meta,
          firstLine: content < 105
            ? sanitizeHtml(urlify(content), {
                allowedTags: [],
                allowedAttributes: {}
              })
            : sanitizeHtml(urlify(content.slice(0, 100)), {
                allowedTags: [],
                allowedAttributes: {}
              }),
          formattedText: content
        }
      };
      currentContentAsHTML = currentContentAsHTML?.replaceAll('<p></p>',"</br>");
      currentContentAsHTML = urlify(currentContentAsHTML)
      changeArticleBody(currentContentAsHTML);
      changeArticle(newArticleData);
    }
  }, [changeArticle, changeArticleBody, editorState, isEdit]);

  useEffect(() => {
    if (descriptionAreaRef.current && isEdit)
      descriptionAreaRef.current.focus();
  }, [isEdit]);

  return (
    <Scrollbars
      style={{
        width: '100%',
        height: isEdit ? 'calc(100% - 10rem)' : 'calc(100% - 7.5rem)',
        borderRadius: '0.25rem',
        background: isEdit ? '#e8f0fe' : 'transparent'
      }}
    >
      <Description
        style={{
          height:
            articleData?.meta?.formattedText?.length === 0 ? '10rem' : 'auto',
          padding: isEdit ? '0.75rem' : '0'
        }}
      >
        {!isEdit && (
          <DescriptionArea
            ref={descriptionAreaRef}
            contentEditable={isEdit}
            isEdit={isEdit}
          >
            {parse(articleBody)}
          </DescriptionArea>
        )}
        {isEdit && (
          <Editor
            editorState={editorState}
            readOnly={readOnly}
            plugins={plugins}
            onChange={handleChange}
            placeholder={readOnly ? "" : "Enter article description here"}
          />
        )}
      </Description>
    </Scrollbars>
  );
};

export default DescriptionComponent;