import React from 'react';
import { Radios } from './styles';

const Target = ({ onTargetChange, articleData }) => {
  return (
    <Radios onChange={onTargetChange}>
      <span
        style={{
          fontWeight: 'bold',
          marginRight: '0.75rem',
          color: '#333333'
        }}
      >
        Target:
      </span>
      <input
        type="radio"
        value="endUser"
        name="target"
        defaultChecked={
          articleData?.meta?.target
            ? articleData?.meta?.target === 'endUser'
            : true
        }
        style={{ marginRight: '0.5rem', cursor: 'pointer' }}
      />{' '}
      End User
      <input
        type="radio"
        value="agent"
        name="target"
        defaultChecked={articleData?.meta?.target === 'agent'}
        style={{
          marginLeft: '1rem',
          marginRight: '0.5rem',
          cursor: 'pointer'
        }}
      />{' '}
      Agent
      <input
        type="radio"
        value="partner"
        name="target"
        defaultChecked={articleData?.meta?.target === 'partner'}
        style={{
          marginLeft: '1rem',
          marginRight: '0.5rem',
          cursor: 'pointer'
        }}
      />{' '}
      Partner
    </Radios>
  );
};

export default Target;
