// Packages:
import React from 'react'
import {
    OutlinedInput,
    InputAdornment,
    FormControl,
    styled,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    InputLabel
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { GAP_TYPE_EVENTS } from '../../../constants/events';
import { Tooltip } from '@mui/material'
import { GAPS_TOOLTIPS } from '../../../constants/tooltips';
const { GAP_TYPE_TOOLTIPS } = GAPS_TOOLTIPS;
// Styles:
const StyledFormControl = styled(FormControl)`
  font-size: 14px;
  background: #FFFFFF;
  & .MuiOutlinedInput-root {
    font-size: 14px;
  }
`;


// Functions:
export const ColumnFilter = ({ column }) => {
    // Constants:
    const { filterValue, setFilter, Header: header } = column
    // Return:
    return (
        <span>
            <ConditionalRenderingFilter header={header} filterValue={filterValue} setFilter={setFilter} />
        </span>
    )
}


function ConditionalRenderingFilter({ header, filterValue, setFilter }) {
    if (header === "Gap Type") {
        return (
            <>
                <FormControl variant="outlined" >
                    <Select
                        multiple
                        id="gaptype-outlined"
                        value={filterValue || []}
                        onChange={(e) => setFilter(e.target.value?.length == 0 ? "" : e.target.value)}
                        sx={{ minWidth: 130, height: 37, backgroundColor: "white", maxWidth: 170 }}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {Object.entries(GAP_TYPE_EVENTS).map(([key, value]) => (
                            <MenuItem key={key} value={value}>
                                <Checkbox checked={!!filterValue?.includes(value)} />
                                <Tooltip title={value} placement="left" arrow>
                                    {value}
                                </Tooltip>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </>
        )
    }
    return (
        <>
            <StyledFormControl variant="outlined">
                <OutlinedInput
                    type="text"
                    value={filterValue || ''}
                    onChange={(e) => setFilter(e.target.value)}
                    placeholder="Filter"
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchOutlinedIcon sx={{ fontSize: '20px' }} />
                        </InputAdornment>
                    }
                    size="small"
                />
            </StyledFormControl>
        </>
    )
}