// Packages:
import styled from 'styled-components'


// Exports:
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const Title = styled.div`
  margin-top: ${ props => props.showAsLinks ? '100px' : '200px' };
  margin-bottom: 30px;
  font-size: ${ props => props.showAsLinks ? '22px' : '28px' };
  transition: all 0.25s ease;
  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 520px) {
    margin-top: ${ props => props.isActive ? '6rem' : '12rem' };
    transition: all 0.25s ease;
  }
`

export const CommonProblems = styled.div`
  width: 50%;
  max-width: 50vw;
  margin-top: ${ props => props.isVisible ? '8.938rem' : 0 };
  font-size: 14px;
  max-height: ${ props => props.isVisible ? '200rem' : 0 };
  overflow: ${ props => props.isVisible ? 'unset' : 'hidden' };
  transition: all 0.25s ease;

  @media (max-width: 520px) {
    font-size: 16px;
  }

  h1 {
    margin: 0;
    font-size: 14px;

    @media (max-width: 520px) {
      font-size: 16px;
    }
  }

  ul {
    padding-left: 0;
    list-style-type: none;
  }
`

export const ListItem = styled.div`
  margin-top: 0.75rem;
`

export const Help = styled.div`
  display: ${ props => props.isVisible ? 'block' : 'none' };
  width: 50%;
  font-size: 14px;
  font-weight: 400;
  margin-top: 40px;
  padding-bottom: 40px;

  @media (max-width: 520px) {
    width: 90%;
    margin-top: 1rem;
    font-size: 16px;
  }

  h3 {
    margin: 0;
    font: inherit;
  }
`