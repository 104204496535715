// Packages:
import styled from 'styled-components';

// Exports:
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-top: 6rem;
  padding-bottom: 2rem;

  @media (max-width: 1024px) {
    width: 98%;
  }
`;

export const Subtitle = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
  color: #333333;
`;

export const Form = styled.div`
  width: 35%;
  padding: 2.25rem 1.5rem;
  font-size: 16px;
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  background: #ffffff;
  border-radius: 4px;
`;

export const InputField = styled.div`
  margin-bottom: 2rem;
`;

export const Tag = styled.div`
  font-size: 14px;
  color: #828282;
`;

export const Input = styled.input`
  width: calc(100% - 1rem);
  font-family: 'Inter', sans-serif;
  background-color: transparent;
  border: none;
  outline: none;

  @media (max-width: 520px) {
    font-size: 0.75rem;
  }
`;