// Packages:
import styled from 'styled-components'


export const Subtitle = styled.p`
    text-align: center; 
    font-size: 14px; 
    color: #333333; 
    line-height: 1.25rem; 
    margin-top: 0;
    margin-bottom: 3rem;

    @media (max-width: 768x) {
        margin-bottom: 0.5rem;
        font-size: 12px;
        line-height: unset;
    }
`

export const Title = styled.h3`
    font-size: 14px;

    @media (max-width: 768x) {
        font-size: 16px;
    }
`

export const RatingText = styled.div`
    font-size: 14px;

    @media (max-width: 768x) {
        margin-bottom: 0.5rem;
        font-size: 16px;
    }
`