// Packages:
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Position } from 'react-flow-renderer';
import hasNodeMoved from '../../../../lib/hasNodeMoved';

// Redux:
import { setSelectedNode } from '../../../../redux/actions/studioActions';

// Styles:
import {
  DecisionNodeWrapper,
  DecisionNodeLabel,
  Body,
  Description,
  Field,
  StyledHandle
} from './styles';

// Functions:
const DecisionNode = ({ data }) => {
  // Constants:
  const dispatch = useDispatch();
  const { id, orderId, decisionType, label } = data;

  // Ref:
  const wrapperRef = useRef(null);

  // State:
  const studio = useSelector((state) => state.studio);
  const [clickOrigin, setClickOrigin] = useState({ x: 0, y: 0 });

  // Functions:
  const handleMouseDown = useCallback(
    (e) => {
      if (e.button === 2) e.stopPropagation();
      if (e.button === 0) {
        setClickOrigin({ x: e.clientX, y: e.clientY });
        dispatch(setSelectedNode(data));
      }
    },
    [data, dispatch]
  );

  const handleMouseUp = useCallback(
    (e) => {
      if (e.button === 0) {
        if (hasNodeMoved(clickOrigin, e)) return;
      }
    },
    [clickOrigin]
  );

  // Effects:
  useEffect(() => {
    wrapperRef.current.addEventListener('mousedown', handleMouseDown);
    wrapperRef.current.addEventListener('mouseup', handleMouseUp);
  }, [handleMouseDown, handleMouseUp, clickOrigin, studio.selectedNode]);

  // Return:
  return (
    <DecisionNodeWrapper
      ref={wrapperRef}
      style={{
        backgroundColor: studio.selectedNode?.id === id ? '#FE7920' : '#FFFFFF',
        borderColor: '#FE7920'
      }}
    >
      <StyledHandle
        type="target"
        position={Position.Top}
        style={{ backgroundColor: 'blue', top: '-15px' }}
      />
      {/* { decisionType === 'binary' ?
        <>
          <StyledHandle
            id='decision-node-output'
            type='source'
            position={Position.Bottom}
            style={{
              backgroundColor: '#65B891',
              left: '25%'
            }}
          />
          <StyledHandle
            id='decision-node-output-false'
            type='source'
            position={Position.Bottom}
            style={{
              backgroundColor: '#ED1C24',
              left: '75%'
            }}
          />
        </>
      : decisionType === 'multiple' ?
        <StyledHandle
            id='decision-node-output'
            type='source'
            position={Position.Bottom}
            style={{
              backgroundColor: '#333333'
            }}
          />
        : <></>
      } */}
      <StyledHandle
        id="decision-node-output"
        type="source"
        position={Position.Bottom}
        style={{
          backgroundColor: 'green',
          bottom: '-15px'
        }}
      />
      <DecisionNodeLabel
        htmlFor="text"
        style={{
          color: studio.selectedNode?.id === id ? '#FFFFFF' : '#333533'
        }}
      >
        {label}
      </DecisionNodeLabel>
      <Body
        style={{
          color: studio.selectedNode?.id === id ? '#FFFFFF' : '#333533'
        }}
      >
        <Description>
          <Field isActive={studio.selectedNode?.id === id}>
            {!decisionType ? (
              <span style={{ fontStyle: 'italic', fontWeight: 400 }}>
                Decision Type{' '}
              </span>
            ) : (
              decisionType
            )}
          </Field>
        </Description>
        <Description>
          <Field isActive={studio.selectedNode?.id === id}>
            {orderId.length === 0 ? (
              <span style={{ fontStyle: 'italic', fontWeight: 400 }}>
                Enter a decision criteria...
              </span>
            ) : (
              orderId
            )}
          </Field>
        </Description>
      </Body>
    </DecisionNodeWrapper>
  );
};

// Exports:
export default DecisionNode;
