import React, { memo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import ROUTES from "../../constants/routes";

const RequireAuth = memo(({ children, isAuthenticated }) => {
  const location = useLocation();

  if (!isAuthenticated)
    return (
      <Navigate to={ROUTES.AGENT.LOGIN} state={{ path: `${location.pathname ?? ROUTES.INDEX}${location.search}` }} /> 
    );

  return children;
});

export default RequireAuth;