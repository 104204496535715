import styled from 'styled-components';

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Tags = styled.span`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  flex-direction: row;
  margin-right: 0.5rem;
`;