import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const GoBack = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background: transparent;
  user-select: none;
  cursor: pointer;
  transition: all 0.25s ease;
  &:hover {
    background: #f4f4f9;
  }
`;

export const Autosave = styled.div`
  display: flex;
  font-size: 14px;
  padding-left: 8px;
  color: #5a5a5a;
  cursor: pointer;

  @media (max-width: 520px) {
    font-size: 16px;
  }
`;
