import { fetchDialogidMappingAPI } from '../../../api';

const fetchDialogIdFn = async (articleData, orgid) => {
  if (!articleData?.dialog_id && articleData?.meta?.articleId) {
    let result;
    try {
      result = await fetchDialogidMappingAPI(
        orgid,
        articleData?.meta?.articleId
      );
    } catch (error) {
      console.error(error);
    }
    return result.dialog_id;
  } else return articleData.dialog_id;
};

export default fetchDialogIdFn;
