import { Position } from 'react-flow-renderer';
export const config = {
    styles: {
      wrapper: {
        default: {
          backgroundColor: "#FFFFFF",
          borderColor: '#3AA640'
        },
        selected: {
          backgroundColor: '#3AA640'
        },
      },
      label: {
        default: {
          color: "#333533",
        },
        selected: {
          color: "#FFFFFF",
        },
      },
      body: {
        default: {
          color: "#333533",
        },
        selected: {
          color: "#FFFFFF",
        },
      },
    },
    handleStyles: [
      {
        properties: {
          type:"source",
          position:Position.Bottom
        },
        style: {
          backgroundColor: "green",
          bottom: '-15px'
        },
      },
    ],
  };