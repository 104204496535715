import reconcileEntityChanges from "../reconcileEntityChanges";

const tagsModifier = (tags) => {
  if (!tags) {
    return [];
  }
  return tags.map((tag) => tag.desc);
};

const tagsComparator = (oldTags, newTags) => {
  oldTags = oldTags ?? [];
  newTags = newTags ?? [];
  if (oldTags.length !== newTags.length) {
    return false;
  }
  return oldTags.every((tag, idx) => tag.desc === newTags[idx].desc);
};

const synonymsModifier = (synonyms) => {
  if (!synonyms) {
    return [];
  }
  return synonyms.map(({ key, description }) => ({ key, description }));
};

const synonymsComparator = (oldSynonyms, newSynonyms) => {
  oldSynonyms = oldSynonyms ?? [];
  newSynonyms = newSynonyms ?? [];
  if (oldSynonyms.length !== newSynonyms.length) {
    return false;
  }
  const callback = (synonym, idx) => {
    return synonym.key === newSynonyms[idx].key
      && synonym.description === newSynonyms[idx].description;
  }
  return oldSynonyms.every(callback);
};

const formattedTextModifier = () => '';

const formattedTextComparator = (oldText, newText) => {
  oldText = oldText ?? '';
  newText = newText ?? '';
  if (oldText.length !== newText.length) {
    return false;
  }
  return oldText === newText;
};

function reconcileKAChanges(previousData, newData) {
  const fields = [
    {
      name: 'access'
    },
    {
      name: 'target'
    },
    {
      name: 'isarticle'
    },
    {
      name: 'question'
    },
    {
      name: 'source'
    },
    {
      name: 'tags',
      comparator: tagsComparator,
      modifier: tagsModifier
    },
    {
      name: 'trainingPhrases',
      comparator: tagsComparator,
      modifier: tagsModifier
    },
    {
      name: 'synonyms',
      comparator: synonymsComparator,
      modifier: synonymsModifier
    },
    {
      name: 'formattedText',
      comparator: formattedTextComparator,
      modifier: formattedTextModifier
    }
  ];

  return reconcileEntityChanges(previousData, newData, fields);
}

export default reconcileKAChanges;