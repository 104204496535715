// Packages:
import React from 'react'
import Button from '@mui/material/Button';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


// Functions:
const EndConfiguration = ({ node, deleteNode }) => {
  // Return:
  return (
    <div>
        <Button
            variant="contained"
            color="error"
            size="small"
            startIcon={<DeleteOutlineOutlinedIcon />}
            sx={{ textTransform: 'none', mt: '1rem' }}
            onClick={ () => deleteNode(node.id) }
        >
            Delete Node
        </Button>
    </div>
  )
}


// Exports:
export default EndConfiguration
