function reconcileEntityChanges(previousData, newData, fields) {
  const changes = {};

  const defaultComparator = (a, b) => a === b;
  const defaultModifier = (a) => a;

  const getValue = (fieldName, data) => {
    if (data.hasOwnProperty(fieldName)) {
      return data[fieldName];
    }
    if (data.meta?.hasOwnProperty(fieldName)) {
      return data.meta[fieldName];
    }
    return null;
  };

  for (const field of fields) {
    const previousValue = getValue(field.name, previousData);
    const newValue = getValue(field.name, newData);

    const comparator = field.comparator ?? defaultComparator;
    
    if (!comparator(previousValue, newValue)) {
      const modifier = field.modifier ?? defaultModifier;
      changes[field.name] = {
        newValue: modifier(newValue),
        previousValue: modifier(previousValue)
      };
    }
  }

  return changes;
}

export default reconcileEntityChanges;