// Packages:
import React, { useState, useEffect } from 'react'
import useInput from '../../../../lib/hooks/use-input'
import {
  Button,
  TextField,
  styled
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


// Styles:
import {
  FieldName,
  VariableField,
  Variable
} from './styles'

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;


// Functions:
export const getVariableNames = (templateString) => {
  return (templateString.match(/\{[a-zA-Z]+\}/g) ?? []).map(rawVariableName => rawVariableName.slice(1,-1))
}

const EmailConfiguration = ({ node, updateNode, deleteNode }) => {
  // State:
  const { value: from, setValue: setFrom, bind: bindFrom } = useInput(node.from ?? '')
  const { value: subject, setValue: setSubject, bind: bindSubject } = useInput(node.subject ?? '')
  const [ subjectVariables, setSubjectVariables ] = useState([])
  const { value: body, setValue: setBody, bind: bindBody } = useInput(node.body ?? '')
  const [ bodyVariables, setBodyVariables ] = useState([])

  // Effects:
  useEffect(() => {
    setFrom(node.from)
    setSubject(node.subject)
    setBody(node.body)
  }, [ node.from, node.subject, node.body ])

  useEffect(() => {
    updateNode(node.id, { from })
  }, [ from ])

  useEffect(() => {
    setSubjectVariables(getVariableNames(subject))
    updateNode(node.id, { subject })
  }, [ subject ])

  useEffect(() => {
    setBodyVariables(getVariableNames(body))
    updateNode(node.id, { body })
  }, [ body ])

  // Return:
  return (
    <div>
      <FieldName>From</FieldName>
      <StyledTextField 
        id="email-from" 
        variant="outlined" 
        placeholder='hello@readyly.com'
        { ...bindFrom }
        size="small"
        fullWidth
      />

      <FieldName>Subject</FieldName>
      <StyledTextField 
        id="email-subject" 
        variant="outlined" 
        placeholder='Enter a subject'
        { ...bindSubject }
        size="small"
        fullWidth
      />

      <VariableField>
        { subjectVariables.map(variable => <Variable key={ variable }>{ variable }</Variable>) }
      </VariableField>
      <div style={{ width: '100%', height: '1rem' }} />
      <FieldName>Body</FieldName>
      <StyledTextField 
        id="email-body" 
        variant="outlined" 
        placeholder='Write an email...'
        { ...bindBody }
        multiline
        rows={10}
        size="small"
        fullWidth
      />

      <VariableField>
        { bodyVariables.map(variable => <Variable key={ variable }>{ variable }</Variable>) }
      </VariableField>

      <Button
        variant="contained"
        color="error"
        size="small"
        startIcon={<DeleteOutlineOutlinedIcon />}
        sx={{ textTransform: 'none', mt: '1rem' }}
        onClick={ () => deleteNode(node.id) }
      >
        Delete Node
      </Button>
    </div>
  )
}


// Exports:
export default EmailConfiguration
