export function extractCoreText(verboseText) {
    if (!verboseText) return "";
    // Find the position of the core topic by locating the single quotes
    const startPos = verboseText.indexOf('"') + 1; // Start after the first quote
    const endPos = verboseText.lastIndexOf('"'); // Find the last quote
  
    // Extract the topic based on the positions of the single quotes
    if (startPos > 0 && endPos > startPos) {
      return verboseText.substring(startPos, endPos);
    }
  
    // Return original label if the pattern doesn't match
    return verboseText;
}