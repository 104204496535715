// Packages:
import React, { useRef, useState, useEffect } from 'react'
import useInput from '../../../../lib/hooks/use-input'
import uuid from 'react-uuid'
import {
  Button,
  TextField,
  styled
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


// Imports:
import { IoMdAddCircle, IoMdRemoveCircle } from 'react-icons/io'


// Styles:
import {
  FieldName,
  TableHeader,
  TableData,
  AddNewVariable,
  VariableField,
  Variable
} from './styles'

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;


// Functions:
const parseOutputObject = (templateString) => {
  try {
    const templateObject = JSON.parse(templateString)
    return ({
      success: {
        ...templateObject._success._output
      },
      failure: {
        ...templateObject._failure._output
      }
    })
  } catch(e) {
    throw new Error('Invalid Template JSON')
  }
}

const APIConfiguration = ({ node, updateNode, deleteNode }) => {
  // Constants:
  const DEFAULT_INPUT = () => ({ id: uuid(), key: '', description: '' })

  // Ref:
  const codeAreaRef = useRef(null)

  // State:
  const [ inputs, setInputs ] = useState(node.inputs)
  const { value: endpoint, setValue: setEndpoint, bind: bindEndpoint } = useInput(node.endpoint ?? '')
  const { value: template, setValue: setTemplate, bind: bindTemplate } = useInput(node.template ?? '')
  const [ templateOutput, setTemplateOutput ] = useState({
    success: {},
    failure: {}
  })

  // Effects:
  useEffect(() => {
    setInputs(node.inputs)
    setEndpoint(node.endpoint)
    setTemplate(node.template)
  }, [ node.inputs, node.endpoint, node.template ])

  useEffect(() => {
    updateNode(node.id, { inputs, endpoint, template })
  }, [ inputs, endpoint, template ])

  useEffect(() => {
    try {
      setTemplateOutput(parseOutputObject(template))
    } catch(e) {
      console.log(e);
    }
  }, [ template ])

  // Return:
  return (
    <div>
      <FieldName>Inputs</FieldName>
      <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
        <thead style={{ borderBottom: '0.5rem solid transparent' }}>
          <tr>
            <TableHeader>KEY</TableHeader>
            <TableHeader>DESCRIPTION</TableHeader>
          </tr>
        </thead>
        <tbody>
          {
            inputs.map((input, i) => (
              <tr key={ `tr-${ input.id }` }>
                <TableData style={{ width: '5rem' }}>
                  <StyledTextField 
                    id="api-key" 
                    variant="outlined" 
                    placeholder='Key'
                    value={ input.key }
                    onChange={ event => {
                      event.preventDefault()
                      event.stopPropagation()
                      const newInputs = [ ...inputs ]
                      newInputs[i] = { ...input, key: event.currentTarget.value }
                      setInputs(newInputs)
                    }}
                    size="small"
                    sx={{mb: '0'}}
                  />
                </TableData>
                <TableData>
                  <StyledTextField 
                    id="api-description" 
                    variant="outlined" 
                    placeholder='Description'
                    value={ input.description }
                    onChange={ event => {
                      event.preventDefault()
                      event.stopPropagation()
                      const newInputs = [ ...inputs ]
                      newInputs[i] = { ...input, description: event.currentTarget.value }
                      setInputs(newInputs)
                    }}
                    size="small"
                    sx={{mb: '0'}}
                  />
                </TableData>
                <TableData>
                  <IoMdRemoveCircle
                    style={{ marginLeft: '0.5rem', fontSize: '1.2rem', color: '#CE4760', cursor: 'pointer' }}
                    onClick={ () => setInputs([ ...inputs ].slice(0, -1)) }
                  />
                </TableData>
              </tr>
            ))
          }
        </tbody>
      </table>
      <AddNewVariable onClick={ () => setInputs([ ...inputs, DEFAULT_INPUT() ]) }>
        <IoMdAddCircle style={{ marginRight: '0.25rem', fontSize: '1rem' }} /> Add new variable
      </AddNewVariable>
      <div style={{ width: '100%', height: '1rem' }} />

      <FieldName>Endpoint</FieldName>
      <StyledTextField 
        id="api-endpoint" 
        variant="outlined" 
        placeholder='Name of the endpoint'
        { ...bindEndpoint }
        size="small"
        fullWidth
      />

      <FieldName>Template</FieldName>
      <StyledTextField 
        id="api-template" 
        variant="outlined"
        ref={ codeAreaRef } 
        placeholder='Enter code for template mapping..'
        { ...bindTemplate }
        onKeyDown={
          e => e.key === 'Tab' ?
          (() => {
            e.preventDefault()
            const start = codeAreaRef.current.selectionStart
            const end = codeAreaRef.current.selectionEnd
            codeAreaRef.current.value = codeAreaRef.current.value.substring(0, start) + '  ' + codeAreaRef.current.value.substring(end)
            setTemplate(codeAreaRef.current.value)
            codeAreaRef.current.selectionStart = codeAreaRef.current.selectionEnd = start + 2
          })() :
          null
        }
        multiline
        rows={10}
        size="small"
        fullWidth
      />

      <FieldName>Outputs</FieldName>
      <VariableField>
        { Object.keys(templateOutput.success).map(variable => <Variable key={ variable }>{ variable }</Variable>) }
      </VariableField>
      <div style={{ width: '100%', height: '1rem' }} />

      <Button
        variant="contained"
        color="error"
        size="small"
        startIcon={<DeleteOutlineOutlinedIcon />}
        sx={{ textTransform: 'none', mt: '1rem' }}
        onClick={ () => deleteNode(node.id) }
      >
        Delete Node
      </Button>
    </div>
  )
}


// Exports:
export default APIConfiguration
