// Packages:
import React, { memo } from "react";
import { useSelector } from "react-redux";
// Styles:
import { Body, Description, Field, NodeLabel, NodeWrapper, StyledHandle } from "../styles";
import BaseNode from "../BaseNode";
import { config } from "./config";
import useNodeStyles from "../../../../hooks/useNodeStyles";

// Functions:
const AINode = ({ data }) => {
  // Constants:
  const { id,label,action } = data;
  // State:
  const studio = useSelector((state) => state.studio);

  const isSelected = useSelector(
    (state) => state.studio.selectedNode?.id === id
  );

  const {computedLabelStyle,computedBodyStyle,computedWrapperStyle}=useNodeStyles({isSelected,config})

  // Return:
  return (
    <BaseNode data={data} config={config}>
      <NodeWrapper style={computedWrapperStyle} backgroundColor={'#FEFFFE'} borderColor={'#7721E8'} >
      {config.handleStyles.map((handle, index) => (
        <StyledHandle
            key={index}
            id={handle.properties.id}
            type={handle.properties.type}
            position={handle.properties.position}
            style={handle.style}
        />
        ))}
        <NodeLabel htmlFor="text" style={computedLabelStyle}>
          {label}
        </NodeLabel>
        <Body style={computedBodyStyle}>
          <Description>
            <Field isActive={studio.selectedNode?.id === id} activeColor={'#491094'} inActiveColor={'#7721E8'}>
            {action.length === 0 ? (
                <span style={{ fontStyle: "italic", fontWeight: 400 }}>
                  Action
                </span>
              ) : action.length >= 20 ? (
                `${action.slice(0, 25).trim()}..`
              ) : (
                action
              )}
            </Field>
          </Description>
         
        </Body>
      </NodeWrapper>
    </BaseNode>
  );
};

// Exports:
export default memo(AINode)