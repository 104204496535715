
const useNodeStyles = ({ isSelected, config }) => {

    const computeStyle = (defaultStyle, selectedStyle) =>
    isSelected ? { ...defaultStyle, ...selectedStyle } : defaultStyle;
  
    const computedLabelStyle = computeStyle(config?.styles?.label?.default, config?.styles?.label?.selected);
    const computedBodyStyle = computeStyle(config?.styles?.body?.default, config?.styles?.body?.selected);
    const computedWrapperStyle = computeStyle(config?.styles?.wrapper?.default, config?.styles?.wrapper?.selected);
    


    return {
        computedLabelStyle,
        computedBodyStyle,
        computedWrapperStyle
    };
};

export default useNodeStyles;