function str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

// Function to perform AES-GCM encryption
export async function encryptData(secretData, password) {
    try {
        const salt = crypto.getRandomValues(new Uint8Array(16));
        const keyMaterial = await crypto.subtle.importKey(
            "raw",
            str2ab(password),
            "PBKDF2",
            false,
            ["deriveKey"]
        );

        const key = await crypto.subtle.deriveKey(
            {
                name: "PBKDF2",
                salt: salt,
                iterations: 100000,
                hash: "SHA-256"
            },
            keyMaterial,
            { name: "AES-GCM", length: 256 },
            false,
            ["encrypt"]
        );
        const data = str2ab(secretData);

        const encrypted = await crypto.subtle.encrypt(
            {
                name: "AES-GCM",
                iv: salt
            },
            key,
            data
        );

        const encryptedData = new Uint8Array(salt.length + encrypted.byteLength);
        encryptedData.set(salt, 0);
        encryptedData.set(new Uint8Array(encrypted), salt.length);
        const base64Encrypted = btoa(String.fromCharCode.apply(null, encryptedData));

        return base64Encrypted;
    } catch (e) {
        console.error("Encryption failed", e);
    }
}