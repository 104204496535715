import React from 'react';
import { TextField, styled } from '@mui/material';

const StyledTextField = styled(TextField)`
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const CustomTextField = ({ field, value, onChange }) => {
  return (
    <StyledTextField
      id={field}
      variant="outlined"
      placeholder='Enter value'
      value={value}
      onChange={(e) => onChange(field, e.target.value)}
      size="small"
    />
  );
};

export default CustomTextField;