import React from 'react';
import { Title, TitleInput } from './styles';

const TitleComponent = ({ isEdit, articleData, changeArticle }) => {
  return isEdit ? (
    <TitleInput
      required
      type="text"
      placeholder="Enter  title here"
      defaultValue={
        articleData?.display_name || articleData?.question || articleData?.name
      }
      onChange={(event) => {
        if (articleData?.display_name) {
          const newArticleData = {
            ...articleData,
            question: event.target.value,
            display_name: event.target.value
          };
          changeArticle(newArticleData);
        } else {
          const newArticleData = {
            ...articleData,
            question: event.target.value
          };
          changeArticle(newArticleData);
        }
      }}
    />
  ) : (
    <Title>
      {articleData?.display_name || articleData?.question || articleData?.name}
    </Title>
  );
};

export default TitleComponent;