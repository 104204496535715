// Packages:
import React, { useState, useEffect } from 'react'
import useInput from '../../../../lib/hooks/use-input'
import {
  Button,
  MenuItem,
  FormControl,
  Select,
  TextField,
  InputAdornment,
  Checkbox,
  ListItemText,
  styled
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { MdCancel } from 'react-icons/md';


// Styles:
import {
  SubFieldName,
  FieldName,
  VariableField,
  Variable
} from './styles'

const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  max-height: 36px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const StyledListItemText = styled(ListItemText)`
  font-size: 12px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;


// Functions:
const parseCustomFields = (raw) => raw.split(',').map(val => val.split(':')[1]).filter(val => val !== undefined)

const TicketConfiguration = ({ node, updateNode, deleteNode, settings }) => {
  // Constants:
  const TICKETCHANNEL = {
    ZENDESK:'ZENDESK'
  }

  const TICKETACTION = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    FETCH_NEW_TICKETS: 'FETCH NEW TICKETS',
    PREDICT_TAGS: 'PREDICT TAGS',
    EXTRACT_INFORMATION: 'EXTRACT INFORMATION'
  }

  const CHANNEL = {
    NONE: 'NONE',
    EMAIL:'EMAIL',
    SMS : 'SMS',
    WHATSAPP:'WHATSAPP',
    FASTTRACK_CHAT:'FASTTRACK CHAT'
  }

  const STATUS = {
    NONE: 'NONE',
    NEW: 'NEW',
    ON_HOLD: 'ON HOLD',
    SUBMIT_AS_SOLVED: 'SUBMIT AS SOLVED',
    OPEN: 'OPEN',
    PENDING: 'PENDING'
  }


  // State:
  const [ ticketChannel, setTicketChannel ] = useState(node.ticketChannel)
  const [ ticketAction, setTicketAction ] = useState(node.ticketAction ?? 'CREATE')
  const { value: subject, setValue: setSubject, bind: bindSubject } = useInput(node.subject ?? '')
  const { value: comment, setValue: setComment, bind: bindComment } = useInput(node.comment ?? '')
  const { value: days, setValue: setDays, bind: bindDays } = useInput(settings?.days ?? '')
  const { value: hours, setValue: setHours, bind: bindHours } = useInput(settings?.hours ?? '')
  const { value: mins, setValue: setMins, bind: bindMins } = useInput(settings?.mins ?? '')
  const [ tags, setTags ] = useState([])
  const { value: customFields, setValue: setCustomFields, bind: bindCustomFields } = useInput(node.customFields ?? '')
  const [ channel, setChannel ] = useState(node.channel)
  const [ status, setStatus ] = useState(node.status)
  const [ multipleStatus, setMultipleStatus ] = useState(node.multipleStatus)
  const [ tagInput, setTagInput ] = useState('')
  const [ customFieldsArray, setCustomFieldsArray ] = useState([])
  const [ daysErrorText, setDaysErrorText] = useState("")
  const [ hoursErrorText, setHoursErrorText] = useState("")
  const [ minsErrorText, setMinsErrorText] = useState("")
  const [ informationToExtract, setInformationToExtract] = useState([]);
  const [ macros, setMacros] = useState([]);
  const [ includedSearchTerms, setIncludedSearchTerms] = useState([]);
  const [ excludedSearchTerms, setExcludedSearchTerms] = useState([]);
  const [ singleInformationToExtract, setSingleInformationToExtract ] = useState('');
  const [ singleMacro, setSingleMacro ] = useState('');
  const [ singleIncludedSearchTerm, setSingleIncludedSearchTerm ] = useState('');
  const [ singleExcludedSearchTerm, setSingleExcludedSearchTerm ] = useState('');


  // Effects:  
  useEffect(() => {
    setTicketChannel(node.ticketChannel)
    setTicketAction(node.ticketAction)
    setSubject(node.subject)
    setComment(node.comment)
    setCustomFields(node.customFields)
    setChannel(node.channel)
    setStatus(node.status)
    setMultipleStatus(node.multipleStatus)
  }, [
    node.ticketChannel,
    node.ticketAction,
    node.subject,
    node.comment,
    node.customFields,
    node.channel,
    node.status,
    node.multipleStatus
  ])

  useEffect(() => {
    if (settings?.checked === true) {
      setDays(settings?.days)
      setHours(settings?.hours)
      setMins(settings?.mins)
    }
    else {
      setDays('')
      setHours('')
      setMins('')
    }
  }, [ settings ])

  useEffect(() => {
    updateNode(node.id, { ticketChannel,ticketAction, subject, comment, days, hours, mins, tags, informationToExtract, macros, includedSearchTerms, excludedSearchTerms, customFields, channel, status, multipleStatus })
  }, [ ticketChannel,ticketAction, subject, comment, days, hours, mins, tags, informationToExtract, macros, includedSearchTerms, excludedSearchTerms, customFields, channel, status, multipleStatus ])

  useEffect(() => {
    if (customFields) setCustomFieldsArray(parseCustomFields(customFields))
  }, [ customFields ])

  useEffect(() => {
    if (days < 0)
      setDaysErrorText("Number of days must be greater than 0")
    else
      setDaysErrorText("")
    if (hours < 0 || hours > 23)
      setHoursErrorText("Number of hours must be greater than 0 and less than 24")
    else
      setHoursErrorText("")
    if (mins < 0 || mins > 59)
      setMinsErrorText("Number of minutes must be greater than 0 and less than 60")
    else
      setMinsErrorText("")
  }, [ days, hours, mins ])

  const handleTagValueChange = (e) => {
    const { value } = e.target;
    setTagInput(value);
  }

  const tagOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = tagInput.trim();
    if ((key === ',' || key === 'Enter' ) && trimmedInput.length && !tags?.includes(trimmedInput)) {
      e.preventDefault();
      setTags(prevState => [...prevState, trimmedInput]);
      setTagInput('');
    }
  };

  const informationToExtractOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = singleInformationToExtract.trim();
    if (
      (key === ',' || key === 'Enter') &&
      trimmedInput.length &&
      !informationToExtract?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setInformationToExtract((prevState) => [...prevState, trimmedInput]);
      setSingleInformationToExtract('');
    }
  };

  const macrosOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = singleMacro.trim();
    if (
      (key === ',' || key === 'Enter') &&
      trimmedInput.length &&
      !macros?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setMacros((prevState) => [...prevState, trimmedInput]);
      setSingleMacro('');
    }
  };

  const includedSearchTermsOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = singleIncludedSearchTerm.trim();
    if (
      (key === ',' || key === 'Enter') &&
      trimmedInput.length &&
      !includedSearchTerms?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setIncludedSearchTerms((prevState) => [...prevState, trimmedInput]);
      setSingleIncludedSearchTerm('');
    }
  };

  const excludedSearchTermsOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = singleExcludedSearchTerm.trim();
    if (
      (key === ',' || key === 'Enter') &&
      trimmedInput.length &&
      !excludedSearchTerms?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setExcludedSearchTerms((prevState) => [...prevState, trimmedInput]);
      setSingleExcludedSearchTerm('');
    }
  };

  const deleteTag = (index) => {
    setTags(prevState => prevState.filter((tag, i) => i !== index))
  }

  const deleteInformationToExtract = (index) => {
    setInformationToExtract((prevState) => prevState?.filter((informationToExtract, i) => i !== index));
  };

  const deleteMacros = (index) => {
    setMacros((prevState) => prevState?.filter((macros, i) => i !== index));
  };

  const deleteIncludedSearchTerms = (index) => {
    setIncludedSearchTerms((prevState) => prevState?.filter((includedSearchTerms, i) => i !== index));
  };

  const deleteExcludedSearchTerms = (index) => {
    setExcludedSearchTerms((prevState) => prevState?.filter((excludedSearchTerms, i) => i !== index));
  };

  const handleMultipleStatusChange = (e) => {
    const {
      target: { value },
    } = e;
    setMultipleStatus(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleInformationToExtractValueChange = (e) => {
    const { value } = e.target;
    setSingleInformationToExtract(value);
  };

  const handleMacrosValueChange = (e) => {
    const { value } = e.target;
    setSingleMacro(value);
  };

  const handleIncludedSearchTermsValueChange = (e) => {
    const { value } = e.target;
    setSingleIncludedSearchTerm(value);
  };

  const handleExcludedSearchTermsValueChange = (e) => {
    const { value } = e.target;
    setSingleExcludedSearchTerm(value);
  };

  // Return:
  return (
    <div>

    <FieldName>Ticket Channel</FieldName>
       <StyledFormControl size="small" fullWidth>
        <Select
          id="ticket-channel"
          value={ ticketChannel }
          onChange={ e => setTicketChannel(e.target.value) }
        >
          {
           Object.values(TICKETCHANNEL).map((channel, index) => (
             <StyledMenuItem key={index} value={channel}>
               {channel}
             </StyledMenuItem>
           ))
         }
        </Select>
      </StyledFormControl>

      <FieldName>Ticket Action</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="ticket-action"
          value={ ticketAction }
          onChange={ e => setTicketAction(e.target.value) }
        >
          {
           Object.values(TICKETACTION).map((action, index) => (
             <StyledMenuItem key={index} value={action}>
               {action}
             </StyledMenuItem>
           ))
         }
        </Select>
      </StyledFormControl>

      {(ticketAction === 'CREATE' || ticketAction === 'UPDATE') && (
        <>
          <FieldName>Subject</FieldName>
          <StyledTextField 
            id="ticket-subject" 
            variant="outlined" 
            placeholder='Enter a subject'
            { ...bindSubject }
            size="small"
            fullWidth
          />

          <FieldName>Comment</FieldName>
          <StyledTextField 
            id="ticket-comment" 
            variant="outlined" 
            placeholder='Enter a comment'
            { ...bindComment }
            size="small"
            fullWidth
          />

          <FieldName>Tags</FieldName>
          <VariableField>
          {
            tags?.map((tag,index) => <Variable key={ tag }>{ tag }<MdCancel style={{ marginLeft:'0.5rem',cursor:'pointer'}} onClick={() => deleteTag(index)}/></Variable>)
          }
          <StyledTextField 
            id="ticket-tags" 
            variant="outlined" 
            value={tagInput}
            placeholder="Enter a tag"
            onKeyDown={ tagOnKeyDown }
            onChange={ handleTagValueChange }
            size="small"
            fullWidth
          />
          </VariableField>

          <FieldName>Macro</FieldName>
          <VariableField>
            {macros?.map((macro, index) => (
              <Variable key={macro}>
                {macro}
                <MdCancel
                  style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                  onClick={() => deleteMacros(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              value={singleMacro}
              placeholder="Enter macros"
              onKeyDown={macrosOnKeyDown}
              onChange={handleMacrosValueChange}
              size="small"
              fullWidth
            />
          </VariableField>

          <FieldName>Custom Fields</FieldName>
          <StyledTextField 
            id="ticket-custom-fields" 
            variant="outlined" 
            placeholder='key1:value1, key2:value2, etc.'
            { ...bindCustomFields }
            size="small"
            fullWidth
          />
          <VariableField style={{ marginTop: '0.5rem' }}>
            { customFieldsArray.map(customField => <Variable key={ customField }>{ customField }</Variable>) }
          </VariableField>
      
          <FieldName>Channel</FieldName>
          <StyledFormControl size="small" fullWidth>
            <Select
              id="channel"
              value={ channel }
              onChange={ e => setChannel(e.target.value) }
            >
              {
              Object.values(CHANNEL).map((channel, index) => (
                <StyledMenuItem key={index} value={channel}>
                  {channel}
                </StyledMenuItem>
              ))
            }
            </Select>
          </StyledFormControl>

          <FieldName>Status</FieldName>
          <StyledFormControl size="small" fullWidth>
            <Select
              id="status"
              value={ status }
              onChange={ e => setStatus(e.target.value) }
            >
              {
              Object.values(STATUS).map((status, index) => (
                <StyledMenuItem key={index} value={status}>
                  {status}
                </StyledMenuItem>
              ))
            }
            </Select>
          </StyledFormControl>
        </>
      )}

      {ticketAction === 'FETCH NEW TICKETS' && (
        <>
          <FieldName>Time Period</FieldName>
            <StyledTextField 
              id="ticket-time-period-days" 
              variant="outlined" 
              placeholder='0'
              error = {daysErrorText.length === 0 ? false : true }
              label = {daysErrorText.length === 0 ? null : "Error" }
              helperText={daysErrorText}
              InputProps={{
                endAdornment: <InputAdornment position="end">days</InputAdornment>,
                readOnly: settings?.checked
              }}
              type="number"
              { ...bindDays }
              size="small"
              fullWidth
            />
            <StyledTextField 
              id="ticket-time-period-hours" 
              variant="outlined" 
              placeholder='0'
              error = {hoursErrorText.length === 0 ? false : true }
              label = {hoursErrorText.length === 0 ? null : "Error" }
              helperText={hoursErrorText}
              InputProps={{
                endAdornment: <InputAdornment position="end">hours</InputAdornment>,
                readOnly: settings?.checked
              }}
              type="number"
              { ...bindHours }
              size="small"
              fullWidth
            />
            <StyledTextField 
              id="ticket-time-period-mins" 
              variant="outlined" 
              placeholder='0'
              error = {minsErrorText.length === 0 ? false : true }
              label = {minsErrorText.length === 0 ? null : "Error" }
              helperText={minsErrorText}
              InputProps={{
                endAdornment: <InputAdornment position="end">mins</InputAdornment>,
                readOnly: settings?.checked
              }}
              type="number"
              { ...bindMins }
              size="small"
              fullWidth
            />

            <FieldName>Status</FieldName>
            <StyledFormControl size="small" fullWidth>
              <Select
                id="status"
                multiple
                value={ multipleStatus }
                onChange={ e => handleMultipleStatusChange(e) }
                renderValue={(selected) => selected.join(', ')}
              >
                {
                Object.values(STATUS).map((status, index) => (
                  <StyledMenuItem key={index} value={status}>
                    <Checkbox size="small" checked={multipleStatus.indexOf(status) > -1} />
                    <StyledListItemText primary={status} />
                  </StyledMenuItem>
                ))
              }
              </Select>
            </StyledFormControl>
          </>
      )}

      {(ticketAction === 'EXTRACT INFORMATION') && (
        <>
          <FieldName>Information to Extract</FieldName>
          <VariableField>
            {informationToExtract?.map((info, index) => (
              <Variable key={info}>
                {info}
                <MdCancel
                  style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                  onClick={() => deleteInformationToExtract(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              value={singleInformationToExtract}
              placeholder="Enter information to extract"
              onKeyDown={informationToExtractOnKeyDown}
              onChange={handleInformationToExtractValueChange}
              size="small"
              fullWidth
            />
          </VariableField>
        </>
      )}
      
      {(ticketAction === 'PREDICT TAGS') && (
        <>
          <FieldName>Search Terms</FieldName>

          <SubFieldName>Include</SubFieldName>
          <VariableField>
            {includedSearchTerms?.map((info, index) => (
              <Variable key={info}>
                {info}
                <MdCancel
                  style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                  onClick={() => deleteIncludedSearchTerms(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              value={singleIncludedSearchTerm}
              placeholder="Enter search terms to include"
              onKeyDown={includedSearchTermsOnKeyDown}
              onChange={handleIncludedSearchTermsValueChange}
              size="small"
              fullWidth
            />
          </VariableField>

          <SubFieldName>Exclude</SubFieldName>
          <VariableField>
            {excludedSearchTerms?.map((info, index) => (
              <Variable key={info}>
                {info}
                <MdCancel
                  style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                  onClick={() => deleteExcludedSearchTerms(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              value={singleExcludedSearchTerm}
              placeholder="Enter search terms to exclude"
              onKeyDown={excludedSearchTermsOnKeyDown}
              onChange={handleExcludedSearchTermsValueChange}
              size="small"
              fullWidth
            />
          </VariableField>

          <FieldName>Tags</FieldName>
          <VariableField>
            {tags?.map((tag, index) => (
              <Variable key={tag}>
                {tag}
                <MdCancel
                  style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                  onClick={() => deleteTag(index)}
                />
              </Variable>
            ))}
            <StyledTextField
              type="text"
              variant="outlined"
              value={tagInput}
              placeholder="Enter tags"
              onKeyDown={tagOnKeyDown}
              onChange={handleTagValueChange}
              size="small"
              fullWidth
            />
          </VariableField>
        </>
      )}

      <Button
        variant="contained"
        color="error"
        size="small"
        startIcon={<DeleteOutlineOutlinedIcon />}
        sx={{ textTransform: 'none', mt: '1rem' }}
        onClick={ () => deleteNode(node.id) }
      >
        Delete Node
      </Button>
    </div>
  )
}


// Exports:
export default TicketConfiguration