// Packages:
import deterministicStringify from "json-stringify-deterministic";
import leven from "leven";
import cloneDeep from "lodash.clonedeep";

// Constants:
import {
  WORKFLOW_IS_DRAFT,
  WORKFLOW_IS_PUBLISHED,
  WORKFLOW_IS_UNDER_REVIEW,
} from "../../../constants/studioV2";
import { getIncomers } from "react-flow-renderer";

// Functions:
export const shouldAutosaveWorkflow = (original, modified, opts) => {
  const LEV_DIS = opts?.triggerAnyway ? 0 : 100 ?? opts?.LEV_DIS;
  const originalStringified = deterministicStringify(original);
  const modifiedStringified = deterministicStringify(modified);
  const isDifferent = originalStringified !== modifiedStringified;
  if (isDifferent)
    if (leven(originalStringified, modifiedStringified) > LEV_DIS) return true;
  return false;
};

function getChoiceNodeData(node, edges) {
  let successNode, failureNode, defaultNode;

  const updatedChoices = node.data?.choices?.map((choice) => {
    const edge = edges.find((edge) => edge.label === choice.branch);
    if (edge) {
      return {
        ...choice,
        nextNode: edge.target,
      };
    }
    return choice;
  });

  edges.forEach((edge) => {
    if (edge.source === node.id) {
      switch (edge.handleLabel) {
        case 'success':
          successNode = edge.target;
          break;
        case 'failure':
          failureNode = edge.target;
          break;
        case 'default' :
          defaultNode = edge.target;
      }
    }
  });

  return {
    choices: updatedChoices,
    defaultNode,
  };
}

function getPreviousNodeId(incomingNodes, nodes, edges) {
  const previousNodeIds = incomingNodes.flatMap(n => {
    if (n.type === 'choice' || n.type === 'parallelHandler' || n.type==='parallel') {
      const previousNodesOfChoice = getIncomers(n, nodes, edges).map(m => m.id);
      return previousNodesOfChoice;
    } else {
      return n.id;
    }
  });
  return previousNodeIds;
}

export  function prepareNodes(nodes, edges) {
  
  return nodes.map((node) => {
    const incomingNodes = getIncomers(node, nodes, edges);
    const previousNodes=getPreviousNodeId(incomingNodes,nodes,edges)
    let parallelNodeId="";
    if(node.type==='parallelHandler'){
      const parallelNode = findParallelNodeId(node?.id, nodes,edges);
      if (parallelNode) {
       parallelNodeId=parallelNode
        };
    const parallelEnd = node.type === 'parallelHandler'
    previousNodes.forEach(prevNodeId => {
      const prevNode = nodes.find(node => node.id === prevNodeId);
      if (prevNode && prevNode.data) {
        const { data } = prevNode;
        prevNode.data = {
          ...data,
          parallelEnd: parallelEnd
        };
      }
    })};
    const baseNodeData = {
      ...node.data,
      __previous__: previousNodes,
    };

    let additionalData = {};
    if (node.type === 'choice') {
      additionalData = getChoiceNodeData(node, edges);
    }
    const newNode = {
      ...node,
      data: { ...baseNodeData, ...additionalData, parallelNodeId },
    };
    
    return Object.fromEntries(
      Object.entries(newNode).filter(([, value]) => value !== undefined)
    );
  });
}

export const determineWorkflowStage = (workflow) => {
  switch (workflow?.meta?.status) {
    case WORKFLOW_IS_DRAFT:
      return WORKFLOW_IS_DRAFT;
    case WORKFLOW_IS_UNDER_REVIEW:
      return WORKFLOW_IS_UNDER_REVIEW;
    case WORKFLOW_IS_PUBLISHED:
      return WORKFLOW_IS_PUBLISHED;
  }
};

export const prepareWorkflowForPublishing = (newWorkflow) => {
  const _workflow = cloneDeep(newWorkflow);
  const workflowName = _workflow.meta.workflowName;
  const nodes = _workflow.meta.nodes?.map((node) => {
    if (node?.type === "message") {
      const outputs = [...node?.data?.outputs];
      const tempOutputs = outputs.map((output) => {
        if (output?.type === "NAME" || output?.type === "ADDRESS") {
          return output?.fields;
        } else return output;
      });
      const newOutputs = tempOutputs.flat(1);
      const newNode = {
        ...node,
        data: { ...node?.data, outputs: newOutputs },
      };
      return newNode;
    } else return node;
  });

  return {
    ..._workflow,
    meta: {
      ..._workflow.meta,
      workflowName,
      status: WORKFLOW_IS_PUBLISHED,
    },
  };
};

export const checkForParallelParent=(nodeId, nodes)=> {
  if (!nodes || nodes.length === 0) {
    return false;
  }
  const node = nodes.find(node => node.id === nodeId);
  if (!node) {
    return false;
  }

  const previousNodes = node.data?.__previous__ || []; 
  if (previousNodes.length === 0) {
    return false;
  }
  const hasParallelParent = previousNodes.some(prevNodeId => {
    const prevNode = nodes.find(node => node.id === prevNodeId);
    return prevNode && prevNode.type === 'parallel';
  });
  if (hasParallelParent) {
    return true;
  } else {
    return previousNodes.some(prevNodeId => checkForParallelParent(prevNodeId, nodes));
  }
}

export const findNodeWithId=(nodeId,nodes)=>{
  if (!nodes || nodes.length === 0) {
    return null;
  }
  const node = nodes.find(node => node.id === nodeId);
  return node
}

const findParallelNodeId = (nodeId, nodes, edges) => {
  const incomingEdges = edges.filter(edge => edge.target === nodeId);
  for (const edge of incomingEdges) {
    const prevNodeId = edge.source; // ID of the previous node
    const prevNode = findNodeWithId(prevNodeId, nodes);
    if (prevNode && prevNode.type === 'parallel') {
      return prevNode.id;
    }
    const parallelNodeId = findParallelNodeId(prevNodeId, nodes, edges);
    if (parallelNodeId) {
      return parallelNodeId;
    }
  }
  return null;
};