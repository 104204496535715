// Packages:
import React, {useState,useEffect, memo } from "react";

import BaseConfiguration from "../BaseConfiguration";
// Functions:
const ParallelHandlerConfiguration = ({
  node,
  updateNode,
  deleteNode,
  isEditable,
  setNodes
}) => {


  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields,setOutputFields]=useState([])

  useEffect(() => {
    updateNode(node.id, { dynamicFields: dynamicFields, outputFields: outputFields });
  }, [dynamicFields]);

  return (
    <BaseConfiguration
      setDynamicFields={setDynamicFields}
      node={node}
      isEditable={isEditable}
      deleteNode={deleteNode}
      outputFields={outputFields}
      setOutputFields={setOutputFields}
      setNodes={setNodes}
    >
    </BaseConfiguration>
  );
};

// Exports:
export default memo(ParallelHandlerConfiguration);