import styled from 'styled-components';

export const FeedbackMessage = styled.span`
  font-weight: 600;
`;

export const ActionStyle = {
  fontSize: '20px',
  marginLeft: '1rem',
  cursor: 'pointer'
};

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  margin-left: -0.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  padding: 0 1rem;
  color: #5f5f5f;
  border-top: 1px solid #e0e0e0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  font-size: 14px;

  @media (max-width: 520px) {
    font-size: 16px;
  }

  @media (max-width: 360px) {
    height: 4rem;
  }
`;
