// Packages:
import React, { useState, useEffect, memo, useCallback } from "react";
import useInput from "../../../../lib/hooks/use-input";
import {
  TextField,
  styled,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
// Imports:
import { MdCancel } from "react-icons/md";

// Styles:
import { FieldName, VariableField, Variable } from "../styles";
import IntervalBox from "../../IntervalBox";
import BaseConfiguration from "../BaseConfiguration";

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;
const StyledSelect = styled(Select)`
  margin-bottom: 1rem;
  height: 2.4rem;
  font-size: 14px;
`;

const StartConfiguration = ({
  node,
  updateNode,
  deleteNode,
  currentRemoteWorkflow,
  setCurrentRemoteWorkflow,
  isEditable,
  setNodes
}) => {
  // State:
  const {
    value: comment,
    setValue: setComment,
    bind: bindComment,
  } = useInput(node.comment ?? "");
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState(node.tags);
  const [access, setAccess] = useState(node.access??"private");
  const [target, setTarget] = useState(node.target??"agent");
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [dynamicFields, setDynamicFields] = useState([]);
  const [outputFields,setOutputFields]=useState([])

  // Effects:
  useEffect(() => {
    setComment(node.comment);
    setTags(node.tags);
    setAccess(node.access?? "private");
    setTarget(node.target ?? "agent");
  }, [node.comment, node.tags, node.access, node.target]);

  useEffect(() => {
    updateNode(node.id, {
      comment,
      tags,
      access,
      target,
      dynamicFields: dynamicFields,
      outputFields:outputFields
    });
  }, [comment, tags, access, target, dynamicFields]);

  const handleTagValueChange = (e) => {
    const { value } = e.target;
    setTagInput(value);
  };

  const handleSettingsClose = useCallback(() =>{
   setSettingsOpen(false)
  },[]);

  const tagOnKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = tagInput.trim();
    if (
      (key === "," || key === "Enter") &&
      trimmedInput.length &&
      !tags?.includes(trimmedInput)
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setTagInput("");
    }
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };


  // Return:
  return (
    <BaseConfiguration
      setDynamicFields={setDynamicFields}
      node={node}
      isEditable={isEditable}
      deleteNode={deleteNode}
      outputFields={outputFields}
      setOutputFields={setOutputFields}
      setNodes={setNodes}
    >
      <FieldName>Setting box</FieldName>
      {settingsOpen && (
        <IntervalBox
          isEditable={isEditable}
          settingsOpen={settingsOpen}
          handleSettingsClose={handleSettingsClose}
          currentRemoteWorkflow={currentRemoteWorkflow}
          setCurrentRemoteWorkflow={setCurrentRemoteWorkflow}
          settings={currentRemoteWorkflow.meta.settings}
        />
      )}
      <Tooltip title="Settings">
        <IconButton onClick={() => setSettingsOpen(true)}>
          <SettingsOutlinedIcon color="primary" sx={{ fontSize: 25 }} />
        </IconButton>
      </Tooltip>
      <FieldName>Target User</FieldName>
      <StyledSelect
        fullWidth
        disabled={!isEditable}
        value={target}
        onChange={(e)=>setTarget(e.target.value)}
      >
        <MenuItem value="endUser">endUser</MenuItem>
        <MenuItem value="agent">agent</MenuItem>
        <MenuItem value="admin">admin</MenuItem>
        <MenuItem value="superadmin">superadmin</MenuItem>
        <MenuItem value="supervisor">supervisor</MenuItem>
      </StyledSelect>
      <FieldName>Access</FieldName>
      <StyledSelect
        fullWidth
        disabled={!isEditable}
        value={access}
        onChange={(e)=> setAccess(e.target.value)}
      >
        <MenuItem value="public">public</MenuItem>
        <MenuItem value="private">private</MenuItem>
      </StyledSelect>
      <FieldName>Reply</FieldName>
      <StyledTextField
        id="start-reply"
        disabled={!isEditable}
        variant="outlined"
        placeholder="Enter a reply"
        {...bindComment}
        size="small"
        fullWidth
      />
      <FieldName>Sample Triggers</FieldName>
      <VariableField>
        {tags.map((tag, index) => (
          <Variable key={tag}>
            {tag}
            <MdCancel
              style={{ marginLeft: "0.5rem", cursor: "pointer" }}
              onClick={() => deleteTag(index)}
            />
          </Variable>
        ))}
        <StyledTextField
          type="text"
          disabled={!isEditable}
          id="start-sample-trigger"
          variant="outlined"
          value={tagInput}
          placeholder="Enter a sample trigger"
          onKeyDown={tagOnKeyDown}
          onChange={handleTagValueChange}
          size="small"
          fullWidth
        />
      </VariableField>
    </BaseConfiguration>
  );
};

// Exports:
export default memo(StartConfiguration);