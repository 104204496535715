import * as React from 'react';
import { useSelector } from 'react-redux';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { useCallback } from 'react';
import { SUPERVISOR, SUPER_ADMIN } from '../../constants/user';

const StyledFormControl = styled(FormControl)`
  width: 180px;
  & .MuiFormLabel-root {
    font-size: 14px;
  }
  & .MuiOutlinedInput-root {
    font-size: 14px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  width: 180px;
  & .MuiTypography-root {
    font-size: 14px;
  }
`;

const CHOICES_FOR_ADMINS = ['Drafts', 'Under Review', 'Published', 'Archived'];
const CHOICES_FOR_AGENTS = ['Drafts', 'Under Review', 'Published'];

export default function Dropdown({ articleType, handleDropdownChange }) {
  const agentType = useSelector((state) => state.content.agent_type);
  const names = agentType === SUPERVISOR || agentType === SUPER_ADMIN
    ? CHOICES_FOR_ADMINS
    : CHOICES_FOR_AGENTS;

  const handleChange = useCallback(
    (event) => {
      const {
        target: { value }
      } = event;
      handleDropdownChange(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    },
    [handleDropdownChange]
  );

  return (
    <StyledFormControl size="small">
      <InputLabel>Type</InputLabel>
      <Select
        multiple
        value={articleType}
        onChange={handleChange}
        input={<OutlinedInput label="Type" />}
        renderValue={(selected) => selected.join(', ')}
      >
        {names.map((name) => (
          <StyledMenuItem key={name} value={name}>
            <Checkbox checked={articleType.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </StyledMenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
}
