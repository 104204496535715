import { MenuItem, Select, Divider } from "@mui/material";
import { CONVERSATION_TYPE, SENTIMENT_TYPE } from "../../../../constants/chat";
import { StyledFormControl } from "../styles/formStyles";
import { useState } from "react";
import "../styles/css/filterStyle.css"

const formControlStyles = {
  minWidth: "200px",
  display: 'flex',
  flexDirection: 'row',
  width: '17vw',
  padding: '0.4rem 0.6rem',
  borderWidth: '0 1px 1px 0',
  borderStyle: 'solid',
  borderColor: '#00000010',
  boxShadow: '0.81px 1.61px 5.16px 0px #0000001A',
  borderRadius: '2rem',
  opacity: '0px'
}

const inputSelectStyles = { 
  width: "70%", 
  borderRadius: '2rem', 
  border: 'none',
  boxShadow: "none",
  color: '#999999',
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
  {
    border: 0,
  },
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
  {
    border: 0,
  },
  "& .MuiSelect-select": 
  {
    fontSize: '12px',
  }
};

const Filters = ({
  topicFilters,
  setTopicFilters
}) => {
  const [sentiment, setSentiment] = useState(topicFilters.sentiment);
  const [outcome, setOutcome] = useState(topicFilters.outcome)

  return (
    <div style={
      {
        display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '12px'
      }
    }>
      <span><strong>FILTER BY</strong></span>
      &nbsp;
      &nbsp;
      &nbsp;
      <StyledFormControl
        sx={formControlStyles}
        size="small"
      >
        <Select
          multiple
          native={false}
          displayEmpty={true}
          value={sentiment || []}
          onChange={(event) => setSentiment(event.target.value)}
          renderValue={(val) => val?.length === 0 ? "Sentiment" : val.join(" ,")}
          sx={inputSelectStyles}
        >
          {Object.entries(SENTIMENT_TYPE).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {value}
            </MenuItem>
          ))}

        </Select>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Select
          multiple
          native={false}
          displayEmpty={true}
          value={outcome || []}
          onChange={(event) => setOutcome(event.target.value)}
          renderValue={(val) => val?.length === 0 ? "Outcome" : val.join(" ,")}
          sx={inputSelectStyles}
        >
          {Object.entries(CONVERSATION_TYPE).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {value}
            </MenuItem>
          ))}

        </Select>
        <button
          className="topicbreakdown-filter-button"
          onClick={() => {
            const updateTopicFilters = {
              ...topicFilters,
              sentiment: sentiment,
              outcome: outcome
            }
            setTopicFilters(updateTopicFilters)
            sessionStorage.setItem('topicFilters', JSON.stringify(updateTopicFilters));
          }}
        > Apply Filters
        </button>
      </StyledFormControl>
    </div>
  );
}

export default Filters;