// Packages:
import React, { useEffect, useState } from 'react';
import useInput from '../../../../lib/hooks/use-input';
import {
  Button,
  MenuItem,
  FormControl,
  Select,
  TextField,
  styled
} from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


// Styles:
import {
  FieldName 
} from './styles';

const StyledFormControl = styled(FormControl)`
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  & .MuiFormLabel-root {
    font-size: 12px;
  }
  & .MuiOutlinedInput-root {
    overflow: hidden;
    font-size: 12px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-size: 12px;
  & .MuiTypography-root {
    font-size: 12px;
  }
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 1rem;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

// Functions:
const GoogleSheetsConfiguration = ({ node, updateNode, deleteNode }) => {
  // Constants:
  const GOOGLESHEETSFUNCTIONALITY = {
    VERIFY_FROM_SHEET: 'VERIFY FROM SHEET'
  }

  // State:
  const [ googleSheetsFunctionality, setGoogleSheetsFunctionality ] = useState(node.googleSheetsFunctionality)
  const { value: sheet, setValue: setSheet, bind: bindSheet } = useInput(node.sheet ?? '')
  const { value: document, setValue: setDocument, bind: bindDocument } = useInput(node.document ?? '')
  const { value: title, setValue: setTitle, bind: bindTitle } = useInput(node.title ?? '')
  const { value: key, setValue: setKey, bind: bindKey } = useInput(node.key ?? '')

  // Effects:
  useEffect(() => {
    setGoogleSheetsFunctionality(node.googleSheetsFunctionality)
    setSheet(node.sheet)
    setDocument(node.document)
    setTitle(node.title)
    setKey(node.key)
  }, [ node.googleSheetsFunctionality, node.sheet, node.document, node.title, node.key ])

  useEffect(() => {
    updateNode(node.id, { googleSheetsFunctionality })
  }, [ googleSheetsFunctionality ])

  useEffect(() => {
    updateNode(node.id, { sheet })
  }, [ sheet ])

  useEffect(() => {
    updateNode(node.id, { document })
  }, [ document ])

  useEffect(() => {
    updateNode(node.id, { title })
  }, [ title ])

  useEffect(() => {
    updateNode(node.id, { key })
  }, [ key ])

  // Return:
  return (
    <div>
      <FieldName>Functionality</FieldName>
      <StyledFormControl size="small" fullWidth>
        <Select
          id="google-sheets-functionality-select"
          value={ googleSheetsFunctionality }
          onChange={ e => setGoogleSheetsFunctionality(e.target.value) }
        >
          {
           Object.values(GOOGLESHEETSFUNCTIONALITY).map((functionality, index) => (
             <StyledMenuItem key={index} value={functionality}>
               {functionality}
             </StyledMenuItem>
           ))
         }
        </Select>
      </StyledFormControl>

      <FieldName>Sheet</FieldName>
      <StyledTextField 
        id="google-sheets-sheet" 
        variant="outlined" 
        placeholder='Please enter the sheet name'
        { ...bindSheet }
        size="small"
        fullWidth
      />

      <FieldName>Document</FieldName>
      <StyledTextField 
        id="google-sheets-document" 
        variant="outlined" 
        placeholder='Please enter the document name'
        { ...bindDocument }
        size="small"
        fullWidth
      />

      <FieldName>Title</FieldName>
      <StyledTextField 
        id="google-sheets-title" 
        variant="outlined" 
        placeholder='Please enter the title'
        { ...bindTitle }
        size="small"
        fullWidth
      />

      <FieldName>Key</FieldName>
      <StyledTextField 
        id="google-sheets-key" 
        variant="outlined" 
        placeholder='Please enter the key'
        { ...bindKey }
        size="small"
        fullWidth
      />

      <Button
        variant="contained"
        color="error"
        size="small"
        startIcon={<DeleteOutlineOutlinedIcon />}
        sx={{ textTransform: 'none', mt: '1rem' }}
        onClick={ () => deleteNode(node.id) }
      >
        Delete Node
      </Button>
    </div>
  );
};

// Exports:
export default GoogleSheetsConfiguration;