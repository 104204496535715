export const EVENT_TYPES = {
    LIKE_EVENT: "like",
    DISLIKE_EVENT: "dislike",
    WIDGET_READY_EVENT: "load",
    WIDGET_AUTO_LAUNCH_EVENT: "auto launch",
    WIDGET_OPEN_EVENT: "launch",
    WIDGET_CLOSE_EVENT: "collapse",
    WIDGET_EXPAND_EVENT: "expand",
    WIDGET_NARROW_EVENT: "narrow",
    INITIAL_PROMPT_EVENT: "initial prompt",
    RESET_CHAT_EVENT: "reset chat",
    OPEN_CHAT_TAB_EVENT: "chat tab",
    OPEN_RESOURCE_TAB_EVENT: "resource tab",
    VIEW_TOPIC_EVENT: "view topic",
    SEARCH_EVENT: "search",
    EXECUTE_WORKFLOW_EVENT: "execute workflow",
    ESCALATION_EVENT: "escalation",
    SUBMIT_TICKET_EVENT: "submit ticket",
    VIEW_ARTICLE_EVENT: "view article",
    VIEW_ORIGINAL_EVENT: "view source",
    WORKFLOW_AUTO_EXECUTE: "auto execute workflow",
    WORKFLOW_START_EVENT: "workflow start",
    WORKFLOW_SUCCESS_EVENT: "workflow end",
    WORKFLOW_FAILED_EVENT: "workflow failed",
    FORM_VIEW_EVENT: "view form",
    FORM_SUBMIT_EVENT: "submit form",
    FORM_CANCEL_EVENT: "cancel form",
    TICKET_CREATED_EVENT: "ticket created",
    TICKET_FAILED_EVENT: "ticket failed",
    MISHIT_EVENT: "mishit"
}

export const GAP_TYPE_EVENTS = {
    NO_ANSWER: 'No Answer',
    NOT_HELPFUL: 'Not helpful',
    HELP_REQUESTED: 'Help requested',
    DISLIKE: EVENT_TYPES.DISLIKE_EVENT
}

export const SUB_EVENTS = {
    RESIZE_SUB_EVENT: "resize",
    WIDGET_SUB_EVENT: "widget",
    REPLY_OPTION_SUB_EVENT: "reply prompt",
    ARTICLE_SUB_EVENT: "article",
    INTENT_SUB_EVENT: "intent",
    INTERACT_SUB_EVENT: "interact",
    TITLE_SEARCH_SUB_EVENT: "title search",
    WORKFLOW_SUB_EVENT: "workflow",
    TICKET_SUB_EVENT: "ticket",
    TAB_CHANGE_SUB_EVENT: "change tab",
    RESOURCE_SECTION_SUB_EVENT: "resource section",
}