// Packages:
import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination
} from 'react-table';
import { useDeepCompareEffect } from 'react-use';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { Chip, CircularProgress, Button } from '@mui/material';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';


// Components:
import Pagination from '../Pagination'


// Imports:
import { getChatActions } from '../../../redux/actions/chatActions';
import { ColumnFilter } from './ColumnFilter';
import {
  Wrapper,
  RecordsTable,
  Header,
  Body,
  Title,
  Filters
} from './styles';

// Functions:
const Table = (props) => {
  // Constants:
  const dispatch = useDispatch();
  const chatActions = getChatActions(dispatch);
  const organisation_info = useSelector((state) => state.content.org_info);
  const org_id = organisation_info?.org_data?._id;
  const chatRecords = useSelector((state) => state.chat.chatRecords);
  const isContentLoading = useSelector((state) => state.chat.loading);
  const [isLoading, setIsLoading] = useState(isContentLoading);

  // State:
  const [selectedRow, setSelectedRow] = useState(null);
  const [bit, setBit] = useState(false);
  const [records, setRecords] = useState(chatRecords);
  const [cols, setCols] = useState([]);
  const [columns, setColumns] = useState([]);
  const data = useMemo(() => records, [records]);

  const initialState = {    
    hiddenColumns: ['Record'], 
    sortBy: [
      {
          id: 'Created Date',
          desc: true
      }
    ],
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    pageCount,
    prepareRow
  } = tableInstance;

  const { pageIndex } = state;


  // Effects:
  useEffect(() => {
    setIsLoading(isContentLoading);
  }, [isContentLoading]);

  useEffect(() => {
    chatActions.fetchChatRecords(org_id);
    // chatActions.fetchChatRecordsV3(org_id, props.startDate, props.endDate);
  }, [bit, org_id]);

  useDeepCompareEffect(() => {
    setRecords(chatRecords);
    chatRecords && chatRecords.length>0 && setCols(Object.keys(chatRecords[0]));
  }, [chatRecords]);

  useDeepCompareEffect(() => {
    const tempArray = cols.map((col, i) => {
      const words = col.split('_');
      const capitalised = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
      );

      return {
        Header: col === 'user_email' ? 'User' : capitalised.join(' '),
        accessor: row => col==="created_date" ? row?.created_date?.substring(0,10) + " " + row?.created_date?.substring(11,16) : col === "tags" && typeof(row[col]) === "object" ? JSON.stringify(row[col]) : row[col],
        Filter: ColumnFilter,
        disableSortBy: (col==="user_email" && props.hideInfo) ? true : false,
        sortType: 'basic'
      };
    });
    setColumns(tempArray);
  }, [cols, props.hideInfo]);

  const handleChatRecord = (row) => {
    setSelectedRow(row.id === selectedRow ? null : row.id);
    props.handleChat(row?.original);
  };

  useEffect(() => {
    if (chatRecords !== undefined && chatRecords !== null) {
      let filteredData = chatRecords.filter(
        (item) =>
          moment(item.created_date).format('YYYY-MM-DD') >=
            moment(props.startDate).format('YYYY-MM-DD') &&
          moment(item.created_date).format('YYYY-MM-DD') <=
            moment(props.endDate).format('YYYY-MM-DD')
      );
      if(props.conversationType.length > 0) {
        let conversationTypeFilteredData = filteredData.filter((item) => props.conversationType.includes(item.conversation_type))
        setRecords(conversationTypeFilteredData);
      }
      else {
        setRecords(filteredData);
      }
    }
  }, [props.startDate, props.endDate, props.conversationType, chatRecords]);
  

  // Return:
  return (
    <Wrapper>
      <RecordsTable {...getTableProps()}>
        { isLoading ? 
        ( <thead style={{ marginTop: '15rem' }}>
            <tr><th><CircularProgress /></th></tr>
          </thead> ) :
          (<>
          <Header>
            {headerGroups.map((headerGroup) => (
                <Title {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' ⬇️'
                            : ' ⬆️'
                          : ''}
                      </span>
                    </th>
                  ))}
                </Title>
            ))}
            {headerGroups.map((headerGroup) => (
                <Filters {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div>
                        {column.canFilter
                          ? column.Header === 'User' && props.hideInfo
                            ? null
                            : column.render('Filter')
                          : null
                        }
                      </div>
                    </th>
                  ))}
                </Filters>
            ))}
          </Header>
          {page && page.length>0 ?(<>
          <Body {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row?.getRowProps()} onClick={() => handleChatRecord(row)} style={{backgroundColor: selectedRow === row.id ? 'lightblue' : ''}}>
                  {row?.cells.map((cell) => {
                    if (cell.column.Header === 'Record Id') {
                      return (
                        <td {...cell.getCellProps()}>
                          {row?.original?.record_id}
                        </td>
                      );
                    } 

                    else if (cell.column.Header === 'Conversation Type') {
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            row?.original?.conversation_type === 'AI' ?
                              <Chip label={row?.original?.conversation_type} icon={<SmartToyOutlinedIcon />} color="info" size="small" variant="outlined" sx={{ fontWeight:'600' }}/> :
                              row?.original?.conversation_type === 'Leave Message' ?
                                <Chip label={row?.original?.conversation_type} icon={<ExitToAppOutlinedIcon />} color="error" size="small" variant="outlined" sx={{ fontWeight:'600' }}/> :
                                row?.original?.conversation_type === 'Agent' ?
                                <Chip label={row?.original?.conversation_type} icon={<SupportAgentOutlinedIcon />} color="secondary" size="small" variant="outlined" sx={{ fontWeight:'600' }}/> :
                                <Chip label={row?.original?.conversation_type} icon={<PersonOffOutlinedIcon />} color="warning" size="small" variant="outlined" sx={{ fontWeight:'600' }}/>
                          }
                        </td>
                      );
                    }
                    
                    else if (cell.column.Header === 'Created Date') {
                      return (
                        <td {...cell.getCellProps()}>
                          {row?.original?.created_date?.substring(0,10) + " " + row?.original?.created_date?.substring(11,16)}
                        </td>
                      );
                    } 

                    else if (cell.column.Header === 'User') {
                      return (
                        <td {...cell.getCellProps()}>
                          {(props.hideInfo && row?.original?.user_email !== 'anonymous') ? (
                            <>••{row?.original?.user_email?.slice(2, 5).trim()}•••{row?.original?.user_email?.slice(8, 11).trim()}••</>
                          ) : (
                            <>{row?.original?.user_email}</>
                          )}
                        </td>
                      );
                    }
                    
                    else if (cell.column.Header === 'Tags') {
                      return (
                        <td {...cell.getCellProps()}>
                          {typeof row?.original?.tags === 'string'
                            ? row?.original?.tags
                            : row?.original?.tags?.map((tag) => <Chip label={tag.value} color="info" size="small" variant="outlined" sx={{ fontWeight:'600', mr: '5px' }}/>)}
                        </td>
                      );
                    } 
                    
                    else {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </Body>
        </>) : <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}><h2 style={{ textAlign: "center"}}>Sorry! No Content in the Given time range</h2></div>}
        </>)}
      </RecordsTable>

      <Pagination 
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
      />

      {records !== null && (
        <CSVLink
          filename={'records.csv'}
          data={records?.map((row) => {
            let data = {
              user_email: row?.user_email,
              created_date: row?.created_date,
              record_id: row?.record_id,
              conversation_type: row?.conversation_type,
              record: row?.record,
              tags: typeof row?.tags === 'object' ? row?.tags?.map((tag) => row?.tags?.map((tag) => `${tag.value} `)) :row?.tags //what to show in here
            };
            return data;
          })}
        >
          <Button 
            variant="contained"
            size="small"
            startIcon={<FileUploadOutlinedIcon />}
            sx={{ textTransform: 'none' }}
          >
            Export Data
          </Button>
        </CSVLink>
      )}
    </Wrapper>
  );
};

// Exports:
export default Table;