import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

import 'react-toastify/dist/ReactToastify.css';
import 'draft-js/dist/Draft.css';
import uuid from 'react-uuid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import levenshtein from 'fast-levenshtein';
import { IconButton, Tooltip, LinearProgress, Button } from '@mui/material';

// Redux:
import { getContentActions } from '../../redux/actions/contentActions';

// Styles:
import {
  Wrapper,
  Article,
  Information,
  Options,
  Header,
  Icons
} from './styles';

// Components:
import {
  Access,
  ArticleCheckbox,
  DescriptionComponent,
  FooterComponent,
  GoBackComponent,
  Iframe,
  Source,
  Synonyms,
  Target,
  TagTypeInputComponent,
  TitleComponent,
  CommentBox,
  ResourceCenter,
  ResourceBox
} from '../../components';

// Functions:
import saveArticle from '../../lib/operationsOnKnowledgeArticles/published/saveArticle';
import deleteArticleFunction from '../../lib/operationsOnKnowledgeArticles/published/deleteArticle';
import fetchDialogIdFn from '../../lib/operationsOnKnowledgeArticles/published/fetchDialogIdFn';
import submitForReview from '../../lib/operationsOnKnowledgeArticles/unpublished/submitForReview';
import deleteUnverifiedArticle from '../../lib/operationsOnKnowledgeArticles/unpublished/deleteUnverifiedArticle';
import { useDeepCompareEffect } from 'react-use';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import {
  addCommentAPI,
  fetchCommentAPI,
  getSingleArticleApi,
  getSingleDraftArticleApi,
  getSingleUnverifiedArticleApi
} from '../../api';
import { urlify } from '../../lib/urlify';
import { toast } from 'react-toastify';
import reconcileKAChanges from '../../lib/operationsOnKnowledgeArticles/reconcileKAChanges';
import logEntityChanges from '../../lib/logEntityChanges';

// Constant

const KnowledgeArticle = () => {
  const params = useParams();
  const urlParams = new URLSearchParams(params?.dialog_id);
  const paramsObject = Object.fromEntries(urlParams);
  const { published } = paramsObject;
  const orgid = useSelector((state) => state.content.org_info?.org_data?._id);
  const [articleData, setArticleData] = useState(null);
  const agent_type = useSelector((state) => state.content.agent_type);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const answer = useSelector((state) => state.content.answer);
  const qna = useSelector((state) => state.content.qna);
  const query = useSelector((state) => state.content.query);

  // Constants:
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const contentActions = getContentActions(dispatch);

  // State:
  const [isEdit, setIsEdit] = useState(() => !!location.state?.isEdit ?? true);
  const [isNew, setIsNew] = useState(() => !!location.state?.isNew ?? true);
  const [tag, setTag] = useState([]);
  const [trainingPhrase, setTrainingPhrase] = useState([]);
  const [open, setOpen] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const previousArticleData = useRef(articleData);
  const articleDataBeforeSave = useRef(articleData);
  const [openModal, setOpenModal] = useState(false);
  const [publishingArticle, setPublishingArticle] = useState(false);
  const [autosaving, setAutosaving] = useState(false);
  const [articleBody, setArticleBody] = useState('<p></p>');
  const [articleComments, setArticleComments] = useState([]);
  const [fetchingComments, setFetchingComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [tab, setTab] = useState('chat');
  const [numberOfResources, setNumberOfResources] = useState(0);
  const [showOptions, setShowOptions] = useState(false)

  const didMount = useRef(false);
  const [modalProps, setModalProps] = useState({
    actionButton: '',
    message: '',
    actionFn: null
  });

  // Functions:
  const changeCommentsArray = useCallback((val) => {
    setComments(val);
  }, []);

  const handleDrawerOpen = () => {
    setOpenCollapse(true);
  };

  const handleDrawerClose = () => {
    setOpenCollapse(false);
  };

  const handleModalProps = useCallback((val) => {
    setModalProps(val);
  }, []);
  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleClickOpen = useCallback(async (orgid, articleId) => {
    setFetchingComments(true);
    setOpen(true);
    const response = await fetchCommentAPI(orgid, articleId);
    const comments = response?.comments;
    const newComments = comments?.map((comment) => {
      const createdAt = new Date(comment?.createdAt).toUTCString();
      return { ...comment, createdAt };
    });
    setArticleComments(newComments);
    setFetchingComments(false);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    changeCommentsArray([]);
  }, [changeCommentsArray]);

  const changeIsNew = useCallback((val) => {
    setIsNew(val);
  }, []);

  const changeIsEdit = useCallback((val) => {
    setIsEdit(val);
  }, []);

  const changeArticle = useCallback((val) => {
    setArticleData(val);
  }, []);

  const changeArticleBody = useCallback((val) => {
    setArticleBody(val);
  }, []);

  const changeTag = useCallback((val) => {
    setTag(val);
  }, []);

  const changeTrainingPhrase = useCallback((val) => {
    setTrainingPhrase(val);
  }, []);

  const handleShowOptions = (value) => {
    if(value === false)
      setShowOptions(false)
    else {
      if(showOptions === false)
        setShowOptions(true)
      else
        setShowOptions(false)
    }
  }

  const fetchData = useCallback(async () => {
    switch (published) {
      case 'true': {
        setPublishingArticle(true);
        const dialog_id = paramsObject?.dialog_id.replaceAll('^', '/');
        try {
          const fetchedArticle = await getSingleArticleApi(
            orgid,
            paramsObject?.isarticle === 'true',
            dialog_id
          );
          contentActions.interact(orgid, fetchedArticle?.question, null, false);
          contentActions.similarity(orgid, fetchedArticle?.question, null);

          const removedQuot = fetchedArticle?.meta?.formattedText;

          const urlified = urlify(removedQuot).replace(/\\n/g, '<br>');
          const trainingPhrases = fetchedArticle?.trainingPhrases
            ? fetchedArticle?.trainingPhrases?.map((phrase) => ({
                id: uuid(),
                desc: phrase
              }))
            : [];

          const tempArticle = {
            ...fetchedArticle,
            trainingPhrases
          };
          setArticleBody(urlified);
          setArticleData(tempArticle);
          previousArticleData.current = tempArticle;
          articleDataBeforeSave.current = tempArticle;
        } catch (error) {
          toast.error('Some error occurred while loading the article');
          console.error(error);
        }
        setPublishingArticle(false);
        break;
      }
      case 'false': {
        setPublishingArticle(true);
        try {
          const fetchedArticle = await getSingleUnverifiedArticleApi(
            orgid,
            paramsObject?.articleId
          );
          contentActions.interact(orgid, fetchedArticle?.question, null, false);
          contentActions.similarity(orgid, fetchedArticle?.question, null);

          const removedQuot = fetchedArticle?.meta?.formattedText;
          const urlified = urlify(removedQuot);
          const tempArticle = { ...fetchedArticle };
          setArticleBody(urlified);
          setArticleData(tempArticle);
          previousArticleData.current = tempArticle;
          articleDataBeforeSave.current = tempArticle;
          // console.log({ fetchedArticle });
        } catch (error) {
          toast.error('Some error occurred while loading the article');
          console.error(error);
        }
        setPublishingArticle(false);
        break;
      }
      case 'undefined': {
        setPublishingArticle(true);
        try {
          const fetchedArticle = await getSingleDraftArticleApi(
            orgid,
            paramsObject?.articleId
          );

          contentActions.interact(orgid, fetchedArticle?.question, null, false);
          contentActions.similarity(orgid, fetchedArticle?.question, null);

          const removedQuot = fetchedArticle?.meta?.formattedText;

          const urlified = urlify(removedQuot);

          const tempArticle = { ...fetchedArticle };
          setArticleBody(urlified);
          setArticleData(tempArticle);
          previousArticleData.current = tempArticle;
          articleDataBeforeSave.current = tempArticle;
        } catch (error) {
          toast.error('Some error occurred while loading the article');
          console.error(error);
        }
        setPublishingArticle(false);
        break;
      }
      case 'new': {
        setPublishingArticle(true);
        const newArticle = {
          orgid,
          articleId: uuid(),
          createdBy: userDetails?.email,
          trainingPhrases: [],
          question: '',
          isarticle: false,
          meta: {
            access: 'private',
            target: 'endUser',
            tags: [],
            source: '',
            isarticle: false,
            firstLine: '',
            formattedText: '<p></p>',
            synonyms: [
              { id: uuid(), key: '', description: '' },
              { id: uuid(), key: '', description: '' }
            ]
          }
        };
        setArticleData(newArticle);
        previousArticleData.current = newArticle;
        articleDataBeforeSave.current = newArticle;
        setPublishingArticle(false);
        break;
      }
      default:
        break;
    }
  }, [
    orgid,
    paramsObject?.articleId,
    paramsObject?.dialog_id,
    paramsObject?.isarticle,
    published,
    userDetails?.email
  ]);

  const autoSaveCallback = useCallback(
    async (incomingArticle) => {
      setAutosaving(true);
      const articleToSave = { ...incomingArticle };
      const title = articleToSave?.question || articleToSave?.display_name;
      if (
        articleToSave?.meta?.formattedText?.length > 10 &&
        title?.length > 3
      ) {
        const articleId =
          articleToSave?.articleId || articleToSave?.meta?.articleId || uuid();
        const createdBy = articleToSave?.createdBy || userDetails?.email;
        delete articleToSave['training_phrases'];
        delete articleToSave['name'];
        delete articleToSave['answer'];
        const data = {
          ...articleToSave,
          question: title,
          orgid,
          articleId,
          createdBy,
          meta: {
            ...articleToSave?.meta,
            firstLine:
              articleToSave?.meta?.formattedText?.length < 105
                ? sanitizeHtml( urlify(articleToSave?.meta?.formattedText), {
                    allowedTags: [],
                    allowedAttributes: {}
                  })
                : sanitizeHtml(
                  urlify(articleToSave?.meta?.formattedText.slice(0, 100)),
                    {
                      allowedTags: [],
                      allowedAttributes: {}
                    }
                  )
          }
        };
        await contentActions.autosave(data);
      }
      setAutosaving(false);
    },
    [userDetails?.email, orgid]
  );

  const logArticleChanges = (action) => {
    const changes = reconcileKAChanges(
      articleDataBeforeSave.current, articleData
    );
    if (Object.keys(changes).length === 0) {
      return;
    }
    logEntityChanges(
      orgid, 'article',
      articleData?.articleId || articleData?.meta?.articleId,
      userDetails?.email, action, changes
    );
  };

  const handlePublish = async () => {
    if (
      agent_type.toLowerCase() === 'supervisor' ||
      agent_type.toLowerCase() === 'superadmin'
      ) {
      setPublishingArticle(true);
      try {
        await saveArticle(
          articleData,
          orgid,
          contentActions.setKnowledgeArticle,
          true,
          changeIsNew,
          changeIsEdit,
          contentActions.deleteUnverifiedKnowledgeArticle,
          contentActions.deleteDraft,
          userDetails
        );
        setPublishingArticle(false);
      } catch (error) {
        setPublishingArticle(false);
        await submitForReview(
          articleData,
          userDetails?.email,
          orgid,
          contentActions.setUnverifiedKnowledgeArticle,
          isNew,
          changeIsNew,
          changeIsEdit
        );
        console.log(error);
      }
      articleDataBeforeSave.current = articleData;
    }
  };

  const saveArticleCallback = async () => {
    setPublishingArticle(true);
    try {
      await submitForReview(
        articleData,
        userDetails?.email,
        orgid,
        contentActions.setUnverifiedKnowledgeArticle,
        changeIsNew,
        changeIsEdit,
        userDetails,
        contentActions.deleteDraft,
        setArticleData
      );
      logArticleChanges('Unverified update');
      articleDataBeforeSave.current = articleData;
    } catch (error) {
      if (!autosaving) {
        await autoSaveCallback(articleData);
      }
      console.error(error);
    }
    setPublishingArticle(false);
  };

  const deleteArticle = async () => {
    setPublishingArticle(true);

    try {
      if (
        Object.keys(articleData?.meta).includes('published') &&
        articleData?.meta?.published === true
      ) {
        const data = {
          dialog_id: articleData.dialogId,
          isarticle: articleData?.meta?.isarticle,
          orgid,
          target: articleData?.meta?.target,
          access: articleData?.meta?.access,
          articleId: articleData?.meta?.articleId
        };
        await deleteArticleFunction(
          data,
          contentActions.deleteKnowledgeArticle,
          userDetails
        );
      } else if (
        Object.keys(articleData?.meta).includes('published') &&
        articleData?.meta?.published === false
      ) {
        let data;
        if (
          agent_type.toLowerCase() === 'supervisor' ||
          agent_type.toLowerCase() === 'superadmin'
        ) {
          data = {
            orgid,
            target: articleData?.meta?.target,
            access: articleData?.meta?.access,
            articleId: articleData?.articleId
          };
        } else {
          data = {
            orgid,
            target: articleData?.meta?.target,
            access: articleData?.meta?.access,
            articleId: articleData?.articleId,
            createdBy: articleData?.createdBy
          };
        }

        await deleteUnverifiedArticle(
          data,
          contentActions.deleteUnverifiedKnowledgeArticle
        );
      } else if (articleData?.meta?.published === undefined) {
        const data = {
          orgid,
          target: articleData?.meta?.target,
          access: articleData?.meta?.access,
          articleId: articleData?.articleId,
          createdBy: articleData?.createdBy
        };
        await contentActions.deleteDraft(data);
      }
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
    setPublishingArticle(false);
  };

  const addReaction = async (type) => {
    try {
      const data = {
        orgid,
        user_email: `${userDetails?.email || 'anonymous'}`,
        type,
        subtype: articleData?.dialog_id
          ? articleData?.meta?.isarticle
            ? 'qna'
            : 'intent'
          : 'similarity',
        dialog_id: fetchDialogIdFn(articleData, orgid),
        title: articleData?.display_name || articleData?.question,
        query
      };
      await contentActions.reactOnArticle(data);
    } catch (error) {
      console.error(error);
    }
  };

  const onTargetChange = (event) => {
    const target = event.target.value;
    const newArticle = {
      ...articleData,
      meta: {
        ...articleData.meta,
        target: event.target.value,
        access:
          target.toLowerCase() === 'agent' || target.toLowerCase() === 'partner'
            ? 'private'
            : articleData.meta.access
      }
    };
    setArticleData(newArticle);
    if (!autosaving) {
      autoSaveCallback(newArticle);
    }
  };

  const onAccessChange = (event) => {
    const newArticle = {
      ...articleData,
      meta: {
        ...articleData.meta,
        access: event.target.value
      }
    };
    setArticleData(newArticle);
    if (!autosaving) {
      autoSaveCallback(newArticle);
    }
  };

  const addTag = () => {
    const tags = articleData?.meta?.tags
      ? [...articleData?.meta?.tags, { id: uuid(), desc: tag }]
      : [{ id: uuid(), desc: tag }];

    const newArticle = {
      ...articleData,
      meta: {
        ...articleData.meta,
        tags
      }
    };
    setArticleData(newArticle);
    setTag('');
    if (!autosaving) {
      autoSaveCallback(newArticle);
    }
  };

  const deleteTag = (id) => {
    const newTags = articleData?.meta?.tags?.filter((tag) => tag.id !== id);
    const newArticle = {
      ...articleData,
      meta: {
        ...articleData.meta,
        tags: newTags
      }
    };
    setArticleData(newArticle);
    if (!autosaving) {
      autoSaveCallback(newArticle);
    }
  };

  const addTrainingPhrase = () => {
    const trainingPhrases = articleData?.trainingPhrases
      ? [...articleData?.trainingPhrases, { id: uuid(), desc: trainingPhrase }]
      : [{ id: uuid(), desc: trainingPhrase }];

    const newArticle = {
      ...articleData,
      trainingPhrases
    };
    setArticleData(newArticle);
    setTrainingPhrase('');
    if (!autosaving) {
      autoSaveCallback(newArticle);
    }
  };

  const deleteTrainingPhrase = (id) => {
    const newTrainingPhrases = articleData?.trainingPhrases?.filter(
      (tag) => tag.id !== id
    );

    const newArticle = {
      ...articleData,
      trainingPhrases: newTrainingPhrases
    };
    setArticleData(newArticle);
    if (!autosaving) {
      autoSaveCallback(newArticle);
    }
  };

  const handleAddComment = useCallback(
    async (comment, changeComment) => {
      const isPublished = !!articleData?.meta?.published;
      if (comment !== '' && !isPublished) {
        let newId = comments.length + 1;
        const d = new Date();
        let newDateTime = d.toString().substring(0, 24);
        let newComment = {
          id: newId,
          createdAt: newDateTime,
          comment: comment,
          author: userDetails?.email
        };
        changeCommentsArray([...comments, newComment]);
        changeComment('');
        await addCommentAPI(
          orgid,
          articleData?.articleId || articleData?.meta?.articleId,
          comment,
          userDetails?.email
        );
        const createdBy = articleData?.createdBy || userDetails?.email;
        if (articleData?.meta?.isReviewed === false) {
          const data = {
            orgid,
            articleId: articleData?.articleId || articleData?.meta?.articleId,
            createdBy,
            question: articleData?.question,
            meta: { ...articleData?.meta, isReviewed: true }
          };
          contentActions.setUnverifiedKnowledgeArticle(data);
        }
      }
    },
    [
      articleData?.articleId,
      articleData?.createdBy,
      articleData?.meta,
      articleData?.question,
      changeCommentsArray,
      comments,
      contentActions,
      orgid,
      userDetails?.email
    ]
  );

  // Effects

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useDeepCompareEffect(() => {
    let interval;
    if (!didMount.current) {
      if (
        isEdit &&
        articleData &&
        Object?.keys(articleData)?.length > 1 &&
        (!Object.keys(articleData?.meta).includes('published') ||
          (Object.keys(articleData?.meta).includes('published') &&
            articleData?.meta?.published === false))
      ) {
        const prevData = previousArticleData.current;
        interval = setInterval(async () => {
          if (!autosaving) {
            await autoSaveCallback(articleData);
          }
        }, 25000);
        (async () => {
          const questionDistance =
            articleData?.meta?.question?.length > 0 &&
            prevData?.meta?.question?.length > 0
              ? levenshtein.get(
                  articleData?.meta?.question,
                  prevData?.meta?.question
                )
              : -1;

          const sourceDistance =
            articleData?.meta?.source?.length > 0 &&
            prevData?.meta?.source?.length > 0
              ? levenshtein.get(
                  articleData?.meta?.source,
                  prevData?.meta?.source
                )
              : -1;

          const bodyDistance =
            articleData?.meta?.formattedText?.length > 0 &&
            prevData?.meta?.formattedText?.length > 0
              ? levenshtein.get(
                  articleData?.meta?.formattedText,
                  prevData?.meta?.formattedText
                )
              : -1;

          if (
            questionDistance > 15 ||
            sourceDistance > 10 ||
            bodyDistance > 50
          ) {
            if (!autosaving) {
              await autoSaveCallback(articleData);
              previousArticleData.current = articleData;
            }
          }
        })();
      }
    } else {
      didMount.current = true;
    }

    return () => {
      clearInterval(interval);
    };
  }, [articleData, autoSaveCallback, isEdit]);

  // Return:
  return (
    <Wrapper>
      {open && (
        <CommentBox
          open={open}
          handleClose={handleClose}
          agent_type={agent_type}
          documentId={articleData?.articleId || articleData?.meta?.articleId}
          handleAddComment={handleAddComment}
          storeComments={articleComments}
          loading={fetchingComments}
          changeCommentsArray={changeCommentsArray}
          comments={comments}
        />
      )}

      <ConfirmationDialog
        title={modalProps?.title}
        message={modalProps?.message}
        performAction={modalProps?.actionFn}
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleCloseModal}
        actionButton={modalProps?.actionButton}
      />

      <Article>
        <Information isEdit={isEdit} showOptions={showOptions}>
          <Header>
            <GoBackComponent
              isEdit={isEdit}
              autosaveFn={autoSaveCallback}
              articleData={articleData}
              autosaving={autosaving}
            />
            {!isEdit && (
              <Icons>
                {agent_type.toLowerCase() !== 'enduser' &&
                  !articleData?.meta?.published && (
                    <Tooltip title="Info">
                      <IconButton
                        onClick={() =>
                          handleClickOpen(
                            orgid,
                            articleData?.articleId ||
                              articleData?.meta?.articleId
                          )
                        }
                      >
                        <InfoOutlinedIcon
                          color="primary"
                          sx={{ fontSize: 25 }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                {(agent_type.toLowerCase() === 'supervisor' ||
                  agent_type.toLowerCase() === 'superadmin') && (
                  <Button 
                    variant="contained"
                    color="success"
                    style={{ textTransform: 'none' }}
                    onClick={() => {
                      handleOpenModal();
                      handleModalProps({
                        actionButton: 'Publish',
                        title: 'Publish Article',
                        message:
                          articleData?.meta?.published !== undefined
                            ? 'Are you sure you want to publish this article?'
                            : 'This article is not reviewed yet, are you sure you want to publish this article?',
                        actionFn: () => handlePublish()
                      });
                    }}
                  >
                      Publish
                  </Button>
                )}

                {articleData?.meta?.source !== '' && !openCollapse && (
                  <Tooltip title="View Original Source">
                    <IconButton onClick={handleDrawerOpen}>
                      <OpenInNewOutlinedIcon
                        color="primary"
                        sx={{ fontSize: 25 }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </Icons>
            )}
          </Header>

          {!(
            (articleData && Object?.keys(articleData)?.length < 2) ||
            publishingArticle ||
            !articleData
          ) && (
            <TitleComponent
              isEdit={isEdit}
              articleData={articleData}
              changeArticle={changeArticle}
            />
          )}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px'
            }}
          >
            <Source
              isEdit={isEdit}
              articleData={articleData}
              changeArticle={changeArticle}
            />

            <ArticleCheckbox
              isNew={isNew}
              isEdit={isEdit}
              changeArticle={changeArticle}
              articleData={articleData}
              autosaveFn={autoSaveCallback}
            />
          </div>

          {(articleData && Object?.keys(articleData)?.length < 2) ||
          publishingArticle ||
          !articleData ? (
            <LinearProgress />
          ) : (
            <DescriptionComponent
              isEdit={isEdit}
              articleData={articleData}
              changeArticle={changeArticle}
              articleBody={articleBody}
              changeArticleBody={changeArticleBody}
            />
          )}
        </Information>
        {isEdit && (
          <Options isEdit={isEdit} showOptions={showOptions}>
            <Target onTargetChange={onTargetChange} articleData={articleData} />
            <Access onAccessChange={onAccessChange} articleData={articleData} />
            <TagTypeInputComponent
              fieldName={'tags'}
              placeholder={'Enter tags'}
              path={articleData?.meta?.tags}
              articleData={articleData}
              deleteTag={deleteTag}
              addTag={addTag}
              changeTag={changeTag}
              tag={tag}
            />

            <TagTypeInputComponent
              fieldName={'training phrases'}
              placeholder={'Enter training phrases'}
              path={articleData?.trainingPhrases}
              articleData={articleData}
              deleteTag={deleteTrainingPhrase}
              addTag={addTrainingPhrase}
              changeTag={changeTrainingPhrase}
              tag={trainingPhrase}
            />

            <Synonyms
              articleData={articleData}
              title="Synonyms"
              buttonTitle="Add New Synonym"
              autosaveFn={autoSaveCallback}
              changeArticle={changeArticle}
            />
          </Options>
        )}
        <FooterComponent
          changeIsEdit={changeIsEdit}
          changeArticle={changeArticle}
          saveArticle={saveArticleCallback}
          isEdit={isEdit}
          articleData={articleData}
          deleteArticle={deleteArticle}
          addReaction={addReaction}
          qna={qna}
          answer={answer}
          autosaveFn={autoSaveCallback}
          handleOpenModal={handleOpenModal}
          handleModalProps={handleModalProps}
          publishingArticle={publishingArticle}
          handleShowOptions={handleShowOptions}
        />
      </Article>

      <ResourceBox
        tab={tab}
        setTab={setTab}
        setNumberOfResources={setNumberOfResources}
        openCollapse={openCollapse}
      />

      {openCollapse &&
        articleData?.meta?.source?.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/g
        ) && (
          <Iframe
            handleDrawerClose={handleDrawerClose}
            openCollapse={openCollapse}
            articleData={articleData}
          />
        )}
    </Wrapper>
  );
};

// Exports:
export default KnowledgeArticle;