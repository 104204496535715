// Packages:
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';


const fadeInAndRiseUp = keyframes`
  from {
    filter: opacity(0);
    transform: translateY(-10px);
  }
  to {
    filter: opacity(1);
    transform: translateY(0);
  }
`


// Exports:
export const Bar = styled.div`
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.height ? props.height : '4rem')};
  background-color:${(props) => ( props.background ? props.background : '#001D4F')};
  box-shadow: rgba(136, 165, 191, 0.48) 2px 3px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  @media (max-width: 520px) {
    height: ${(props) => (props.height ? props.height : '50px')};
  }
`;

export const Title = styled.div`
  margin-left: 2.125rem;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
    transition: all .25s ease;
  }
  &:active {
    transform: scale(0.99);
    transition: all .25s ease;
  }
  @media (max-width: 520px) {
    margin-left: 2rem;
    font-size: 16px;
  }
  @media (max-width: 360px) {
    margin-left: 1rem;
  }
`;


export const Brand = styled.img`
  height: ${(props) => (props.height ? props.height : props.subdomain === 'sharkzen' ? '40px' : props.subdomain === 'mentorcollective' || props.subdomain === 'tumble' ? '28px' : '22px')};
  cursor: pointer;
  margin: 0rem 1rem;
  @media (max-width: 520px) {
    margin-left: 0.5rem;
  }
  
  @media (max-width: 360px) {
    margin-left: 0.5rem;
  }
`

export const FlexButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`

export const Menu = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  margin-right: 2.125rem;
  font-size: 14px; 
  @media (max-width: 768px) {
    animation: ${ fadeInAndRiseUp } 1s ease;
    width: 100%;
    margin-right: 0;
    position: absolute;
    top: 4rem;
    right: 0;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    display: ${(props) => (props.toggle ? 'flex' : 'none')};
    align-items: center;
    background-color: #001D4F;
    opacity: 0.9;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  @media (max-width: 520px) {
    display: none;
    font-size: 16px;
  }
`;

export const StyledLink = styled(Link)`
  width: fit-content;
  padding: 0 1rem;
  text-decoration: none;
  color: ${(props)=>(props.color || "#ffffff")};
  font-size: 14px;
  &:hover {
    transform: scale(1.03);
    transition: all .25s ease;
  }
  &:active {
    transform: scale(0.97);
    transition: all .25s ease;
  }
  @media (max-width: 768px) {
    width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  @media (max-width: 520px) {
    font-size: 16px;
  }
`;

export const StyledAnchorTag = styled.a`
width: fit-content;
padding: 0 1rem;
text-decoration: none;
color: #ffffff;
font-size: 14px;
&:hover {
  transform: scale(1.03);
  transition: all .25s ease;
}
&:active {
  transform: scale(0.97);
  transition: all .25s ease;
}
@media (max-width: 768px) {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media (max-width: 520px) {
  font-size: 16px;
}
`

export const Toggle = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  
  @media (max-width: 768px) {
    display: flex;
  }
  @media (max-width: 520px) {
    display: none;
  }
`
export const Setting = () =>{
  return <div style={{color:'white', cursor:'pointer'}}>
    <SettingsIcon/>
  </div>
}