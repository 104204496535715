import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    AGENT_STATUS,
    AGENT_TYPE,
    GET_AGENTS,
    HANDLE_AGENTS,
} from "../constants/Endpoint";
toast.configure();

export const getAgentType = async (org, email) => {
    try {
        const result = await axios.get(`${AGENT_TYPE}`, {
            params: { email: `${email}`, org_id: `${org}` },
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


export const agentStatus = async (action, orgId, userId, status) => {
    try {
        const response = await axios.post(
            AGENT_STATUS,
            {
                action: action,
                orgId: orgId,
                userId: userId,
                status: status
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        return response?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};


export const getAgents = async (orgId) => {
    try {
        const result = await axios.get(`${GET_AGENTS}`, {
            params: { org_id: `${orgId}` },
            headers: {
                'x-api-key': process.env.REACT_APP_API_KEY
            }
        });
        return result?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const agentOperations = async (
    action,
    orgId,
    userId,
    firstName,
    lastName,
    role,
    slackId,
    personas,
    userpoolId
) => {
    try {
        const response = await axios.post(
            HANDLE_AGENTS,
            {
                action: action,
                org_id: orgId,
                user_id: userId,
                first_name: firstName,
                last_name: lastName,
                role: role,
                slack_id: slackId,
                personas: personas,
                userpool_id: userpoolId
            },
            {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            }
        );
        if (response?.data?.body?.ResponseMetadata) {
            toast.success('Action performed successfully');
        } else {
            toast.error(response.data.body.split(':')[1]);
        }
        return response?.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
