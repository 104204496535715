import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Suggestions = styled.div`
  background: #FFFFFF;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  width: 50%;
  max-height: ${(props) => (props.isVisible ? '200rem' : 0)};
  margin-top: 0.125rem;
  font-size: 14px;
  border: ${(props) => (props.showAsLink ? 'unset' : '1px solid #E0E0E0')};
  box-shadow: ${(props) => (props.showAsLink ? 'unset' : '0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%);')};
  border-radius: 4px;
  filter: ${(props) => (props.isVisible ? 'opacity(1)' : 'opacity(0)')};
  transition: filter 0.25s ease, max-height 0.25s ease;

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 520px) {
    font-size: 16px;
  }
`;

export const StyledLink = styled(Link)`
  width: ${(props) => (props.showaslink === 'true' ? 'fit-content' : '100%')};
  margin: ${(props) => (props.showaslink === 'true' ? '1rem 0.875rem' : '')};

  &:hover {
    transform scale(1.01);
    transition: all .25s ease;
  }

  &:active {
    transform scale(0.99);
    transition: all .25s ease;
  }

  @media (max-width: 520px) {
    margin: ${(props) => (props.showaslink === 'true' ? '0.5rem 0.675rem' : '')};
  }
`;

export const Suggestion = styled.div`
  font-size: ${(props) => (props.showAsLink ? '18px' : '14px')};
  margin-top: ${(props) => (props.showAsLink ? '20px' : '0')};
  font-weight: ${(props) => (props.showAsLink ? '600' : '400')};
  padding: ${(props) => (props.showAsLink ? '' : '1rem 1.75rem')};
  color: ${(props) => (props.showAsLink ? '#2F80ED' : '#333333')};
  user-select: none;

  div {
    color: #333333;
    font-size: 14px;
    margin-top: 10px;

    p {
      margin: 0;
    }

    @media (max-width: 520px) {
      font-size: 16px;
    }
  }

  &:hover {
    background-color: ${(props) => (props.showAsLink ? 'none' : '#e2f6ff')};
    transition: background-color 0.25s ease;
  }

  @media (max-width: 520px) {
    font-size: 16px;
  }
`;
