// Packages:
import React from 'react';
import { FiExternalLink } from 'react-icons/fi';
import {
  TextField,
  styled
} from '@mui/material';


// Styles:
const StyledTextField = styled(TextField)`
  width: 350px;
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;


// Functions:
const Source = ({ isEdit, articleData, changeArticle }) => {
  return isEdit ? (
    <>
      <span
        style={{
          fontWeight: 'bold',
          fontSize:"14px",
          marginRight: '0.75rem',
          color: '#333333'
        }}
      >
        source:
      </span>
      <StyledTextField 
        id="article-sources" 
        variant="outlined" 
        placeholder="Enter Source"
        defaultValue={articleData?.meta?.source}
        onChange={(event) => {
          const newArticleData = {
            ...articleData,
            meta: {
              ...articleData.meta,
              source: event.target.value
            }
          };
          changeArticle(newArticleData);
        }}
        size="small"
      />
    </>
  ) : articleData?.meta?.source ? (
    <a
      target="_blank"
      href={`${articleData?.meta?.source}`}
      rel="noreferrer"
      style={{
        marginBottom: '0.75rem',
        marginLeft: '0.75rem',
        fontWeight: 700,
        fontSize: '0.9rem'
      }}
    >
      SOURCE <FiExternalLink style={{ marginBottom: '-0.05rem' }} />
    </a>
  ) : (
    <></>
  );
};

export default Source;
