// Packages:
import styled from 'styled-components'


export const Wrapper = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    gap: 0.5rem;
    margin-right: 0.5rem;
  }
`