import { toast } from 'react-toastify';
import sanitizeHtml from 'sanitize-html';
import { urlify } from '../../urlify';

const submitForReview = async (
  articleData,
  userEmail,
  orgid,
  setKnowledgeArticleFn,
  changeIsNew,
  changeIsEdit,
  userDetails,
  deleteDraft,
  setArticleData
) => {
  const articleId = articleData?.articleId || articleData?.meta?.articleId;
  const createdBy = articleData?.createdBy || userEmail;
  const data = {
    orgid,
    articleId,
    createdBy,
    trainingPhrases: articleData?.trainingPhrases,
    question: articleData?.question || articleData?.display_name,
    meta: {
      ...articleData?.meta,
      published: false,
      isReviewed: false,
      firstLine:
        articleData?.meta?.formattedText?.length < 105
          ? sanitizeHtml(urlify(articleData?.meta?.formattedText), {
              allowedTags: [],
              allowedAttributes: {}
            })
          : sanitizeHtml(urlify(articleData?.meta?.formattedText.slice(0, 100)), {
              allowedTags: [],
              allowedAttributes: {}
            })
    }
  };
  if (!data?.question || data?.question?.trim()?.length === 0) {
    toast.error('Title cannot be empty');
    throw new Error('Title cannot be empty');
  } else if (!data?.meta?.target) {
    toast.error('Select the target user');
    throw new Error('Select the target user');
  } else if (!data?.meta?.access) {
    toast.error('Select privacy level');
    throw new Error('Select privacy level');
  } else if (
    !data?.meta?.formattedText ||
    data?.meta?.formattedText?.trim()?.length < 1
  ) {
    toast.error('Description cannot be empty');
    throw new Error('Description cannot be empty');
  } else {
    changeIsEdit(false);
    changeIsNew(false);
    try {
      await setKnowledgeArticleFn(data);
      const deleteData = {
        orgid,
        target: articleData?.meta?.target,
        access: articleData?.meta?.access,
        articleId: articleData?.articleId || articleData?.meta?.articleId,
        createdBy: articleData?.createdBy || userDetails?.email
      };
      // console.log({ saveData: data, deleteData });
      await deleteDraft(deleteData);

      setArticleData((prev) => ({
        ...prev,
        meta: { ...prev?.meta, published: false, isReviewed: false }
      }));
    } catch (error) {
      toast.error('Could not submit, some error occured');
      console.error(error);
    }
  }
};

export default submitForReview;
