import { logEntityChangesAPI } from "../api";

async function logEntityChanges(orgid, entity, entityid, actor, action, changes) {
  try {
    await logEntityChangesAPI(orgid, entity, {
      entityid, actor,
      action, changes,
      timestamp: new Date().toUTCString()
    });
  } catch (error) {
    console.error(`Error logging changes for ${entity}`);
  }
}

export default logEntityChanges;