// Packages:
import styled from 'styled-components'


// Exports:
export const Wrapper = styled.div`
    background: #f4f4f9;
    display: flex;
    flex-direction: column; 
    align-items: center;
    padding: 1.5rem 1rem;
    width: 50%;
    margin: 0 auto;
    margin-top: 0.5rem;
    border-radius: 0.5rem;
`

export const Title = styled.div`
    text-align: center;
    font-size: 1.5rem;
    color: #333333;
    margin-bottom: 16px;
`

export const Subtitle = styled.div`
    text-align: center;
    font-size: 14px;
    color: #646464;
    margin-bottom: 12px;
`

export const Group = styled.div`
    display: flex; 
    gap: 1rem; 
    width: 100%;
`