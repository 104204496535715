
export const ACTION={
    GET_AVAILABILITY:'GET AVAILABILITY',
    BOOK_SLOT:'BOOK SLOT'
  }

export const DEFAULT_OUTPUT_USERID = {
    key: "userId",
    value: {
      fieldName: null,
      value: null,
    },
  };
  export const DEFAULT_OUTPUT_ACCOUNTID = {
    key: "accountIdType",
    value: {
      fieldName: null,
      value: null,
    },
  };
  export const DEFAULT_OUTPUT_EMAIL = {
    key: "email",
    value: {
      fieldName: null,
      value: null,
    },
  }; 
  
  export const DEFAULT_OUTPUT_FIRSTNAME = {
    key: "firstName",
    value: {
      fieldName: null,
      value: null,
    },
  }; 

  export const DEFAULT_OUTPUT_LASTNAME = {
    key: "lastName",
    value: {
      fieldName: null,
      value: null,
    },
  }; 

  export const DEFAULT_OUTPUT_DATETIME = {
    key: "dateTime",
    value: {
      fieldName: null,
      value: null,
    },
  }; 
  export const DEFAULT_OUTPUT_TIMEZONE = {
    key: "timeZone",
    value: {
      fieldName: null,
      value: null,
    },
  }; 

export const DEFAULT_OUTPUT_PHONENUMBER = {
    key: "phoneNumber",
    value: {
      fieldName: null,
      value: null,
    },
  };   
  