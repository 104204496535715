// Packages:
import { useState } from 'react'


// Functions:
const useInput = (initialValue) => {
  // State:
  const [ value, setValue ] = useState(initialValue)

  // Return:
  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: event => {
        event.preventDefault()
        event.stopPropagation()
        setValue(event.currentTarget.value)
      }
    }
  }
}


// Exports:
export default useInput
