import React from 'react';
import { useState } from 'react';
import { TextField, styled, Button } from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { toast } from 'react-toastify';

import Dropdown from './Dropdown';
import BooleanField from './BooleanField';
import CustomTextField from './CustomTextField';
import ArrayField from './ArrayField';
import { CustomFieldsWrapper, FieldWrapper, CustomFieldsFormWrapper } from './styles';

const StyledTextField = styled(TextField)`
  font-size: 14px;
  & .MuiOutlinedInput-input {
    font-size: 14px;
  }
`;

const FIELD_TYPES = {
  'boolean': {
    initialValue: true,
    typeChecker: (value) => typeof value === 'boolean',
    component: (props) => <BooleanField {...props} />
  },
  'string': {
    initialValue: '',
    typeChecker: (value) => typeof value === 'string',
    component: (props) => <CustomTextField {...props} />
  },
  'array': {
    initialValue: [],
    typeChecker: (value) => Array.isArray(value),
    component: (props) => <ArrayField {...props} />
  }
};

const mapFieldToComponent = (field, customFields, onChange, removeField) => {
  for (const type in FIELD_TYPES) {
    if (!FIELD_TYPES[type]['typeChecker'](customFields[field])) {
      continue;
    }
    const props = {
      field,
      value: customFields[field],
      onChange
    };
    return (
      <FieldWrapper key={field}>
        <span
          style={{ marginRight: '1rem', verticalAlign: 'middle', fontWeight: 'bold' }}>
          {field}:
        </span>
        {FIELD_TYPES[type]['component'](props)}
        <Button
          size="small"
          color="error"
          variant="contained"
          startIcon={<RemoveOutlinedIcon />}
          sx={{ textTransform: 'none', marginLeft: '1rem' }}
          onClick={() => removeField(field)}
        >
          Remove {field}
        </Button>
      </FieldWrapper>
    );
  }
  return null;
};

const CustomMetadata = ({ articleData, changeArticle }) => {
  const [fieldName, setFieldName] = useState('');
  const [fieldType, setFieldType] = useState('boolean');
  const customFields = { ...(articleData?.meta?.customFields ?? {}) };

  const addField = () => {
    if (fieldName.length < 3) {
      toast.warn('Field name should be more than 2 characters long');
      return;
    }
    if (customFields.hasOwnProperty(fieldName)) {
      toast.error(`${fieldName} already exists`);
      return;
    }
    changeArticle({
      ...articleData,
      meta: {
        ...articleData.meta,
        customFields: {
          ...customFields,
          [fieldName]: FIELD_TYPES[fieldType]['initialValue']
        }
      }
    });
    setFieldName('');
  }

  const removeField = (field) => {
    if (!customFields.hasOwnProperty(field)) {
      toast.error(`${field} does not exist`);
      return;
    }
    delete customFields[field];
    changeArticle({
      ...articleData,
      meta: {
        ...articleData.meta,
        customFields: {
          ...customFields,
        }
      }
    });
  }

  const onChange = (field, value) => {
    changeArticle({
      ...articleData,
      meta: {
        ...articleData.meta,
        customFields: {
          ...customFields,
          [field]: value
        }
      }
    });
  };

  const mapCallback = (field) =>
    mapFieldToComponent(field, customFields, onChange, removeField);

  return (
    <>
      <CustomFieldsWrapper>
        {Object.keys(customFields)
          .map(mapCallback)}
      </CustomFieldsWrapper>
      <CustomFieldsFormWrapper>
        <StyledTextField
          id="custom-field-name"
          variant="outlined"
          placeholder='Enter field name'
          value={fieldName}
          onChange={(e) => setFieldName(e.target.value)}
          onKeyDown={(event) => (event.key === 'Enter' ? addField() : null)}
          size="small"
        />
        <Dropdown type={fieldType} changeType={setFieldType} />
        <Button
          size="small"
          variant="contained"
          startIcon={<AddOutlinedIcon />}
          sx={{ textTransform: 'none' }}
          onClick={() => addField()}
        >
          Add Custom Field
        </Button>
      </CustomFieldsFormWrapper>
    </>
  );
}

export default CustomMetadata;