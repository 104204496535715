export const CREATE_DRAFT_OPERATION = 'createDraft';
export const SAVE_DRAFT_OPERATION = 'saveDraft';
export const DELETE_DRAFT_OPERATION = 'deleteDraft';
export const SUBMIT_DRAFT_OPERATION = 'submitDraft';
export const REJECT_DRAFT_OPERATION = 'rejectDraft';
export const PUBLISH_DRAFT_OPERATION = 'publishDraft';
export const ARCHIVE_ARTICLE_OPERATION = 'archive'; // archive a published version

export const ARTICLE_IS_DRAFT = 'DRAFT';
export const ARTICLE_IS_UNDER_REVIEW = 'UNDER_REVIEW';
export const ARTICLE_IS_PUBLISHED = 'PUBLISHED';

export const ACCESS_IS_PUBLIC = 'public';
export const ACCESS_IS_PRIVATE = 'private';

export const TARGET_IS_ENDUSER = 'endUser';
export const TARGET_IS_AGENT = 'agent';
export const TARGET_IS_PARTNER = 'partner';

export const REQUIRED_FIELDS = ['articleId', 'orgId', 'meta']
export const REQUIRED_META_FIELDS = ['access', 'target', 'createdBy',
                                      'title', 'status', 'importFromSource']